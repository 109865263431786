import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledDefaultImage = styled('div')({
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '56px',
  maxWidth: '56px',
  height: '56px',
  backgroundColor: palette.desertStorm,

  '&.big': {
    minWidth: '217px',
    maxWidth: '217px',
    height: '217px',
  },

  '&.medium': {
    minWidth: '104px',
    maxWidth: '217px',
    height: '104px',
  },

  [theme.breakpoints.down('md')]: {
    '&.medium': {
      maxWidth: '104px',
    },
  },
});
