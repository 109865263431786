import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentsList from '../../components/CommentsList';
import Container from '../../components/Container';
import Provider from '../../components/Provider';
import BasicTabs from '../../components/UI/Tabs';
import { USER_PROFILE_TAB } from '../../constants/constants';
import { useGetFavouriteServices, useGetReviewsWritten } from '../../graphql/hooks/useQueries';
import TabReservations from './components/TabReservations';

const UserProfile = ({ setModalData, setIsModalOpen, setModalType, handleZoomImage }) => {
  const { data: arrayOfReviews, loading: reviewsLoading } = useGetReviewsWritten();
  const reviews = arrayOfReviews?.getReviewsWritten?.rows;
  const { data } = useGetFavouriteServices();
  const { t } = useTranslation();

  return (
    <>
      <BasicTabs
        tabs={USER_PROFILE_TAB}
        tabPanels={[
          <>
            <CommentsList
              loading={reviewsLoading}
              reviews={reviews}
              type={'withProviderPhoto'}
              className={'fontVariant1'}
            />
          </>,
          <>
            <Container direction='column'>
              {data?.getFavouriteServices?.rows?.length > 0 ? (
                data?.getFavouriteServices?.rows?.map((service) => {
                  return (
                    <Provider
                      serviceId={service.id}
                      user={{
                        id: service.user.id,
                        contacts: service.user.contacts,
                        photo: service.user.photo,
                        firstName: service.user.firstName,
                        lastName: service.user.lastName,
                        reviewsStatistic: service.user.reviewsStatistic,
                        servicePhotos: service.user.servicePhotos,
                      }}
                      price={service.price}
                      pricePer={service.pricePer}
                      currencyCode={service.currencyCode}
                      subcategories={service?.subcategories}
                      description={service?.description}
                      isFavourite={true}
                      key={service.id}
                      setModalData={setModalData}
                      setIsModalOpen={setIsModalOpen}
                      setModalType={setModalType}
                      handleZoomImage={handleZoomImage}
                      {...service}
                    />
                  );
                })
              ) : (
                <Typography variant={'body2Semibold'}>{t('searchResultsEmpty')}</Typography>
              )}
            </Container>
          </>,
          <TabReservations />,
        ]}
      />
    </>
  );
};

export default UserProfile;
