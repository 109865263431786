import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlInput } from '../../../components/UI/Input';
import { client } from '../../../graphql/client';
import { GET_COUNTRIES } from '../../../graphql/queries';
import {
  getNameFromTranslations,
  getObjectNameAndIdFromArray,
} from '../../../utils/getDifferentFormatOfData';
import { ChooseServiceLocationStyled, StyledSelect } from '../styles';

export const ChooseServiceLocation = ({
  serviceCity,
  serviceCountry,
  control,
  setValue,
  errors,
  watch,
}) => {
  const { t, i18n } = useTranslation();
  const [countryState, setCountryState] = useState({
    name: getNameFromTranslations(serviceCountry?.translations, i18n),
    id: serviceCountry?.id || '',
  });
  const [stateCity, setStateCity] = useState({
    name: getNameFromTranslations(serviceCity?.translations, i18n),
    id: serviceCity?.id || '',
  });
  const countriesFromCache = client.readQuery({
    query: GET_COUNTRIES,
  });

  useEffect(() => {
    if (countryState.name) {
      const localeName = getObjectNameAndIdFromArray(countriesFromCache?.getCountries, i18n).find(
        (element) => {
          return element?.id === countryState?.id;
        },
      )?.name;
      setCountryState((last) => {
        return {
          ...last,
          name: localeName,
        };
      });
    }
    if (stateCity.name) {
      const localeName = getObjectNameAndIdFromArray(
        countriesFromCache?.getCountries?.find((country) => country.id === countryState.id)?.cities,
        i18n,
      )?.find((element) => {
        return element.id === stateCity.id;
      })?.name;
      setStateCity((last) => {
        return {
          ...last,
          name: localeName,
        };
      });
    }
  }, [i18n.resolvedLanguage]);

  const handleChangeCountry = (event) => {
    const { value } = event.target;
    const selectedId = getObjectNameAndIdFromArray(countriesFromCache?.getCountries, i18n).find(
      (element) => {
        return element.name === value;
      },
    ).id;
    setValue('selectCountry', selectedId);
    setCountryState({
      name: value,
      id: selectedId,
    });
    setStateCity({
      name: t('chooseCity'),
      id: '',
    });
  };

  const handleChangeCity = (event) => {
    const { value } = event.target;
    const selectedId = getObjectNameAndIdFromArray(
      countriesFromCache?.getCountries?.find((country) => country.id === countryState.id)?.cities,
      i18n,
    ).find((element) => {
      return element.name === value;
    }).id;
    setValue('selectCity', selectedId);
    setStateCity({
      name: value,
      id: selectedId,
    });
  };

  return (
    <ChooseServiceLocationStyled>
      <Typography variant={'h5'} marginBottom={'10px'}>
        {t('whatLocationYouProvideService')}
      </Typography>
      <StyledSelect
        control={control}
        name={'selectCountry'}
        value={countryState.name || t('chooseCountry')}
        onChange={handleChangeCountry}
        variant={'outlined'}
        displayEmpty
        dataArray={getObjectNameAndIdFromArray(countriesFromCache?.getCountries, i18n) || []}
        selectTitle={t('chooseCountry')}
        error={errors?.selectCountry?.message}
        className={errors?.selectCountry?.message && 'errorSelect'}
      />
      {countryState?.name && (
        <StyledSelect
          control={control}
          name={'selectCity'}
          value={stateCity.name || t('chooseCity')}
          onChange={handleChangeCity}
          variant={'outlined'}
          displayEmpty
          dataArray={
            getObjectNameAndIdFromArray(
              countriesFromCache?.getCountries?.find((country) => country.id === countryState.id)
                ?.cities,
              i18n,
            ) || []
          }
          selectTitle={t('chooseCity')}
          error={errors?.selectCity?.message}
          className={errors?.selectCity?.message && 'errorSelect'}
        />
      )}
      {countryState?.name && stateCity?.name && (
        <Box margin='12px 0'>
          <ControlInput
            control={control}
            value={watch('address')}
            name={'address'}
            placeholder={t('address')}
          />
        </Box>
      )}
    </ChooseServiceLocationStyled>
  );
};
