import { gql } from '@apollo/client';

import { CATEGORY, USER } from '../fragments';

export const CREATE_SERVICE = gql`
  ${USER}
  ${CATEGORY}
  mutation createService($input: ServiceInput!) {
    createService(input: $input) {
      id
      user {
        ...UserFragment
      }
      category {
        ...CategoryFragment
      }
      price
      pricePer
      currencyCode
      translations {
        description
        languageCode
        languageId
      }
      createdAt
      subcategories {
        id
        translations {
          languageCode
          name
        }
      }
      subservices {
        currencyCode
        translations {
          description
          languageCode
          languageId
        }
        id
        price
        pricePer
      }
      enableBooking
      servicePhotos {
        path
        about
      }
      certificatePhotos {
        path
        about
      }
      workplaces {
        id
        translations {
          languageCode
          name
        }
      }
      city {
        id
        translations {
          languageCode
          name
        }
        country {
          id
          translations {
            languageCode
            name
          }
        }
      }
      country {
        id
        translations {
          languageCode
          name
        }
        cities {
          id
          translations {
            languageCode
            name
          }
        }
      }
    }
  }
`;
