import { Typography } from '@mui/material';

import Container from '../../components/Container';
import { NotFoundWrapper } from './styles';

const NotFound = () => {
  return (
    <Container>
      <NotFoundWrapper>
        <Typography variant='h2' marginBottom='20px'>
          Not Found
        </Typography>
        <Typography variant='body1'>Something is going wrong...</Typography>
      </NotFoundWrapper>
    </Container>
  );
};

export default NotFound;
