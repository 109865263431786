import { Button as MuiButton, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Container from '../../components/Container';
import ModalComponent from '../../components/Modal';
import ProfileForm from '../../components/ProfileForm';
import Divider from '../../components/UI/Divider';
import { useDeleteAccountRequest } from '../../graphql/hooks/useMutations';
import { useGetCurrentUser } from '../../graphql/hooks/useQueries';
import { useAuth, useImageZoom, useToast } from '../../hooks';
import DeleteIcon from '../../icons/DeleteIcon';
import theme from '../../theme';
import palette from '../../theme/palette';
import { getObjectNameAndIdFromArray } from '../../utils/getDifferentFormatOfData';
import ProviderProfile from './ProviderProfile';
import { ProfileWrapper } from './styles';
import UserProfile from './UserProfile';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { setUserInfo, logout } = useAuth();
  const [variant, setToastConfig] = useToast();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [userLocalState, setUserLocalState] = useState();
  const [dataFromModal, setDataFromModal] = useState([]);
  const [userLanguages, setUserLanguages] = useState(userLocalState);
  const [modalData, setModalData] = useState();
  const { loading: userLoading } = useGetCurrentUser({
    onError: (data) => {
      if (data?.message === 'UNAUTHORIZED') {
        logout();
      }
    },
    onCompleted: (data) => {
      setUserLocalState(data?.currentUser);
      setDataFromModal(
        getObjectNameAndIdFromArray(data?.currentUser?.services[0]?.subcategories, i18n),
      );

      const result = data?.currentUser;
      setUserInfo({
        id: result?.id,
        firstName: result?.firstName,
        lastName: result?.lastName,
        photo: result?.photo,
        city: result?.city,
        locale: result?.locale,
        email: result?.email,
        role: result?.role,
        tariffPlan: result?.tariffPlan,
      });
      setUserLanguages(result.userLanguages);
    },
  });
  const [deleteAccountRequest] = useDeleteAccountRequest({
    onCompleted: () => setToastConfig(variant.confirmDeleteAccount),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('signIn');
  const [modalDataArray] = useState([]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };
  const { handleZoomImage } = useImageZoom(setIsModalOpen, setModalType, setModalData);

  const sendDeletingRequest = async () => {
    await deleteAccountRequest();
  };

  useEffect(() => {
    if (userLocalState?.service) {
      setDataFromModal(getObjectNameAndIdFromArray(userLocalState?.service?.subcategories, i18n));
    }
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    const handleModalOpen = (parameters, type) => {
      if (searchParameters.has(parameters)) {
        setIsModalOpen(true);
        setModalType(type);
        searchParameters.delete(parameters);
        setSearchParameters(searchParameters);
      }
    };

    handleModalOpen('changePassword', 'changePassword');
    handleModalOpen('deleteAccount', 'deleteAccount');
  }, [searchParameters]);

  return (
    <Container
      sx={{
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
      }}
    >
      <ProfileWrapper>
        {userLoading ? (
          <CircularProgress
            sx={{
              color: palette.primary,
            }}
          />
        ) : userLocalState && userLocalState.role === 'PROVIDER' ? (
          <>
            <ProfileForm
              userLocalState={userLocalState}
              userLanguages={userLanguages}
              setUserLanguages={setUserLanguages}
              setUserLocalState={setUserLocalState}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
            />
            <ProviderProfile
              userLocalState={userLocalState}
              setUserLocalState={setUserLocalState}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
              setModalData={setModalData}
            />
            <Divider margin={'30px 0'} />
            <MuiButton
              className={'deleteAccount'}
              onClick={sendDeletingRequest}
              disableRipple
              disableFocusRipple
            >
              <DeleteIcon />
              {t('deleteAccount')}
            </MuiButton>
          </>
        ) : userLocalState && userLocalState.role === 'USER' ? (
          <>
            <ProfileForm
              userLocalState={userLocalState}
              setUserLocalState={setUserLocalState}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
            />
            <UserProfile
              setModalData={setModalData}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
              handleZoomImage={handleZoomImage}
            />
            <Divider margin={'30px 0'} />
            <MuiButton
              className={'deleteAccount'}
              onClick={sendDeletingRequest}
              disableRipple
              disableFocusRipple
            >
              <DeleteIcon />
              {t('deleteAccount')}
            </MuiButton>
          </>
        ) : (
          ''
        )}
      </ProfileWrapper>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        setModalType={setModalType}
        modalDataArray={modalDataArray}
        dataFromModal={dataFromModal}
        setDataFromModal={setDataFromModal}
        modalData={modalData}
        languageModalProps={{
          userLanguages,
          setUserLanguages,
        }}
      />
    </Container>
  );
};

export default Profile;
