import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button as MuiButton, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePasswordChangeRequest, useUpdateProfile } from '../../graphql/hooks/useMutations';
import { useGetLocales } from '../../graphql/hooks/useQueries';
import { useAuth, useToast } from '../../hooks';
import { useSetImage } from '../../hooks/useSetImage';
import AlertIcon from '../../icons/AlertIcon';
import ChangeIcon from '../../icons/ChangeIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import theme from '../../theme';
import {
  getIdFromArray,
  transformLocalesToArrayTranslation,
  transformLocalesToObjectWithUserData,
} from '../../utils/getDifferentFormatOfData';
import {
  updateProviderProfileValidationSchema,
  updateUserProfileValidationSchema,
} from '../../validation/schema/updateProfileValidationSchema';
import DefaultImage from '../DefaultImage';
import Button from '../UI/Button';
import ButtonAdd from '../UI/Button/buttonAdd';
import { ControlInput } from '../UI/Input';
import { SelectLanguages } from './components';
import {
  AddPhotoButton,
  ButtonManage,
  ButtonWrapper,
  ControlsBox,
  FieldsWrapper,
  Form,
  Heading,
  Image,
  PhotoWrap,
  StyledProfileForm,
  StyledRemoveIcon,
  SymbolCounter,
  TopPanel,
} from './styles';

const ProfileForm = ({
  userLocalState,
  userLanguages,
  setUserLanguages,
  setUserLocalState,
  setIsModalOpen,
  setModalType,
}) => {
  const { data: locales } = useGetLocales();
  const [symbolCount, setSymbolCount] = useState({
    ru: 255,
    sr: 255,
    en: 255,
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setUserInfo } = useAuth();
  const [variant, setToastConfig, setSpecificErrorInformation] = useToast();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const userInfoTranslations = transformLocalesToObjectWithUserData(
    locales?.getLocales,
    userLocalState?.translations,
  );
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(
      userLocalState.role === 'PROVIDER'
        ? updateProviderProfileValidationSchema
        : updateUserProfileValidationSchema,
    ),
    defaultValues: {
      name: userLocalState?.firstName,
      surname: userLocalState?.lastName,
      email: userLocalState?.email,
      companyName: userLocalState?.companyName || '',
      about: {
        ru: userInfoTranslations?.ru?.userTranslate?.about || '',
        sr: userInfoTranslations?.sr?.userTranslate?.about || '',
        en: userInfoTranslations?.en?.userTranslate?.about || '',
      },
      userLanguageIds: getIdFromArray(userLanguages, i18n) || [],
      photo: '',
    },
  });

  const [image, setImage] = useState(
    userLocalState?.photo ? `${process.env.REACT_APP_API_HOST}/${userLocalState.photo}` : null,
  );
  const [updateProfile] = useUpdateProfile();
  const [changePass] = usePasswordChangeRequest({
    onCompleted: () => setToastConfig(variant.requestForChangePasswordSuccessfullySent),
  });
  const [isDeletedProfileImage, setIsDeletedProfileImage] = useState(false);

  const { handleSetImage } = useSetImage('photo', setImage, setValue, clearErrors, setError);

  const lengthRu = watch('about.ru')?.length || 0;
  const lengthSr = watch('about.sr')?.length || 0;
  const lengthEn = watch('about.en')?.length || 0;

  useEffect(() => {
    setSymbolCount({
      ru: 255 - lengthRu,
      sr: 255 - lengthSr,
      en: 255 - lengthEn,
    });
  }, [lengthRu, lengthSr, lengthEn]);

  useEffect(() => {
    setValue('userLanguageIds', getIdFromArray(userLanguages, i18n));
  }, [userLanguages]);

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit = async ({ name, surname, email, about, companyName, userLanguageIds, photo }) => {
    const formData = new FormData();

    const translations = transformLocalesToArrayTranslation(locales?.getLocales, about, 'about');

    formData.append('photo', photo);
    const objectArguments = {
      firstName: name,
      lastName: surname,
      translations,
      companyName,
      email,
      userLanguageIds,
      ...((isDeletedProfileImage || photo) && {
        photo: photo ? formData.get('photo') : null,
      }),
    };
    await updateProfile({
      variables: {
        input: objectArguments,
      },
    })
      .then((response) => {
        const result = response?.data?.updateProfile;
        const changedFields = {
          email: result?.email,
          firstName: result?.firstName,
          lastName: result?.lastName,
          translations: result?.translations,
          companyName: result?.companyName,
          userLanguages: result.userLanguages,
          photo: result?.photo,
        };
        setUserLocalState((previous) => ({
          ...previous,
          ...changedFields,
        }));
        setUserInfo((previous) => ({
          ...previous,
          ...changedFields,
        }));
        setToastConfig(variant.success);
      })
      .catch((error_) => {
        console.log(error_.message);
        setSpecificErrorInformation(error_.message);
        setToastConfig(variant.error);
      });
  };

  const resetData = () => {
    navigate(-1);
  };

  const handleImage = (event) => {
    const uploadImage = event.target.files[0];
    handleSetImage(uploadImage);
    setIsDeletedProfileImage(false);
  };

  const deletePhoto = () => {
    setValue('photo', null);
    setImage(null);
    setIsDeletedProfileImage(true);
  };

  const handleChangePass = async () => {
    await changePass();
  };

  const ButtonManageBlock = () => (
    <ButtonManage>
      <MuiButton
        className={'cancel'}
        variant={'text'}
        onClick={resetData}
        disableRipple
        disableFocusRipple
      >
        {t('cancel')}
      </MuiButton>
      {!isMobile ? (
        <MuiButton
          className={'save'}
          variant={'text'}
          type={'submit'}
          disableRipple
          disableFocusRipple
        >
          {t('save')}
        </MuiButton>
      ) : (
        <Button className={'saveMobile'} type={'submit'} title={t('save')} />
      )}
    </ButtonManage>
  );

  return (
    <StyledProfileForm>
      <Helmet>
        <title>{t('profile')}</title>
      </Helmet>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TopPanel>
          <Heading variant='h1'>{t('profile')}</Heading>
          {!isMobile && <ButtonManageBlock />}
        </TopPanel>
        <FieldsWrapper>
          <ControlsBox>
            <ControlInput
              control={control}
              name={'name'}
              placeholder={t('name')}
              defaultValue={userLocalState?.firstName}
              maxLength={20}
            />
            <ControlInput
              control={control}
              name={'surname'}
              placeholder={t('surname')}
              defaultValue={userLocalState?.lastName}
              maxLength={20}
            />
            <ControlInput
              control={control}
              name={'email'}
              placeholder={'Email@mail.com'}
              defaultValue={userLocalState?.email}
              maxLength={50}
            />
            {userLocalState.role === 'PROVIDER' && (
              <>
                <ControlInput
                  control={control}
                  name={'companyName'}
                  placeholder={t('companyName')}
                  value={watch('companyName')}
                  maxLength={50}
                />
                <Box mb={'15px'}>
                  <AlertIcon
                    sx={{
                      marginBottom: '-7px',
                      marginRight: '5px',
                    }}
                  />
                  <Typography component={'span'} variant={'body3'}>
                    {t('profileAboutRequiredMessage')}
                  </Typography>
                </Box>
                <ControlInput
                  control={control}
                  name={'about.ru'}
                  rows={7}
                  multiline
                  placeholder={t('aboutMeRu')}
                  defaultValue={userInfoTranslations?.ru?.userTranslate?.about}
                  maxLength={255}
                />
                <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${
                  symbolCount.ru
                } ${t('symbol', {
                  count: symbolCount.ru,
                })}`}</SymbolCounter>
                <ControlInput
                  control={control}
                  name={'about.sr'}
                  rows={7}
                  multiline
                  placeholder={t('aboutMeSr')}
                  defaultValue={userInfoTranslations?.sr?.userTranslate?.about}
                  maxLength={255}
                />
                <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${
                  symbolCount.sr
                } ${t('symbol', {
                  count: symbolCount.sr,
                })}`}</SymbolCounter>
                <ControlInput
                  control={control}
                  name={'about.en'}
                  rows={7}
                  multiline
                  placeholder={t('aboutMeEn')}
                  defaultValue={userInfoTranslations?.en?.userTranslate?.about}
                  maxLength={255}
                />
                <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${
                  symbolCount.en
                } ${t('symbol', {
                  count: symbolCount.en,
                })}`}</SymbolCounter>
                <SelectLanguages
                  userLanguages={userLanguages}
                  setUserLanguages={setUserLanguages}
                  setValue={setValue}
                  setModalType={setModalType}
                  setIsModalOpen={setIsModalOpen}
                  error={errors?.userLanguageIds?.message}
                />
              </>
            )}
            <MuiButton
              className={'changePass'}
              onClick={handleChangePass}
              disableRipple
              disableFocusRipple
            >
              <ChangeIcon />
              {t('changePass')}
            </MuiButton>
            {isMobile && <ButtonManageBlock />}
          </ControlsBox>
          <ButtonWrapper>
            <AddPhotoButton type='button' disableRipple disableFocusRipple>
              <ControlInput
                control={control}
                name={'photo'}
                type={'file'}
                accept={'image/*'}
                wrapperClassName={'hidden'}
                onChange={handleImage}
              />
              {!isDeletedProfileImage && image ? (
                <PhotoWrap>
                  <StyledRemoveIcon onClick={deletePhoto}>
                    <RemoveIcon />
                  </StyledRemoveIcon>
                  <Image src={image} alt={image} />
                </PhotoWrap>
              ) : (
                <DefaultImage className={'big'} />
              )}
            </AddPhotoButton>
            <ButtonAdd title={userLocalState?.photo || image ? 'changePhoto' : 'addPhoto'} />
            <Typography component={'span'} fontSize={10} align={'center'}>
              {t('imageSize')}
            </Typography>
            {errors?.photo?.message && (
              <Typography
                fontSize={12}
                color={'#D32F2F'}
                maxWidth={'189px'}
                align={'center'}
                lineHeight={'16px'}
              >
                {t(errors?.photo?.message)}
              </Typography>
            )}
          </ButtonWrapper>
        </FieldsWrapper>
      </Form>
    </StyledProfileForm>
  );
};

export default ProfileForm;
