import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import { useGetPage } from '../../graphql/hooks/useQueries';
import { AboutContent, Heading, MapWrapper, Wrapper } from './styles';

const About = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetPage({
    variables: {
      url: 'about-us',
    },
  });

  const infoContent = data?.getPage?.translations?.find(
    (trans) => trans.languageCode === i18n.resolvedLanguage,
  );

  return (
    <Container direction='column'>
      <Helmet>
        <title>{t('about')}</title>
      </Helmet>
      <Heading
        variant='h1'
        dangerouslySetInnerHTML={{
          __html: infoContent?.title,
        }}
      />
      <AboutContent
        dangerouslySetInnerHTML={{
          __html: infoContent?.content,
        }}
      />
      <Wrapper>
        <Box>
          <Typography variant='body1' mb='10px'>
            SVE U REDU DOO NOVI SAD
          </Typography>
          <Typography variant='body1' mb='10px'>
            ZMAJ JOVINA 25, NOVI SAD, 21101, SRBIJA
          </Typography>
          <Typography variant='body1' mb='10px'>
            PIB: 113305047
          </Typography>
          <Typography variant='body1' mb='10px'>
            MB: 21842753
          </Typography>
        </Box>
        <MapWrapper>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d702.1357003186964!2d19.84726073756699!3d45.25703729391262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b1066280d0621%3A0xababe7751297fbe2!2z0JfQvNCw0Zgg0IjQvtCy0LjQvdCwIDI1LCDQndC-0LLQuCDQodCw0LQsINCh0LXRgNCx0LjRjw!5e0!3m2!1sru!2sua!4v1683288173397!5m2!1sru!2sua'
            width='100%'
            height='100%'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title={'map'}
          />
        </MapWrapper>
      </Wrapper>
    </Container>
  );
};

export default About;
