import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const CREATE_REVIEW = gql`
  ${USER}
  mutation createReview($input: ReviewInput!) {
    createReview(input: $input) {
      id
      text
      rating
      createdAt
      author {
        ...UserFragment
      }
      user {
        ...UserFragment
      }
    }
  }
`;
