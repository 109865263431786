/* eslint-disable unicorn/no-thenable */
import * as yup from 'yup';

import { ONLINE_WORKPLACE_ID } from '../../constants/constants';

export const createServiceSchema = yup.object().shape({
  categoryId: yup.string().required('REQUIRE_MESSAGE'),
  description: yup.object().shape({
    ru: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
    sr: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
    en: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
  }),
  price: yup
    .string()
    .required('REQUIRE_MESSAGE')
    .matches(/^[1-9]\d*$/, 'ONLY_NUMBERS')
    .nullable(true),
  subcategoryIds: yup.array().min(1, 'MIN_SUBCATEGORIES').required('REQUIRE_MESSAGE'),
  subservices: yup
    .array()
    .of(
      yup.object().shape({
        price: yup
          .string()
          .required('REQUIRE_MESSAGE')
          .matches(/^[1-9]\d*$/, 'ONLY_NUMBERS')
          .nullable(true),
        pricePer: yup.string().required('REQUIRE_MESSAGE'),
        currencyCode: yup.string().required('REQUIRE_MESSAGE'),
        description: yup.object().shape({
          ru: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
          sr: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
          en: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
        }),
      }),
    )
    .required(),
  workplaces: yup.array().test('check-more-then', 'REQUIRE_MESSAGE', (value) => {
    return value.length;
  }),
  selectCountry: yup.string().when(['workplaces'], {
    is: (value) => {
      const isNonOnlyOnline = value?.some(
        (workplace) => Number(workplace.id) !== ONLINE_WORKPLACE_ID,
      );
      return !isNonOnlyOnline;
    },
    then: yup.string().notRequired(),
    otherwise: yup.string().required('REQUIRE_MESSAGE'),
  }),
  selectCity: yup.string().when(['workplaces'], {
    is: (value) => {
      const isNonOnlyOnline = value?.some(
        (workplace) => Number(workplace.id) !== ONLINE_WORKPLACE_ID,
      );
      return !isNonOnlyOnline;
    },
    then: yup.string().notRequired(),
    otherwise: yup.string().required('REQUIRE_MESSAGE'),
  }),
});
