import React from 'react';

import CommentsList from '../../../components/CommentsList';
import { useGetReviews } from '../../../graphql/hooks/useQueries';

const TabComments = ({ userLocalState }) => {
  const { data: arrayOfReviews, loading: reviewsLoading } = useGetReviews({
    variables: {
      userId: userLocalState?.id,
    },
  });

  return (
    <>
      <CommentsList
        loading={reviewsLoading}
        reviews={arrayOfReviews?.getReviews?.rows}
        customEmptyMessageTranslation={'emptyCommentsProvider'}
      />
    </>
  );
};

export default TabComments;
