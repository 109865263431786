import { gql } from '@apollo/client';

export const USER_LANGUAGES = gql`
  query GetUserLanguages {
    getUserLanguages {
      id
      translations {
        languageCode
        name
      }
    }
  }
`;
