import { Button as MuiButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NotificationItem from '../NotificationItem';
import { Block, ShowMoreNotifications } from './styles';

const NotificationsList = ({
  notifications,
  allNotificationCount,
  fetchMoreNotifications,
  handleOpenModal,
  showNotificationsWindow,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      {notifications.map((notification, index) => {
        return (
          <Block key={notification.id}>
            {index > 0 && notification?.type !== notifications[index - 1]?.type && (
              <Typography variant={'h5'}>
                {notification.type === 'ORDER_PROVIDING_QUESTION'
                  ? t('gotService')
                  : t('notifications')}
              </Typography>
            )}
            <NotificationItem
              notification={notification}
              question={notification.type === 'ORDER_PROVIDING_QUESTION'}
              handleOpenModal={handleOpenModal}
              showNotificationsWindow={showNotificationsWindow}
            />
          </Block>
        );
      })}
      <ShowMoreNotifications>
        {allNotificationCount > notifications.length && (
          <MuiButton
            variant={'text'}
            disableRipple
            disableFocusRipple
            onClick={fetchMoreNotifications}
          >
            {t('showMoreNotifications')}
          </MuiButton>
        )}
      </ShowMoreNotifications>
    </Box>
  );
};

export default NotificationsList;
