import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import ConsentWithCookie from './components/ConsentWithCookie';
import GeneralHelmet from './components/GeneralHelmet';
import { ModalLayout } from './components/ModalsLayout';
import ScrollToTop from './components/ScrollToTop';
import { IPIFY_URL } from './constants/constants';
import { AuthContextProvider } from './context/AuthContext';
import { ModalContextProvider } from './context/ModalContext';
import { client } from './graphql/client';
import { RouterWrapper } from './router/Router';
import { StyledSnackbarProvider } from './styles';
import theme from './theme';

const App = () => {
  const [cookies, setCookie] = useCookies(['USER_IP']);

  const getIP = useCallback(async () => {
    const response = await fetch(IPIFY_URL);
    const data = await response.json();
    setCookie('USER_IP', data?.ip, {
      path: '/',
    });
  }, []);

  useEffect(() => {
    getIP();
  }, [cookies, getIP]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledSnackbarProvider maxSnack={1}>
          <BrowserRouter>
            <ScrollToTop>
              <ModalContextProvider>
                <ModalLayout>
                  <AuthContextProvider>
                    <HelmetProvider>
                      <GeneralHelmet />
                      <RouterWrapper />
                    </HelmetProvider>
                  </AuthContextProvider>
                </ModalLayout>
              </ModalContextProvider>
            </ScrollToTop>
            <ConsentWithCookie />
          </BrowserRouter>
        </StyledSnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
