import { gql } from '@apollo/client';

export const GET_SERVICES_COUNT = gql`
  query GetServicesCount {
    getServicesCount {
      byCity
      remotely
      countryId
      cityId
    }
  }
`;
