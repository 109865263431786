import { gql } from '@apollo/client';

export const GET_PAGE = gql`
  query getPage($url: String!) {
    getPage(url: $url) {
      translations {
        languageCode
        title
        content
      }
    }
  }
`;
