import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const SET_LOCATION = gql`
  ${USER}
  mutation setLocation($countryId: ID!, $cityId: ID!) {
    setLocation(countryId: $countryId, cityId: $cityId) {
      ...UserFragment
    }
  }
`;
