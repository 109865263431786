import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import { useCancelOrder } from '../../graphql/hooks/useMutations';
import { useGetLocales } from '../../graphql/hooks/useQueries';
import { useToast } from '../../hooks';
import CancelIcon from '../../icons/CancelIcon';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import PhoneIcon from '../../icons/PhoneIcon';
import palette from '../../theme/palette';
import { convertStatus, convertToMinutes } from '../../utils/convert';
import { getUTCHoursAndMinutes } from '../../utils/formatTime';
import {
  findFunction,
  transformLocalesToObjectWithUserData,
} from '../../utils/getDifferentFormatOfData';
import { getLocale } from '../../utils/getLocale';
import DefaultImage from '../DefaultImage';
import Dot from '../UI/Dot';
import {
  Avatar,
  CenteredText,
  CenteredTypography,
  CommentBlock,
  IconWrapper,
  StyledActionBlock,
  StyledChip,
  StyledMenu,
  StyledReservation,
  StyledReservationComment,
  StyledReservationInfo,
  StyledUserInfo,
} from './styles';

const UserReservation = ({
  reservation,
  anchorElementUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
}) => {
  const { i18n, t } = useTranslation();
  const { data: locales } = useGetLocales();
  const [cancelOrder] = useCancelOrder();
  const [order, setOrder] = useState(reservation);
  const [variant, setToastConfig] = useToast();

  const {
    service,
    startTime,
    endTime,
    comment,
    status,
    id,
    subservice,
    price,
    pricePer,
    currencyCode,
    rejectionReason,
  } = order;

  const providerUrl = `${ROUTES.provider}/${service?.id}`;

  const cancelHandler = async () => {
    await cancelOrder({
      variables: {
        cancelOrderId: id,
      },
      onCompleted(data) {
        handleCloseUserMenu();
        setOrder(data.cancelOrder);
        setToastConfig(variant.success);
      },
      onError() {
        setToastConfig(variant.error);
      },
    });
  };

  return (
    <StyledReservation>
      <StyledUserInfo>
        <Box display='flex'>
          {service?.user?.photo ? (
            <Link to={`${providerUrl}`}>
              <Avatar src={`${HOST_IMAGE_URL}/${service?.user?.photo}`} alt={'avatar'} />
            </Link>
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <StyledReservationInfo>
            <CenteredTypography variant={'body1Semibold'} component={'div'}>
              {findFunction(service?.category?.translations, i18n)?.name}
            </CenteredTypography>
            <CenteredTypography variant={'body2'} component={'div'} color={palette.oldLavender}>
              {format(new Date(startTime), 'EEEE', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {format(new Date(startTime), 'dd.MM', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {`${getUTCHoursAndMinutes(startTime)}-${getUTCHoursAndMinutes(endTime)}`}
            </CenteredTypography>
            <CenteredTypography component={'div'}>
              <Link to={`${providerUrl}`}>
                <Typography variant={'body2'} component={'div'}>
                  {`${service?.user?.firstName} ${service?.user?.lastName}`}
                </Typography>
              </Link>
              <Dot />
              <CenteredText>
                <EmptyStarIcon />
                <span>{service?.user.reviewsStatistic?.average?.toFixed(1) || t('noRating')}</span>
              </CenteredText>
              <Dot />
              <Link to={`${providerUrl}?reviews`}>
                <CenteredText>
                  {service?.user.reviewsStatistic?.count || 0}{' '}
                  {t('endingOfComments', {
                    count: service?.user.reviewsStatistic?.count || 0,
                  })}
                </CenteredText>
              </Link>
            </CenteredTypography>
            <Box variant={'body-2'} component={'div'}>
              {subservice
                ? transformLocalesToObjectWithUserData(
                    locales?.getLocales,
                    subservice.translations,
                  )?.[i18n.resolvedLanguage]?.userTranslate?.description
                : transformLocalesToObjectWithUserData(locales?.getLocales, service.translations)?.[
                    i18n.resolvedLanguage
                  ]?.userTranslate?.description}
            </Box>

            {price && (
              <Typography variant={'body1Semibold'} component={'div'}>
                {`${t('priceFrom')}: ${price} ${t(currencyCode)}/${
                  Number.isNaN(+pricePer) ? t(pricePer) : convertToMinutes(pricePer, t)
                }`}
              </Typography>
            )}
          </StyledReservationInfo>
        </Box>
        <StyledActionBlock>
          <StyledChip label={convertStatus(status, t)} variant={status.toLowerCase()} />
          <Box
            sx={{
              flexGrow: 0,
            }}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{
                p: 0,
              }}
            >
              <MoreVertIcon />
            </IconButton>

            <StyledMenu
              id='menu-appbar'
              anchorEl={anchorElementUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElementUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Link to={`${providerUrl}?contacts`}>
                  <IconWrapper>
                    <PhoneIcon color={palette.oldLavender} />
                  </IconWrapper>
                  <Typography textAlign='center'>{t('contacts')}</Typography>
                </Link>
              </MenuItem>
              {status !== 'CANCELED' && (
                <MenuItem onClick={cancelHandler}>
                  <IconWrapper>
                    <CancelIcon />
                  </IconWrapper>
                  <Typography textAlign='center'>{t('cancelOrder')}</Typography>
                </MenuItem>
              )}
            </StyledMenu>
          </Box>
        </StyledActionBlock>
      </StyledUserInfo>
      {comment && (
        <CommentBlock>
          <StyledReservationComment component={'div'}>
            <strong>{t('commentToTheOrder')}</strong>
            <Typography component={'p'}>{comment}</Typography>
          </StyledReservationComment>
        </CommentBlock>
      )}
      {rejectionReason && (
        <CommentBlock>
          <StyledReservationComment component={'div'}>
            <strong>{t('reasonForCancellation')}</strong>
            <Typography component={'p'}>{rejectionReason}</Typography>
          </StyledReservationComment>
        </CommentBlock>
      )}
    </StyledReservation>
  );
};

export default UserReservation;
