import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSignUp } from '../../graphql/hooks/useMutations';
import { useGetUserLanguages } from '../../graphql/hooks/useQueries';
import palette from '../../theme/palette';
import { getIdFromArray, getObjectNameAndIdFromArray } from '../../utils/getDifferentFormatOfData';
import { registrationValidationSchema } from '../../validation/schema/registrationSchema';
import Button from '../UI/Button';
import { ControlCheckbox } from '../UI/Checkbox';
import Divider from '../UI/Divider';
import { ControlInput } from '../UI/Input';
import EndAdornment from '../UI/Input/components/EndAdornment';
import {
  BlockQuestion,
  ButtonText,
  CenterTypography,
  ContinueButtonWrapper,
  Form,
  Greeting,
  StyledError,
  SubcategoriesList,
} from './styles';

const Registration = ({ setModalType, closeAdaptiveMenu }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isShowSecondStep, setIsShowSecondStep] = useState(false);

  const { data: userLanguages } = useGetUserLanguages();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registrationValidationSchema),
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
      isSpecialist: '',
      isClient: '',
      role: '',
      languagesList: [],
      isSecondStep: false,
    },
  });

  const [error, setError] = useState();
  const isSpecialist = watch('isSpecialist').length > 0;
  const isClient = watch('isClient').length > 0;
  const { t, i18n } = useTranslation();

  const languagesList = watch('languagesList');

  const [signUp, { loading }] = useSignUp();

  const isSpecAndFirstStep = isSpecialist && !isShowSecondStep;

  const setToSecondStepHandler = () => {
    setIsShowSecondStep(true);
    setValue('isSecondStep', true);
  };

  const onSubmit = async ({ email, password, name, surname }) => {
    if (isSpecAndFirstStep) {
      setToSecondStepHandler();
    } else {
      const options = !isSpecialist
        ? {
            firstName: name,
            lastName: surname,
            email,
            password,
            role: 'USER',
          }
        : {
            firstName: name,
            lastName: surname,
            email,
            password,
            role: 'PROVIDER',
            userLanguageIds: getIdFromArray(languagesList),
          };
      await signUp({
        variables: {
          input: options,
        },
      })
        .then(() => {
          if (!loading) {
            closeAdaptiveMenu('left', false)();
            setModalType('confirmEmail');
          }
        })
        .catch((error_) => {
          const errorMessage = error_?.message;
          if (errorMessage === 'INTERNAL_SERVER_ERROR') {
            return setError(t(errorMessage));
          }

          return setError(errorMessage);
        });
    }
  };

  const openSignIn = () => setModalType('signIn');

  const handleClickShowPassword = (setStateFunction, state) => {
    setStateFunction(!state);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isClient && isSpecialist) {
      setValue('isSpecialist', '');
      setValue('isClient', [t('client')]);
    }
  }, [isClient]);

  useEffect(() => {
    if (isSpecialist && isClient) {
      setValue('isClient', '');
      setValue('isSpecialist', [t('specialist')]);
    }
  }, [isSpecialist]);

  const firstStep = () => (
    <>
      <ControlInput control={control} name={'name'} placeholder={t('name')} maxLength={20} />
      <ControlInput control={control} name={'surname'} placeholder={t('surname')} maxLength={20} />
      <ControlInput control={control} name={'email'} placeholder={'Email'} />
      <ControlInput
        control={control}
        name={'password'}
        variant={'filled'}
        label={t('password')}
        type={isShowPassword ? 'text' : 'password'}
        endAdornment={
          <EndAdornment
            onClick={() => handleClickShowPassword(setIsShowPassword, isShowPassword)}
            onMouseDown={handleMouseDownPassword}
            isShow={isShowPassword}
          />
        }
      />
      <ControlInput
        control={control}
        name={'confirmPassword'}
        variant={'filled'}
        label={t('confirmPassword')}
        type={isShowConfirmPassword ? 'text' : 'password'}
        endAdornment={
          <EndAdornment
            onClick={() => handleClickShowPassword(setIsShowConfirmPassword, isShowConfirmPassword)}
            onMouseDown={handleMouseDownPassword}
            isShow={isShowConfirmPassword}
          />
        }
      />
      <ControlCheckbox
        options={[t('specialist')]}
        control={control}
        name={'isSpecialist'}
        singleCheckbox
      />
      <ControlCheckbox options={[t('client')]} control={control} name={'isClient'} singleCheckbox />
      {errors?.role?.message && (
        <Typography
          fontSize={12}
          color={'#D32F2F'}
          maxWidth={'189px'}
          align={'center'}
          lineHeight={'16px'}
          marginTop={'5px'}
        >
          {t(errors?.role?.message)}
        </Typography>
      )}
    </>
  );

  const secondStep = () => (
    <>
      <Box mb='20px'>
        <Box mb='20px'>
          <Greeting align={'center'} variant={'body1'}>
            {t('languageSelectionDescription')}
          </Greeting>
        </Box>
        {userLanguages?.getUserLanguages && (
          <SubcategoriesList>
            <ControlCheckbox
              control={control}
              name={'languagesList'}
              margin={'0 0 12px 0'}
              options={getObjectNameAndIdFromArray(userLanguages?.getUserLanguages, i18n)}
            />
          </SubcategoriesList>
        )}
        {errors?.languagesList?.message && (
          <Typography
            fontSize={12}
            color={'#D32F2F'}
            maxWidth={'189px'}
            lineHeight={'16px'}
            marginTop={'5px'}
          >
            {t(errors?.languagesList?.message)}
          </Typography>
        )}
      </Box>
    </>
  );

  return (
    <>
      <CenterTypography variant={'h4'}>{t('registration')}</CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isShowSecondStep ? secondStep() : firstStep()}
        {error && <StyledError variant={'body1'}>{t(error)}</StyledError>}
        <ContinueButtonWrapper margin={'28px 0 39px'}>
          <Button
            type={'submit'}
            title={
              loading ? (
                <CircularProgress
                  sx={{
                    color: palette.secondary,
                  }}
                />
              ) : isSpecAndFirstStep ? (
                t('continue')
              ) : (
                t('createAnAccount')
              )
            }
            margin={'0 auto'}
          />
        </ContinueButtonWrapper>
      </Form>
      <Divider margin={'0 -24px'} />
      <BlockQuestion>
        <Typography variant={'body1'} component={'span'}>
          {t('haveYouAnAccountYet')}
        </Typography>
        <ButtonText disableRipple disableFocusRipple className={'blue'} onClick={openSignIn}>
          {t('input')}
        </ButtonText>
      </BlockQuestion>
    </>
  );
};

export default Registration;
