import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const GeneralHelmet = () => {
  const { t } = useTranslation();

  return (
    <Helmet>
      <title>{t('metaTitle')}</title>
      <meta name='description' content={t('metaGeneralDescription')} />
      <meta name='keywords' content={t('metaKeyWords')} />
    </Helmet>
  );
};

export default GeneralHelmet;
