import { Box } from '@mui/material';
import React from 'react';

import { StyledTabPanel } from '../styles';

const TabPanel = (properties) => {
  const { children, value, index, ...other } = properties;

  return (
    <StyledTabPanel
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 3,
          }}
        >
          {children}
        </Box>
      )}
    </StyledTabPanel>
  );
};

export default TabPanel;
