import React from 'react';

const FilterIcon = ({ width = 20, height = 20, className, color = '#1A1E26' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5554 16.1271C12.5865 16.3604 12.5087 16.6092 12.3299 16.7725C12.0266 17.0758 11.5367 17.0758 11.2334 16.7725L8.1149 13.6541C7.93604 13.4752 7.85827 13.2341 7.88938 13.0086V9.02694L4.16434 4.25983C3.89993 3.92543 3.96215 3.4355 4.29654 3.17109C4.4443 3.06221 4.60761 3 4.7787 3H15.6661C15.8372 3 16.0005 3.06221 16.1482 3.17109C16.4826 3.4355 16.5448 3.92543 16.2804 4.25983L12.5554 9.02694V16.1271ZM6.36514 4.55534L9.44472 8.49035V12.7831L11.0001 14.3384V8.48257L14.0796 4.55534H6.36514Z'
        fill={color}
      />
    </svg>
  );
};

export default FilterIcon;
