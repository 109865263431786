import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButtonLogin } from './styles';

const ButtonLogin = ({ onClick }) => {
  const { t } = useTranslation();

  return <StyledButtonLogin onClick={onClick}>{t('buttonLogin')}</StyledButtonLogin>;
};

export default ButtonLogin;
