import { gql } from '@apollo/client';

export const CONFIRM_ORDER = gql`
  mutation ConfirmOrder($confirmOrderId: ID!) {
    confirmOrder(id: $confirmOrderId) {
      comment
      id
      status
      startTime
      endTime
      user {
        id
        firstName
        lastName
        photo
      }
      service {
        user {
          firstName
          lastName
          photo
        }
      }
    }
  }
`;
