import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import BackIcon from '../../../icons/BackIcon';
import palette from '../../../theme/palette';
import { StyledBreadcrums } from './styles';

const Breadcrumbs = ({ title, url }) => {
  const { t } = useTranslation();

  return (
    <StyledBreadcrums>
      <Link component={RouterLink} to={url} underline={'none'} color={palette.oldLavender}>
        <BackIcon />
        <Typography variant={'body-1'}>{t(title)}</Typography>
      </Link>
    </StyledBreadcrums>
  );
};

export default Breadcrumbs;
