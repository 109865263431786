export const findFunction = (data, i18n) =>
  data?.find((translations) => translations?.languageCode === i18n.resolvedLanguage);

export const getObjectNameAndIdFromArray = (data, i18n) =>
  data?.map((item) => ({
    name: findFunction(item?.translations, i18n)?.name,
    id: item.id,
  }));

export const getCorrectObjectFromArray = (data, i18n) =>
  data?.map((item) => ({
    name: findFunction(item?.translations, i18n)?.name,
    id: item.id,
    slug: item.slug,
  }));

export const getNameFromArray = (data, i18n) =>
  data?.map((item) => {
    return findFunction(item?.translations, i18n)?.name;
  });

export const getIdFromArray = (data, getString = false) =>
  data?.map((item) => (getString ? item.id : +item.id));

export const getNameFromTranslations = (data, i18n) => findFunction(data, i18n)?.name;

export const getObjectByValue = (array, value) => {
  return array?.find((object) => object.value === value);
};

export const getDefaulValueObject = (name) => ({
  name,
  id: 'defId',
});

export const transformLocalesToObjectWithUserData = (languages, userTranslates) => {
  return languages?.reduce((accumulator, language) => {
    const languageCode = language.code;
    const matchingUserTranslate = userTranslates?.find(
      (userTranslate) => userTranslate.languageCode === languageCode,
    );
    if (matchingUserTranslate) {
      accumulator[languageCode] = {
        ...language,
        userTranslate: matchingUserTranslate,
      };
    } else {
      accumulator[languageCode] = language;
    }

    return accumulator;
  }, {});
};

export const transformLocalesToArrayTranslation = (locales, object, key) => {
  return locales?.map(({ id, code }) => {
    return {
      languageId: Number(id),
      [key]: object[code],
    };
  });
};
