import palette from '../theme/palette';

export const EyeIcon = ({ width = 20, height = 14, className, color = palette.primary }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 20 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10 10.646C11.014 10.646 11.875 10.2917 12.583 9.58299C13.2917 8.87499 13.646 8.01399 13.646 6.99999C13.646 5.98599 13.2917 5.12499 12.583 4.41699C11.875 3.70833 11.014 3.35399 10 3.35399C8.98601 3.35399 8.12501 3.70833 7.41701 4.41699C6.70834 5.12499 6.35401 5.98599 6.35401 6.99999C6.35401 8.01399 6.70834 8.87499 7.41701 9.58299C8.12501 10.2917 8.98601 10.646 10 10.646ZM10 9.04199C9.43067 9.04199 8.94801 8.84399 8.55201 8.44799C8.15601 8.05199 7.95801 7.56933 7.95801 6.99999C7.95801 6.43066 8.15601 5.94799 8.55201 5.55199C8.94801 5.15599 9.43067 4.95799 10 4.95799C10.5693 4.95799 11.052 5.15599 11.448 5.55199C11.844 5.94799 12.042 6.43066 12.042 6.99999C12.042 7.56933 11.844 8.05199 11.448 8.44799C11.052 8.84399 10.5693 9.04199 10 9.04199ZM10 13.208C7.93067 13.208 6.05901 12.642 4.38501 11.51C2.71167 10.378 1.48601 8.87466 0.708008 6.99999C1.48601 5.12533 2.71167 3.62199 4.38501 2.48999C6.05901 1.35799 7.93067 0.791992 10 0.791992C12.0693 0.791992 13.941 1.35799 15.615 2.48999C17.2883 3.62199 18.514 5.12533 19.292 6.99999C18.514 8.87466 17.2883 10.378 15.615 11.51C13.941 12.642 12.0693 13.208 10 13.208ZM10 11.583C11.5833 11.583 13.052 11.1733 14.406 10.354C15.7607 9.53466 16.799 8.41666 17.521 6.99999C16.799 5.58333 15.7607 4.46533 14.406 3.64599C13.052 2.82666 11.5833 2.41699 10 2.41699C8.41667 2.41699 6.94801 2.82666 5.59401 3.64599C4.23934 4.46533 3.20101 5.58333 2.47901 6.99999C3.20101 8.41666 4.23934 9.53466 5.59401 10.354C6.94801 11.1733 8.41667 11.583 10 11.583Z'
      fill={color}
    />
  </svg>
);
