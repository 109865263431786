import { IconButton } from '@mui/material';
import React from 'react';
import { IconLeft, IconRight } from 'react-day-picker';

import {
  About,
  ContentWrapper,
  ControlButtons,
  ShowBigImageModal,
  StyledFadeImage,
} from './styles';

const ShowBigImage = ({
  open,
  modalData: {
    imageUrl,
    imageDescription,
    nextImage,
    previousImage,
    arrayImages,
    currentImageIndex,
  },
}) => {
  const showNextImage = () => {
    nextImage(arrayImages);
  };
  const showPreviousImage = () => {
    previousImage(arrayImages);
  };

  return (
    <>
      <ShowBigImageModal>
        <ControlButtons>
          {currentImageIndex !== 0 && (
            <IconButton className={'previous'} onClick={showPreviousImage}>
              <IconLeft />
            </IconButton>
          )}
          {currentImageIndex + 1 !== arrayImages?.length && (
            <IconButton className={'next'} onClick={showNextImage}>
              <IconRight />
            </IconButton>
          )}
        </ControlButtons>
        <ContentWrapper>
          <StyledFadeImage in={open} timeout={500}>
            <img src={imageUrl} alt={'service'} />
          </StyledFadeImage>
          {imageDescription && <About>{imageDescription}</About>}
        </ContentWrapper>
      </ShowBigImageModal>
    </>
  );
};

export default ShowBigImage;
