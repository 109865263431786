import { Rating, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledComment = styled('div')({
  padding: '16px 0 18px',
});

export const CommentUserInfo = styled('div')({
  display: 'flex',
});

export const Avatar = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '8px',
  objectFit: 'cover',
});

export const CommenterInfo = styled('div')({
  marginLeft: '8px',
  '&.fontVariant1': {
    'a > div': {
      fontSize: '16px',
    },
  },
});

export const CenteredTypography = styled(Typography)(({ lineHeight }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: lineHeight ? `${lineHeight}px` : '20px',
  marginBottom: '0px',
  marginTop: '2px',
}));

export const CenteredText = styled('span')({
  display: 'flex',
  alignItems: 'center',
  svg: {
    marginRight: '4px',
    marginTop: '-4px',
  },
});

export const StyledRating = styled(Rating)({
  '.MuiRating-icon': {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const MiddleBlock = styled('div')({
  margin: '12px 0',
  '&.fontVariant1': {
    p: {
      fontSize: '14px',
    },
  },
});
