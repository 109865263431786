const PhoneContactIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 40 40'
    id='phone'
    width={width}
    height={height}
  >
    <defs>
      <linearGradient id='a' x1='50%' x2='50%' y1='0%' y2='100%'>
        <stop offset='0%' stop-color='#86FC6F'></stop>
        <stop offset='100%' stop-color='#0CD419'></stop>
      </linearGradient>
    </defs>
    <g fill='none' fill-rule='evenodd'>
      <circle cx='20' cy='20' r='20' fill='url(#a)'></circle>
      <path
        fill='#FFF'
        d='M23.1179863,24.9844003 C22.7849875,25.1958537 22.3681546,25.2262713 22.0079863,25.0654003 C21.2939863,24.6694003 19.9919863,23.7244003 18.1859863,21.9184003 C16.3799863,20.1124003 15.3509863,18.7234003 14.9519863,18.0184003 C14.7911153,17.658232 14.8215329,17.2413991 15.0329863,16.9084003 L16.0709863,15.0904003 C16.4429863,14.4454003 16.5269863,14.0374003 16.1219863,13.4104003 L13.4549863,9.32740033 C12.6959863,8.12740033 12.3089863,8.18140033 11.6549863,8.48140033 C8.25298626,10.0294003 6.92398626,12.2194003 11.7509863,19.8424003 C13.0529863,21.8974003 17.0309863,26.2474003 20.4239863,28.4494003 C27.6899863,33.1684003 30.0809863,31.7494003 31.4999863,28.4494003 C31.7999863,27.7834003 31.9319863,27.3964003 30.7889863,26.6494003 L26.6609863,24.0244003 C26.0339863,23.6224003 25.7279863,23.5174003 25.0799863,23.8924003 L23.1179863,24.9844003 Z'
      ></path>
    </g>
  </svg>
);

export default PhoneContactIcon;
