import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const StyledRreservations = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '336px',
});

export const BlockDescription = styled('span')({
  fontSize: '16px',
  lineHeight: '22px ',
  color: palette.oldLavender,
  display: 'block',
});
