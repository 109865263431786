import { CircularProgress, IconButton, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NOTIFICATIONS_PER_PAGE } from '../../constants/constants';
import { useNotification } from '../../hooks';
import CloseIcon from '../../icons/CloseIcon';
import NotificationsIcon from '../../icons/NotificationsIcon';
import OldNotificationIcon from '../../icons/OldNotificationIcon';
import theme from '../../theme';
import palette from '../../theme/palette';
import ModalComponent from '../Modal';
import NotificationsList from '../NotificationsList';
import {
  NotificationTitle,
  StyledIconButton,
  StyledNotification,
  StyledPopper,
  UnreadNotifications,
} from './styles';

const Notification = () => {
  const { t } = useTranslation();
  const {
    setIsNewNotification,
    notifications,
    notificationsLoader,
    allNotificationCount,
    quantityUnreadNotifications,
    markAsReadNotifications,
    fetchMoreNotifications,
    setUnreadNotificationsCounter,
  } = useNotification(NOTIFICATIONS_PER_PAGE);
  const popperReference = useRef(null);
  const notificationIconReference = useRef(null);
  const [notificationIconClickCount, setNotificationIconClickCount] = useState(0);
  const [modalType, setModalType] = useState('createReview');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const popperModifier = isTablet ? [0, 15] : [84, 0];

  const [anchorElement, setAnchorElement] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !notificationIconReference.current.contains(event.target) &&
        popperReference.current &&
        !popperReference.current.contains(event.target)
      ) {
        setAnchorElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperReference]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const handleClick = async (event) => {
    setNotificationIconClickCount(notificationIconClickCount + 1);
    if (anchorElement) {
      setAnchorElement(null);
    } else {
      setAnchorElement(event.currentTarget);
      setIsNewNotification(false);
      if (quantityUnreadNotifications) {
        await markAsReadNotifications();
        setUnreadNotificationsCounter(0);
      }
    }
  };

  const handleOpenModal = (userId, provider, answer) => {
    setIsModalOpen(true);
    setModalData({
      userId,
      provider,
    });
    return answer === 'yes' ? setModalType('createReview') : setModalType('createReviewForAdmin');
  };

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <StyledNotification>
        <StyledIconButton onClick={handleClick} ref={notificationIconReference}>
          {quantityUnreadNotifications > 0 && (
            <UnreadNotifications>{quantityUnreadNotifications}</UnreadNotifications>
          )}
          {quantityUnreadNotifications ? <NotificationsIcon /> : <OldNotificationIcon />}
        </StyledIconButton>
        <StyledPopper
          id={id}
          open={open}
          anchorEl={anchorElement}
          ref={popperReference}
          placement='bottom-end'
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: popperModifier,
              },
            },
          ]}
        >
          <NotificationTitle>
            {notifications[0]?.type === 'ORDER_PROVIDING_QUESTION' ? (
              <Typography variant={'h5'}>{t('gotService')}</Typography>
            ) : (
              <Typography variant={'h5'}>{t('notifications')}</Typography>
            )}
            <IconButton onClick={handleClick}>
              <CloseIcon />
            </IconButton>
          </NotificationTitle>
          {notifications.length > 0 ? (
            <NotificationsList
              notifications={notifications}
              fetchMoreNotifications={fetchMoreNotifications}
              allNotificationCount={allNotificationCount}
              handleOpenModal={handleOpenModal}
              showNotificationsWindow={setAnchorElement}
            />
          ) : notificationsLoader ? (
            <CircularProgress
              sx={{
                color: palette.primary,
              }}
            />
          ) : (
            <Typography variant={'body1'}>{t('noNotifications')}</Typography>
          )}
        </StyledPopper>
      </StyledNotification>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        setModalType={setModalType}
        modalData={modalData}
      />
    </>
  );
};

export default Notification;
