import FooterLogoImage from '../../images/footerLogo.svg';
import LogoImage from '../../images/logoHeader.svg';
import { StyledLogo } from './styles';

const Logo = ({ footerLogo }) => {
  return (
    <StyledLogo to='/' className={footerLogo ? 'footerLogo' : 'headerLogo'}>
      {footerLogo ? <img src={FooterLogoImage} alt='Logo' /> : <img src={LogoImage} alt='Logo' />}
    </StyledLogo>
  );
};

export default Logo;
