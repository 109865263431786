import { Button as MuiButton, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/UI/Button';
import Loader from '../../../components/UI/Loader';
import theme from '../../../theme';
import { ButtonManage } from '../styles';

export const ButtonManageWrapper = ({ navigate, deleteService, loading }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const resetData = () => {
    navigate(-1);
  };

  return (
    <ButtonManage>
      <MuiButton
        className={'cancel'}
        variant={'text'}
        onClick={resetData}
        disableRipple
        disableFocusRipple
      >
        {t('cancel')}
      </MuiButton>
      <MuiButton
        className={'delete'}
        variant={'text'}
        onClick={deleteService}
        disableRipple
        disableFocusRipple
      >
        {t('justDelete')}
      </MuiButton>
      {!isMobile ? (
        <MuiButton
          className={'save'}
          variant={'text'}
          type={'submit'}
          disableRipple
          disableFocusRipple
          disabled={loading}
        >
          {loading ? <Loader size={20} /> : t('save')}
        </MuiButton>
      ) : (
        <Button
          className={'saveMobile'}
          type={'submit'}
          title={loading ? <Loader color={'#fff'} size={20} /> : t('save')}
          disabled={loading}
        />
      )}
    </ButtonManage>
  );
};
