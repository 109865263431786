import { Box, Rating, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import theme from '../../theme';
import palette from '../../theme/palette';

export const ProviderItem = styled('div')({
  marginTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});
export const StyledRatingWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});
export const StyledRating = styled(Rating)({
  '.MuiRating-icon': {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export const UpperPart = styled('div')({
  display: 'flex',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
});
export const Avatar = styled('div')({
  marginBottom: '15px',
  img: {
    width: '216px',
    height: '216px',
    borderRadius: '12px',
    objectFit: 'cover',
  },
  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
  },
});

export const Photo = styled('img')({
  marginRight: '16px',
  width: '104px',
  height: '104px',
  minWidth: '104px',
  borderRadius: '8px',
  objectFit: 'contain',
  padding: '5px',
  backgroundColor: palette.seashell,
  marginBottom: '16px',
  cursor: 'pointer',
});

export const PhotoTab = styled('img')({
  width: '217px',
  height: '217px',
  minWidth: '217px',
  borderRadius: '12px',
  objectFit: 'contain',
  padding: '5px',
  backgroundColor: palette.seashell,
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    width: '104px',
    height: '104px',
    minWidth: '104px',
    borderRadius: '8px',
    marginBottom: '12px',
  },
});

export const ColumnWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '456px',

  '&.leftColumn': {
    marginRight: '24px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    '&.center': {
      alignItems: 'center',
    },
    '&.leftColumn': {
      marginRight: '0px',
    },
  },
});

export const CenteredTypography = styled(Typography)(({ lineHeight }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: lineHeight ? `${lineHeight}px` : '20px',
  marginBottom: '6px',
}));

export const CenteredText = styled('span')({
  display: 'flex',
  alignItems: 'center',
  color: palette.oldLavender,
  span: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  svg: {
    marginRight: '4px',
    marginTop: '-4px',
  },
});

export const ConnectionBlock = styled('div')({
  marginBottom: '20px',
  display: 'flex',
  width: '100%',
});

export const Gallery = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1180px',
  margin: '34px 0 -16px',
  flexWrap: 'wrap',

  [theme.breakpoints.down('xl')]: {
    overflowX: 'scroll !important',
    width: 'auto',
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const TabGallery = styled('div')({
  display: 'grid',
  alignItems: 'center',
  maxWidth: '1180px',
  flexWrap: 'wrap',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: '20px',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(4, 217px)',
    justifyContent: 'space-between',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(3, 217px)',
  },

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    gridGap: 'unset',
    'img:not(:nth-of-type(6n))': {
      marginRight: '12px',
    },
  },

  [theme.breakpoints.down(731)]: {
    'img:nth-of-type(5n)': {
      marginRight: '0',
    },
    'img:not(:nth-of-type(5n))': {
      marginRight: '12px',
    },
  },

  [theme.breakpoints.down(615)]: {
    'img:nth-of-type(4n)': {
      marginRight: '0',
    },
    'img:not(:nth-of-type(4n))': {
      marginRight: '12px',
    },
  },

  [theme.breakpoints.down(499)]: {
    'img:nth-of-type(3n)': {
      marginRight: '0',
    },
    'img:not(:nth-of-type(3n))': {
      marginRight: '12px',
    },
  },
});

export const WrappedDescription = styled('div')({
  marginBottom: '20px',
});

export const StyledBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10px',
});
export const BlockTitle = styled(Typography)({
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '1.4',
  marginBottom: '10px',
});
export const BlockSubTitle = styled(Typography)({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '1.4',
  color: palette.oldLavender,
});

export const Addition = styled(Typography)({
  fontWeight: '400',
  lineHeight: '1.4',
  marginBottom: '10px',
  p: {
    margin: 0,
    fontSize: '16px!important',
    '&:first-of-type': {
      marginBottom: '10px',
    },
  },
});

export const BlockDescription = styled('span')({
  fontSize: '16px',
  lineHeight: '22px ',
  color: palette.oldLavender,
  display: 'block',
});

export const StyledButtonText = styled('button')({
  background: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  color: palette.oldLavender,
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  fontFamily: 'Open Sans',
});

export const StyledContactsList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '-5px',
  svg: {
    width: '40px',
    height: '40px',
  },
});

export const StyledContactItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',

  '& a': {
    margin: '5px',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '1.4',
    color: palette.oldLavender,
  },
});

export const MobileTitle = styled(Typography)({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '16px',
    fontWeight: 600,
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
});

export const DesktopTitle = styled(Typography)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
});

export const StyledLink = styled(Link)({
  '&[href]': {
    fontSize: '14px',
    textDecoration: 'underline',
  },
});

export const StyledBookingChip = styled('div')({
  marginTop: '5px',
  textAlign: 'center',
  background:
    'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%),linear-gradient(269.91deg, #58FF87 -0.03%,' +
    ' #008EFF 97.94%),linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%),linear-gradient(180deg, #8BFF58 0%, #008EFF 100%),#D9D9D9',
  fontSize: '14px',
  borderRadius: '8px',
  padding: '6px 20px',
  color: palette.secondary,
});

export const FavouriteIconBlock = styled(Box)({});
