/* eslint-disable object-curly-newline */
import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';

import StarIcon from '../../../icons/StarIcon';
import theme from '../../../theme';
import palette from '../../../theme/palette';
import { Block, BlocksLine, DashboardTabWrapper, StyledTooltip } from '../styles';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length > 0) {
    return (
      <StyledTooltip>
        <p className='label'>{`Period: ${payload[0].payload.month}`}</p>
        <p className='label'>{`Opening: ${payload[0].value}`}</p>
      </StyledTooltip>
    );
  }
  return null;
};

const CustomizeDot = (properties) => {
  const { cx, cy, stroke } = properties;

  return (
    <svg
      x={cx - 4}
      y={cy - 4}
      width='8'
      height='55'
      viewBox='0 0 8 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='4' cy='4' r='4' fill={stroke} />
      <path d='M4 4V55' stroke={stroke} />
    </svg>
  );
};

const createPreviousMonthData = () => {
  const data = new Date();

  if (data.getMonth()) {
    return `${data.getFullYear()}-${data.getMonth()}`;
  }

  return `${data.getFullYear() - 1}-${data.setMonth(12)}`;
};

const TabDashboard = ({ userLocalState }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const { reviewsStatistic, dashboard } = userLocalState;
  const dashboardArray =
    dashboard?.visits.length === 1
      ? [{ month: createPreviousMonthData(), viewCount: 0 }, ...dashboard?.visits]
      : dashboard?.visits;

  return (
    <DashboardTabWrapper>
      <BlocksLine>
        <Block className={'rate'}>
          <Typography
            width={'125px'}
            height={'40px'}
            variant={'body2Semibold'}
            component={'div'}
            className={'gradient'}
          >
            {t('yourRating')}
          </Typography>
          <Box className={'info'}>
            {reviewsStatistic ? (
              <>
                <StarIcon width={'24px'} height={'24px'} />
                <span>{reviewsStatistic?.average.toFixed(1)}</span>
              </>
            ) : (
              <span>{t('notYet')}</span>
            )}
          </Box>
        </Block>
        <Block className={'favorite'}>
          <Typography width={'125px'} height={'40px'} variant={'body2Semibold'} component={'div'}>
            {t('favorites')}
          </Typography>
          <Box className={'info'}>
            <span>{dashboard?.favorites || 0}</span>
          </Box>
        </Block>
        <Block className={'opened'}>
          <Box>
            <Typography width={'125px'} height={'40px'} variant={'body2Semibold'} component={'div'}>
              {t('visitedCount')}
            </Typography>
            <Box className={'info'}>
              <span>{dashboard?.visitedCount}</span>
            </Box>
          </Box>
          <ResponsiveContainer minWidth={isMobile ? '50%' : 240} height={70}>
            <AreaChart
              data={dashboard?.visits.length > 0 ? dashboardArray : []}
              margin={{
                top: 10,
                right: 30,
                left: 10,
                bottom: 0,
              }}
            >
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Area
                activeDot={true}
                dot={<CustomizeDot stroke={palette.blue} />}
                type='monotone'
                dataKey='viewCount'
                stroke='#F0F1F3'
                fill='#F0F1F3'
                fillOpacity='1'
                isAnimationActive={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Block>
      </BlocksLine>
    </DashboardTabWrapper>
  );
};

export default TabDashboard;
