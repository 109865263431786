import { gql } from '@apollo/client';

export const GET_CATEGORY_SUBCATEGORY_WITH_PHOTO = gql`
  query Query {
    getCategorySubcategoryWithPhoto {
      ... on Category {
        id
        photo
        slug
        translations {
          languageCode
          name
          shortName
        }
      }
      ... on Subcategory {
        id
        photo
        categoryId
        parentCategorySlug
        slug
        translations {
          languageCode
          name
        }
      }
    }
  }
`;
