import { gql } from '@apollo/client';

export const NEW_NOTIFICATION = gql`
  subscription NewNotification {
    newNotification {
      id
      type
      order {
        id
        status
        startTime
        endTime
        comment
        service {
          user {
            id
            photo
            lastName
            firstName
            reviewsStatistic {
              average
              count
            }
          }
          category {
            translations {
              languageCode
              name
            }
          }
          id
        }
        user {
          photo
          firstName
          lastName
          reviewsStatistic {
            average
            count
          }
        }
      }
      questionDescription
      questionResult
      type
    }
  }
`;
