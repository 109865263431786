import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HOST_IMAGE_URL } from '../../constants/constants';
import { useConfirmOrder } from '../../graphql/hooks/useMutations';
import { useGetLocales } from '../../graphql/hooks/useQueries';
import { useToast } from '../../hooks';
import CancelIcon from '../../icons/CancelIcon';
import ConfirmIcon from '../../icons/ConfirmIcon';
import palette from '../../theme/palette';
import { convertStatus, convertToMinutes } from '../../utils/convert';
import { getUTCHoursAndMinutes } from '../../utils/formatTime';
import { transformLocalesToObjectWithUserData } from '../../utils/getDifferentFormatOfData';
import { getLocale } from '../../utils/getLocale';
import DefaultImage from '../DefaultImage';
import ModalComponent from '../Modal';
import Dot from '../UI/Dot';
import {
  Avatar,
  CenteredTypography,
  CommentBlock,
  IconWrapper,
  StyledChip,
  StyledMenu,
  StyledReservation,
  StyledReservationComment,
  StyledReservationInfo,
  StyledUserInfo,
} from './styles';

const ProviderReservation = ({
  reservation,
  anchorElementUser,
  handleOpenUserMenu,
  handleCloseUserMenu,
}) => {
  const { i18n, t } = useTranslation();
  const { data: locales } = useGetLocales();
  const [variant, setToastConfig] = useToast();
  const [order, setOrder] = useState(reservation);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmOrder] = useConfirmOrder();

  const {
    user,
    startTime,
    endTime,
    comment,
    status,
    id,
    subservice,
    service,
    price,
    pricePer,
    currencyCode,
    rejectionReason,
  } = order;

  useEffect(() => {
    setOrder(reservation);
  });

  const cancelOrderHandler = async () => {
    setIsModalOpen(true);
    handleCloseUserMenu();
  };

  const handleConfirmOrder = async () => {
    await confirmOrder({
      variables: {
        confirmOrderId: id,
      },
      onCompleted(data) {
        handleCloseUserMenu();
        setOrder(data.confirmOrder);
        setToastConfig(variant.success);
      },
      onError() {
        setToastConfig(variant.error);
      },
    });
  };

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <StyledReservation>
        <StyledUserInfo>
          <Box display='flex'>
            {user?.photo ? (
              <Avatar src={`${HOST_IMAGE_URL}/${user?.photo}`} alt={'avatar'} />
            ) : (
              <DefaultImage iconSize={'40px'} />
            )}
            <StyledReservationInfo>
              <Typography variant={'body2'} component={'div'}>
                {`${user?.firstName} ${user?.lastName}`}
              </Typography>
              <CenteredTypography variant={'body2'} component={'div'} color={palette.oldLavender}>
                {format(new Date(startTime), 'EEEE', {
                  locale: getLocale(i18n.resolvedLanguage),
                })}
                <Dot />
                {format(new Date(startTime), 'dd.MM', {
                  locale: getLocale(i18n.resolvedLanguage),
                })}
                <Dot />
                {`${getUTCHoursAndMinutes(startTime)}-${getUTCHoursAndMinutes(endTime)}`}
              </CenteredTypography>

              <Box variant={'body-2'} component={'div'}>
                {subservice
                  ? transformLocalesToObjectWithUserData(
                      locales?.getLocales,
                      subservice.translations,
                    )?.[i18n.resolvedLanguage]?.userTranslate?.description
                  : transformLocalesToObjectWithUserData(
                      locales?.getLocales,
                      service.translations,
                    )?.[i18n.resolvedLanguage]?.userTranslate?.description}
              </Box>

              {price && (
                <Typography variant={'body1Semibold'} component={'div'}>
                  {`${t('priceFrom')}: ${price} ${t(currencyCode)}/${
                    Number.isNaN(+pricePer) ? t(pricePer) : convertToMinutes(pricePer, t)
                  }`}
                </Typography>
              )}
            </StyledReservationInfo>
          </Box>
          <Box display='flex' alignItems='center'>
            <StyledChip label={convertStatus(status, t)} variant={status.toLowerCase()} />
            {status !== 'CANCELED' && (
              <Box
                sx={{
                  flexGrow: 0,
                }}
              >
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{
                    p: 0,
                  }}
                >
                  <MoreVertIcon />
                </IconButton>

                <StyledMenu
                  sx={{
                    mt: '45px',
                  }}
                  id='menu-appbar'
                  anchorEl={anchorElementUser}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElementUser)}
                  onClose={handleCloseUserMenu}
                >
                  {status !== 'CONFIRMED' && (
                    <MenuItem onClick={handleConfirmOrder}>
                      <IconWrapper>
                        <ConfirmIcon color={palette.oldLavender} />
                      </IconWrapper>
                      <Typography textAlign='center'>{t('confirm')}</Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={cancelOrderHandler}>
                    <IconWrapper>
                      <CancelIcon />
                    </IconWrapper>
                    <Typography textAlign='center'>{t('cancelOrder')}</Typography>
                  </MenuItem>
                </StyledMenu>
              </Box>
            )}
          </Box>
        </StyledUserInfo>
        {comment && (
          <CommentBlock>
            <StyledReservationComment component={'div'}>
              <strong>{t('commentToTheOrder')}</strong>
              <Typography component={'p'}>{comment}</Typography>
            </StyledReservationComment>
          </CommentBlock>
        )}
        {rejectionReason && (
          <CommentBlock>
            <StyledReservationComment component={'div'}>
              <strong>{t('reasonForCancellation')}</strong>
              <Typography component={'p'}>{rejectionReason}</Typography>
            </StyledReservationComment>
          </CommentBlock>
        )}
      </StyledReservation>
      <ModalComponent
        type={'cancelOrder'}
        open={isModalOpen}
        handleClose={handleCloseModal}
        modalData={{
          cancelOrderId: id,
        }}
      />
    </>
  );
};

export default ProviderReservation;
