import { gql } from '@apollo/client';

export const GET_CATEGORIES_NAME = gql`
  query Query($isPhotoExist: Boolean) {
    getCategories(isPhotoExist: $isPhotoExist) {
      id
      translations {
        languageCode
        name
      }
    }
  }
`;
