import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Divider from '../Divider';
import ToolTip from '../ToolTip';
import { StyledTab, StyledTabs, StyledWrap } from './styles';
import TabPanel from './TabPanel';

const a11yProperties = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const BasicTabs = ({
  tabs,
  tabPanels,
  initialTab,
  autoScrollToTab,
  tabsNamesArrayWithWarnings = [],
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const reference = useRef(null);

  useEffect(() => {
    const initialTabIndex = tabs.findIndex((tab) => tab.title === initialTab);
    if (initialTabIndex !== -1) {
      setValue(initialTabIndex);
    }
  }, [initialTab]);

  useEffect(() => {
    if (autoScrollToTab) {
      reference?.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [autoScrollToTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Divider margin={'32px 0 24px'} />
      <StyledWrap>
        <StyledTabs value={value} onChange={handleChange}>
          {tabs.map(({ title }, index) => {
            const warning = tabsNamesArrayWithWarnings?.find((item) => item.title === title);

            return (
              <StyledTab
                key={title}
                label={
                  warning ? (
                    <ToolTip open={true} title={warning.message} placement={'top'} arrow>
                      <Box isWarning={warning}>{t(title)}</Box>
                    </ToolTip>
                  ) : (
                    t(title)
                  )
                }
                {...a11yProperties(index)}
                isWarning={warning}
              />
            );
          })}
        </StyledTabs>
      </StyledWrap>
      <Divider margin={'24px 0 32px'} />
      {tabPanels.map((panel, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            <Box ref={reference}>{panel}</Box>
          </TabPanel>
        );
      })}
    </>
  );
};

export default BasicTabs;
