import React from 'react';

const TelegramIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 32C24.8365 32 32 24.8365 32 16C32 7.16347 24.8365 0 16 0C7.16347 0 0 7.16347 0 16C0 24.8365 7.16347 32 16 32Z'
        fill='url(#paint0_linear_1724_5999)'
      />
      <path
        d='M5.95875 16.7826C7.82914 15.7523 9.91701 14.8924 11.8678 14.0282C15.2239 12.6126 18.5934 11.2215 21.9969 9.92645C22.6591 9.70578 23.8489 9.48998 23.9655 10.4713C23.9017 11.8604 23.6389 13.2413 23.4586 14.6222C23.0011 17.659 22.4723 20.6854 21.9566 23.7122C21.7789 24.7204 20.5159 25.2424 19.7078 24.5972C17.7657 23.2853 15.8086 21.9862 13.8913 20.644C13.2632 20.0058 13.8456 19.0893 14.4065 18.6336C16.0061 17.0572 17.7025 15.7178 19.2185 14.06C19.6275 13.0724 18.4192 13.9047 18.0207 14.1597C15.8308 15.6688 13.6945 17.27 11.3857 18.5962C10.2064 19.2454 8.83187 18.6906 7.65307 18.3284C6.59613 17.8908 5.04731 17.4499 5.95865 16.7826L5.95875 16.7826Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1724_5999'
          x1='12.0019'
          y1='1.3344'
          x2='4.00187'
          y2='20'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#37AEE2' />
          <stop offset='1' stopColor='#1E96C8' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TelegramIcon;
