const palette = {
  primary: '#1A1E26',
  secondary: '#FFFFFF',
  oldLavender: '#706F7A',
  desertStorm: '#F7F7F7',
  seashell: '#F0F1F3',
  whiteSmoke: '#F0F0F0',
  gainsboro: '#d9d9d9',
  artyClickRed: '#EE0E00',
  overlay: 'rgba(0, 0, 0, 0.75);',
  blue: '#008EFF',
  selected: '#46C7AB',
  boxShadow: {
    drawerPaper: '0px 4px 24px rgba(0, 0, 0, 0.45)',
    drawerPaperLight: '0px 4px 18px rgba(0, 0, 0, 0.09)',
  },
  mainGradient:
    'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9',
  btnColor: {},
};

export default palette;
