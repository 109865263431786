import palette from '../theme/palette';

const BackIcon = ({ width = 12, height = 12, className, color = palette.oldLavender }) => (
  <svg
    width={width}
    height={height}
    className={className}
    color={color}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6 12L0 6L6 0L7.062 1.062L2.875 5.25H12V6.75H2.875L7.062 10.938L6 12Z'
      fill='#706F7A'
    />
  </svg>
);

export default BackIcon;
