import { gql } from '@apollo/client';

export const CREATE_WORK_SCHEDULE = gql`
  mutation CreateWorkSchedule($input: WorkScheduleInput!) {
    createWorkSchedule(input: $input) {
      endLunch
      endTime
      id
      startLunch
      startTime
      workdays
    }
  }
`;
