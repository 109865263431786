import palette from '../theme/palette';

const DropdownIcon = ({ width = '20', height = '20', className, color = palette.oldLavender }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path d='M10 13.062L5 8.062L6.062 7L10 10.938L13.938 7L15 8.062L10 13.062Z' fill={color} />
  </svg>
);

export default DropdownIcon;
