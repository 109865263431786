import { gql } from '@apollo/client';

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($updateNotificationId: ID!, $input: UpdateNotificationInput!) {
    updateNotification(id: $updateNotificationId, input: $input) {
      id
      questionDescription
      questionResult
      type
      order {
        user {
          firstName
          lastName
          photo
        }
      }
      user {
        lastName
        photo
        firstName
      }
    }
  }
`;
