import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../Container';
import { InputComponent } from '../UI/Input';

const Search = ({ newValue }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(newValue || '');

  const handleCurrentValue = (event) => {
    setValue(event.target.value);
  };

  return (
    <Container direction='column'>
      <InputComponent
        type='text'
        wrapperClassName='searchWrapper'
        inputClassName='searchInput'
        searchButton
        value={value}
        placeholder={t('searchPlaceholder')}
        onChange={handleCurrentValue}
      />
    </Container>
  );
};

export default Search;
