import { getPatternObject, getRequiredObject } from './validation';

export const getRulesByContactType = (type, t) => {
  switch (type) {
    case 'PHONE': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      };
    }

    case 'VIBER': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      };
    }

    case 'WHATSAPP': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      };
    }

    case 'TELEGRAM': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('TELEGRAM_NICKNAME_PATTERN', t),
      };
    }

    case 'EMAIL': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('EMAIL_PATTERN', t),
      };
    }

    case 'SOCIAL': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    case 'INSTAGRAM': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    case 'FACEBOOK': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    case 'YOUTUBE': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    case 'TWITTER': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    case 'LINKEDIN': {
      return {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      };
    }

    default: {
      return {
        required: getRequiredObject(t),
        pattern: null,
      };
    }
  }
};
