import { format } from 'date-fns';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateOrder } from '../../../graphql/hooks/useMutations';
import { useGetService } from '../../../graphql/hooks/useQueries';
import { GET_GROUPED_BY_DATE_INTERVAL_ORDERS, GET_ORDERS } from '../../../graphql/queries';
import { useToast } from '../../../hooks';
import Loader from '../../UI/Loader';
import { Form, WrapperFormStyled } from '../styles';
import CreateOrderStep1 from './CreateOrderStep1';
import CreateOrderStep2 from './CreateOrderStep2';
import CreateOrderStep3 from './CreateOrderStep3';

export const CreateOrder = ({ handleClose, modalData }) => {
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [selectedTime, setSelectedTime] = useState();

  const [variant, setToastConfig] = useToast();

  const { loading } = useGetService({
    variables: {
      getServiceId: modalData?.serviceId,
    },
    skip: !modalData?.serviceId,
    onCompleted: (data) => {
      const serviceData = data?.getService;
      const subservices = serviceData?.subservices ?? [];

      const mainService = {
        price: serviceData?.price,
        currencyCode: serviceData?.currencyCode,
        pricePer: serviceData?.pricePer,
        translations: serviceData?.translations,
      };

      setServices([mainService, ...subservices]);
      setSelectedService(mainService);
    },
  });

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      text: '',
    },
  });

  const [createOrder] = useCreateOrder({
    onCompleted() {
      handleClose();
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
  });

  const [step, setStep] = useState(1);

  const handleChangeStep = (stepNumber) => () => {
    setStep(stepNumber);
  };

  const onSubmit = async ({ text }) => {
    const startTime = `${format(selectedTime.start, 'yyyy-MM-dd')}T${format(
      selectedTime.start,
      'HH:mm:ss',
    )}.000Z`;

    await createOrder({
      variables: {
        input: {
          serviceId: modalData?.serviceId,
          startTime,
          subserviceId: selectedService.id || null,
          comment: text || null,
        },
      },
      refetchQueries: [GET_GROUPED_BY_DATE_INTERVAL_ORDERS, GET_ORDERS],
    });
  };

  return (
    <WrapperFormStyled>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          {step === 1 && (
            <CreateOrderStep1
              setSelectedService={setSelectedService}
              services={services}
              setStep={setStep}
            />
          )}
          {step === 2 && (
            <CreateOrderStep2
              handleClose={handleClose}
              handleChangeStep={handleChangeStep}
              providerId={modalData.userId}
              selectedService={selectedService}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          )}
          {step === 3 && (
            <CreateOrderStep3
              handleChangeStep={handleChangeStep}
              onSubmit={onSubmit}
              control={control}
              watch={watch}
              selectedTime={selectedTime}
            />
          )}
        </Form>
      )}
    </WrapperFormStyled>
  );
};
