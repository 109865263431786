import { gql } from '@apollo/client';

export const GET_NON_WORKING_HOURS_BY_DATE_INTERVAL = gql`
  query GetGroupedByDateIntervalNonWorkingHours($providerId: ID!, $month: Date) {
    getGroupedByDateIntervalNonWorkingHours(providerId: $providerId, month: $month) {
      date
      intervals {
        endTime
        startTime
      }
    }
  }
`;
