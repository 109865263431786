import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ControlCheckbox } from '../../../components/UI/Checkbox';
import { ONLINE_WORKPLACE_ID } from '../../../constants/constants';
import { useGetWorkplaces } from '../../../graphql/hooks/useQueries';
import { getObjectNameAndIdFromArray } from '../../../utils/getDifferentFormatOfData';
import { StyledChooseWorkPlaces } from '../styles';

export const ChooseWorkPlaces = ({ control, errors, isOnline }) => {
  const { t, i18n } = useTranslation();

  const { data } = useGetWorkplaces();

  const filterOptions = () => {
    return data?.getWorkplaces && !isOnline
      ? getObjectNameAndIdFromArray(
          data.getWorkplaces.filter((place) => +place.id !== ONLINE_WORKPLACE_ID),
          i18n,
        )
      : data?.getWorkplaces
      ? getObjectNameAndIdFromArray(data?.getWorkplaces, i18n)
      : [];
  };

  return (
    <StyledChooseWorkPlaces display={'flex'} flexDirection={'column'}>
      <Typography variant={'h5'} marginBottom={'4px'}>
        {t('serviceLocation')}
      </Typography>
      <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        <ControlCheckbox
          control={control}
          name={'workplaces'}
          withoutCheckboxIcon={true}
          margin={'0'}
          options={filterOptions()}
        />
      </Box>
      {errors?.workplaces?.message && (
        <Typography
          fontSize={12}
          color={'#D32F2F'}
          maxWidth={'189px'}
          lineHeight={'16px'}
          marginTop={'5px'}
        >
          {t(errors?.workplaces?.message)}
        </Typography>
      )}
    </StyledChooseWorkPlaces>
  );
};
