import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks';
import palette from '../../theme/palette';
import Reservation from '../Reservation';
import { BlockDescription, StyledRreservations } from './styles';

const ReservationsList = ({ loading, reservations }) => {
  const { userInfo } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      <StyledRreservations>
        {reservations && reservations.length === 0 && (
          <BlockDescription>
            {userInfo.role === 'PROVIDER' ? t('emptyReservationsProvider') : t('emptyReservations')}
          </BlockDescription>
        )}
        {loading ? (
          <CircularProgress
            sx={{
              color: palette.primary,
            }}
          />
        ) : (
          reservations &&
          reservations.length > 0 &&
          reservations.map((reservation) => (
            <Reservation reservation={reservation} key={reservation.id} />
          ))
        )}
      </StyledRreservations>
    </>
  );
};

export default ReservationsList;
