import { gql } from '@apollo/client';

import { CATEGORY } from './category.fragment';

export const SERVICE = gql`
  ${CATEGORY}
  fragment ServiceFragment on Service {
    id
    category {
      ...CategoryFragment
    }
    price
    pricePer
    createdAt
    subcategories {
      id
      categoryId
      photo
      slug
      order
      isOnline
      parentCategorySlug
      translations {
        languageCode
        name
      }
    }
    currencyCode
    address
    workplaces {
      id
      translations {
        languageCode
        name
      }
    }
    servicePhotos {
      path
      about
    }
    certificatePhotos {
      path
      about
    }
    inFavorites
    status
    subservices {
      id
      price
      pricePer
      currencyCode
      translations {
        languageCode
        description
      }
    }
    enableBooking
    translations {
      languageCode
      description
    }
  }
`;
