import { Box } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../../components/DayPicker';
import ReservationsList from '../../../components/ReservationsList';
import Button from '../../../components/UI/Button';
import Pagination from '../../../components/UI/Pagination';
import { Select } from '../../../components/UI/Select';
import { ORDERS_PER_PAGE, RESERVATIONS_FILTER_VARIANTS } from '../../../constants/constants';
import {
  useGetGroupedByDateIntervalOrders,
  useGetNonWorkingHoursByDateInterval,
  useGetOrders,
  useGetWorkSchedule,
} from '../../../graphql/hooks/useQueries';
import { useAuth, usePagination } from '../../../hooks';
import palette from '../../../theme/palette';
import { getObjectByValue } from '../../../utils/getDifferentFormatOfData';
import { getWeekendsArray } from '../../../utils/getWeekendsArray';
import { ListWithPagination, ListWrapper } from '../styles';

const TabReservations = () => {
  const { userInfo } = useAuth();
  const { t, i18n } = useTranslation();

  const [day, setDay] = useState();
  const [bookedDays, setBookedDays] = useState();
  const [ordersList, setOrdersList] = useState();

  const [selectedFilter, setSelectedFilter] = useState(
    RESERVATIONS_FILTER_VARIANTS[i18n.resolvedLanguage][0],
  );

  const [offset, setOffset] = useState(0);
  const [ordersCounter, setOrdersCounter] = useState(0);
  const { loading, fetchMore, refetch } = useGetOrders({
    variables: {
      offset,
      limit: ORDERS_PER_PAGE,
      periodType: selectedFilter.value,
      ...(day && {
        periodValue: format(day, 'yyyy-MM-dd'),
      }),
    },
    onCompleted: (data) => {
      setOrdersList(data?.getOrders?.rows);
      setOrdersCounter(data?.getOrders?.count);
    },
  });

  const { onChangeHandler } = usePagination(fetchMore, refetch, ORDERS_PER_PAGE, setOffset);

  const { data: schedule } = useGetWorkSchedule({
    variables: {
      userId: userInfo.id,
    },
  });

  useGetGroupedByDateIntervalOrders({
    variables: {
      providerId: userInfo.id,
      month: format(day || Date.now(), 'yyyy-MM-dd'),
    },
    onCompleted: (data) => {
      const booked = data?.getGroupedByDateIntervalOrders?.map((d) => new Date(d.date));
      setBookedDays(booked);
    },
  });

  const [disabledDays, setDisabledDays] = useState();
  useGetNonWorkingHoursByDateInterval({
    variables: {
      providerId: userInfo.id,
      month: format(day || Date.now(), 'yyyy-MM-dd'),
    },
    onCompleted: (data) => {
      const arrayOfNonWorkingDays = data?.getGroupedByDateIntervalNonWorkingHours
        ?.filter((dayOff) => {
          const nonWorkingDay = dayOff?.intervals?.find((hour) => {
            return (
              hour.startTime === `${format(new Date(dayOff.date), 'yyyy-MM-dd')}T00:00:00.000Z` &&
              hour.endTime === `${format(new Date(dayOff.date), 'yyyy-MM-dd')}T23:59:59.000Z`
            );
          });
          return nonWorkingDay;
        })
        .map((d) => new Date(d.date));
      setDisabledDays(arrayOfNonWorkingDays);
    },
  });

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const setDayHandler = (date) => {
    if (date) {
      setSelectedFilter({
        id: Date.now(),
        name: t('bookingsPerDay'),
        value: 'DAY',
      });
      setDay(date);
    }
  };

  const setMonthHandler = (date) => {
    if (date) {
      const filter = getObjectByValue(RESERVATIONS_FILTER_VARIANTS[i18n.resolvedLanguage], 'MONTH');
      setSelectedFilter(filter);
      setDay(date);
    }
  };

  const resetToMonthHandler = () => {
    const filter = getObjectByValue(RESERVATIONS_FILTER_VARIANTS[i18n.resolvedLanguage], 'MONTH');
    setSelectedFilter(filter);
  };

  return (
    <Box display='flex' flexWrap='wrap' justifyContent='space-between'>
      <ListWrapper>
        {userInfo.role === 'PROVIDER' && (
          <Box marginBottom='40px' width='100%'>
            <Select
              value={selectedFilter.name}
              dataArray={RESERVATIONS_FILTER_VARIANTS[i18n.resolvedLanguage]}
              className='fullWidh'
              onChange={handleChange}
              isCustomValue
            />
          </Box>
        )}
        <ListWithPagination>
          <ReservationsList reservations={ordersList} loading={loading} />
          <Pagination onChange={onChangeHandler} elementsCount={ordersCounter} />
        </ListWithPagination>
      </ListWrapper>
      {userInfo.role === 'PROVIDER' && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <DatePicker
            days={day}
            setDays={setDayHandler}
            bookedDays={bookedDays}
            disabledDays={disabledDays}
            disableWeekends={getWeekendsArray(schedule?.getWorkSchedule?.workdays)}
            setMonth={setMonthHandler}
          />
          <Button
            sx={{
              textTransform: 'inherit',
              color: `${palette.blue} !important`,
            }}
            className={'buttonWhite sm'}
            margin={'0 10px 0 0'}
            title={t('AllInMonth')}
            onClickButton={resetToMonthHandler}
          />
        </Box>
      )}
    </Box>
  );
};

export default TabReservations;
