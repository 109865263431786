import { Button } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../../theme';
import palette from '../../../theme/palette';

export const ButtonText = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '12px',
  fontSize: '12px',
  color: '#706f7a',
  cursor: 'pointer',
  textTransform: 'none',
  padding: 0,
  height: 20,
  ':hover': {
    backgroundColor: 'transparent',
  },
  svg: {
    marginRight: '6px',
  },
});

export const StyledButtonLogin = styled(Button)({
  padding: 0,
  color: `${palette.primary}!important`,
  fontSize: '14px',
  fontWeight: 600,
  cursor: 'pointer',
  textTransform: 'none',

  [theme.breakpoints.down('lg')]: {
    color: palette.blue,
  },
});

export const StyledButton = styled(Button)({
  width: '216px',
  height: '60px',
  background:
    'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%),' +
    ' linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9',
  borderRadius: '18px',
  textTransform: 'capitalize',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '20px',
  display: 'block',

  '&:hover': {
    boxShadow: 'none',
  },

  '&.md': {
    height: '44px',
    width: '136px',
    borderRadius: '12px',
  },

  '&.sm': {
    height: '40px',
    borderRadius: '12px',
    display: 'flex',
  },

  '&.fullWidth': {
    width: '100%',
    textTransform: 'none',
  },

  '&.buttonGray': {
    background: palette.seashell,
    color: palette.primary,
  },

  '&.buttonWhite': {
    width: 'fit-content',
    background: palette.secondary,
    color: palette.primary,
  },

  '&.searchButton': {
    flexShrink: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,

    [theme.breakpoints.down('md')]: {
      height: '48px',
      maxWidth: '101px!important',
    },
  },

  '&.profileButton': {
    height: '40px',
    margin: '0 auto !important',
    padding: 0,
    color: palette.blue,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    background: 'none',

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  '&.saveButton': {
    minWidth: 'auto',
    width: 'auto',
    height: '54px',
    padding: '0 15px 0 10px',
    color: palette.primary,
    background: palette.seashell,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: `1px solid #e0e0e1 !important`,
    borderLeft: 'none !important',

    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      padding: '8px 0 0 0',
      color: palette.blue,
      background: 'none',
      border: 'none !important',
    },
  },

  '& .MuiButton-startIcon': {
    display: 'unset',
  },

  [theme.breakpoints.down('md')]: {
    width: '200px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
    lineHeight: '16px',
  },
});
