import { Select } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../../theme';
import palette from '../../../theme/palette';

export const SelectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '.errorSelect': {
    border: '1px solid #d32f2f!important',
  },
  '.error': {
    color: '#d32f2f',
    paddingLeft: '20px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 400,
    marginTop: '-8px',
  },
  [theme.breakpoints.down('sm')]: {
    '.error': {
      marginTop: 0,
    },
  },
});

export const StyledSelect = styled(Select)({
  '&.MuiInputBase-root': {
    margin: '24px 0 14px',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '12px',
    background: palette.desertStorm,
    width: '456px',
    height: '56px',
  },
  '&.fullWidh': {
    width: '100%',
    margin: 0,
  },
  '&.shortSelect': {
    width: '96px',
    margin: 0,
  },
  '.MuiSelect-select': {
    padding: '16px 20px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '+ span': {
    color: palette.oldLavender,
    marginBottom: '92px',
    display: 'block',
  },
  [theme.breakpoints.down('sm')]: {
    '&.MuiInputBase-root': {
      width: '100%',
    },
  },
});
