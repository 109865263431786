import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdatePhoto } from '../../graphql/hooks/useMutations';
import { GET_SERVICE } from '../../graphql/queries';
import { useToast } from '../../hooks';
import { useSetImage } from '../../hooks/useSetImage';
import NoImgIcon from '../../icons/NoImgIcon';
import RemoveIcon from '../../icons/RemoveIcon';
import palette from '../../theme/palette';
import { Image } from '../ProfileForm/styles';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import {
  CenterTypography,
  Form,
  StyledCreateOrderFooter,
  StyledEditPhotoWrap,
  StyledPhotoPhotoWrap,
  StyledUploadZone,
  SymbolCounter,
} from './styles';

export const EditPhoto = ({ handleClose, modalData }) => {
  const { t } = useTranslation();
  const [variant, setToastConfig] = useToast();

  const [oldImg, setOldImg] = useState(modalData?.path);
  const [img, setImg] = useState();

  const [updadePhoto] = useUpdatePhoto({
    onCompleted() {
      handleClose();
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
  });

  const [symbolCount, setSymbolCount] = useState(100);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: '',
    },
  });
  const { handleSetImage } = useSetImage('photo', setImg, setValue, clearErrors, setError);

  const textLength = watch('text').length;

  useEffect(() => {
    if (modalData?.isSinglePhotoUpdate) {
      setImg(modalData?.path);
    }
  }, [modalData?.isSinglePhotoUpdate]);

  useEffect(() => {
    setSymbolCount(100 - textLength);
  }, [textLength]);

  useEffect(() => {
    setValue('text', modalData?.about || '');
  }, []);

  const handleRemoveImg = () => {
    setImg(null);
    setOldImg(null);
  };

  const handleImage = (event) => {
    const uploadImage = event.target.files[0];
    handleSetImage(uploadImage);
  };

  const onSubmit = async ({ text, photo }) => {
    const formData = new FormData();
    formData.append('photo', photo);

    if (modalData?.isSinglePhotoUpdate) {
      const key =
        modalData?.isSinglePhotoUpdate?.type === 'SERVICE' ? 'servicePhotos' : 'certificatePhotos';

      modalData?.isSinglePhotoUpdate?.setUploadedPhotos((previous) => {
        return {
          ...previous,
          [key]: [
            ...previous[key],
            {
              path:
                formData.get('photo') !== 'undefined'
                  ? URL.createObjectURL(formData.get('photo'))
                  : modalData.path,
              about: text,
            },
          ],
        };
      });
      modalData?.isSinglePhotoUpdate?.setConvertedPhotosArray((previous) => {
        return {
          ...previous,
          [key]: [
            ...previous[key],
            {
              file:
                formData.get('photo') !== 'undefined'
                  ? formData.get('photo')
                  : modalData?.isSinglePhotoUpdate?.photo,
              about: text,
            },
          ],
        };
      });

      handleClose();
    } else {
      updadePhoto({
        variables: {
          path: modalData?.path,
          about: text,
          ...(formData.get('photo') !== 'undefined' && {
            photo: formData.get('photo'),
          }),
        },
        refetchQueries: [GET_SERVICE],
      });
    }
  };

  return (
    <Box paddingBottom={'20px'}>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('changePhoto')}
      </CenterTypography>
      <CenterTypography
        margin={'0 0 20px 0'}
        variant={'body2'}
        color={palette.oldLavender}
        fontWeight={400}
      >
        {t('photoRules')}
      </CenterTypography>

      {oldImg || img ? (
        <>
          <StyledEditPhotoWrap>
            <StyledPhotoPhotoWrap onClick={handleRemoveImg}>
              <RemoveIcon />
            </StyledPhotoPhotoWrap>
            <Image src={img || `${process.env.REACT_APP_API_HOST}/${oldImg}`} alt={'#'} />
          </StyledEditPhotoWrap>
          {errors?.photo?.message && (
            <Typography
              fontSize={12}
              color={'#D32F2F'}
              maxWidth={'189px'}
              align={'center'}
              lineHeight={'16px'}
              margin={'0 auto'}
            >
              {t(errors?.photo?.message)}
            </Typography>
          )}
        </>
      ) : (
        <StyledUploadZone>
          <ControlInput
            control={control}
            name={'photo'}
            type={'file'}
            accept={'image/*'}
            multiple
            wrapperClassName={'hiddenServicePhotoUpload'}
            onChange={handleImage}
          />
          <NoImgIcon width={'50px'} height={'50px'} />
        </StyledUploadZone>
      )}

      <Box marginTop={'20px'}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlInput
            control={control}
            value={watch('text')}
            name={'text'}
            rows={2}
            multiline
            placeholder={t('addComment')}
            inputClassName={'serviceDescription'}
            maxLength={100}
          />

          <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount} ${t(
            'symbol',
            {
              count: symbolCount,
            },
          )}`}</SymbolCounter>

          <StyledCreateOrderFooter>
            <Button
              className={'buttonWhite sm'}
              margin={'0 10px 0 0'}
              title={t('cancel')}
              onClickButton={handleClose}
            />
            <Button
              className={'sm'}
              sx={{
                width: 'fit-content',
                padding: '0 24px !important',
                marginLeft: '10px',
              }}
              type={'submit'}
              title={t('save')}
            />
          </StyledCreateOrderFooter>
        </Form>
      </Box>
    </Box>
  );
};
