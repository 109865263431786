import { styled } from '@mui/system';

import theme from '../../../theme';

export const StyledBreadcrums = styled('div')({
  svg: {
    marginRight: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
});
