import { gql } from '@apollo/client';

export const ADD_NON_WORKING_TIME = gql`
  mutation AddNonWorkingHour($input: NonWorkingHourInput!) {
    addNonWorkingHour(input: $input) {
      endTime
      id
      startTime
      userId
    }
  }
`;
