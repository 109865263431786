import { Chip } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const ChipWrapper = styled('div')(({ margin }) => ({
  margin: margin || '16px 0 0',
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
}));

export const FixedBlock = styled('div')({
  maxWidth: '456px',
  height: '32px',
  overflow: 'hidden',
  marginBottom: '16px',

  '&.full': {
    maxWidth: '456px',
    height: 'auto',
    marginBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    '&.full': {
      maxWidth: '100%',
    },
  },
});

export const StyledChip = styled(Chip)({
  background: 'rgba(123, 238, 64, 0.2)',
  height: '32px',
  padding: '6px 20px',
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ':not(:last-child)': {
    marginRight: '12px',
  },
  '.MuiChip-label': {
    padding: 0,
    letterSpacing: '.4',
  },
});

export const ShowMore = styled('button')({
  background: palette.seashell,
  border: 'none',
  minWidth: '38px',
  height: '32px',
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'absolute',
  right: '0',
  '&.hidden': {
    display: 'none',
  },
});
