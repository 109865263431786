import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Container from '../../components/Container';
import ModalComponent from '../../components/Modal';
import Divider from '../../components/UI/Divider';
import { ONLINE_WORKPLACE_ID, ROUTES } from '../../constants/constants';
import {
  useCreateService,
  useDeletePhoto,
  useDeleteService,
  useUpdateService,
} from '../../graphql/hooks/useMutations';
import { useGetLocales, useGetService } from '../../graphql/hooks/useQueries';
import { GET_CURRENT_USER } from '../../graphql/queries';
import { useAuth, useImageZoom, useToast } from '../../hooks';
import theme from '../../theme';
import palette from '../../theme/palette';
import {
  convertFormatCurrency,
  convertFormatPricePer,
  convertImageFileToObject,
  convertToSeconds,
} from '../../utils/convert';
import {
  getIdFromArray,
  transformLocalesToArrayTranslation,
  transformLocalesToObjectWithUserData,
} from '../../utils/getDifferentFormatOfData';
import { createServiceSchema } from '../../validation/schema/createServiceSchema';
import {
  ButtonManageWrapper,
  ChooseServiceLocation,
  ChooseWorkPlaces,
  EnableOnlineBooking,
  ImagesBlock,
  PriceAndDescription,
  SelectService,
  SelectServiceTypes,
  SubServicePricesBlock,
} from './components';
import {
  Heading,
  MobilePadding,
  MyServiceWrapper,
  StyledForm,
  StyledsubTitle,
  TopPanel,
} from './styles';

const MenuProperties = {
  PaperProps: {
    style: {
      maxHeight: '235px',
    },
  },
};

const MyServices = () => {
  const { t } = useTranslation();
  const [urlParameters] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: locales } = useGetLocales();

  const serviceId = urlParameters.get('id');
  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState('signIn');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDataArray, setModalDataArray] = useState([]);
  const [service, setService] = useState();
  const [dataFromModal, setDataFromModal] = useState([]);
  const [category, setCategory] = useState();
  const [typeUploadedPhotos, setTypeUploadedPhotos] = useState();
  const [uploadedPhotos, setUploadedPhotos] = useState({
    servicePhotos: [],
    certificatePhotos: [],
  });
  const [convertedPhotosArray, setConvertedPhotosArray] = useState({
    servicePhotos: [],
    certificatePhotos: [],
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      categoryId: service?.category?.id || '',
      price: '',
      pricePer: t('DAY'),
      currencyCode: t('RSD'),
      subservices: [],
      enableBooking: false,
      subcategoryIds: [],
      about: {
        ru: '',
        sr: '',
        en: '',
      },
      servicePhotos: [],
      certificatePhotos: [],
      workplaces: [],
      selectCountry: '',
      selectCity: '',
      address: '',
    },
    resolver: yupResolver(createServiceSchema),
  });

  const [servicePhotos, setServicePhotos] = useState([]);
  const [certificatePhotos, setCertificatePhotos] = useState([]);
  const [servicePhotosFormData, setServicePhotosFormData] = useState(new FormData());
  const [certificatePhotosFormData, setCertificatePhotosFormData] = useState(new FormData());

  const { loading: serviceLoading, refetch } = useGetService({
    variables: {
      getServiceId: serviceId,
    },
    skip: !serviceId,
    onCompleted: (data) => {
      const serviceTranslations = transformLocalesToObjectWithUserData(
        locales?.getLocales,
        data?.getService?.translations,
      );

      const formattedSubservices = data?.getService?.subservices?.map((sub) => {
        const subTranslations = transformLocalesToObjectWithUserData(
          locales?.getLocales,
          sub?.translations,
        );
        return {
          ...sub,
          description: {
            ru: subTranslations.ru.userTranslate.description,
            sr: subTranslations.sr.userTranslate.description,
            en: subTranslations.en.userTranslate.description,
          },
        };
      });

      setValue('categoryId', data?.getService?.category?.id || '');
      setValue('subcategoryIds', getIdFromArray(data?.getService?.subcategories) || []);
      setValue('price', data?.getService?.price);
      setValue('pricePer', t(data?.getService?.pricePer) || t('DAY'));
      setValue('currencyCode', t(data?.getService?.currencyCode) || t('RSD'));
      setValue('description', {
        ru: serviceTranslations?.ru?.userTranslate?.description,
        sr: serviceTranslations?.sr?.userTranslate?.description,
        en: serviceTranslations?.en?.userTranslate?.description,
      });
      setValue('subservices', formattedSubservices || []);
      setValue('enableBooking', data?.getService?.enableBooking);
      setValue('selectCountry', data?.getService?.country?.id || '');
      setValue('selectCity', data?.getService?.city?.id || '');
      setValue('workplaces', data?.getService?.workplaces || []);
      setValue('address', data?.getService?.address);
      setService(data?.getService);
      setServicePhotos(data?.getService?.servicePhotos);
      setCertificatePhotos(data?.getService?.certificatePhotos);
    },
    onError: () => navigate('/'),
  });
  const [deletePhoto] = useDeletePhoto();
  const [createService, { loading: loadingCreateService }] = useCreateService();
  const [updateService, { loading: loadingUpdateService }] = useUpdateService();
  const [variant, setToastConfig, setSpecificErrorInformation] = useToast();
  const { token, userInfo, logout } = useAuth();
  const [deleteService] = useDeleteService({
    onCompleted: (data) =>
      data.deleteService ? navigate(ROUTES.profile) : setToastConfig(variant.errorDelete),
    onError: () => setToastConfig(variant.errorDelete),
    refetchQueries: [GET_CURRENT_USER],
  });

  const { handleZoomImage } = useImageZoom(setIsModalOpen, setModalType, setModalData);

  const selectedCategoryId = watch('categoryId');
  const selectedWorkplaces = watch('workplaces');

  useEffect(() => {
    if (!serviceId) {
      trigger();
    }
  }, [serviceId]);

  useEffect(() => {
    if (serviceId) refetch();
  }, [location.pathname]);

  useEffect(() => {
    if (!token || !userInfo) logout();
  }, [token, userInfo]);

  useEffect(() => {
    const isServicePhotos = typeUploadedPhotos === 'SERVICE';
    const formData = new FormData();

    if (isServicePhotos) {
      for (const p of watch('servicePhotos').slice(servicePhotos?.length)) {
        formData.append('servicePhotos', p);
      }
      for (const [key] of servicePhotosFormData.entries()) {
        if (!formData.has(key)) {
          servicePhotosFormData.delete(key);
        }
      }
      setServicePhotosFormData(formData);
    } else {
      for (const p of watch('certificatePhotos').slice(certificatePhotos?.length))
        formData.append('certificatePhotos', p);
      for (const [key] of certificatePhotosFormData.entries()) {
        if (!formData.has(key)) {
          certificatePhotosFormData.delete(key);
        }
      }
      setCertificatePhotosFormData(formData);
    }
  }, [uploadedPhotos, service]);

  const handleDeleteService = (deleteServiceId) => async () => {
    await deleteService({
      variables: {
        deleteServiceId,
      },
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const handleEditPhoto =
    (path, about, isSinglePhotoUpdate = null) =>
    () => {
      setIsModalOpen(true);
      setModalData({
        path,
        about,
        isSinglePhotoUpdate,
      });
      setModalType('editPhoto');
    };

  const handleDeleteImage = (photo, type, uploadedPhotosArray, setPhotos, index) => async () => {
    if (uploadedPhotosArray.includes(photo)) {
      setValue(
        type,
        watch(type).filter((_, photoIndex) => uploadedPhotosArray[photoIndex] !== photo),
      );
      setConvertedPhotosArray((previous) => {
        return {
          ...previous,
          [type]: previous[type].filter(
            (_, indexElement) => uploadedPhotosArray[indexElement] !== photo,
          ),
        };
      });
      setUploadedPhotos((previous) => {
        return {
          ...previous,
          [type]: previous[type].filter((element) => element !== photo),
        };
      });

      const formData = type === 'servicePhotos' ? servicePhotosFormData : certificatePhotosFormData;
      formData.delete(type);
      for (const file of watch(type)) {
        if (file !== photo) {
          formData.append(type, file);
        }
      }
      if (type === 'servicePhotos') {
        setServicePhotosFormData(formData);
      } else {
        setCertificatePhotosFormData(formData);
      }
    } else {
      await deletePhoto({
        variables: {
          path: photo?.path,
        },
        onCompleted: () => {
          setToastConfig(variant.successDelete);
          const filterFunction = (array) => array.filter((element) => element.path !== photo?.path);
          setPhotos((previous) => filterFunction(previous));
          setValue(
            type,
            watch(type).filter((_, photoIndex) => {
              return photoIndex !== index;
            }),
          );
        },
      });
    }
  };

  const errorHandlerForFormSubmit = (error) => {
    const lotOfPhoto =
      [...servicePhotos, ...watch('servicePhotos')].length >
      userInfo?.tariffPlan?.countServicePhoto;
    const lotOfCertificate =
      [...certificatePhotos, ...watch('certificatePhotos')].length >
      userInfo?.tariffPlan?.countCertificatePhoto;

    if (error.message === 'EXCEED_PHOTO_COUNT' || lotOfPhoto || lotOfCertificate) {
      if (lotOfPhoto) setToastConfig(variant.notMorePhotoOfWorksThan);
      if (lotOfCertificate) setToastConfig(variant.notMorePhotoCertificatesThan);
    } else {
      console.log(error);
      setSpecificErrorInformation(error.message);
      setToastConfig(variant.error);
    }
  };

  const handleImage = (type) => (event) => {
    const newImageUrls = [];

    const singleImgEdit = {
      photo: event.target.files[0],
      type,
      setUploadedPhotos,
      setConvertedPhotosArray,
    };

    if (type === 'SERVICE') {
      for (const image of event.target.files) {
        const isBigSize = image.size / 1024 > 10240;
        if (image?.type.includes('image')) {
          if (isBigSize) {
            setToastConfig(variant.bigImageSize);
          } else {
            setValue('servicePhotos', [...watch('servicePhotos'), image]);
            newImageUrls.push(URL.createObjectURL(image));
          }
        }
      }
      if (newImageUrls.length === 1) {
        if (
          [...servicePhotos, ...watch('servicePhotos')].length >
          userInfo?.tariffPlan?.countServicePhoto
        ) {
          setValue('servicePhotos', [...watch('servicePhotos')].slice(0, -1));

          setToastConfig(variant.notMorePhotoOfWorksThan);
        } else {
          handleEditPhoto(newImageUrls[0], '', singleImgEdit)();
        }
      }
      if (newImageUrls.length > 1) {
        setUploadedPhotos((previous) => {
          return {
            ...previous,
            servicePhotos: [...previous.servicePhotos, ...convertImageFileToObject(newImageUrls)],
          };
        });
        setConvertedPhotosArray({
          ...convertedPhotosArray,
          servicePhotos: watch('servicePhotos').map((photo, index) => {
            return {
              file: photo,
              about: convertedPhotosArray?.servicePhotos?.[index]?.about || '',
            };
          }),
        });
      }
    }
    if (type === 'CERTIFICATE') {
      for (const image of event.target.files) {
        const isBigSize = image.size / 1024 > 10240;
        if (image?.type.includes('image')) {
          if (isBigSize) {
            setToastConfig(variant.bigImageSize);
          } else {
            setValue('certificatePhotos', [...watch('certificatePhotos'), image]);
            newImageUrls.push(URL.createObjectURL(image));
          }
        }
      }
      if (newImageUrls.length === 1) {
        if (
          [...certificatePhotos, ...watch('certificatePhotos')].length >
          userInfo?.tariffPlan?.countCertificatePhoto
        ) {
          setValue('certificatePhotos', [...watch('certificatePhotos')].slice(0, -1));
          setToastConfig(variant.notMorePhotoCertificatesThan);
        } else {
          handleEditPhoto(newImageUrls[0], '', singleImgEdit)();
        }
      }
      if (newImageUrls.length > 1) {
        setUploadedPhotos((previous) => {
          return {
            ...previous,
            certificatePhotos: [
              ...previous.certificatePhotos,
              ...convertImageFileToObject(newImageUrls),
            ],
          };
        });
        setConvertedPhotosArray({
          ...convertedPhotosArray,
          certificatePhotos: watch('certificatePhotos').map((photo, index) => {
            return {
              file: photo,
              about: convertedPhotosArray?.certificatePhotos?.[index]?.about || '',
            };
          }),
        });
      }
    }

    setTypeUploadedPhotos(type);
  };

  const isNonOnlyOnline = () =>
    selectedWorkplaces?.some((workplace) => Number(workplace.id) !== ONLINE_WORKPLACE_ID);

  const onSubmit = async ({
    categoryId,
    price,
    pricePer,
    description,
    currencyCode,
    subservices,
    enableBooking,
    subcategoryIds,
    workplaces,
    selectCountry,
    selectCity,
    address,
  }) => {
    const translations = transformLocalesToArrayTranslation(
      locales?.getLocales,
      description,
      'description',
    );

    const convertedSubservices = subservices.map(
      ({
        description: subsDescription,
        currencyCode: subsCurrencyCode,
        price: subsPrice,
        pricePer: subsPricePer,
        id: subsId,
      }) => {
        const subsTranslations = transformLocalesToArrayTranslation(
          locales?.getLocales,
          subsDescription,
          'description',
        );

        return {
          translations: subsTranslations,
          currencyCode: convertFormatCurrency(subsCurrencyCode),
          price: +subsPrice,
          pricePer:
            subsPricePer === 'number'
              ? subsPricePer.toString()
              : /\d/.test(subsPricePer)
              ? convertToSeconds(subsPricePer, t).toString()
              : convertFormatPricePer(subsPricePer, t),
          ...(subsId && {
            id: subsId,
          }),
        };
      },
    );

    const parameters = {
      categoryId,
      translations,
      price: +price,
      pricePer:
        typeof pricePer === 'number'
          ? pricePer.toString()
          : /\d/.test(pricePer)
          ? convertToSeconds(pricePer, t).toString()
          : convertFormatPricePer(pricePer, t),
      currencyCode: convertFormatCurrency(currencyCode),
      subservices: convertedSubservices,
      enableBooking,
      subcategoryIds,
      workplaceIds: getIdFromArray(workplaces),
      countryId: selectCountry || null,
      cityId: selectCity || null,
      servicePhotos: convertedPhotosArray.servicePhotos,
      certificatePhotos: convertedPhotosArray.certificatePhotos,
      address,
    };

    if (serviceId)
      await updateService({
        variables: {
          updateServiceId: serviceId,
          input: parameters,
        },
      })
        .then((result) => {
          const data = result?.data?.updateService;
          if (data.user) {
            setToastConfig(variant.success);
            setUploadedPhotos({
              servicePhotos: [],
              certificatePhotos: [],
            });
            setServicePhotosFormData(new FormData());
            setCertificatePhotosFormData(new FormData());
          }
          navigate(`${ROUTES.profile}?services`);
        })
        .catch((error) => {
          errorHandlerForFormSubmit(error);
        });
    else
      await createService({
        variables: {
          input: parameters,
        },
      })
        .then((result) => {
          const data = result?.data?.createService;
          if (data.user) {
            setToastConfig(variant.success);
            setUploadedPhotos({
              servicePhotos: [],
              certificatePhotos: [],
            });
            setServicePhotosFormData(new FormData());
            setCertificatePhotosFormData(new FormData());
            navigate(`${ROUTES.profile}?services`);
          }
        })
        .catch((error) => {
          errorHandlerForFormSubmit(error);
        });
  };

  return (
    <MyServiceWrapper>
      <Helmet>
        <title>{serviceId ? t('editService') : t('addNewService')}</title>
      </Helmet>
      <Container
        sx={{
          [theme.breakpoints.down('sm')]: {
            padding: 0,
          },
        }}
      >
        {serviceLoading ? (
          <Box p={'10px'} display='flex' justifyContent={'center'}>
            <CircularProgress
              sx={{
                color: palette.primary,
              }}
              size={20}
            />
          </Box>
        ) : (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <TopPanel>
              <Heading variant='h1'>{serviceId ? t('editService') : t('addNewService')}</Heading>
              <ButtonManageWrapper
                navigate={navigate}
                deleteService={handleDeleteService(serviceId)}
                loading={loadingCreateService || loadingUpdateService}
              />
            </TopPanel>
            <MobilePadding>
              <StyledsubTitle variant={'h5'}>{t('details')}</StyledsubTitle>
              <SelectService
                category={category}
                setCategory={setCategory}
                selectedCategoryId={selectedCategoryId}
                setModalDataArray={setModalDataArray}
                setDataFromModal={setDataFromModal}
                setValue={setValue}
                control={control}
                MenuProperties={MenuProperties}
              />
              <SelectServiceTypes
                subcategories={service?.subcategories}
                modalDataArray={modalDataArray}
                dataFromModal={dataFromModal}
                setDataFromModal={setDataFromModal}
                setModalType={setModalType}
                setIsModalOpen={setIsModalOpen}
                setValue={setValue}
                selectedCategoryId={selectedCategoryId}
                errors={errors}
              />
              <PriceAndDescription watch={watch} control={control} setValue={setValue} />
              <SubServicePricesBlock watch={watch} control={control} setValue={setValue} />
              <EnableOnlineBooking control={control} watch={watch} setValue={setValue} />
              <Divider margin={'15px 0'} />
              <ChooseWorkPlaces
                control={control}
                errors={errors}
                isOnline={modalDataArray?.subcategories?.some(
                  (sub) => watch('subcategoryIds').includes(+sub.id) && sub.isOnline,
                )}
              />
              <Divider margin={'15px 0'} />
              {isNonOnlyOnline() && (
                <>
                  <ChooseServiceLocation
                    serviceCity={service?.city}
                    serviceCountry={service?.country}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    watch={watch}
                  />
                  <Divider margin={'15px 0'} />
                </>
              )}
              <ImagesBlock
                uploadedPhotos={uploadedPhotos.servicePhotos}
                photosArray={[...servicePhotos, ...uploadedPhotos.servicePhotos]}
                setPhotosArray={setServicePhotos}
                photosType={'servicePhotos'}
                typeOfZoomedImage={'SERVICE'}
                control={control}
                title={'servicesPhoto'}
                handleZoomImage={handleZoomImage}
                handleEditPhoto={handleEditPhoto}
                handleDeleteImage={handleDeleteImage}
                handleImage={handleImage}
              />
              <ImagesBlock
                uploadedPhotos={uploadedPhotos.certificatePhotos}
                photosArray={[...certificatePhotos, ...uploadedPhotos.certificatePhotos]}
                setPhotosArray={setCertificatePhotos}
                photosType={'certificatePhotos'}
                typeOfZoomedImage={'CERTIFICATE'}
                control={control}
                title={'certificatesPhoto'}
                handleZoomImage={handleZoomImage}
                handleEditPhoto={handleEditPhoto}
                handleDeleteImage={handleDeleteImage}
                handleImage={handleImage}
              />
            </MobilePadding>
          </StyledForm>
        )}
        <ModalComponent
          type={modalType}
          open={isModalOpen}
          handleClose={handleCloseModal}
          setModalType={setModalType}
          modalDataArray={modalDataArray}
          dataFromModal={dataFromModal}
          setDataFromModal={setDataFromModal}
          modalData={modalData}
          languageModalProps={{
            setValue,
          }}
        />
      </Container>
    </MyServiceWrapper>
  );
};

export default MyServices;
