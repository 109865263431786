import { Button as MuiButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCancelOrder } from '../../graphql/hooks/useMutations';
import { useToast } from '../../hooks';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import { ButtonManage, CancelOrderWrapper, CenterTypography, Form, SymbolCounter } from './styles';

const CancelOrder = ({ handleClose, modalData }) => {
  const { t } = useTranslation();

  const [symbolCount, setSymbolCount] = useState(100);

  const [cancelOrder] = useCancelOrder();
  const [variant, setToastConfig] = useToast();

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      text: '',
    },
  });

  const textLength = watch('text').length;

  useEffect(() => {
    setSymbolCount(100 - textLength);
  }, [textLength]);

  const onCancelClick = () => {
    handleClose(true);
  };

  const onSubmit = async ({ text }) => {
    await cancelOrder({
      variables: {
        cancelOrderId: modalData.cancelOrderId,
        rejectionReason: text,
      },
      onCompleted() {
        handleClose();
      },
      onError() {
        setToastConfig(variant.error);
      },
    });
  };

  return (
    <CancelOrderWrapper>
      <CenterTypography variant={'h4'} className={'m-0'}>
        {t('cancellationOfBooking')}
      </CenterTypography>
      <CenterTypography
        variant={'body1'}
        component={'div'}
        className={'fw-400'}
        margin={'20px 0 16px 0'}
      >
        {t('reasonCancellation')}
      </CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlInput
          control={control}
          name={'text'}
          rows={3}
          multiline
          placeholder={t('addComment')}
          inputClassName={'reviewTextArea sm'}
          maxLength={100}
        />
        <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount} ${t(
          'symbol',
          {
            count: symbolCount,
          },
        )}`}</SymbolCounter>
        <ButtonManage>
          <MuiButton
            className={'cancel'}
            variant={'text'}
            onClick={onCancelClick}
            disableRipple
            disableFocusRipple
          >
            {t('cancel')}
          </MuiButton>
          <Button
            title={t('send')}
            className={'md'}
            variant={'text'}
            disableRipple
            disableFocusRipple
            type={'submit'}
          />
        </ButtonManage>
      </Form>
    </CancelOrderWrapper>
  );
};

export default CancelOrder;
