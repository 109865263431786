import { Box } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledFooter = styled('footer')({
  padding: '64px 0 62px',
  backgroundColor: palette.primary,

  '& .footerLink': {
    opacity: '0.7',
  },

  [theme.breakpoints.down('md')]: {
    padding: '30px 0',
  },
});

export const FooterWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  color: palette.secondary,

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const FooterRight = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',

  [theme.breakpoints.down('md')]: {
    textAlign: 'initial',
    marginTop: '20px',
  },
});

export const FooterEmail = styled('a')({});

export const FooterPhone = styled('a')({});
