import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ButtonAdd from '../../../components/UI/Button/buttonAdd';
import { ControlInput } from '../../../components/UI/Input';
import {
  useCreateContact,
  useDeleteContact,
  useUpdateContact,
} from '../../../graphql/hooks/useMutations';
import { useToast } from '../../../hooks';
import palette from '../../../theme/palette';
import { getPatternObject, getRequiredObject } from '../../../utils/validation';
import { Block, BlocksLine, DeleteInputButton, SpaceBetween } from '../styles';

const TabContacts = ({
  userLocalState,
  setUserLocalState,
  setModalType,
  setIsModalOpen,
  setModalData,
}) => {
  const { contacts } = userLocalState;

  const { control, watch, trigger, setValue } = useForm({
    defaultValues: {
      field: contacts[0]?.value,
    },
  });
  const { t } = useTranslation();

  const [createContact] = useCreateContact();
  const [updateContact] = useUpdateContact();
  const [deleteContact] = useDeleteContact();
  const [variant, setToastConfig] = useToast();

  const createFirstContact = async () => {
    const validated = await trigger('field');
    if (validated)
      await createContact({
        variables: {
          input: {
            type: 'PHONE',
            value: watch('field'),
          },
        },
      })
        .then((response) => {
          const result = response?.data?.createContact;
          setUserLocalState((previous) => ({
            ...previous,
            contacts: [
              {
                id: result?.id,
                type: result?.type,
                value: result?.value,
              },
            ],
          }));
          setToastConfig(variant.success);
        })
        .catch((error) => {
          console.log(error);
          setToastConfig(variant.error);
        });
    return null;
  };

  const updateContactWithId = async (contactId, type, value, fieldName, userContact) => {
    if (!watch(fieldName)) setValue(fieldName, userContact);
    const validated = await trigger(fieldName);
    if (validated && userContact !== watch(fieldName))
      await updateContact({
        variables: {
          id: contactId,
          input: {
            type,
            value,
          },
        },
      })
        .then((response) => {
          const result = response?.data?.updateContact;
          setUserLocalState((previous) => {
            const oldContacts = previous.contacts.filter((contact) => contact.id !== result?.id);
            return {
              ...previous,
              contacts: [
                ...oldContacts,
                {
                  id: result?.id,
                  type: result?.type,
                  value: result?.value,
                },
              ],
            };
          });
          setToastConfig(variant.success);
        })
        .catch((error) => {
          console.log(error);
          setToastConfig(variant.error);
        });
  };

  const removeContact = async (id) => {
    await deleteContact({
      variables: {
        deleteContactId: id,
      },
    }).then(() => {
      setUserLocalState((previous) => {
        const withoutDeleteContact = previous.contacts.filter((contact) => contact.id !== id);
        return {
          ...previous,
          contacts: [...withoutDeleteContact],
        };
      });
    });
  };

  const contactFieldSample = (contact, index, title, rules, fieldType) => {
    return (
      <Box key={contact.id}>
        <SpaceBetween display={'flex'} justifyContent={'space-between'}>
          <Typography margin={'8px 0'} color={palette.oldLavender} variant={'body1'}>
            {title}
          </Typography>
          <DeleteInputButton type={'button'} onClick={() => removeContact(contact.id)}>
            {t('delete')}
          </DeleteInputButton>
        </SpaceBetween>
        <ControlInput
          control={control}
          name={`field${index}`}
          rules={rules}
          type={fieldType}
          margin={'0 0 18px 0'}
          defaultValue={watch(`field${index}`) || contact.value}
          wrapperClassName='saveWrapper'
          inputClassName='borderSave'
          saveButton
          clickSaveButton={() =>
            updateContactWithId(
              contact.id,
              contact.type,
              watch(`field${index}`),
              `field${index}`,
              contact.value,
            )
          }
        />
      </Box>
    );
  };

  const contactTypeOptions = {
    PHONE: {
      label: 'typePhone',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      },
      type: 'text',
    },
    VIBER: {
      label: 'Viber',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      },
      type: 'text',
    },
    WHATSAPP: {
      label: 'WhatsApp',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('PHONE_PATTERN', t),
      },
      type: 'text',
    },
    TELEGRAM: {
      label: 'Telegram',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('TELEGRAM_NICKNAME_PATTERN', t),
      },
      type: 'text',
    },
    EMAIL: {
      label: 'typeEmail',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('EMAIL_PATTERN', t),
      },
      type: 'email',
    },
    INSTAGRAM: {
      label: 'Instagram',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'text',
    },
    FACEBOOK: {
      label: 'Facebook',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'text',
    },
    YOUTUBE: {
      label: 'Youtube',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'text',
    },
    TWITTER: {
      label: 'Twitter',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'text',
    },
    LINKEDIN: {
      label: 'Linkedin',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'text',
    },
    SOCIAL: {
      label: 'Website',
      props: {
        required: getRequiredObject(t),
        pattern: getPatternObject('SOCIAL_PATTERN', t),
      },
      type: 'url',
    },
  };

  const generateInput = (contact, index) => {
    const contactType = contactTypeOptions[contact.type] || {};
    const label =
      contactType.label ||
      contact.type.charAt(0).toUpperCase() + contact.type.slice(1).toLowerCase();
    const properties = contactType.props || {
      required: getRequiredObject(t),
      pattern: null,
    };
    const type = contactType.type || 'text';

    return contactFieldSample(contact, index, t(label), properties, type);
  };

  const openModal = () => {
    setModalType('addContactModal');
    setIsModalOpen(true);
    setModalData({
      contacts,
    });
  };

  return (
    <BlocksLine>
      <form>
        <Block className={'contacts'}>
          {contacts.length > 0 ? (
            contacts
              ?.slice()
              .sort((a, b) => a.id - b.id)
              .map((contact, index) => {
                return generateInput(contact, index);
              })
          ) : (
            <>
              <Typography margin={'8px 0'} color={palette.oldLavender} variant={'body1'}>
                {t('typePhone')}
              </Typography>
              <ControlInput
                control={control}
                name={'field'}
                placeholder={t('PHONE')}
                inputClassName={'borderSave'}
                type={'text'}
                margin={'0 0 18px 0'}
                defaultValue={watch('field')}
                wrapperClassName='saveWrapper'
                saveButton
                clickSaveButton={createFirstContact}
                rules={{
                  required: getRequiredObject(t),
                  pattern: getPatternObject('PHONE_PATTERN', t),
                }}
              />
            </>
          )}
          <ButtonAdd title='addContact' onClick={openModal} />
        </Block>
      </form>
    </BlocksLine>
  );
};

export default TabContacts;
