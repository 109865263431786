import { Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Container from '../../components/Container';
import { CatalogLink } from '../../components/ServiceCatalog/styles';
import Breadcrumbs from '../../components/UI/Breadcrumbs';
import Loader from '../../components/UI/Loader';
import { ROUTES } from '../../constants/constants';
import { useGetCategoryBySlug } from '../../graphql/hooks/useQueries';
import theme from '../../theme';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';
import { findFunction, getNameFromTranslations } from '../../utils/getDifferentFormatOfData';
import { Lists, StyledCatalog, StyledH1 } from './styles';

const CategoryList = () => {
  const [searchParameters] = useSearchParams();
  const categorySlug = searchParameters.get('category');
  const { i18n } = useTranslation();
  const [categoryMetatags, setCategoryMetatags] = useState([]);
  const { data: category, loading } = useGetCategoryBySlug({
    variables: {
      slug: categorySlug,
    },
    skip: !categorySlug,
  });

  const categoryName = getNameFromTranslations(category?.getCategoryBySlug?.translations, i18n);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (category) {
      setCategoryMetatags(findFunction(category?.getCategoryBySlug?.metatags, i18n));
    }
  }, [i18n.resolvedLanguage, category]);

  return (
    <StyledCatalog>
      <Helmet>
        <title>{categoryMetatags?.title}</title>
        <meta name='description' content={categoryMetatags?.description} />
        <meta name='keywords' content={categoryMetatags?.keywords} />
      </Helmet>
      <Container>
        <Box>
          <Breadcrumbs
            title={isMobile ? 'catalog' : 'home'}
            url={isMobile ? ROUTES.catalog : '/'}
          />
          <StyledH1 variant='h1'>{categoryName}</StyledH1>
        </Box>
      </Container>
      <Container>
        <Lists>
          {loading ? (
            <Loader />
          ) : (
            alphabeticalSorting(
              category?.getCategoryBySlug?.subcategories,
              (sub) => sub.translations,
              i18n,
            )?.map((sub) => {
              return (
                <Typography key={sub.id} variant='body2' component={'div'}>
                  <CatalogLink
                    to={`${ROUTES.service}?category=${sub.parentCategorySlug}&sub=${sub?.slug}`}
                  >
                    {
                      sub.translations.find(
                        (subTrans) => subTrans.languageCode === i18n.resolvedLanguage,
                      ).name
                    }
                  </CatalogLink>
                </Typography>
              );
            })
          )}
        </Lists>
      </Container>
    </StyledCatalog>
  );
};

export default CategoryList;
