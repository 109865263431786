import React from 'react';

import palette from '../../../theme/palette';

const Divider = ({ margin, color }) => {
  return (
    <hr
      color={color || palette.desertStorm}
      style={{
        margin: margin || 0,
      }}
    />
  );
};

export default Divider;
