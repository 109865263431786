import * as yup from 'yup';

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string('PROMPT_MAIL')
    .required('REQUIRE_MESSAGE')
    .test('no-space', 'SPACE_NOT_ALLOWED', (value) => {
      return value && !value.includes(' ');
    })
    .email('PROMPT_MAIL'),
  password: yup.string('PROMPT_PASSWORD').min(8, 'MIN_SYMBOL').required('REQUIRE_MESSAGE'),
});
