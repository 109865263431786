import { Box } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledHome = styled('div')({
  '& .homeTitle': {
    maxWidth: '700px',
    width: '100%',
  },

  [theme.breakpoints.down('lg')]: {
    '& .homeTitle': {
      display: 'none',
    },
  },
});

export const Reviews = styled(Box)({
  padding: '16px 0',
  [theme.breakpoints.down('md')]: {
    padding: '0',
    h1: {
      fontWeight: '600',
      fontSize: '24px',
      marginBottom: '16px',
      p: {
        fontSize: '14px',
      },
    },
  },
});

export const ReviewsWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',

  '> div': {
    width: '376px',
  },
});

export const LocationPanel = styled(Box)({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
  },
  [theme.breakpoints.down('sm')]: {
    '>div:first-of-type': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
});

export const OpenCatalog = styled(Box)({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    display: 'flex',
    background: palette.desertStorm,
    borderRadius: '16px',
    padding: '12px',
    margin: '10px 0 20px',
    a: {
      width: '100%',
    },
    button: {
      width: '100%',
      borderRadius: '14px',
      fontSize: '14px',
    },
  },
});
