import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledSocialLinks = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginTop: '16px',
});

export const SocialLink = styled('a')({
  marginRight: '16px',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  overflow: 'hidden',

  '&:last-child': {
    marginRight: 0,
  },
});
