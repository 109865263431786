import { gql } from '@apollo/client';

import { SERVICE } from './service.fragment';

export const USER = gql`
  ${SERVICE}
  fragment UserFragment on User {
    companyName
    email
    firstName
    countryId
    contacts {
      id
      type
      value
    }
    city {
      id
      translations {
        languageCode
        name
      }
    }
    id
    lastName
    locale
    middleName
    photo
    reviewsStatistic {
      average
      count
    }
    role
    services {
      ...ServiceFragment
    }
    dashboard {
      favorites
      visits {
        month
        viewCount
      }
      visitedCount
    }
    tariffPlan {
      countCertificatePhoto
      countService
      countServicePhoto
      countSubservice
      id
    }
    userLanguages {
      id
      translations {
        languageCode
        name
      }
    }
    translations {
      languageCode
      languageId
      about
    }
  }
`;
