import React from 'react';

import palette from '../theme/palette';

const AddIcon = ({ width = 20, height = 20, className, color = palette.oldLavender }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7507 16.3201L9.24883 16.3201V10.7509L3.67966 10.7509V9.24905L9.24883 9.24905V3.67988L10.7507 3.67988L10.7507 9.24905H16.3199V10.7509H10.7507L10.7507 16.3201Z'
        fill={color}
      />
    </svg>
  );
};

export default AddIcon;
