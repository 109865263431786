import { styled } from '@mui/system';
import { SnackbarProvider } from 'notistack';

import palette from './theme/palette';

export const StyledSnackbarProvider = styled(SnackbarProvider)({
  '.SnackbarItem-action svg, .SnackbarItem-action path': {
    stroke: palette.secondary,
    fill: palette.secondary,
  },
  '&.SnackbarItem-variantSuccess': {
    background:
      'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%),' +
      ' linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9',
  },
});
