import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from '../../components/Container';
import Logo from '../../components/Logo';
import SocialLinks from '../../components/SocialLinks';
import { PHONE_NUMBER } from '../../constants/constants';
import { FooterEmail, FooterPhone, FooterRight, FooterWrapper, StyledFooter } from './styles';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Container>
        <FooterWrapper>
          <Box>
            <Logo footerLogo />
            <Link href='/'>
              <Typography variant='body2' className='footerLink'>
                {t('footerLink')}
              </Typography>
            </Link>
            <SocialLinks />
          </Box>
          <FooterRight>
            <Typography variant='body2' className='footerLink' mt='-5px' mb='10px'>
              SVE U REDU DOO NOVI SAD
            </Typography>
            <Typography variant='body2' className='footerLink' mb='10px'>
              PIB: 113305047
            </Typography>
            <Typography variant='body2' className='footerLink' mb='10px'>
              MB: 21842753
            </Typography>
            <Typography variant='body2' className='footerLink' mb='10px'>
              e-mail:&nbsp;
              <FooterEmail href='mailto:sveuredu.rs@gmail.com'>sveuredu.rs@gmail.com</FooterEmail>
            </Typography>
            <Typography variant='body2' className='footerLink'>
              Tel:&nbsp;
              <FooterPhone href={`tel:+${PHONE_NUMBER}`}>{`+${PHONE_NUMBER}`}</FooterPhone>
            </Typography>
          </FooterRight>
        </FooterWrapper>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
