import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonAdd from '../../../components/UI/Button/buttonAdd';
import { ControlInput } from '../../../components/UI/Input';
import { HOST_IMAGE_URL } from '../../../constants/constants';
import { useAuth } from '../../../hooks';
import EditIcon from '../../../icons/EditIcon';
import RemoveIcon from '../../../icons/RemoveIcon';
import {
  BlockDescription,
  Gallery,
  Photo,
  PhotoWrap,
  StyledPhotoActions,
  StyledPhotoActionsButton,
  StyledRemoveIcon,
  StyledTitle,
} from '../styles';

export const ImagesBlock = ({
  uploadedPhotos,
  photosArray,
  setPhotosArray,
  photosType,
  typeOfZoomedImage,
  control,
  title,
  handleZoomImage,
  handleEditPhoto,
  handleDeleteImage,
  handleImage,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();

  return (
    <Box paddingBottom={'15px'}>
      <StyledTitle variant={'h5'}>{t(title)}</StyledTitle>
      {photosArray.length > 0 ? (
        <Gallery>
          {photosArray?.map((photo, index, array) => {
            const { path, about } = photo;
            return (
              <Box key={path}>
                <PhotoWrap>
                  {uploadedPhotos.includes(photo) && (
                    <StyledRemoveIcon
                      onClick={handleDeleteImage(
                        photo,
                        photosType,
                        uploadedPhotos,
                        setPhotosArray,
                        index,
                      )}
                    >
                      <RemoveIcon />
                    </StyledRemoveIcon>
                  )}
                  <Photo
                    src={path.includes('blob') ? path : `${HOST_IMAGE_URL}/${path}`}
                    alt={path}
                    onClick={() =>
                      handleZoomImage(
                        {
                          path: path.includes('blob') ? path : `${HOST_IMAGE_URL}/${path}`,
                          about,
                        },
                        index,
                        array,
                      )
                    }
                  />
                </PhotoWrap>
                {!uploadedPhotos.includes(photo) ? (
                  <StyledPhotoActions>
                    <>
                      <StyledPhotoActionsButton
                        label={t('change')}
                        icon={<EditIcon />}
                        clickable
                        onClick={handleEditPhoto(path, about)}
                      />
                      <StyledPhotoActionsButton
                        label={t('deletePhoto')}
                        icon={<RemoveIcon />}
                        clickable
                        onClick={handleDeleteImage(
                          photo,
                          photosType,
                          uploadedPhotos,
                          setPhotosArray,
                          index,
                        )}
                      />
                    </>
                  </StyledPhotoActions>
                ) : (
                  <StyledPhotoActions />
                )}
              </Box>
            );
          })}
        </Gallery>
      ) : (
        <BlockDescription>
          {t(photosType === 'certificatePhotos' ? 'noCertificatesPhoto' : 'noServicesPhoto')}
        </BlockDescription>
      )}
      <ButtonAdd>
        <ControlInput
          control={control}
          name={photosType}
          type={'file'}
          accept={'image/*'}
          multiple
          wrapperClassName={'hiddenServicePhotoUpload'}
          onChange={handleImage(typeOfZoomedImage)}
        />
      </ButtonAdd>
      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
        <Typography component={'span'} fontSize={10} align={'center'}>
          {t('imageSize')}
        </Typography>
        <Typography component={'span'} fontSize={10} align={'center'} marginTop={'-8px'}>
          (
          {t(photosType === 'certificatePhotos' ? 'maxCertificates' : 'maxPhotos', {
            count:
              photosType === 'certificatePhotos'
                ? userInfo?.tariffPlan?.countCertificatePhoto
                : userInfo?.tariffPlan?.countServicePhoto,
          })}
          )
        </Typography>
      </Box>
    </Box>
  );
};
