import { List } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const MenuList = styled(List)({
  /* Desktop */
  display: 'flex',
  alignItems: 'center',
  padding: 0,

  '& .MuiListItem-root': {
    width: 'auto',
    margin: '0 24px 0 0',
    padding: 0,

    '&:last-child': {
      marginRight: 0,
    },
  },

  '& .menuLink': {
    display: 'flex',
    padding: '6px 20px',
    color: palette.primary,

    '&.active, &:hover': {
      fontWeight: 600,
      background: palette.desertStorm,
      borderRadius: '20px',
    },
  },

  /* Adaptive */
  '&.adaptiveMenu': {
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& .MuiListItem-root': {
      '&:not(:last-of-type)': {
        margin: '0 0 20px 0',
      },
    },

    '& .menuLink': {
      fontSize: '16px',
      fontWeight: 600,
      padding: '6px 16px',
      '&.active': {
        color: palette.secondary,
        background:
          'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%),' +
          ' linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9',
      },
    },
  },
});
