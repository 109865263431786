import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useRef, useState } from 'react';
import { CookieConsent, getCookieConsentValue } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import CloseIcon from '../../icons/CloseIcon';
import logo from '../../images/logo.svg';
import Container from '../Container';
import Button from '../UI/Button';
import { BottomBlock, StyledCloseButton, StyledCookieNotification, TopBlock } from './styles';

const ConsentWithCookie = () => {
  const { t } = useTranslation();
  const cookieConsentReference = useRef();
  const [isHide, setIsHide] = useState(false);
  const [hideCookieNotification, setHideCookieNotification] = useState(getCookieConsentValue());

  const acceptCookie = () => {
    cookieConsentReference.current.accept();
    setHideCookieNotification(true);
  };
  const hideUserAgreement = () => {
    setIsHide(true);
  };

  return (
    <StyledCookieNotification
      isHideAgreement={isHide}
      sx={{
        display: hideCookieNotification && 'none',
      }}
    >
      <CookieConsent
        contentStyle={{
          margin: 0,
        }}
        location={'bottom'}
        expires={365}
        disableStyles={true}
        buttonStyle={{
          display: 'none',
        }}
        containerClasses={'wrapper'}
        ref={cookieConsentReference}
      >
        {!isHide && (
          <TopBlock>
            <Container>
              <Typography variant={'h5'} component={'div'} lineHeight={'22px'}>
                {t('continueUseThisSite')}{' '}
                <Link to={ROUTES.termsOfUse} className={'link'}>
                  {t('userAgreement')}
                </Link>
              </Typography>
              <StyledCloseButton onClick={hideUserAgreement}>
                <CloseIcon />
              </StyledCloseButton>
            </Container>
          </TopBlock>
        )}
        <BottomBlock>
          <Container>
            <Box>
              <img src={logo} alt={'SVEUREDU'} />
              <Typography variant={'h5'} component={'div'} margin={'0 24px'}>
                {t('thisSiteUseCookie')}
              </Typography>
            </Box>
            <Button title={t('accept')} onClickButton={acceptCookie} />
          </Container>
        </BottomBlock>
      </CookieConsent>
    </StyledCookieNotification>
  );
};

export default ConsentWithCookie;
