export const getLinkByContactType = (type, value) => {
  switch (type) {
    case 'PHONE': {
      return `tel:${value}`;
    }

    case 'EMAIL': {
      return `mailto:${value}`;
    }

    case 'VIBER': {
      return `viber://chat?number=%2B${value.slice(1)}`;
    }

    case 'TELEGRAM': {
      return `https://telegram.me/${value.slice(1)}`;
    }

    case 'WHATSAPP': {
      return `https://wa.me/${value}`;
    }

    default: {
      return value;
    }
  }
};
