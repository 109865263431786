import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';

export const StyledCatalog = styled(Box)({
  '.searchWrapper': {
    margin: 0,
  },
});

export const StyledH1 = styled(Typography)({
  marginTop: '10px',
});

export const Lists = styled('div')({
  marginTop: '20px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '100%',
  '> div': {
    width: '24%',
  },
  [theme.breakpoints.down('xl')]: {
    '> div': {
      width: '31%',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '> div': {
      width: '48%',
    },
  },
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
    '> div': {
      width: '100%',
    },
  },
});
