import { gql } from '@apollo/client';

import { SERVICE } from '../fragments';

export const CREATE_ORDER = gql`
  ${SERVICE}
  mutation CreateOrder($input: OrderInput!) {
    createOrder(input: $input) {
      comment
      createdAt
      id
      service {
        ...ServiceFragment
      }
      status
      endTime
      startTime
      updatedAt
    }
  }
`;
