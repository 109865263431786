import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import palette from '../../theme/palette';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';
import Service from '../Service';
import { BlockDescription, StyledRreservations } from './styles';

const ServicesList = ({ loading, services, onToast, setUserLocalState }) => {
  const { t, i18n } = useTranslation();
  const [deletedServiceIds, setDeletedServiceIds] = useState([]);

  return loading ? (
    <CircularProgress
      sx={{
        color: palette.primary,
      }}
    />
  ) : (
    <StyledRreservations>
      {services && services.length === 0 && (
        <BlockDescription>{t('emptyServices')}</BlockDescription>
      )}
      {services &&
        services.length > 0 &&
        alphabeticalSorting(services, (service) => service.category.translations, i18n)
          .filter((service) => !deletedServiceIds.includes(service.id))
          .map((service) => (
            <Service
              service={service}
              setDeletedServiceIds={setDeletedServiceIds}
              onToast={onToast}
              key={service?.id}
              setUserLocalState={setUserLocalState}
            />
          ))}
    </StyledRreservations>
  );
};

export default ServicesList;
