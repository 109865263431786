export const getWeekendsArray = (type) => {
  switch (type) {
    case 'MON_FRI': {
      return [0, 6];
    }

    case 'MON_SAT': {
      return [0];
    }

    default: {
      return [];
    }
  }
};
