import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../UI/Button';
import { FirstLoginStyled } from './styles';

const NeedLogin = ({ setModalType }) => {
  const { t } = useTranslation();

  const openLogin = () => setModalType('signIn');

  return (
    <FirstLoginStyled>
      <Typography variant={'h2'} textAlign={'center'}>
        {t('firstYouNeedToLogin')}
      </Typography>
      <Button type={'button'} title={t('input')} margin={'20px auto'} onClickButton={openLogin} />
    </FirstLoginStyled>
  );
};

export default NeedLogin;
