import { Box, Button as MuiButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import { useUpdateNotification } from '../../graphql/hooks/useMutations';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import palette from '../../theme/palette';
import { convertStatus } from '../../utils/convert';
import { getUTCHoursAndMinutes } from '../../utils/formatTime';
import { findFunction } from '../../utils/getDifferentFormatOfData';
import { getLocale } from '../../utils/getLocale';
import DefaultImage from '../DefaultImage';
import Button from '../UI/Button';
import Dot from '../UI/Dot';
import {
  Answer,
  AnswerActions,
  Avatar,
  CenteredText,
  CenteredTypography,
  StyledActionBlock,
  StyledChip,
  StyledReservation,
  StyledReservationInfo,
  StyledUserInfo,
} from './styles';

const UserNotificationItem = ({
  notification,
  question = false,
  handleOpenModal,
  showNotificationsWindow,
}) => {
  const { t, i18n } = useTranslation();
  const providerUrl = `${ROUTES.provider}/${notification?.order?.service?.id}`;

  const [updateNotification] = useUpdateNotification();

  const yesClickHandler = async () => {
    showNotificationsWindow(null);
    await updateNotification({
      variables: {
        updateNotificationId: notification.id,
        input: {
          questionResult: true,
        },
      },
    });
    handleOpenModal(notification?.order?.service?.user?.id, notification?.order?.service, 'yes');
  };

  const noClickHandler = async () => {
    showNotificationsWindow(null);
    await updateNotification({
      variables: {
        updateNotificationId: notification.id,
        input: {
          questionResult: false,
        },
      },
    });
    handleOpenModal(notification?.order?.service?.user?.id, notification?.order?.service, 'no');
  };

  return (
    <StyledReservation>
      <StyledUserInfo>
        <Box display='flex'>
          {notification?.order?.service?.user?.photo ? (
            <Link to={`${providerUrl}`}>
              <Avatar
                src={`${HOST_IMAGE_URL}/${notification?.order?.service?.user?.photo}`}
                alt={'avatar'}
              />
            </Link>
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <StyledReservationInfo>
            <CenteredTypography variant={'body1Semibold'} component={'div'}>
              {findFunction(notification?.order?.service?.category?.translations, i18n)?.name}
            </CenteredTypography>
            <CenteredTypography variant={'body2'} component={'div'} color={palette.oldLavender}>
              {format(new Date(notification?.order?.startTime), 'EEEE', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {format(new Date(notification?.order?.startTime), 'dd.MM', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {`${getUTCHoursAndMinutes(notification?.order?.startTime)}-${getUTCHoursAndMinutes(
                notification?.order?.endTime,
              )}`}
            </CenteredTypography>
            <CenteredTypography component={'div'} className={'master-info'}>
              <Link to={`${providerUrl}`}>
                <Typography variant={'body2'} component={'div'}>
                  {`${notification?.order?.service?.user?.firstName} ${notification?.order?.service?.user?.lastName}`}
                </Typography>
              </Link>
              <Dot />
              <CenteredText>
                <EmptyStarIcon />
                <span>
                  {notification?.order?.service?.user?.reviewsStatistic?.average?.toFixed(1) ||
                    t('noRating')}
                </span>
              </CenteredText>
              <Dot />
              <Link to={`${providerUrl}?reviews`}>
                <CenteredText>
                  {notification?.order?.service?.user?.reviewsStatistic?.count || 0}{' '}
                  {t('endingOfComments', {
                    count: notification?.order?.service?.user?.reviewsStatistic?.count || 0,
                  })}
                </CenteredText>
              </Link>
            </CenteredTypography>
          </StyledReservationInfo>
        </Box>
        <StyledActionBlock>
          {question ? (
            notification?.questionResult !== null && notification?.questionResult === true ? (
              <Answer>
                <Button title={t('yes')} className={'sm disabled'} margin={'0'} disabled />
              </Answer>
            ) : notification?.questionResult !== null && notification?.questionResult === false ? (
              <Answer>
                <MuiButton
                  variant={'text'}
                  className={'disabled'}
                  disableRipple
                  disableFocusRipple
                  disabled
                >
                  {t('no')}
                </MuiButton>
              </Answer>
            ) : (
              <AnswerActions>
                <Button
                  title={t('yes')}
                  className={'sm'}
                  margin={'0'}
                  onClickButton={yesClickHandler}
                />
                <MuiButton
                  variant={'text'}
                  disableRipple
                  disableFocusRipple
                  onClick={noClickHandler}
                >
                  {t('no')}
                </MuiButton>
              </AnswerActions>
            )
          ) : (
            <StyledChip
              label={convertStatus(notification?.order?.status, t)}
              variant={notification?.order?.status.toLowerCase()}
            />
          )}
        </StyledActionBlock>
      </StyledUserInfo>
    </StyledReservation>
  );
};

export default UserNotificationItem;
