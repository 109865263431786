import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getUTCHoursAndMinutes = (date) => {
  const d = new Date(date);
  const utcD = utcToZonedTime(d, 'UTC');

  return format(utcD, 'HH:mm');
};

export const formatDateToExtendedFormat = (day, time) => {
  return `${format(day || Date.now(), 'yyyy-MM-dd')}T${format(new Date(time), 'HH:mm')}:00.000Z`;
};

export const formatDateWithTimeZone = (date) => {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
};
