import { Box } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import Search from '../../components/Search';
import ServiceCatalog from '../../components/ServiceCatalog';
import Breadcrumbs from '../../components/UI/Breadcrumbs';
import { StyledCatalog, StyledH1 } from './styles';

const Catalog = () => {
  const { t } = useTranslation();

  return (
    <StyledCatalog>
      <Helmet>
        <title>{t('catalog')}</title>
      </Helmet>
      <Container>
        <Box>
          <Breadcrumbs title={'home'} url={'/'} />
          <StyledH1 variant='h1'>{t('catalog')}</StyledH1>
        </Box>
      </Container>
      <Search />
      <ServiceCatalog withDivider={true} className={'catalog-page'} />
    </StyledCatalog>
  );
};

export default Catalog;
