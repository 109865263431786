import React from 'react';

const EmailContactIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 41 40'
      id='mail'
    >
      <defs>
        <linearGradient id='a' x1='50%' x2='50%' y1='0%' y2='100%'>
          <stop offset='0%' stop-color='#1D6FF2'></stop>
          <stop offset='100%' stop-color='#1AC8FC'></stop>
        </linearGradient>
      </defs>
      <g fill='none' fill-rule='evenodd'>
        <circle cx='20.5' cy='20' r='20' fill='url(#a)'></circle>
        <path
          fill='#FFF'
          d='M33.5213743,28.728481 C33.2275869,28.9010475 32.8853493,29 32.5200001,29 L8.4799999,29 C8.11465069,29 7.77241309,28.9010475 7.47862575,28.728481 L15.5,20.7071068 L17.4797799,22.6868867 C18.2448601,23.4519669 19.2618374,23.8333333 20.5,23.8333333 C21.7381626,23.8333333 22.7551399,23.4519669 23.5202201,22.6868867 L25.5,20.7071068 L33.5213743,28.728481 Z M34.228481,28.0213743 L26.2071068,20 L34.228481,11.9786257 C34.4010475,12.2724131 34.5,12.6146507 34.5,12.9799999 L34.5,27.0200001 C34.5,27.3853493 34.4010475,27.7275869 34.228481,28.0213743 Z M6.77151897,28.0213743 C6.59895254,27.7275869 6.5,27.3853493 6.5,27.0200001 L6.5,12.9799999 C6.5,12.6146507 6.59895254,12.2724131 6.77151897,11.9786257 L14.7928932,20 L6.77151897,28.0213743 Z M33.5213743,11.271519 L22.8131133,21.9797799 C22.2448601,22.5480331 21.4840596,22.8333333 20.5,22.8333333 C19.5159404,22.8333333 18.7551399,22.5480331 18.1868867,21.9797799 L7.47862575,11.271519 C7.77241309,11.0989525 8.11465069,11 8.4799999,11 L32.5200001,11 C32.8853493,11 33.2275869,11.0989525 33.5213743,11.271519 Z'
        ></path>
      </g>
    </svg>
  );
};

export default EmailContactIcon;
