import Container from '../../components/Container';
import LoginForm from '../../components/LoginForm';

const Login = () => {
  return (
    <>
      <Container>
        Login
        <LoginForm />
      </Container>
    </>
  );
};

export default Login;
