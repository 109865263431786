/* eslint-disable no-continue */

import { addMinutes, format } from 'date-fns';

/* eslint-disable no-plusplus */
export const createEventsArray = (start, end, lunchStart, lunchEnd, selectedDay) => {
  const times = [];

  if (start && end) {
    for (let hour = start; hour < end; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        if (hour >= lunchStart && hour < lunchEnd && (minute === 0 || minute === 30)) {
          continue;
        }

        const instance = {
          start: new Date(
            `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T${hour < 10 ? '0' : ''}${hour}:${
              minute === 0 ? '00' : minute
            }:00`,
          ),

          end: addMinutes(
            new Date(
              `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T${hour < 10 ? '0' : ''}${hour}:${
                minute === 0 ? '00' : minute
              }:00`,
            ),
            30,
          ),
          status: `FREE`,
          title: `free`,
        };

        times.push(instance);
      }
    }
  }

  return times;
};
