import { Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CheckedIcon from '../../../icons/CheckedIcon';
import UncheckedIcon from '../../../icons/UncheckedIcon';
import { StyledFormControlLabel } from './styles';

export const ControlCheckbox = ({
  options,
  control,
  name,
  margin = 0,
  withMulti,
  onChange,
  singleCheckbox,
  withoutCheckboxIcon = false,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {options ? (
        options.map((option) => {
          const isObject = typeof option === 'object';
          const optionName = isObject ? option.name : option;
          const optionId = isObject ? option.id : option;
          return (
            <Controller
              key={optionName}
              name={name}
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <StyledFormControlLabel
                      sx={{
                        margin,
                      }}
                      label={withMulti ? t(optionName) : optionName}
                      control={
                        <Checkbox
                          value={optionId}
                          checked={
                            singleCheckbox && field.value.length > 0
                              ? true
                              : field.value
                              ? field.value.some((element) => {
                                  return +element.id === +optionId;
                                })
                              : false
                          }
                          onChange={(event, checked) => {
                            if (checked) {
                              field.onChange(
                                isObject
                                  ? [
                                      ...field.value,
                                      {
                                        id: event.target.value,
                                        name: optionName,
                                      },
                                    ]
                                  : [...field.value, event.target.value],
                              );
                            } else {
                              field.onChange(
                                field.value.filter((value) =>
                                  isObject
                                    ? +value.id !== +event.target.value
                                    : value !== event.target.value,
                                ),
                              );
                            }
                          }}
                          disableRipple
                          icon={withoutCheckboxIcon ? <></> : <UncheckedIcon />}
                          checkedIcon={withoutCheckboxIcon ? <></> : <CheckedIcon />}
                        >
                          {optionName}
                        </Checkbox>
                      }
                    />
                  </>
                );
              }}
            />
          );
        })
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <>
                <StyledFormControlLabel
                  sx={{
                    margin,
                  }}
                  label={t(name)}
                  control={
                    <Checkbox
                      value={name}
                      disableRipple
                      checked={field.value}
                      onChange={onChange || ((event) => field.onChange(event.target.checked))}
                      icon={withoutCheckboxIcon ? <></> : <UncheckedIcon />}
                      checkedIcon={withoutCheckboxIcon ? <></> : <CheckedIcon />}
                      disabled={disabled}
                    >
                      {t(name)}
                    </Checkbox>
                  }
                />
              </>
            );
          }}
        />
      )}
    </>
  );
};
