import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import { useGetFaq } from '../../graphql/hooks/useQueries';
import DropdownIcon from '../../icons/DropdownIcon';
import theme from '../../theme';
import { FaqList, FaqTitleLinkList, Heading, StyledAnchor, Wrapper } from './styles';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState('1');
  const { data: faqList } = useGetFaq();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClick = (panel) => {
    setExpanded(panel);
  };

  return (
    <Container direction='column'>
      <Helmet>
        <title>{t('faq')}</title>
      </Helmet>
      <Heading variant='h1'>{t('faqTitle')}</Heading>
      <Wrapper>
        <FaqList>
          {faqList?.getFaqs &&
            faqList?.getFaqs.map(({ id, translations }) => {
              const { answer, question } = translations.find(
                (trans) => trans.languageCode === i18n.resolvedLanguage,
              );
              return (
                <Accordion
                  key={id}
                  expanded={expanded === id}
                  onChange={handleChange(id)}
                  id={`id${id}`}
                >
                  <AccordionSummary expandIcon={<DropdownIcon />}>
                    <Typography variant={'h4'}>{question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: answer,
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </FaqList>
        {!isTablet && (
          <FaqTitleLinkList>
            {faqList?.getFaqs &&
              faqList?.getFaqs.map(({ id, translations }) => {
                const { question } = translations.find(
                  (trans) => trans.languageCode === i18n.resolvedLanguage,
                );
                return (
                  <StyledAnchor
                    className={expanded === id ? 'active' : ''}
                    onClick={() => handleClick(id)}
                    key={question}
                    href={`#id${id}`}
                  >
                    <Typography variant={'body1'} component={'div'}>
                      {question}
                    </Typography>
                  </StyledAnchor>
                );
              })}
          </FaqTitleLinkList>
        )}
      </Wrapper>
    </Container>
  );
};

export default FAQ;
