import { gql } from '@apollo/client';

export const GET_LOCALES = gql`
  query getLocales {
    getLocales {
      name
      code
      id
    }
  }
`;
