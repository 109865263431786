import { Typography, useMediaQuery } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { COUNTRY_NAMES, ROUTES } from '../../constants/constants';
import { useGetCategories, useGetServicesCount } from '../../graphql/hooks/useQueries';
import palette from '../../theme/palette';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';
import { getNeedDecline } from '../../utils/getNeedDecline';
import Container from '../Container';
import Divider from '../UI/Divider';
import {
  CatalogInner,
  CatalogItem,
  CatalogLink,
  CatalogWrapper,
  StyledServiceCatalog,
} from './styles';

const ServiceCatalog = ({ className, withDivider }) => {
  const { t, i18n } = useTranslation();
  const { data } = useGetCategories();
  const { data: servicesCount } = useGetServicesCount();
  const specialistQuantity = servicesCount?.getServicesCount?.byCity || 0;
  const remotelyQuantity = servicesCount?.getServicesCount?.remotely || 0;

  const specialist = getNeedDecline(specialistQuantity, [
    t('specialistFirst'),
    t('specialistSecond'),
    t('specialistThird'),
  ]);

  const ready = getNeedDecline(remotelyQuantity, [t('readyFirst'), t('readySecond')]);
  const readyOnlineSpecialists = getNeedDecline(remotelyQuantity, [
    t('specialistFirst'),
    t('specialistSecond'),
    t('specialistThird'),
  ]);
  const countryId = Number(localStorage.getItem('country'));
  const cityId = Number(localStorage.getItem('city'));
  const place = !cityId && countryId ? t(COUNTRY_NAMES[countryId]) : t('yourCity');

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(480));

  return (
    <StyledServiceCatalog className={className}>
      <Container>
        <Typography variant='h1'>
          {t('serviceCatalogTitle')}
          <Typography variant='body1' className='subTitle' color={palette.oldLavender}>
            {t('serviceCatalogSubtitle', {
              place,
              byCity: specialistQuantity,
              specialistText: specialist,
              remotely: remotelyQuantity,
              readyOnlineSpecialists,
              readyText: ready,
            })}
          </Typography>
        </Typography>
      </Container>
      <CatalogWrapper>
        <Container>
          <CatalogInner>
            {data?.getCategories &&
              alphabeticalSorting(data?.getCategories, (cat) => cat.translations, i18n).map(
                (item, index) => {
                  return (
                    <Box key={item.id}>
                      <CatalogItem>
                        <CatalogLink to={`${ROUTES.service}?category=${item.slug}`}>
                          <Typography variant='body2' fontWeight={600}>
                            {
                              item.translations.find(
                                (trans) => trans.languageCode === i18n.resolvedLanguage,
                              ).name
                            }
                            <span>{item.serviceCount}</span>
                          </Typography>
                        </CatalogLink>
                        {alphabeticalSorting(
                          item.subcategories,
                          (subcategory) => subcategory.translations,
                          i18n,
                        )
                          .slice(0, 5)
                          .map((sub) => {
                            return (
                              <Typography key={sub.id} variant='body2'>
                                <CatalogLink
                                  to={`${ROUTES.service}?category=${sub.parentCategorySlug}&sub=${sub.slug}`}
                                >
                                  {
                                    sub.translations.find(
                                      (subTrans) => subTrans.languageCode === i18n.resolvedLanguage,
                                    ).name
                                  }
                                </CatalogLink>
                              </Typography>
                            );
                          })}
                        <Link to={`${ROUTES.categoryList}?category=${item.slug}`}>
                          <Typography variant='body2' color={palette.oldLavender}>
                            {t('totalServices')} {item.subcategories.length}
                          </Typography>
                        </Link>
                      </CatalogItem>
                      {withDivider && mobile && index + 1 !== data?.getCategories?.length && (
                        <Divider margin={'26px -15px 0'} />
                      )}
                    </Box>
                  );
                },
              )}
          </CatalogInner>
        </Container>
      </CatalogWrapper>
    </StyledServiceCatalog>
  );
};

export default ServiceCatalog;
