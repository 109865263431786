import { Radio, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetLocales } from '../../../graphql/hooks/useQueries';
import CheckedIcon from '../../../icons/CheckedIcon';
import UncheckedIcon from '../../../icons/UncheckedIcon';
import { convertToMinutes } from '../../../utils/convert';
import { transformLocalesToObjectWithUserData } from '../../../utils/getDifferentFormatOfData';
import Button from '../../UI/Button';
import { CenterTypography, StyledFormControlRadioLabel, StyledRadioLabel } from '../styles';

const CreateOrderStep1 = ({ services, setSelectedService, setStep }) => {
  const { t, i18n } = useTranslation();
  const { data: locales } = useGetLocales();

  const handleGoToStep2 = () => {
    setStep(2);
  };

  const handleChangeService = (service) => () => {
    setSelectedService(service);
  };

  const RadioLabel = ({ data: { price, currencyCode, pricePer, translations } }) => {
    const description = transformLocalesToObjectWithUserData(locales?.getLocales, translations)?.[
      i18n.resolvedLanguage
    ]?.userTranslate?.description;

    return (
      <StyledRadioLabel>
        <span>{description}</span>
        <span>{`${price} ${t(currencyCode)} - ${convertToMinutes(pricePer, t)}`}</span>
      </StyledRadioLabel>
    );
  };

  return (
    <>
      <Box paddingBottom={'20px'}>
        <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
          {t('selectSubServiceToBook')}
        </CenterTypography>
        <RadioGroup defaultValue={0}>
          {services
            ?.filter((service) => !Number.isNaN(+service.pricePer))
            .map((element, index) => (
              <StyledFormControlRadioLabel
                key={index}
                value={index}
                onChange={handleChangeService(element)}
                control={<Radio icon={<UncheckedIcon />} checkedIcon={<CheckedIcon />} />}
                label={<RadioLabel data={element} />}
              />
            ))}
        </RadioGroup>
        <Button
          className={'saveMobile'}
          type={'button'}
          title={t('continue')}
          margin={'20px auto 0'}
          onClickButton={handleGoToStep2}
        />
      </Box>
    </>
  );
};

export default CreateOrderStep1;
