import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { HOST_IMAGE_URL } from '../../constants/constants';
import palette from '../../theme/palette';
import { convertStatus } from '../../utils/convert';
import { getUTCHoursAndMinutes } from '../../utils/formatTime';
import { findFunction } from '../../utils/getDifferentFormatOfData';
import { getLocale } from '../../utils/getLocale';
import DefaultImage from '../DefaultImage';
import Dot from '../UI/Dot';
import {
  Avatar,
  CenteredTypography,
  StyledChip,
  StyledReservation,
  StyledReservationInfo,
  StyledUserInfo,
} from './styles';

const ProviderNotificationItem = ({ notification }) => {
  const { t, i18n } = useTranslation();
  return (
    <StyledReservation>
      <StyledUserInfo>
        <Box display='flex'>
          {notification?.order?.user?.photo ? (
            <Avatar src={`${HOST_IMAGE_URL}/${notification?.order?.user?.photo}`} alt={'avatar'} />
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <StyledReservationInfo>
            <CenteredTypography variant={'body1Semibold'} component={'div'}>
              {findFunction(notification?.order?.service?.category?.translations, i18n)?.name}
            </CenteredTypography>
            <CenteredTypography variant={'body2'} component={'div'} color={palette.oldLavender}>
              {format(new Date(notification?.order?.startTime), 'EEEE', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {format(new Date(notification?.order?.startTime), 'dd.MM', {
                locale: getLocale(i18n.resolvedLanguage),
              })}
              <Dot />
              {`${getUTCHoursAndMinutes(notification?.order?.startTime)}-${getUTCHoursAndMinutes(
                notification?.order?.endTime,
              )}`}
            </CenteredTypography>
            <Typography variant={'body2'} component={'div'}>
              {`${notification?.order?.user?.firstName} ${notification?.order?.user?.lastName}`}
            </Typography>
          </StyledReservationInfo>
        </Box>
        <Box display='flex' alignItems='center'>
          <StyledChip
            label={convertStatus(notification?.order?.status, t)}
            variant={notification?.order?.status.toLowerCase()}
          />
        </Box>
      </StyledUserInfo>
    </StyledReservation>
  );
};

export default ProviderNotificationItem;
