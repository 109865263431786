import React from 'react';

const NotificationsIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.667 14.1667H18.3337V15.8334H1.66699V14.1667H3.33366V8.33341C3.33366 6.5653 4.03604 4.86961 5.28628 3.61937C6.53652 2.36913 8.23222 1.66675 10.0003 1.66675C11.7684 1.66675 13.4641 2.36913 14.7144 3.61937C15.9646 4.86961 16.667 6.5653 16.667 8.33341V14.1667ZM7.50033 17.5001H12.5003V19.1667H7.50033V17.5001Z'
        fill='#1A1E26'
      />
      <circle cx='16' cy='4' r='4' fill='#D9D9D9' />
      <circle cx='16' cy='4' r='4' fill='url(#paint0_linear_2211_16790)' />
      <circle cx='16' cy='4' r='4' fill='url(#paint1_linear_2211_16790)' />
      <circle cx='16' cy='4' r='4' fill='url(#paint2_linear_2211_16790)' />
      <circle cx='16' cy='4' r='4' fill='url(#paint3_linear_2211_16790)' />
      <defs>
        <linearGradient
          id='paint0_linear_2211_16790'
          x1='16'
          y1='0'
          x2='16'
          y2='8'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8BFF58' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_2211_16790'
          x1='19.9922'
          y1='-6.57217'
          x2='12.1421'
          y2='-6.5599'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.182292' stopColor='#8BFF58' />
          <stop offset='0.586425' stopColor='#46C7AB' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_2211_16790'
          x1='19.9922'
          y1='-6.57217'
          x2='12.1421'
          y2='-6.5599'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#58FF87' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_2211_16790'
          x1='19.9922'
          y1='-6.57217'
          x2='12.1421'
          y2='-6.5599'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FFD21' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotificationsIcon;
