import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const GET_CURRENT_USER = gql`
  ${USER}
  query CurrentUser {
    currentUser {
      ...UserFragment
    }
  }
`;
