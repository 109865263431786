import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';

export const Heading = styled(Typography)({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '1.2',
    marginTop: '8px',
    marginBottom: '32px',
  },
});

export const AboutContent = styled(Box)({
  textAlign: 'justify',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
  '& img': {
    marginBottom: '48px',
    width: '100%',
    height: '100%',
  },

  h2: {
    marginBottom: '16px',
  },

  p: {
    maxWidth: '820px',
    width: '100%',
    margin: '0 auto 10px',
    marginBottom: '10px',

    '&:last-child': {
      marginBottom: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: '100%',
      height: '264px',
      objectFit: 'cover',
      objectPosition: 'left',
      marginBottom: '32px',
    },
    h2: {
      fontWeight: '600',
    },
    p: {
      fontSize: '14px',
    },
  },
});

export const MapWrapper = styled('div')({
  maxWidth: '820px',
  height: '600px',
  iframe: {
    border: 0,
  },
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '820px',
  width: '100%',
  margin: '0 auto 53px',
  '> div': {
    width: '100%',
  },
});
