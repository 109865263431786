export const convertToMinutes = (number, t) => {
  const hours = Math.floor(number / 60);
  const minutes = number % 60;
  if (hours === 0) {
    return `${minutes} ${t('minute')}`;
  }
  if (minutes === 0) {
    return `${hours} ${t('hour', {
      count: hours,
    })}`;
  }
  return `${hours} ${t('hour', {
    count: hours,
  })} ${minutes} ${t('minute')}`;
};

export const convertArrayValuesToMinutes = (array, t) => {
  return array.map((number) => {
    return convertToMinutes(number, t);
  });
};

export const convertToSeconds = (timeString, t) => {
  let minutes = 0;
  const parts = timeString.trim().split(' ');
  for (let index = 0; index < parts.length; index++) {
    const part = parts[index];
    if (!isNaN(+part)) {
      if (
        index < parts.length - 1 &&
        [t('hour_one'), t('hour_few'), t('hour_many'), t('hour_other')].includes(parts[index + 1])
      ) {
        minutes += parseInt(part, 10) * 60;
      } else {
        minutes += parseInt(part, 10);
      }
    }
  }

  return minutes;
};

export const convertFormatPricePer = (pricePer, t) => {
  return pricePer === 'день' || pricePer === 'дан' || pricePer === 'day' || pricePer === 'DAY'
    ? 'DAY'
    : pricePer === 'вызов' || pricePer === 'позив' || pricePer === 'call' || pricePer === 'CALL'
    ? 'CALL'
    : convertToMinutes(pricePer, t);
};

export const convertFormatCurrency = (currency) => {
  return currency === 'дин' || currency === 'rsd' || currency === 'RSD'
    ? 'RSD'
    : currency === 'руб' || currency === 'rub' || currency === 'RUB'
    ? 'RUB'
    : 'EUR';
};

export const convertTypeContact = (typeContact, t) => {
  return typeContact === t('PHONE')
    ? 'PHONE'
    : typeContact === t('EMAIL')
    ? 'EMAIL'
    : 'SOCIAL_NETWORK';
};

export const convertStatus = (staus, t) => {
  switch (staus) {
    case 'NEW': {
      return t('statusNew');
    }

    case 'CONFIRMED': {
      return t('statusConfirmed');
    }

    case 'CANCELED': {
      return t('statusCanceled');
    }

    default: {
      return null;
    }
  }
};

export const convertImageFileToObject = (array) =>
  array.map((path) => {
    return {
      path,
      about: '',
    };
  });
