import { Button as MuiButton, Chip } from '@mui/material';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetUserLanguages } from '../../graphql/hooks/useQueries';
import { RightIcon } from '../../icons/RightIcon';
import deleteIcon from '../../images/deleteIcon.svg';
import { getIdFromArray, getObjectNameAndIdFromArray } from '../../utils/getDifferentFormatOfData';
import Button from '../UI/Button';
import { ControlCheckbox } from '../UI/Checkbox';
import {
  ButtonManage,
  CenterTypography,
  ChooseServiceTypesWrapper,
  Counter,
  EmptyBlock,
  List,
  SelectedSubcategories,
  StyledScrollButton,
  SubcategoriesList,
} from './styles';

const ChooseLanguages = ({
  handleClose,
  languageModalProps: { userLanguages, setUserLanguages },
}) => {
  const { data: languages } = useGetUserLanguages();
  const { t, i18n } = useTranslation();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      languagesList: getObjectNameAndIdFromArray(userLanguages, i18n) || [],
    },
  });

  const languagesList = watch('languagesList');
  const selectedLanguagesReference = useRef(null);

  const scroll = (scrollOffset) => {
    selectedLanguagesReference.current.scrollLeft += scrollOffset;
  };

  const handleDelete = (chipToDelete) => {
    setValue(
      'languagesList',
      languagesList.filter((category) => {
        return category.id !== chipToDelete;
      }),
    );
  };

  const onCancelClick = () => {
    handleClose(true);
  };

  const handleSave = () => {
    const filteredArray = [];
    languagesList.map((language) => {
      return languages?.getUserLanguages?.filter((element) => {
        if (+language.id === +element.id) {
          filteredArray.push(element);
        }
        return null;
      });
    });
    setValue('languagesList', getIdFromArray(languagesList));
    setUserLanguages(filteredArray);
    handleClose(true);
  };

  return (
    <ChooseServiceTypesWrapper>
      <CenterTypography variant={'h4'} className={'m-0'}>
        {t('chooseLanguage')}
      </CenterTypography>
      <CenterTypography
        variant={'body1'}
        component={'div'}
        className={'fw-400'}
        margin={'20px 0 16px 0'}
      >
        {t('languageSelectionDescription')}
      </CenterTypography>
      <form>
        {languages?.getUserLanguages && (
          <SubcategoriesList>
            <ControlCheckbox
              control={control}
              name={'languagesList'}
              margin={'0 0 12px 0'}
              options={getObjectNameAndIdFromArray(languages?.getUserLanguages, i18n)}
            />
          </SubcategoriesList>
        )}
        {languagesList?.length > 0 ? (
          <SelectedSubcategories>
            <Counter>{`${t('selectedLanguages')}: ${languagesList.length}`}</Counter>
            <List ref={selectedLanguagesReference}>
              {languagesList?.map(({ name, id }) => (
                <Chip
                  key={id}
                  deleteIcon={<img src={deleteIcon} alt={'remove'} />}
                  label={name}
                  onDelete={() => handleDelete(id)}
                />
              ))}
              {languagesList?.length > 2 && (
                <StyledScrollButton onClick={() => scroll(100)}>
                  <RightIcon />
                </StyledScrollButton>
              )}
            </List>
          </SelectedSubcategories>
        ) : (
          <EmptyBlock />
        )}
        <ButtonManage>
          <MuiButton
            className={'cancel'}
            variant={'text'}
            onClick={onCancelClick}
            disableRipple
            disableFocusRipple
          >
            {t('cancel')}
          </MuiButton>
          <Button
            title={t('save')}
            className={'md'}
            variant={'text'}
            disableRipple
            disableFocusRipple
            onClickButton={handleSave}
          />
        </ButtonManage>
      </form>
    </ChooseServiceTypesWrapper>
  );
};

export default ChooseLanguages;
