import { gql } from '@apollo/client';

import { REVIEW } from '../fragments';

export const GET_RANDOM_REVIEWS = gql`
  ${REVIEW}
  query GetRandomReviews($limit: Int) {
    getRandomReviews(limit: $limit) {
      count
      rows {
        ...ReviewFragment
      }
    }
  }
`;
