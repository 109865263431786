import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSetPassword } from '../../graphql/hooks/useMutations';
import { useToast } from '../../hooks';
import palette from '../../theme/palette';
import { thirdStepSchema } from '../../validation/schema/forgotPasswordSchema';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import EndAdornment from '../UI/Input/components/EndAdornment';
import {
  CenterTypography,
  ContinueButtonWrapper,
  Form,
  ModalDescription,
  StyledForgotPassword,
} from './styles';

const ChangePassword = ({ handleClose }) => {
  const [variant, setToastConfig, setSpecificErrorInformation] = useToast();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(thirdStepSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const { t } = useTranslation();
  const [setPassword, { loading: setPasswordLoading }] = useSetPassword({
    onCompleted: () => {
      setToastConfig(variant.success);
      setTimeout(() => {
        handleClose();
      }, 0);
    },
    onError: (error) => {
      console.log(error.message);
      setSpecificErrorInformation(error.message);
      setToastConfig(variant.error);
    },
  });
  const isLoading = setPasswordLoading;

  const handleClickShowPassword = (setStateFunction, state) => {
    setStateFunction(!state);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async ({ password }) => {
    await setPassword({
      variables: {
        password,
      },
    });
  };

  return (
    <StyledForgotPassword>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('changePass')}
      </CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <ModalDescription align={'center'} variant={'body1'}>
            {t('createNewPass')}
          </ModalDescription>
          <ControlInput
            control={control}
            name={'password'}
            variant={'filled'}
            label={t('password')}
            type={isShowPassword ? 'text' : 'password'}
            margin={'20px 0'}
            endAdornment={
              <EndAdornment
                onClick={() => handleClickShowPassword(setIsShowPassword, isShowPassword)}
                onMouseDown={handleMouseDownPassword}
                isShow={isShowPassword}
              />
            }
          />
          <ControlInput
            control={control}
            name={'confirmPassword'}
            variant={'filled'}
            label={t('confirmPassword')}
            type={isShowConfirmPassword ? 'text' : 'password'}
            endAdornment={
              <EndAdornment
                onClick={() =>
                  handleClickShowPassword(setIsShowConfirmPassword, isShowConfirmPassword)
                }
                onMouseDown={handleMouseDownPassword}
                isShow={isShowConfirmPassword}
              />
            }
          />
        </Box>
        <ContinueButtonWrapper margin={'28px 0 39px'}>
          <Button
            type={'submit'}
            title={
              isLoading ? (
                <CircularProgress
                  sx={{
                    color: palette.secondary,
                  }}
                />
              ) : (
                t('goNextStep')
              )
            }
            margin={'0 auto'}
          />
        </ContinueButtonWrapper>
      </Form>
    </StyledForgotPassword>
  );
};

export default ChangePassword;
