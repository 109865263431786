export const getNeedDecline = (number, titles) => {
  const actualTranslationsArray = titles.filter(Boolean);
  if (actualTranslationsArray.length === 3) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }
  if (actualTranslationsArray.length === 2) {
    return number === 1 ? titles[0] : titles[1];
  }
  if (actualTranslationsArray.length === 1) {
    return titles[0];
  }
  return 'not found translation';
};
