import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const UPDATE_PROFILE = gql`
  ${USER}
  mutation updateProfile($input: updateProfileInput!) {
    updateProfile(input: $input) {
      ...UserFragment
    }
  }
`;
