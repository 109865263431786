import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';

export const Heading = styled(Typography)({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '1.2',
    marginTop: '8px',
    marginBottom: '32px',
  },
});

export const Content = styled(Box)({
  p: {
    marginBottom: '15px',
  },
});
