import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import Button from '../UI/Button';
import { FirstLoginStyled } from './styles';

const NeedUserDetails = ({ setModalType }) => {
  const { t } = useTranslation();

  const openLogin = () => setModalType('signIn');

  return (
    <FirstLoginStyled>
      <Typography variant={'h2'} textAlign={'center'}>
        {t('enterYourDetails')}
      </Typography>
      <Link to={ROUTES.profile}>
        <Button type={'button'} title={t('enter')} margin={'20px auto'} onClickButton={openLogin} />
      </Link>
    </FirstLoginStyled>
  );
};

export default NeedUserDetails;
