import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../icons/CloseIcon';
import { useAuth } from './useAuth';

export const useToast = () => {
  const { userInfo } = useAuth();
  const [config, setConfig] = useState({});
  const [specificErrorInformation, setSpecificErrorInformation] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const toastVariant = {
    success: {
      msg: t('saved'),
      variant: 'success',
    },
    successDelete: {
      msg: t('successDelete'),
      variant: 'success',
    },
    requestForChangePasswordSuccessfullySent: {
      msg: `${t('requestForChangePassword')}`,
      variant: 'success',
    },
    confirmDeleteAccount: {
      msg: `${t('confirmDeleteAccount')}`,
      variant: 'success',
    },
    error: {
      msg: t('saveError'),
      variant: 'error',
    },
    errorDelete: {
      msg: t('errorDelete'),
      variant: 'error',
    },
    bigImageSize: {
      msg: t('notBigSize'),
      variant: 'error',
    },
    notMoreServicesThan: {
      msg: `${t('notMoreServicesThan', {
        count: userInfo?.tariffPlan?.countService,
      })}`,
      variant: 'error',
    },
    notMoreSubServicesThan: {
      msg: `${t('notMoreSubServicesThan', {
        count: userInfo?.tariffPlan?.countSubservice,
      })}`,
      variant: 'error',
    },
    notMorePhotoOfWorksThan: {
      msg: `${t('maxPhotos', {
        count: userInfo?.tariffPlan?.countServicePhoto,
      })}`,
      variant: 'error',
    },
    notMorePhotoCertificatesThan: {
      msg: `${t('maxCertificates', {
        count: userInfo?.tariffPlan?.countCertificatePhoto,
      })}`,
      variant: 'error',
    },
  };

  const action = (key) => (
    <>
      <IconButton
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
  useEffect(() => {
    if (config?.msg) {
      let variant = 'info';
      if (config.variant) {
        variant = config.variant;
      }
      enqueueSnackbar(
        <div>
          <p>{config.msg}</p>
          {variant === 'error' && specificErrorInformation ? <p>{specificErrorInformation}</p> : ''}
        </div>,
        {
          variant,
          autoHideDuration: 3000,
          action,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        },
      );
    }
  }, [config, specificErrorInformation]);
  return [toastVariant, setConfig, setSpecificErrorInformation];
};
