export const alphabeticalSorting = (array, getTranslations, i18n) => {
  const mutableArray = [...array];
  return mutableArray.sort((a, b) => {
    const orderA = a.order;
    const orderB = b.order;

    if (orderA !== null && orderB !== null) {
      return orderA - orderB;
    } else if (orderA !== null) {
      return -1;
    } else if (orderB !== null) {
      return 1;
    }

    const nameA = getTranslations(a).find(
      (translation) => translation.languageCode === i18n.resolvedLanguage,
    ).name;
    const nameB = getTranslations(b).find(
      (translation) => translation.languageCode === i18n.resolvedLanguage,
    ).name;
    return nameA.localeCompare(nameB);
  });
};
