import { useEffect, useState } from 'react';

export const usePagination = (fetchFunction, refetch, elementsPerPage, setOffset) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    const fetchNewData = async () => {
      await fetchFunction({
        variables: {
          offset: elementsPerPage * (page - 1),
          limit: elementsPerPage,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          setOffset(elementsPerPage * (page - 1));
          return {
            ...fetchMoreResult,
          };
        },
      });
    };
    if (page) {
      fetchNewData();
      setOffset(elementsPerPage * (page - 1));
    }
  }, [page]);

  useEffect(() => {
    refetch({
      offset: 0,
      limit: elementsPerPage,
    });
  }, []);

  const onChangeHandler = (_, pageNumber) => {
    setPage(pageNumber);
  };

  return {
    onChangeHandler,
  };
};
