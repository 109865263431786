import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import theme from '../../theme';

export const StyledPreviewCatalog = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  justifyContent: 'space-between',
  width: '100%',
  padding: '28px 0 20px',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridRowGap: '20px',
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '12.5px',
  },
});

export const PreviewCatalogItem = styled(Link)({
  '& .catalogItemTitle': {
    marginTop: '8px',
  },

  [theme.breakpoints.down('md')]: {
    margin: '0 auto',

    '& .catalogItemTitle': {
      fontSize: '12px',
      lineHeight: '16px',
    },

    '& img': {
      display: 'flex',
      margin: '0 auto',
      width: '100%',
    },
  },
});
