import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Advertising from '../../components/Advertising';
import CommentsList from '../../components/CommentsList';
import Container from '../../components/Container';
import Location from '../../components/Location';
import ModalComponent from '../../components/Modal';
import PreviewCatalog from '../../components/PreviewCatalog';
import Search from '../../components/Search';
import ServiceCatalog from '../../components/ServiceCatalog';
import Button from '../../components/UI/Button';
import LanguagePicker from '../../components/UI/LanguagePicker';
import { ROUTES } from '../../constants/constants';
import { useGetPositiveReviewsCount, useGetRandomReviews } from '../../graphql/hooks/useQueries';
import palette from '../../theme/palette';
import { LocationPanel, OpenCatalog, Reviews, StyledHome } from './styles';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('signIn');
  const { t } = useTranslation();
  const { data: arrayOfReviews, loading: reviewsLoading } = useGetRandomReviews({
    variables: {
      limit: 6,
    },
  });

  const { data } = useGetPositiveReviewsCount();
  const reviews = arrayOfReviews?.getRandomReviews?.rows;
  const positiveReviewsCount = data?.getPositiveReviewsCount;
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType('signIn');
  };

  const openLocation = () => {
    handleOpenModal();
    setModalType('location');
  };

  return (
    <StyledHome>
      <Helmet>
        <meta name='description' content={t('metaHomeDescription')} />
      </Helmet>
      <Container>
        <Typography variant='h1' className='homeTitle'>
          {t('homeTitle')}
        </Typography>
      </Container>
      <Search />
      <Container>
        <LocationPanel>
          <Location openModal={openLocation} isModalOpen={isModalOpen} />
          <LanguagePicker />
        </LocationPanel>
      </Container>
      <PreviewCatalog />
      <ServiceCatalog />
      <Container>
        <OpenCatalog>
          <Link to={ROUTES.catalog}>
            <Button title={t('goToCatalog')} />
          </Link>
        </OpenCatalog>
      </Container>
      <Advertising />
      {reviewsLoading ? (
        <Box p={'10px'} display='flex' justifyContent={'center'}>
          <CircularProgress
            sx={{
              color: palette.primary,
            }}
            size={20}
          />
        </Box>
      ) : (
        <Reviews>
          <Container direction='column'>
            <Typography variant='h1'>
              {t('reviewsTitle')}
              <Typography variant='body1' className='subTitle' color={palette.oldLavender}>
                {`${arrayOfReviews?.getRandomReviews?.count} ${t('endingOfComments', {
                  count: arrayOfReviews?.getRandomReviews?.count,
                })}${t('reviewsSubtitle')}${t('positiveReviews')}${positiveReviewsCount}.`}
              </Typography>
            </Typography>
            <CommentsList loading={reviewsLoading} reviews={reviews} type={'withProviderPhoto'} />
          </Container>
        </Reviews>
      )}
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        setModalType={setModalType}
      />
    </StyledHome>
  );
};

export default Home;
