/* eslint-disable no-restricted-globals */
import { useTranslation } from 'react-i18next';

import { useToast } from './useToast';

export const useSetImage = (fieldName, setImg, setValue, clearErrors, setError) => {
  const [variant, setToastConfig] = useToast();
  const { t } = useTranslation();

  const handleSetImage = (uploadImage) => {
    const isBigSize = uploadImage?.size / 1024 > 10240;
    if (uploadImage?.type.includes('image')) {
      // eslint-disable-next-line no-unused-expressions
      if (isBigSize) {
        setError(fieldName, {
          message: t('notBigSize'),
        });
        setToastConfig(variant.bigImageSize);
      } else {
        clearErrors(fieldName);
        setValue(fieldName, uploadImage);
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.readyState === 2 && !isBigSize) {
          setImg(reader.result);
        }
      });
      reader.readAsDataURL(event.target.files[0]);
    } else if (uploadImage) {
      setError(fieldName, {
        message: t('onlyImage'),
      });
    }
  };

  return {
    handleSetImage,
  };
};
