import React from 'react';

const DeleteIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.5 17C6.08333 17 5.72933 16.854 5.438 16.562C5.146 16.2707 5 15.9167 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.5C15 15.9167 14.854 16.2707 14.562 16.562C14.2707 16.854 13.9167 17 13.5 17H6.5ZM13.5 5.5H6.5V15.5H13.5V5.5ZM8 14H9.5V7H8V14ZM10.5 14H12V7H10.5V14ZM6.5 5.5V15.5V5.5Z'
        fill='#EE0E00'
      />
    </svg>
  );
};

export default DeleteIcon;
