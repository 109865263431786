import { Chip } from '@mui/material';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Divider from '../../../components/UI/Divider';
import { TIMESTAMPS } from '../../../constants/constants';
import { useAuth, useToast } from '../../../hooks';
import AddIcon from '../../../icons/AddIcon';
import { DeleteInputButton, ServicePriceBlock, StyledAddButton, Wrapper } from '../styles';
import { PriceAndDescription } from './PriceAndDescription';

export const SubServicePricesBlock = ({ watch, control, setValue }) => {
  const [variant, setToastConfig] = useToast();
  const { userInfo } = useAuth();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subservices',
  });
  const { t } = useTranslation();

  const addNewBlockWithPrice = () => {
    if (fields.length < userInfo?.tariffPlan?.countSubservice - 1) {
      append({
        currencyCode: t('RSD'),
        description: '',
        price: '',
        pricePer: TIMESTAMPS[1],
      });
    } else {
      setToastConfig(variant.notMoreSubServicesThan);
    }
  };

  const deleteBlockWithPrice = (id) => () => {
    remove(id);
  };

  return (
    <Wrapper>
      {fields?.length > 0 &&
        fields.map((subservice, index) => {
          return (
            <ServicePriceBlock key={index}>
              <Divider margin={'0 0 15px'} />
              <PriceAndDescription
                watch={watch}
                control={control}
                setValue={setValue}
                subservice={
                  subservice
                    ? {
                        ...subservice,
                        elementIndexInArray: index,
                      }
                    : {}
                }
              />
              <DeleteInputButton type={'button'} onClick={deleteBlockWithPrice(index)}>
                {t('deleteBlockWithPrice')}
              </DeleteInputButton>
            </ServicePriceBlock>
          );
        })}
      <StyledAddButton>
        <Chip
          label={t('addAdditionalPrices')}
          icon={<AddIcon />}
          clickable
          onClick={addNewBlockWithPrice}
          classes={{
            root: 'addChip',
          }}
        />
      </StyledAddButton>
    </Wrapper>
  );
};
