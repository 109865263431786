import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress } from '@mui/material';
import { endOfDay, format, startOfDay } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '../../../components/DayPicker';
import { DaySchedule } from '../../../components/DaySchedule';
import { ButtonManageServices } from '../../../components/ProfileForm/styles';
import { ControlCheckbox } from '../../../components/UI/Checkbox';
import { WORKING_DAYS, WORKING_HOURS_FROM, WORKING_HOURS_TO } from '../../../constants/constants';
import {
  useAddNonWorkingTime,
  useCreateWorkSchedule,
  useDeleteNonWorkingTime,
  useUpdateWorkSchedule,
} from '../../../graphql/hooks/useMutations';
import {
  useGetGroupedByDateIntervalOrders,
  useGetNonWorkingHoursByDateInterval,
  useGetWorkSchedule,
} from '../../../graphql/hooks/useQueries';
import {
  GET_NON_WORKING_HOURS_BY_DATE_INTERVAL,
  GET_WORK_SCHEDULE,
} from '../../../graphql/queries';
import { useAuth, useToast } from '../../../hooks';
import { createEventsArray } from '../../../utils/createEventsArray';
import {
  formatDateToExtendedFormat,
  formatDateWithTimeZone,
  getUTCHoursAndMinutes,
} from '../../../utils/formatTime';
import { getDefaulValueObject, getObjectByValue } from '../../../utils/getDifferentFormatOfData';
import { getWeekendsArray } from '../../../utils/getWeekendsArray';
import { scheduleSchema } from '../../../validation/schema/scheduleSchema';
import { PriceTimeLine, StyledSelect, StyledsubTitle, StyledWorkingHours } from '../styles';

const MenuProperties = {
  PaperProps: {
    style: {
      maxHeight: '235px',
    },
  },
};

const TabSchedule = () => {
  const { t, i18n } = useTranslation();

  const [variant, setToastConfig] = useToast();

  const [selectedDay, setSelectedDay] = useState();

  const [addNonWorkingTime] = useAddNonWorkingTime({
    onCompleted() {
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
    refetchQueries: [GET_WORK_SCHEDULE, GET_NON_WORKING_HOURS_BY_DATE_INTERVAL],
  });

  const [deleteNonWorkingTime] = useDeleteNonWorkingTime({
    onCompleted() {
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
    refetchQueries: [GET_WORK_SCHEDULE, GET_NON_WORKING_HOURS_BY_DATE_INTERVAL],
  });

  const [createWorkSchedule] = useCreateWorkSchedule({
    onCompleted() {
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
    refetchQueries: [GET_WORK_SCHEDULE],
  });
  const [updateWorkSchedule] = useUpdateWorkSchedule({
    onCompleted() {
      setToastConfig(variant.success);
    },
    onError() {
      setToastConfig(variant.error);
    },
    refetchQueries: [GET_WORK_SCHEDULE],
  });
  const { userInfo } = useAuth();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      workingDay: WORKING_DAYS[i18n.resolvedLanguage][0],
      workingHoursFrom: getDefaulValueObject(t('from')),
      workingHoursTo: getDefaulValueObject(t('to')),
      lunchBreakFrom: getDefaulValueObject(t('from')),
      lunchBreakTo: getDefaulValueObject(t('to')),
      lunchBreak: [],
      disableDay: false,
    },
    resolver: yupResolver(scheduleSchema),
  });

  const lunchBreak = watch('lunchBreak').length > 0;
  const disableDay = watch('disableDay');

  const [workingDaysSelectedVariant, setWorkingDaysSelectedVariant] = useState(
    WORKING_DAYS[i18n.resolvedLanguage][0],
  );

  const [workingHoursFrom, setWorkingHoursFrom] = useState(watch('workingHoursFrom'));
  const [workingHoursTo, setWorkingHoursTo] = useState(watch('workingHoursTo'));
  const [lunchBreakFrom, setLunchBreakFrom] = useState(watch('lunchBreakFrom'));
  const [lunchBreakTo, setLunchBreakTo] = useState(watch('lunchBreakTo'));
  const [bookedHours, setBookedHours] = useState([]);
  const [bookedDays, setBookedDays] = useState([]);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!disableDay)
      setEvents(
        createEventsArray(
          workingHoursFrom?.value,
          workingHoursTo?.value,
          lunchBreakFrom?.value,
          lunchBreakTo?.value,
          disableDay ? null : selectedDay,
        ),
      );
  }, [disableDay]);

  const { data: orders } = useGetGroupedByDateIntervalOrders({
    variables: {
      providerId: userInfo.id,
      month: format(selectedDay || Date.now(), 'yyyy-MM-dd'),
    },
    onCompleted: (data) => {
      const booked = data?.getGroupedByDateIntervalOrders.map((day) => new Date(day.date));
      setBookedDays(booked);
    },
  });

  const { data: nonWorkingHours } = useGetNonWorkingHoursByDateInterval({
    variables: {
      providerId: userInfo.id,
      month: format(selectedDay || Date.now(), 'yyyy-MM-dd'),
    },
    skip: events.length === 0,
    onError() {
      setToastConfig(variant.error);
    },
  });

  useEffect(() => {
    const nonWorkingHoursForDay = nonWorkingHours?.getGroupedByDateIntervalNonWorkingHours?.find(
      (day) => day.date === format(selectedDay || Date.now(), 'yyyy-MM-dd'),
    );

    const isFullDay = nonWorkingHoursForDay?.intervals?.find((hour) => {
      return (
        hour.startTime === `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T00:00:00.000Z` &&
        hour.endTime === `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T23:59:59.000Z`
      );
    });

    if (isFullDay) {
      setValue('disableDay', true);
      setEvents([
        {
          start: formatDateWithTimeZone(isFullDay.startTime),
          end: formatDateWithTimeZone(isFullDay.endTime),
          status: 'CANCELED',
          title: 'nonWorkingHours',
        },
      ]);
    } else {
      const updatedDayEvents = events.map((event) => {
        const isCanceled = nonWorkingHoursForDay?.intervals?.find((hour) => {
          return getUTCHoursAndMinutes(hour.startTime) === format(event.start, 'HH:mm');
        });

        return {
          ...event,
          status: isCanceled ? 'CANCELED' : 'FREE',
          title: isCanceled ? 'nonWorkingHours' : 'free',
        };
      });
      setEvents(updatedDayEvents);
      if (bookedHours) {
        setEvents([
          ...updatedDayEvents.filter((event) => {
            const isNonWorkingHour = bookedHours?.some((bookedHour) => {
              const eventStart = event.start.getTime();
              const eventEnd = event.end.getTime();
              const bookedStart = bookedHour.start.getTime();
              const bookedEnd = bookedHour.end.getTime();
              return (
                (eventStart >= bookedStart && eventStart < bookedEnd) ||
                (eventEnd > bookedStart && eventEnd <= bookedEnd) ||
                (eventStart <= bookedStart && eventEnd >= bookedEnd) ||
                (eventStart >= bookedStart && eventEnd <= bookedEnd)
              );
            });
            return !isNonWorkingHour;
          }),

          ...(bookedHours || []),
        ]);
      }
    }
  }, [selectedDay, nonWorkingHours, bookedHours]);

  useEffect(() => {
    if (orders) {
      const ordersHoursForDay = orders?.getGroupedByDateIntervalOrders?.find(
        (day) => day.date === format(selectedDay || Date.now(), 'yyyy-MM-dd'),
      );

      const orderHours = ordersHoursForDay?.intervals.map((hour) => {
        return {
          start: formatDateWithTimeZone(hour.startTime),
          end: formatDateWithTimeZone(hour.endTime),
          status: 'BOOKED',
          title: 'booked',
        };
      });

      setBookedHours(orderHours);
    } else {
      setBookedHours([]);
    }
  }, [selectedDay, orders]);

  const { loading, data: schedule } = useGetWorkSchedule({
    variables: {
      userId: userInfo.id,
    },
    onCompleted: (data) => {
      const daysVariant = getObjectByValue(
        WORKING_DAYS[i18n.resolvedLanguage],
        data?.getWorkSchedule?.workdays,
      );
      const from = getObjectByValue(WORKING_HOURS_FROM, data?.getWorkSchedule?.startTime);
      const to = getObjectByValue(WORKING_HOURS_TO, data?.getWorkSchedule?.endTime);
      const lunchFrom = getObjectByValue(WORKING_HOURS_FROM, data?.getWorkSchedule?.startLunch);
      const lunchTo = getObjectByValue(WORKING_HOURS_TO, data?.getWorkSchedule?.endLunch);
      setWorkingDaysSelectedVariant(daysVariant);
      setWorkingHoursFrom(from);
      setWorkingHoursTo(to);
      setValue('workingDay', daysVariant);
      setValue('workingHoursFrom', from);
      setValue('workingHoursTo', to);

      if (lunchFrom && lunchTo) {
        setLunchBreakFrom(lunchFrom);
        setLunchBreakTo(lunchTo);
        setValue('lunchBreak', [t('lunchBreak')]);
        setValue('lunchBreakFrom', lunchFrom);
        setValue('lunchBreakTo', lunchTo);
      } else {
        setLunchBreakFrom(getDefaulValueObject(t('from')));
        setLunchBreakTo(getDefaulValueObject(t('to')));
        setValue('lunchBreakFrom', getDefaulValueObject(t('from')));
        setValue('lunchBreakTo', getDefaulValueObject(t('to')));
      }
    },
  });

  const handleLunchBreakFromChange = (event) => {
    const { value } = event.target;
    setValue('lunchBreakFrom', value);
    setLunchBreakFrom(value);
  };

  const handleLunchBreakToChange = (event) => {
    const { value } = event.target;
    setValue('lunchBreakTo', value);
    setLunchBreakTo(value);
  };

  const handleWorkingHoursFromChange = (event) => {
    const { value } = event.target;
    setValue('workingHoursFrom', value);
    setWorkingHoursFrom(value);
  };

  const handleWorkingHoursToChange = (event) => {
    const { value } = event.target;
    setValue('workingHoursTo', value);
    setWorkingHoursTo(value);
  };

  const handleWorkingDaysVariantChange = (event) => {
    const { value } = event.target;
    setValue('workingDay', value);
    setWorkingDaysSelectedVariant(value);
  };

  const handleSetNonWorkingTime = async ({ status, start, end }) => {
    if (status === 'FREE') {
      await addNonWorkingTime({
        variables: {
          input: {
            endTime: formatDateToExtendedFormat(selectedDay, end),
            startTime: formatDateToExtendedFormat(selectedDay, start),
          },
        },
      });
    } else {
      await deleteNonWorkingTime({
        variables: {
          input: {
            endTime: formatDateToExtendedFormat(selectedDay, end),
            startTime: formatDateToExtendedFormat(selectedDay, start),
          },
        },
      });
    }
  };

  const setDayHandler = (date) => {
    setValue('disableDay', false);
    if (date) {
      setSelectedDay(date);
      setEvents(
        createEventsArray(
          workingHoursFrom?.value,
          workingHoursTo?.value,
          lunchBreakFrom?.value,
          lunchBreakTo?.value,
          date,
        ),
      );
    }
  };

  const onSubmit = async ({
    workingDay: workdays,
    workingHoursFrom: startTime,
    workingHoursTo: endTime,
    lunchBreakFrom: startLunch,
    lunchBreakTo: endLunch,
  }) => {
    const input = {
      workdays: workdays.value,
      startTime: startTime.value,
      endTime: endTime.value,
      startLunch: lunchBreak ? startLunch.value : null,
      endLunch: lunchBreak ? endLunch.value : null,
    };
    if (schedule) {
      await updateWorkSchedule({
        variables: {
          updateWorkScheduleId: schedule.getWorkSchedule.id,
          input,
        },
      });
    } else {
      await createWorkSchedule({
        variables: {
          input,
        },
      });
    }
  };

  if (loading) {
    return (
      <Box p={'10px'} display='flex' justifyContent={'center'}>
        <CircularProgress size={20} />
      </Box>
    );
  }

  const chageDayStatus = (event) => {
    setValue('disableDay', event.target.checked);
    if (event.target.checked) {
      addNonWorkingTime({
        variables: {
          input: {
            endTime: format(endOfDay(selectedDay), `yyyy-MM-dd'T'HH:mm:ss'Z'`),
            startTime: format(startOfDay(selectedDay), `yyyy-MM-dd'T'HH:mm:ss'Z'`),
          },
        },
      });
    } else {
      deleteNonWorkingTime({
        variables: {
          input: {
            endTime: format(endOfDay(selectedDay), `yyyy-MM-dd'T'HH:mm:ss'Z'`),
            startTime: format(startOfDay(selectedDay), `yyyy-MM-dd'T'HH:mm:ss'Z'`),
          },
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledsubTitle variant={'h5'}>{t('workingDay')}</StyledsubTitle>
        <StyledSelect
          control={control}
          name={'workingDay'}
          value={workingDaysSelectedVariant.name}
          onChange={handleWorkingDaysVariantChange}
          variant={'outlined'}
          displayEmpty
          isCustomValue
          MenuProps={MenuProperties}
          dataArray={WORKING_DAYS[i18n.resolvedLanguage]}
        />
        <StyledsubTitle variant={'h5'}>{t('workingHours')}</StyledsubTitle>
        <StyledWorkingHours>
          <PriceTimeLine>
            <StyledSelect
              value={workingHoursFrom.name}
              name={'workingHoursFrom'}
              control={control}
              onChange={handleWorkingHoursFromChange}
              variant={'outlined'}
              className={'shortSelectDuration'}
              MenuProps={MenuProperties}
              dataArray={WORKING_HOURS_FROM}
              isCustomValue
              error={errors?.workingHoursFrom?.message}
            />
            <StyledSelect
              value={workingHoursTo.name}
              name={'workingHoursTo'}
              control={control}
              onChange={handleWorkingHoursToChange}
              variant={'outlined'}
              className={'shortSelectDuration'}
              MenuProps={MenuProperties}
              dataArray={WORKING_HOURS_TO}
              isCustomValue
              error={errors?.workingHoursTo?.message}
            />
          </PriceTimeLine>
          {lunchBreak && (
            <PriceTimeLine>
              <StyledSelect
                value={lunchBreakFrom.name}
                name={'lunchBreakFrom'}
                control={control}
                onChange={handleLunchBreakFromChange}
                variant={'outlined'}
                className={'shortSelectDuration'}
                MenuProps={MenuProperties}
                dataArray={WORKING_HOURS_FROM}
                isCustomValue
                error={errors?.lunchBreakFrom?.message}
              />
              <StyledSelect
                value={lunchBreakTo.name}
                name={'lunchBreakTo'}
                control={control}
                onChange={handleLunchBreakToChange}
                variant={'outlined'}
                className={'shortSelectDuration'}
                MenuProps={MenuProperties}
                dataArray={WORKING_HOURS_TO}
                isCustomValue
                error={errors?.lunchBreakTo?.message}
              />
            </PriceTimeLine>
          )}

          <Box marginTop='20px'>
            <ControlCheckbox
              options={[t('lunchBreak')]}
              control={control}
              name={'lunchBreak'}
              singleCheckbox
            />
          </Box>
        </StyledWorkingHours>
        {(workingHoursFrom?.value || workingHoursFrom?.value === 0) && workingHoursTo?.value && (
          <>
            <StyledsubTitle variant={'h5'}>{t('additionalScheduleSettings')}</StyledsubTitle>
            <Box display='flex' width='100%' flexWrap='wrap' gap='15px'>
              <DatePicker
                days={selectedDay}
                setDays={setDayHandler}
                bookedDays={bookedDays}
                disableWeekends={getWeekendsArray(workingDaysSelectedVariant.value)}
                disabledDaysBeforeToday
              />
              {selectedDay && (
                <Box
                  sx={{
                    padding: '16px',
                  }}
                >
                  <DaySchedule
                    selectedDate={selectedDay}
                    events={events}
                    onSelectTime={handleSetNonWorkingTime}
                  />
                  <Box margin='15px 0 0 0'>
                    <ControlCheckbox
                      control={control}
                      name={'disableDay'}
                      onChange={chageDayStatus}
                      singleCheckbox
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
        <ButtonManageServices margin={'15px 0 0 0'}>
          <Button
            className={'save'}
            variant={'text'}
            type={'submit'}
            disableRipple
            disableFocusRipple
          >
            {t('save')}
          </Button>
        </ButtonManageServices>
      </form>
    </>
  );
};

export default TabSchedule;
