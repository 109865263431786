import { Box } from '@mui/material';
import React from 'react';

const Index = ({ margin = '0px 4px' }) => {
  return (
    <Box
      component={'div'}
      sx={{
        margin,
      }}
    >
      ·
    </Box>
  );
};

export default Index;
