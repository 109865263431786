import palette from '../theme/palette';

const RemoveIcon = ({ width = '20', height = '20', className, color = palette.artyClickRed }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6.062 15L5 13.938L8.938 10L5 6.062L6.062 5L10 8.938L13.938 5L15 6.062L11.062 10L15 13.938L13.938 15L10 11.062L6.062 15Z'
      fill={color}
    />
  </svg>
);

export default RemoveIcon;
