import { CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import palette from '../../theme/palette';
import Comment from '../Comment';
import Button from '../UI/Button';
import { BlockDescription, ButtonManage, StyledReviews } from './styles';

const CommentsList = ({
  loading,
  scrollToReference,
  reviews,
  type,
  openCreateComment,
  createNewComment = false,
  className,
  customEmptyMessageTranslation,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <CircularProgress
          sx={{
            color: palette.primary,
          }}
        />
      ) : (
        <>
          <StyledReviews ref={scrollToReference}>
            {reviews && reviews.length === 0 && (
              <BlockDescription>
                {t(customEmptyMessageTranslation || 'emptyComments')}
              </BlockDescription>
            )}
            {reviews &&
              reviews.length > 0 &&
              reviews.map((comment) => (
                <Comment className={className} type={type} comment={comment} key={comment.id} />
              ))}
          </StyledReviews>
          {createNewComment && (
            <ButtonManage>
              <Button
                onClickButton={openCreateComment}
                title={t('leaveFeedback')}
                margin={'20px'}
              />
            </ButtonManage>
          )}
        </>
      )}
    </>
  );
};

export default CommentsList;
