import { gql } from '@apollo/client';

export const SET_SERVICE_CITY = gql`
  mutation SetServiceCity($cityId: ID!) {
    setServiceCity(cityId: $cityId) {
      serviceCity {
        id
        translations {
          languageCode
          name
        }
      }
    }
  }
`;
