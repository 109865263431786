import { Button as MuiButton, Chip, InputAdornment, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRequestAddingSubcategory } from '../../graphql/hooks/useMutations';
import { RightIcon } from '../../icons/RightIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import deleteIcon from '../../images/deleteIcon.svg';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';
import { getObjectNameAndIdFromArray } from '../../utils/getDifferentFormatOfData';
import Button from '../UI/Button';
import { ControlCheckbox } from '../UI/Checkbox';
import { ControlInput } from '../UI/Input';
import {
  ButtonManage,
  CenterBlock,
  CenterTypography,
  ChooseServiceTypesWrapper,
  Counter,
  List,
  NewSubcategory,
  SelectedSubcategories,
  StyledScrollButton,
  SubcategoriesList,
} from './styles';

const ChooseServiceTypes = ({ handleClose, modalDataArray, dataFromModal, setDataFromModal }) => {
  const [filteredArray, setFilteredArray] = useState(null);
  const [isShowThankWindow, setIsShowThankWindow] = useState(false);
  const { control, watch, setValue } = useForm({
    defaultValues: {
      search: '',
      typeOfCategories: dataFromModal || [],
    },
  });
  const { t, i18n } = useTranslation();
  const [requestAddingSubcategory] = useRequestAddingSubcategory();
  const typeOfCategories = watch('typeOfCategories');
  const selectedSubcategoriesReference = useRef(null);

  const scroll = (scrollOffset) => {
    selectedSubcategoriesReference.current.scrollLeft += scrollOffset;
  };

  const handleDelete = (chipToDelete) => {
    setValue(
      'typeOfCategories',
      typeOfCategories.filter((category) => {
        return category.id !== chipToDelete;
      }),
    );
  };

  const dataSubCategories = alphabeticalSorting(
    modalDataArray?.subcategories,
    (sub) => sub.translations,
    i18n,
  );

  const onCancelClick = () => {
    setDataFromModal(dataFromModal);
    handleClose(true);
  };

  const handleSave = () => {
    setDataFromModal(typeOfCategories);
    handleClose(true);
  };

  const onSearchChange = (event) => {
    const array = getObjectNameAndIdFromArray(dataSubCategories, i18n);
    setFilteredArray(array.filter((item) => item.name.includes(event.target.value)));
  };

  const addNewSubcategory = async () => {
    await requestAddingSubcategory({
      variables: {
        subcategoryName: watch('search'),
      },
    }).then((response) => {
      if (response?.data.requestAddingSubcategory) {
        setIsShowThankWindow(true);
      }
    });
  };

  return (
    <ChooseServiceTypesWrapper>
      <CenterTypography variant={'h4'} className={'m-0'}>
        {t('serviceTypes')}
      </CenterTypography>
      <CenterTypography
        variant={'body1'}
        component={'div'}
        className={'fw-400'}
        margin={'20px 0 16px 0'}
      >
        {t('whatServicesDoYouProvide')}
      </CenterTypography>
      <form>
        <ControlInput
          control={control}
          name={'search'}
          placeholder={t('enterServiceName')}
          inputClassName={'searchService'}
          startAdornment={
            <InputAdornment position={'start'}>
              <SearchIcon />
            </InputAdornment>
          }
          onChange={onSearchChange}
        />
        <CenterBlock>
          {filteredArray?.length > 0 ? (
            <SubcategoriesList>
              <ControlCheckbox
                control={control}
                name={'typeOfCategories'}
                margin={'0 0 12px 0'}
                options={filteredArray}
              />
            </SubcategoriesList>
          ) : filteredArray?.length === 0 ? (
            !isShowThankWindow ? (
              <NewSubcategory>
                <CenterTypography variant={'h4'} className={'m-0'} textAlign={'left'}>
                  {t('requestSubcategory')}
                </CenterTypography>
                <Typography variant={'body-2'} margin={'10px 0 20px'}>
                  {t('requestToAddSubcategory')}
                </Typography>
                <Button
                  type={'button'}
                  title={t('request')}
                  className={'fullWidth'}
                  onClickButton={addNewSubcategory}
                />
              </NewSubcategory>
            ) : (
              <NewSubcategory className={'successful'}>
                <CenterTypography textAlign={'left'} variant={'h4'} className={'m-0'}>
                  {t('thank')}
                </CenterTypography>
                <Typography variant={'body-2'} margin={'10px 0 8px'}>
                  {`${t('weReceivedYourRequest')} ${watch('search')} ${t('appearInOurCatalog')}`}
                </Typography>
              </NewSubcategory>
            )
          ) : modalDataArray?.subcategories ? (
            <SubcategoriesList>
              <ControlCheckbox
                control={control}
                name={'typeOfCategories'}
                margin={'0 0 12px 0'}
                options={getObjectNameAndIdFromArray(dataSubCategories, i18n)}
              />
            </SubcategoriesList>
          ) : (
            <CenterTypography>{t('selectServiceFirst')}</CenterTypography>
          )}
        </CenterBlock>
        {typeOfCategories.length > 0 && (
          <SelectedSubcategories>
            <Counter>{`${t('selectedServices')}: ${typeOfCategories.length}`}</Counter>
            <List ref={selectedSubcategoriesReference}>
              {typeOfCategories?.map(({ name, id }) => (
                <Chip
                  key={id}
                  deleteIcon={<img src={deleteIcon} alt={'remove'} />}
                  label={name}
                  onDelete={() => handleDelete(id)}
                />
              ))}
              {typeOfCategories.length > 2 && (
                <StyledScrollButton onClick={() => scroll(100)}>
                  <RightIcon />
                </StyledScrollButton>
              )}
            </List>
          </SelectedSubcategories>
        )}
        <ButtonManage>
          <MuiButton
            className={'cancel'}
            variant={'text'}
            onClick={onCancelClick}
            disableRipple
            disableFocusRipple
          >
            {t('cancel')}
          </MuiButton>
          <Button
            title={t('save')}
            className={'md'}
            variant={'text'}
            disableRipple
            disableFocusRipple
            onClickButton={handleSave}
          />
        </ButtonManage>
      </form>
    </ChooseServiceTypesWrapper>
  );
};

export default ChooseServiceTypes;
