import Container from '../../components/Container';

const Registration = () => {
  return (
    <>
      <Container>
        <h1>Registration</h1>
      </Container>
    </>
  );
};

export default Registration;
