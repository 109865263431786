import { ListItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { MENU } from '../../constants/constants';
import { useAuth } from '../../hooks';
import theme from '../../theme';
import { MenuList } from './styles';

const Menu = ({ variant, handleClose }) => {
  const { t } = useTranslation();
  const { logout, isAuth } = useAuth();
  const handleLogOut = () => {
    logout();
    handleClose();
  };

  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <MenuList className={variant === 'adaptive' ? 'adaptiveMenu' : null}>
      {MENU.map((item) => {
        return (
          <ListItem key={item.id}>
            <NavLink
              to={item.link}
              onClick={handleClose}
              className={(linkState) => {
                return `${linkState?.isActive ? 'menuLink active' : 'menuLink'}`;
              }}
              end
            >
              {t(item.title)}
            </NavLink>
          </ListItem>
        );
      })}
      {isTablet && isAuth && (
        <ListItem className={'menuLink'} onClick={handleLogOut}>
          {t('logout')}
        </ListItem>
      )}
    </MenuList>
  );
};
export default Menu;
