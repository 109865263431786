import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGE_ICONS } from '../../../constants/constants';
import { useSetLocale } from '../../../graphql/hooks/useMutations';
import { useGetLocales } from '../../../graphql/hooks/useQueries';
import { useAuth } from '../../../hooks';
import DropdownIcon from '../../../icons/DropdownIcon';
import { FieldValue, Icon, Option, SelectWrapper, StyledLanguagePicker } from './styles';

const LanguagePicker = () => {
  const { i18n } = useTranslation();
  const { data } = useGetLocales();
  const [setLocale] = useSetLocale();
  const { isAuth, setUserInfo } = useAuth();

  const defaultOption = () => {
    const languageDefault = localStorage.getItem('switch');
    return languageDefault || i18n.resolvedLanguage;
  };

  const [state, setState] = useState(defaultOption());

  const renderFieldValue = (languageCode) => {
    return (
      <FieldValue>
        <Icon>
          <img src={LANGUAGE_ICONS[languageCode]} alt={languageCode} />
        </Icon>
        {languageCode.toUpperCase()}
      </FieldValue>
    );
  };

  const handleChange = (event) => {
    setState(event.target.value);
  };

  const handleChangeLanguage = (code) => {
    i18n.changeLanguage(code);
    localStorage.setItem('switch', code);
    if (isAuth) {
      setLocale({
        variables: {
          code,
        },
      }).then(() =>
        setUserInfo((previous) => ({
          ...previous,
          locale: code,
        })),
      );
    }
  };

  return (
    <StyledLanguagePicker>
      <SelectWrapper
        value={state}
        variant='standard'
        width='100px'
        onChange={handleChange}
        renderValue={(value) => renderFieldValue(value)}
        displayEmpty
        fullWidth
        IconComponent={DropdownIcon}
      >
        {data?.getLocales?.map((item) => {
          return (
            <Option
              key={item.code}
              value={item.code}
              onClick={() => handleChangeLanguage(item.code)}
            >
              {renderFieldValue(item.code)}
            </Option>
          );
        })}
      </SelectWrapper>
    </StyledLanguagePicker>
  );
};

export default LanguagePicker;
