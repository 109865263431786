import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import deleteIcon from '../../images/deleteIcon.svg';
import { getNameFromTranslations } from '../../utils/getDifferentFormatOfData';
import { ChipWrapper, FixedBlock, ShowMore, StyledChip } from './styles';

const ChipsList = ({ chipsArr, withDeleteButton = false, handleDelete, margin }) => {
  const chipsBlock = useRef(null);
  const [isAllChips, setIsAllChips] = useState(false);
  const [chipWidths, setChipWidths] = useState([]);
  const [visibleChips, setVisibleChips] = useState(0);
  const [summaryChipWidth, setSummaryChipWidth] = useState(0);
  const { i18n } = useTranslation();
  const showAllChips = () => {
    setIsAllChips(true);
  };

  const getElementWidthWithMargin = (element) => {
    const rect = element.getBoundingClientRect();
    const style = window.getComputedStyle(element);

    const marginLeft = parseFloat(style.marginLeft) || 0;
    const marginRight = parseFloat(style.marginRight) || 0;

    return rect.width + marginLeft + marginRight;
  };

  useEffect(() => {
    setChipWidths(
      [...chipsBlock?.current?.childNodes].map((child) => {
        return getElementWidthWithMargin(child);
      }),
    );
  }, [chipsBlock]);

  useEffect(() => {
    let counter = 0;
    let sum = 0;
    for (const width of chipWidths) {
      sum += width;
      if (Math.round(sum) <= chipsBlock.current.offsetWidth) {
        counter++;
      }
    }
    setSummaryChipWidth(sum);
    setVisibleChips(counter);
  }, [chipWidths]);

  return chipsArr?.length ? (
    <ChipWrapper margin={margin}>
      <FixedBlock className={isAllChips && 'full'} ref={chipsBlock}>
        {chipsArr.map((item, index) => {
          return (
            <StyledChip
              key={item?.id}
              deleteIcon={withDeleteButton ? <img src={deleteIcon} alt={'remove'} /> : <></>}
              onDelete={withDeleteButton ? () => handleDelete(item.id) : null}
              label={
                item?.translations ? getNameFromTranslations(item?.translations, i18n) : item?.name
              }
              width={chipWidths[index]}
            />
          );
        })}
      </FixedBlock>
      {summaryChipWidth > chipsBlock?.current?.offsetWidth &&
        chipsArr?.length > visibleChips &&
        chipsArr?.length > 1 && (
          <ShowMore className={isAllChips && 'hidden'} onClick={() => showAllChips()}>
            +{chipsArr?.length - visibleChips}
          </ShowMore>
        )}
    </ChipWrapper>
  ) : null;
};

export default ChipsList;
