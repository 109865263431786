import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const CONFIRM_EMAIL = gql`
  ${USER}
  mutation ConfirmEmail($confirmationCode: String!) {
    confirmEmail(confirmationCode: $confirmationCode) {
      token
      user {
        ...UserFragment
      }
    }
  }
`;
