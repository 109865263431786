import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button as MuiButton } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDeleteAccount } from '../../graphql/hooks/useMutations';
import { useAuth, useToast } from '../../hooks';
import { deleteAccountSchema } from '../../validation/schema/deleteAccountSchema';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import EndAdornment from '../UI/Input/components/EndAdornment';
import {
  CenterTypography,
  ContinueButtonWrapper,
  DeleteModalWrapper,
  Form,
  ModalDescription,
} from './styles';

export const ConfirmAccountDeleting = ({ handleClose }) => {
  const { logout } = useAuth();
  const [step, setStep] = useState(1);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [variant, setToastConfig, setSpecificErrorInformation] = useToast();
  const { t } = useTranslation();
  const [deleteAccount] = useDeleteAccount();
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(deleteAccountSchema),
    defaultValues: {
      password: '',
    },
  });

  const handleDelete = () => {
    setStep(2);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleClickShowPassword = (setStateFunction, state) => {
    setStateFunction(!state);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async ({ password }) => {
    await deleteAccount({
      variables: {
        password,
      },
      onCompleted: () => {
        setToastConfig(variant.successDelete);
        setTimeout(() => {
          logout();
          handleClose();
        }, 0);
      },
      onError: (error) => {
        console.log(error.message);
        setSpecificErrorInformation(error.message);
        setToastConfig(variant.error);
      },
    });
  };

  const firstStep = () => (
    <>
      <CenterTypography margin={'10px 0 20px 0'} variant={'h4'}>
        {t('youWantDeleteAccount')}
      </CenterTypography>
      <ModalDescription
        align={'center'}
        variant={'body1'}
        component={'div'}
        maxWidth={'350px!important'}
      >
        {t('deleteWarning')}
      </ModalDescription>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        className={'btnWrapper'}
      >
        <MuiButton
          className={'cancel'}
          variant={'text'}
          onClick={handleCancel}
          disableRipple
          disableFocusRipple
        >
          {t('cancel')}
        </MuiButton>
        <Button onClickButton={handleDelete} title={t('justDelete')} />
      </Box>
    </>
  );

  const secondStep = () => (
    <>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('enterYourPassForDeleting')}
      </CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlInput
          control={control}
          name={'password'}
          variant={'filled'}
          label={t('password')}
          type={isShowPassword ? 'text' : 'password'}
          margin={'20px 0'}
          endAdornment={
            <EndAdornment
              onClick={() => handleClickShowPassword(setIsShowPassword, isShowPassword)}
              onMouseDown={handleMouseDownPassword}
              isShow={isShowPassword}
            />
          }
        />
        <ContinueButtonWrapper>
          <Button
            type={'submit'}
            title={t('deleteAccount')}
            margin={'0 auto'}
            className={'deleteAccount'}
          />
        </ContinueButtonWrapper>
      </Form>
    </>
  );

  return <DeleteModalWrapper>{step === 1 ? firstStep() : secondStep()}</DeleteModalWrapper>;
};
