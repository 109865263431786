import { gql } from '@apollo/client';

export const UPDATE_WORK_SCHEDULE = gql`
  mutation UpdateWorkSchedule($updateWorkScheduleId: ID!, $input: WorkScheduleInput!) {
    updateWorkSchedule(id: $updateWorkScheduleId, input: $input) {
      endLunch
      endTime
      id
      startLunch
      startTime
      workdays
    }
  }
`;
