import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';

import Footer from './Footer';
import Header from './Header';
import { Main, StyledLayout } from './styles';

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </>
    </StyledLayout>
  );
};

export default Layout;
