import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import { useConfirmEmail } from '../../graphql/hooks/useMutations';
import { useAuth } from '../../hooks';

const ConfirmEmail = () => {
  const [query] = useSearchParams();
  const [confirmEmail] = useConfirmEmail();
  const { setUserInfo, setJwtToLocalStorage } = useAuth();
  const queryToken = query.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    const request = async (jwt) => {
      await confirmEmail({
        variables: {
          confirmationCode: jwt,
        },
      }).then((response) => {
        const { user, token } = response?.data?.confirmEmail;
        setUserInfo({
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          photo: user?.photo,
          city: user?.city,
          locale: user?.locale,
          email: user?.email,
          role: user?.role,
        });
        setJwtToLocalStorage(token);
        navigate(ROUTES.profile);
      });
    };

    if (queryToken) {
      request(queryToken);
    }

    navigate('/');
  }, []);
};

export default ConfirmEmail;
