import EngIcon from '../images/en.png';
import FacebookIcon from '../images/facebook.svg';
import InstagramIcon from '../images/instagram.svg';
import RuIcon from '../images/ru.png';
import SerIcon from '../images/sr.png';
import Telegram from '../images/telegram.svg';
import WhatsApp from '../images/whatsApp.svg';

export const ROUTES = {
  home: '/',
  catalog: '/catalog',
  categoryList: '/category-list',
  profile: '/profile',
  myService: '/profile/my-service',
  service: '/service',
  provider: '/master',
  providerWithId: '/master/:id',
  providerServices: '/master-services',
  providerServicesWithId: '/master-services/:userId',
  about: '/about-us',
  faq: '/faq',
  searchResults: '/search-results',
  devPage: '/devPage',
  login: '/login',
  registration: '/registration',
  confirmEmail: '/confirmEmail',
  termsOfUse: '/terms-of-use',
};

export const HOST_IMAGE_URL = process.env.REACT_APP_API_HOST;

export const COUNTRY_NAMES = {
  1: 'Serbia',
  2: 'Montenegro',
  3: 'Bosnia',
};

export const PHONE_NUMBER = '381629337456';

export const TYPE_MODAL = {
  TEST: 'test',
};

export const USER_PROFILE_TAB = [
  {
    title: 'commentsHistory',
  },
  {
    title: 'featuredMasters',
  },
  {
    title: 'reservations',
  },
];

export const PROVIDER_PROFILE_TAB = [
  {
    title: 'services',
  },
  {
    title: 'contacts',
  },
  {
    title: 'reviews',
  },
  {
    title: 'dashboard',
  },
  {
    title: 'schedule',
  },
  {
    title: 'reservations',
  },
];

export const PROVIDER_PAGE_TAB = [
  {
    title: 'reviews',
    key: 'reviews',
  },
  {
    title: 'contacts',
    key: 'contacts',
  },
  {
    title: 'servicesPhoto',
    key: 'photos',
  },
  {
    title: 'certificatesPhoto',
    key: 'certificates',
  },
];

export const CURRENCY_TYPE = 'p';

export const MENU = [
  {
    id: '1',
    title: 'home',
    link: '/',
  },
  {
    id: '2',
    title: 'about',
    link: '/about-us',
  },
  {
    id: '3',
    title: 'faq',
    link: '/faq',
  },
];

export const CONTACT_TYPE = {
  ru: [
    {
      id: '1',
      name: 'Телефон',
      type: 'PHONE',
    },
    {
      id: '2',
      name: 'Email',
      type: 'EMAIL',
    },
    {
      id: '3',
      name: 'Ваш сайт',
      type: 'SOCIAL',
    },
    {
      id: '4',
      name: 'Telegram',
      type: 'TELEGRAM',
    },
    {
      id: '5',
      name: 'WhatsApp',
      type: 'WHATSAPP',
    },
    {
      id: '6',
      name: 'Viber',
      type: 'VIBER',
    },
    {
      id: '7',
      name: 'Instagram',
      type: 'INSTAGRAM',
    },
    {
      id: '8',
      name: 'Facebook',
      type: 'FACEBOOK',
    },
    {
      id: '9',
      name: 'Youtube',
      type: 'YOUTUBE',
    },
    {
      id: '10',
      name: 'Twitter',
      type: 'TWITTER',
    },
    {
      id: '11',
      name: 'Linkedin',
      type: 'LINKEDIN',
    },
  ],
  en: [
    {
      id: '1',
      name: 'Phone',
      type: 'PHONE',
    },
    {
      id: '2',
      name: 'Email',
      type: 'EMAIL',
    },
    {
      id: '3',
      name: 'Your website',
      type: 'SOCIAL',
    },
    {
      id: '4',
      name: 'Telegram',
      type: 'TELEGRAM',
    },
    {
      id: '5',
      name: 'WhatsApp',
      type: 'WHATSAPP',
    },
    {
      id: '6',
      name: 'Viber',
      type: 'VIBER',
    },
    {
      id: '7',
      name: 'Instagram',
      type: 'INSTAGRAM',
    },
    {
      id: '8',
      name: 'Facebook',
      type: 'FACEBOOK',
    },
    {
      id: '9',
      name: 'Youtube',
      type: 'YOUTUBE',
    },
    {
      id: '10',
      name: 'Twitter',
      type: 'TWITTER',
    },
    {
      id: '11',
      name: 'Linkedin',
      type: 'LINKEDIN',
    },
  ],
  sr: [
    {
      id: '1',
      name: 'Телефон',
      type: 'PHONE',
    },
    {
      id: '2',
      name: 'Email',
      type: 'EMAIL',
    },
    {
      id: '3',
      name: 'Ваш сајт',
      type: 'SOCIAL',
    },
    {
      id: '4',
      name: 'Telegram',
      type: 'TELEGRAM',
    },
    {
      id: '5',
      name: 'WhatsApp',
      type: 'WHATSAPP',
    },
    {
      id: '6',
      name: 'Viber',
      type: 'VIBER',
    },
    {
      id: '7',
      name: 'Instagram',
      type: 'INSTAGRAM',
    },
    {
      id: '8',
      name: 'Facebook',
      type: 'FACEBOOK',
    },
    {
      id: '9',
      name: 'Youtube',
      type: 'YOUTUBE',
    },
    {
      id: '10',
      name: 'Twitter',
      type: 'TWITTER',
    },
    {
      id: '11',
      name: 'Linkedin',
      type: 'LINKEDIN',
    },
  ],
};

export const CONTACT_ORDER = {
  PHONE: 1,
  EMAIL: 2,
  SOCIAL: 3,
  TELEGRAM: 4,
  WHATSAPP: 5,
  VIBER: 6,
  INSTAGRAM: 7,
  FACEBOOK: 8,
  YOUTUBE: 9,
  TWITTER: 10,
  LINKEDIN: 11,
};

export const TIME = ['DAY', 'CALL'];

export const CURRENCY = ['RUB', 'RSD', 'EUR'];

export const TIMESTAMPS = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];

export const LANGUAGE_ICONS = {
  ru: RuIcon,
  en: EngIcon,
  sr: SerIcon,
};

export const SOCIAL_LINKS = [
  {
    id: '1',
    icon: FacebookIcon,
    title: 'Facebook',
    link: 'https://www.facebook.com/sveuredu.rs',
  },
  {
    id: '2',
    icon: InstagramIcon,
    title: 'Instagram',
    link: 'https://www.instagram.com/sveuredu.rs',
  },
  {
    id: '3',
    icon: Telegram,
    title: 'Telegram',
    link: `https://t.me/+${PHONE_NUMBER}`,
  },
  {
    id: '4',
    icon: WhatsApp,
    title: 'WhatsApp',
    link: `https://wa.me/${PHONE_NUMBER}`,
  },
];

export const DEFAULT_CITY_ID = 2;

export const ORDERS_PER_PAGE = 3;

export const NOTIFICATIONS_PER_PAGE = 3;

export const IPIFY_URL = 'https://api.ipify.org/?format=json';

export const WORKING_HOURS_FROM = [
  {
    id: '00:00',
    name: '00:00',
    value: 0,
  },
  {
    id: '01:00',
    name: '01:00',
    value: 1,
  },
  {
    id: '02:00',
    name: '02:00',
    value: 2,
  },
  {
    id: '03:00',
    name: '03:00',
    value: 3,
  },
  {
    id: '04:00',
    name: '04:00',
    value: 4,
  },
  {
    id: '05:00',
    name: '05:00',
    value: 5,
  },
  {
    id: '06:00',
    name: '06:00',
    value: 6,
  },
  {
    id: '07:00',
    name: '07:00',
    value: 7,
  },
  {
    id: '08:00',
    name: '08:00',
    value: 8,
  },
  {
    id: '09:00',
    name: '09:00',
    value: 9,
  },
  {
    id: '10:00',
    name: '10:00',
    value: 10,
  },
  {
    id: '11:00',
    name: '11:00',
    value: 11,
  },
  {
    id: '12:00',
    name: '12:00',
    value: 12,
  },
  {
    id: '13:00',
    name: '13:00',
    value: 13,
  },
  {
    id: '14:00',
    name: '14:00',
    value: 14,
  },
  {
    id: '15:00',
    name: '15:00',
    value: 15,
  },
  {
    id: '16:00',
    name: '16:00',
    value: 16,
  },
  {
    id: '17:00',
    name: '17:00',
    value: 17,
  },
  {
    id: '18:00',
    name: '18:00',
    value: 18,
  },
  {
    id: '19:00',
    name: '19:00',
    value: 19,
  },
  {
    id: '20:00',
    name: '20:00',
    value: 20,
  },
  {
    id: '21:00',
    name: '21:00',
    value: 21,
  },
  {
    id: '22:00',
    name: '22:00',
    value: 22,
  },
  {
    id: '23:00',
    name: '23:00',
    value: 23,
  },
];

export const WORKING_HOURS_TO = [
  {
    id: '01:00',
    name: '01:00',
    value: 1,
  },
  {
    id: '02:00',
    name: '02:00',
    value: 2,
  },
  {
    id: '03:00',
    name: '03:00',
    value: 3,
  },
  {
    id: '04:00',
    name: '04:00',
    value: 4,
  },
  {
    id: '05:00',
    name: '05:00',
    value: 5,
  },
  {
    id: '06:00',
    name: '06:00',
    value: 6,
  },
  {
    id: '07:00',
    name: '07:00',
    value: 7,
  },
  {
    id: '08:00',
    name: '08:00',
    value: 8,
  },
  {
    id: '09:00',
    name: '09:00',
    value: 9,
  },
  {
    id: '10:00',
    name: '10:00',
    value: 10,
  },
  {
    id: '11:00',
    name: '11:00',
    value: 11,
  },
  {
    id: '12:00',
    name: '12:00',
    value: 12,
  },
  {
    id: '13:00',
    name: '13:00',
    value: 13,
  },
  {
    id: '14:00',
    name: '14:00',
    value: 14,
  },
  {
    id: '15:00',
    name: '15:00',
    value: 15,
  },
  {
    id: '16:00',
    name: '16:00',
    value: 16,
  },
  {
    id: '17:00',
    name: '17:00',
    value: 17,
  },
  {
    id: '18:00',
    name: '18:00',
    value: 18,
  },
  {
    id: '19:00',
    name: '19:00',
    value: 19,
  },
  {
    id: '20:00',
    name: '20:00',
    value: 20,
  },
  {
    id: '21:00',
    name: '21:00',
    value: 21,
  },
  {
    id: '22:00',
    name: '22:00',
    value: 22,
  },
  {
    id: '23:00',
    name: '23:00',
    value: 23,
  },
  {
    id: '24:00',
    name: '24:00',
    value: 24,
  },
];

export const WORKING_DAYS = {
  en: [
    {
      id: '1',
      name: 'Monday - Friday',
      value: 'MON_FRI',
    },
    {
      id: '2',
      name: 'Monday - Saturday',
      value: 'MON_SAT',
    },
    {
      id: '3',
      name: 'Seven days a week',
      value: 'MON_SUN',
    },
  ],
  ru: [
    {
      id: '1',
      name: 'Понедельник - Пятница',
      value: 'MON_FRI',
    },
    {
      id: '2',
      name: 'Понедельник - Суббота',
      value: 'MON_SAT',
    },
    {
      id: '3',
      name: 'Без выходных',
      value: 'MON_SUN',
    },
  ],
  sr: [
    {
      id: '1',
      name: 'Понедељак - Петак',
      value: 'MON_FRI',
    },
    {
      id: '2',
      name: 'Понедељак - Субота',
      value: 'MON_SAT',
    },
    {
      id: '3',
      name: 'Седам дана у недељи',
      value: 'MON_SUN',
    },
  ],
};

export const RESERVATIONS_FILTER_VARIANTS = {
  en: [
    {
      name: 'New bookings (by date of application)',
      id: 1,
      value: 'YEAR',
    },
    {
      name: 'Bookings per month',
      id: 2,
      value: 'MONTH',
    },
  ],
  ru: [
    {
      name: 'Новые бронирования (по дате заявки)',
      id: 1,
      value: 'YEAR',
    },
    {
      name: 'Бронирования за месяц',
      id: 2,
      value: 'MONTH',
    },
  ],
  sr: [
    {
      name: 'Нове резервације (по датуму пријаве)',
      id: 1,
      value: 'YEAR',
    },
    {
      name: 'Резервације месечно',
      id: 2,
      value: 'MONTH',
    },
  ],
};

export const ONLINE_WORKPLACE_ID = 2;
