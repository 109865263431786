import React from 'react';

import { TYPE_MODAL } from '../../constants/constants';
import { useModalContext } from '../../context/ModalContext';
import Modal from '../Modal';

export const ModalController = () => {
  const { modalParameters } = useModalContext();

  switch (modalParameters.type) {
    case TYPE_MODAL.TEST: {
      return <Modal />;
    }

    default: {
      return <div />;
    }
  }
};

export const BasicModal = () => {
  const { modalParameters, handleChangeModalParameters } = useModalContext();

  return (
    <div
      open={modalParameters?.isOpen}
      onClose={() => {
        handleChangeModalParameters([
          {
            key: 'isOpen',
            value: false,
          },
        ]);
      }}
      style={{
        maxHeight: '100vh',
        overflowY: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          position: 'relative',
          margin: 'auto',
          width: 'fit-content',
          height: 'fit-content',
          outline: '0 !important',
        }}
      >
        <ModalController />
      </div>
    </div>
  );
};
