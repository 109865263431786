import { gql } from '@apollo/client';

export const GET_CATEGORY_BY_ID = gql`
  query GetCategoryById($categoryId: Int!) {
    getCategoryById(categoryId: $categoryId) {
      id
      photo
      slug
      translations {
        languageCode
        name
      }
      subcategories {
        id
        photo
        slug
        translations {
          languageCode
          name
        }
      }
      serviceCount
    }
  }
`;
