import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../../theme';
import palette from '../../../theme/palette';

export const StyledTabs = styled(Tabs)({
  minHeight: '32px',

  '& .MuiTabs-flexContainer': {
    flexWrap: 'wrap',
    gap: '10px',
  },
  '.MuiTabs-indicator': {
    display: 'none',
  },

  [theme.breakpoints.down('lg')]: {
    '.MuiTabs-scroller': {
      overflowX: 'scroll !important',
      width: 'auto',
      whiteSpace: 'nowrap',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
});
export const StyledWrap = styled(Box)({
  padding: 0,
  [theme.breakpoints.down('sm')]: {
    padding: '0 15px',
  },
});

export const StyledTab = styled(Tab)(({ isWarning = false }) => {
  return {
    '&.MuiTab-root': {
      borderBottom: 0,
      color: isWarning ? palette.artyClickRed : palette.primary,
      height: '32px',
      minHeight: '32px',
      padding: '0 20px',
      boxSizing: 'border-box',
      textTransform: 'none',

      '&:not(:last-child)': {
        marginRight: '8px',
      },
    },

    '&.Mui-selected': {
      background: palette.whiteSmoke,
      borderRadius: '20px',
      fontWeight: 'bold',
    },
  };
});

export const StyledTabPanel = styled('div')({
  '> div': {
    padding: '0',
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0 15px',
  },
});
