import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import AnchorLink from 'react-anchor-link-smooth-scroll/lib/anchor-link';

import theme from '../../theme';
import palette from '../../theme/palette';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
});

export const Heading = styled(Typography)({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '1.2',
    marginTop: '8px',
    marginBottom: '32px',
  },
});

export const FaqList = styled('div')({
  maxWidth: '696px',
  width: '100%',
  '.MuiAccordion-root': {
    boxShadow: 'none',
    marginBottom: '20px',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '18px!important',
    padding: '32px 24px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0!important',
    h4: {
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '1.2',
      },
    },
  },
  '.MuiAccordionSummary-root': {
    border: 'none',
    minHeight: '30px!important',
    padding: 0,
  },
  '.MuiAccordion-rounded::before': {
    display: 'none',
  },
  '.MuiAccordionDetails-root': {
    padding: '16px 0 0 0',
    textAlign: 'justify',
    '& .MuiTypography-body1': {
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    '.MuiAccordion-root': {
      padding: '24px 16px',
      marginBottom: '12px',
    },
  },
});

export const FaqTitleLinkList = styled('div')({
  border: '1px solid rgba(26, 30, 38, 0.1)',
  borderRadius: '18px!important',
  padding: '16px',
  marginLeft: '24px',
  maxWidth: '336px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '250px',
  },
  [theme.breakpoints.down('md')]: {
    margin: 0,
    marginBottom: '10px',
    maxWidth: '100%',
  },
});

export const StyledAnchor = styled(AnchorLink)({
  background: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  '&.active': {
    color: palette.blue,
    fontWeight: 600,
  },
  '&:not(:last-of-type)': {
    marginBottom: '12px',
  },
});
