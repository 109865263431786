import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import { ControlSelect } from '../../components/UI/Select';
import theme from '../../theme';
import palette from '../../theme/palette';

export const BlockDescription = styled('span')({
  fontSize: '14px',
  lineHeight: '1.4',
  color: palette.oldLavender,
  display: 'block',
  marginTop: '-10px',
});

export const ProfileWrapper = styled('div')({
  flex: 1,
  width: '100%',
  '.deleteAccount': {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 0px',
    color: palette.artyClickRed,
    textDecoration: 'underline',
    justifyContent: 'flex-start',
    svg: {
      marginRight: '8px',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    '.deleteAccount': {
      marginLeft: '12px',
    },
  },
});

export const StyledSelect = styled(ControlSelect)({
  '&.MuiInputBase-root': {
    margin: '12px 0',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '12px',
    background: palette.desertStorm,
    width: '456px',
    height: '56px',
  },

  '&.shortSelect': {
    width: '96px',
    margin: 0,
  },

  '&.shortSelectDuration': {
    width: '216px',
    margin: 0,
  },

  '.MuiSelect-select': {
    padding: '16px 20px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '.MuiOutlinedInput-input': {
    paddingRight: '18px',
  },

  [theme.breakpoints.down('md')]: {
    '&.MuiInputBase-root': {
      width: '100%',
    },

    '&.shortSelect': {
      width: '96px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&.MuiInputBase-root': {
      margin: 0,
    },
  },
});

export const ServiceTypes = styled('div')({
  padding: '12px 0 0',
  '> span': {
    color: palette.oldLavender,
  },
  'div.error': {
    color: '#D32F2F',
    marginTop: '-20px',
    marginBottom: '10px',
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export const StyledTypesList = styled('div')({
  marginBottom: '12px',
  padding: '12px 0 0',
  maxWidth: '456px',
  display: 'flex',
  flexWrap: 'wrap',
  '> div': {
    marginBottom: '12px',
  },

  '.MuiChip-root': {
    background: 'rgba(123, 238, 64, 0.2)',
    height: '32px',
    padding: '6px 14px 6px 20px',
    fontSize: '14px',
    lineHeight: '20px',

    ':not(:last-child)': {
      marginRight: '12px',
    },

    '&.addChip': {
      background: palette.desertStorm,
      padding: '6px 20px 6px 14px',

      '.MuiChip-label': {
        margin: '0 0 0 6px',
        padding: 0,
      },

      '.MuiChip-icon': {
        margin: 0,
      },
    },
  },

  '.MuiChip-label': {
    margin: '0 6px 0 0',
    padding: 0,
  },

  '.MuiChip-deleteIcon': {
    margin: '0!important',
  },

  '.addButton': {
    background: 'red',
  },

  [theme.breakpoints.down('md')]: {
    overflowX: 'scroll !important',
    width: 'auto',
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap-reverse',
  },
});

export const PriceTimeLine = styled('div')({
  display: 'flex',
  marginBottom: '16px',

  '> div:first-of-type': {
    marginBottom: 0,
    marginRight: '24px',
    maxWidth: '216px',
  },

  '> div:nth-of-type(2)': {
    marginRight: '24px',
  },

  [theme.breakpoints.down('md')]: {
    '> div:first-of-type': {
      width: '100%',
    },
  },
});

export const StyledsubTitle = styled(Typography)({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
  },
});

export const ButtonManage = styled('div')({
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',
    '&.cancel': {
      color: palette.oldLavender,
    },
    '&.save': {
      color: palette.blue,
    },
  },
});

export const DashboardTabWrapper = styled('div')({
  form: {
    width: '100%',
  },
});
export const BlocksLine = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('xl')]: {
    flexWrap: 'wrap',
  },
});
export const Block = styled('div')({
  padding: '16px 24px',
  background: palette.secondary,
  border: `1px solid ${palette.seashell}`,
  color: palette.primary,
  borderRadius: '12px',
  width: '100%',
  marginBottom: '24px',
  '.recharts-dot': {
    fill: palette.blue,
    stroke: palette.blue,
  },
  '.recharts-tooltip-wrapper': {
    width: '94px',
  },
  '.info': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    lineHeight: '27px',
    fontWeight: 600,
    marginTop: '6px',
    span: {
      background:
        'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9',
      backgroundSize: '100%',
      backgroundRepeat: 'repeat',
      textFillColor: 'transparent',
      backgroundClip: 'text',
    },
  },
  '&.contacts': {
    maxWidth: '456px',
  },
  '&.rate': {
    maxWidth: '216px',
    '.info': {
      svg: {
        marginRight: '5px',
        marginTop: '-3px',
      },
    },
  },
  '&.favorite': {
    maxWidth: '216px',
  },
  '&.opened': {
    maxWidth: '696px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.gradient': {},
  button: {
    padding: 0,
  },
  [theme.breakpoints.down('xl')]: {
    '&.favorite, &.rate': {
      width: '49%',
      maxWidth: '100%',
    },
    '&.opened': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.opened': {
      flexDirection: 'column',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&.favorite, &.rate': {
      width: '100%',
      maxWidth: '100%',
    },
    '&.opened': {
      flexDirection: 'row',
    },
    '&.favorite, &.rate, &.opened': {
      marginBottom: '12px',
    },
  },
});

export const SpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const StyledTooltip = styled('div')({
  background: palette.secondary,
  borderRadius: '12px',
  padding: '5px 10px',
  fontSize: '10px',
  lineHeight: '14px',
});

export const DeleteInputButton = styled('button')({
  border: 'none',
  background: 'transparent',
  color: '#D32F2F',
  cursor: 'pointer',
  marginTop: '2px',
  fontFamily: ['Open Sans, sans-serif'].join(','),
});

export const StyledWorkingHours = styled(Box)({
  marginTop: '24px',
  marginBottom: '55px',
  '& .error': {
    maxWidth: '216px',
    marginTop: '5px',
  },
});

export const ListWrapper = styled('div')({
  maxWidth: '695px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

export const ListWithPagination = styled('div')({
  minHeight: '320px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});
