import { Chip, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AddIcon from '../../../icons/AddIcon';
import deleteIcon from '../../../images/deleteIcon.svg';
import {
  getIdFromArray,
  getObjectNameAndIdFromArray,
} from '../../../utils/getDifferentFormatOfData';
import { BlockDescription, SelectLanguageWrapper, StyledTypesList } from '../styles';

export const SelectLanguages = ({
  userLanguages,
  setValue,
  setUserLanguages,
  setModalType,
  setIsModalOpen,
  error,
}) => {
  const { t, i18n } = useTranslation();

  const handleDeleteLang = (chipToDelete) => {
    const filteredArray = userLanguages.filter((chip) => {
      return chip.id !== chipToDelete;
    });
    setValue('userLanguageIds', getIdFromArray(filteredArray));
    setUserLanguages(filteredArray);
  };

  return (
    <SelectLanguageWrapper>
      <Typography marginBottom='16px' variant={'h5'}>
        {t('chooseLanguage')}
      </Typography>
      <BlockDescription variant={'body-1'}>{t('languageSelectionDescription')}</BlockDescription>
      <StyledTypesList>
        {userLanguages?.length > 0 &&
          getObjectNameAndIdFromArray(userLanguages, i18n)?.map(({ id, name }) => (
            <Chip
              key={id}
              deleteIcon={<img src={deleteIcon} alt={'remove'} />}
              label={name}
              onDelete={() => handleDeleteLang(id)}
            />
          ))}
        <Chip
          label={t('add')}
          icon={<AddIcon />}
          clickable
          onClick={() => {
            setModalType('chooseLanguages');
            setIsModalOpen(true);
          }}
          classes={{
            root: 'addChip',
          }}
        />
      </StyledTypesList>
      {error && (
        <Typography
          fontSize={12}
          color={'#D32F2F'}
          maxWidth={'189px'}
          lineHeight={'16px'}
          marginTop={'-15px'}
        >
          {t(error)}
        </Typography>
      )}
    </SelectLanguageWrapper>
  );
};
