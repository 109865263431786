import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import Container from '../../components/Container';
import { useGetPage } from '../../graphql/hooks/useQueries';
import { Content, Heading } from './styles';

const TermsOfUse = () => {
  const [info, setInfo] = useState({});
  const { t, i18n } = useTranslation();
  useGetPage({
    variables: {
      url: 'terms-of-use',
    },
    onCompleted: (data) => {
      const translations = data?.getPage?.translations;
      const isTranslations = translations.length > 1;
      const infoContent = isTranslations
        ? translations?.find((trans) => trans.languageCode === i18n.resolvedLanguage)
        : translations[0];
      setInfo(infoContent);
    },
  });

  return (
    <Container direction='column'>
      <Helmet>
        <title>{t('termsOfUse')}</title>
      </Helmet>
      <Heading
        variant='h1'
        dangerouslySetInnerHTML={{
          __html: info?.title,
        }}
      />
      <Content
        dangerouslySetInnerHTML={{
          __html: info?.content,
        }}
      />
    </Container>
  );
};

export default TermsOfUse;
