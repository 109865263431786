import { format } from 'date-fns';
import React from 'react';
import { useNavigation } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { getLocale } from '../../../utils/getLocale';
import { StyledMounth, StyledNavBar, StyledSelectedMounth } from './styles';

export const Navbar = ({ displayMonth, setMonth }) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const { i18n } = useTranslation();

  const handleGoToMonth = (month) => () => {
    if (setMonth) setMonth(month);
    if (month) goToMonth(month);
  };

  return (
    <StyledNavBar width='full'>
      <StyledMounth onClick={handleGoToMonth(previousMonth)}>
        {format(previousMonth, 'LLLL', {
          locale: getLocale(i18n.resolvedLanguage),
        })}
      </StyledMounth>
      <StyledSelectedMounth>
        {format(displayMonth, 'LLLL', {
          locale: getLocale(i18n.resolvedLanguage),
        })}
      </StyledSelectedMounth>
      <StyledMounth onClick={handleGoToMonth(nextMonth)}>
        {format(nextMonth, 'LLLL', {
          locale: getLocale(i18n.resolvedLanguage),
        })}
      </StyledMounth>
    </StyledNavBar>
  );
};
