import { InputAdornment } from '@mui/material';
import React from 'react';

import { EyeIcon } from '../../../../icons/EyeIcon';
import { HideEyeIcon } from '../../../../icons/HideEyeIcon';
import { StyledEyeIcon } from '../styles';

const EndAdornment = ({ onClick, onMouseDown, isShow }) => {
  return (
    <InputAdornment position='end'>
      <StyledEyeIcon
        aria-label='toggle password visibility'
        onClick={onClick}
        onMouseDown={onMouseDown}
        edge='end'
        className={'eye-icon'}
        disableFocusRipple
        disableRipple
      >
        {isShow ? <HideEyeIcon /> : <EyeIcon />}
      </StyledEyeIcon>
    </InputAdornment>
  );
};

export default EndAdornment;
