import { gql } from '@apollo/client';

export const GET_SUBCATEGORIES = gql`
  query getSubcategories($categoryId: Int, $limit: Int, $offset: Int) {
    getSubcategories(categoryId: $categoryId, limit: $limit, offset: $offset) {
      count
      rows {
        id
        translations {
          languageCode
          name
        }
      }
    }
  }
`;
