import { Typography } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const StyledDescription = styled(Typography)({
  lineHeight: '20px',
  display: 'inline-block',
  textAlign: 'justify',

  span: {
    overflow: 'hidden',
    maxHeight: '60px',
  },
});

export const StyledMoreButton = styled('button')({
  fontFamily: 'Open Sans',
  border: 'none',
  background: 'transparent',
  color: palette.blue,
  cursor: 'pointer',
});
