import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../UI/Button';
import { CenterTypography, ConfirmEmailWrapper } from './styles';

const ConfirmEmail = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <ConfirmEmailWrapper>
      <CenterTypography margin={'0'} variant={'h4'}>
        {t('confirmEmail')}
      </CenterTypography>
      <CenterTypography variant={'h4'}>{t('PSConfirmEmail')}</CenterTypography>
      <Button title={t('ready')} margin={'10px auto 0px'} onClickButton={handleClose} />
    </ConfirmEmailWrapper>
  );
};

export default ConfirmEmail;
