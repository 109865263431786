import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlCheckbox } from '../../../components/UI/Checkbox';
import { Warning, WrapperEnableOnlineBooking } from '../styles';

export const EnableOnlineBooking = ({ control, watch, setValue }) => {
  const { t } = useTranslation();

  const [isBookingDisabled, setIsBookingDisabled] = useState(false);

  useEffect(() => {
    if (watch('pricePer') === t('CALL') || watch('pricePer') === t('DAY')) {
      setValue('enableBooking', false);
      setIsBookingDisabled(true);
    } else {
      setIsBookingDisabled(false);
    }
  }, [watch('pricePer')]);

  return (
    <WrapperEnableOnlineBooking>
      <ControlCheckbox
        control={control}
        name={'enableBooking'}
        singleCheckbox
        disabled={isBookingDisabled}
      />
      <Warning>{t('bookingWarning')}</Warning>
    </WrapperEnableOnlineBooking>
  );
};
