import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledLocation = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '& .locationText': {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: '4px',
    },
  },

  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
  },
});

export const Label = styled(InputLabel)({
  color: palette.oldLavender,
});
