import { MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectWrapper, StyledSelect } from './styles';

export const Select = ({
  dataArray,
  selectTitle,
  field,
  name,
  wrapperClassName,
  className,
  onChange,
  value,
  MenuProps,
  error,
  isCustomValue,
  ...restParameters
}) => {
  const { t } = useTranslation();
  return (
    <SelectWrapper className={wrapperClassName}>
      <StyledSelect
        {...field}
        {...restParameters}
        value={value}
        MenuProps={MenuProps}
        onChange={onChange}
        variant={'outlined'}
        className={className}
        name={name}
        displayEmpty
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <Typography variant={'body-1'}>{selectTitle}</Typography>;
          }
          return selected;
        }}
      >
        {dataArray.map((item) => {
          if (isCustomValue) {
            return (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            );
          }
          if (typeof item !== 'string' && typeof item !== 'number') {
            return (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </StyledSelect>
      <Box className={'error'}>{t(error)}</Box>
    </SelectWrapper>
  );
};

export const ControlSelect = ({ control, name, errors, ...restParameters }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return <Select field={field} errors={errors} {...restParameters} />;
      }}
    />
  );
};

export default Select;
