import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { compareContacts } from '../../../utils/compareContacts';
import { getIconByContactType } from '../../../utils/getIconByContactType';
import { getLinkByContactType } from '../../../utils/getLinkByContactType';
import { StyledContactItem, StyledContactsList } from '../styles';

export const TabContacts = ({ contacts }) => {
  const { t } = useTranslation();

  const specialContacts = contacts.filter(
    (item) => item.type === 'SOCIAL' || item.type === 'PHONE',
  );

  return (
    <>
      {contacts?.length > 0 && (
        <StyledContactsList>
          <Box display={'flex'} flexWrap={'wrap'}>
            {[...contacts].sort(compareContacts)?.map((item) => {
              return (
                item.type !== 'SOCIAL' && (
                  <StyledContactItem key={item.id}>
                    <a
                      href={getLinkByContactType(item.type, item.value)}
                      target={item.type !== 'PHONE' ? '_blank' : ''}
                    >
                      {getIconByContactType(item.type)}
                    </a>
                  </StyledContactItem>
                )
              );
            })}
          </Box>
          {specialContacts?.map((item) => {
            return (
              <Box key={item.id} ml={'5px'} mb={'5px'}>
                {item.type === 'PHONE' ? t('phone') : t('site')}:{' '}
                <a href={getLinkByContactType(item.type, item.value)}>{item.value}</a>
              </Box>
            );
          })}
        </StyledContactsList>
      )}
    </>
  );
};
