import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledDescription, StyledMoreButton } from './styles';

const ReadMore = ({ children }) => {
  const text = children;
  const { t } = useTranslation();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <StyledDescription component={'p'} className='text'>
      {isReadMore ? text?.slice(0, 160) : text}
      {text.length > 160 ? (
        <StyledMoreButton component={'span'} onClick={toggleReadMore} className='read-or-hide'>
          {isReadMore ? t('more') : t('hide')}
        </StyledMoreButton>
      ) : (
        ''
      )}
    </StyledDescription>
  );
};

export default ReadMore;
