import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSignIn } from '../graphql/hooks/useMutations';
import { useStorage } from '../hooks';

export const AuthContext = React.createContext();

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

export const AuthContextProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [fetchSignIn, { loading: signInLoading }] = useSignIn();
  const [userInfo, setUserInfo] = useStorage('user', localStorage.getItem('user'));
  const [jwtToLocalStorage, setJwtToLocalStorage] = useStorage(
    'token',
    localStorage.getItem('token'),
  );
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUserInfo();
    setIsAuth(false);
    navigate('/');
  };

  useEffect(() => {
    if (isAuth && (!jwtToLocalStorage || !userInfo)) {
      logout();
    }
    if (!jwtToLocalStorage || !userInfo) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setUserInfo();
    }
    return jwtToLocalStorage && userInfo ? setIsAuth(true) : setIsAuth(false);
  }, [jwtToLocalStorage, userInfo]);

  const signIn = async ({ email, password, rememberMe }) => {
    await fetchSignIn({
      variables: {
        email,
        password,
        rememberMe,
      },
    }).then((response) => {
      const data = response?.data?.signIn;
      const { token, user } = data;
      if (token && user) {
        if (user.countryId) localStorage.setItem('country', user.countryId);
        setUserInfo({
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
          photo: user?.photo,
          city: user?.city,
          locale: user?.locale,
          email: user?.email,
          role: user?.role,
        });
        setJwtToLocalStorage(token);
        setIsAuth(true);
      }
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        signIn,
        logout,
        userInfo,
        setUserInfo,
        token: jwtToLocalStorage,
        setJwtToLocalStorage,
        signInLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
