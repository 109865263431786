import { gql } from '@apollo/client';

export const GET_CATEGORY_BY_SLUG = gql`
  query GetCategoryBySlug($slug: String!) {
    getCategoryBySlug(slug: $slug) {
      id
      photo
      slug
      translations {
        languageCode
        name
      }
      order
      metatags {
        description
        keywords
        languageCode
        title
      }
      subcategories {
        id
        photo
        parentCategorySlug
        slug
        order
        metatags {
          description
          keywords
          languageCode
          title
        }
        translations {
          languageCode
          name
        }
      }
      serviceCount
    }
  }
`;
