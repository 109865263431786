import { SOCIAL_LINKS } from '../../constants/constants';
import { SocialLink, StyledSocialLinks } from './styles';

const SocialLinks = () => {
  return (
    <StyledSocialLinks>
      {SOCIAL_LINKS.map((item) => {
        return (
          <SocialLink key={item.id} href={item.link} target={'_blank'}>
            <img src={item.icon} alt={item.title} />
          </SocialLink>
        );
      })}
    </StyledSocialLinks>
  );
};

export default SocialLinks;
