import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import { useDeleteService, useUpdateService } from '../../graphql/hooks/useMutations';
import { GET_CURRENT_USER } from '../../graphql/queries';
import { useAuth } from '../../hooks';
import palette from '../../theme/palette';
import { convertToMinutes } from '../../utils/convert';
import DefaultImage from '../DefaultImage';
import Dot from '../UI/Dot';
import {
  Avatar,
  CenteredTypography,
  StyledActionBlock,
  StyledChip,
  StyledMenu,
  StyledReservation,
  StyledReservationInfo,
  StyledUserInfo,
} from './styles';

const Service = ({ service, setDeletedServiceIds, onToast, setUserLocalState }) => {
  const { userInfo } = useAuth();
  const { t, i18n } = useTranslation();

  const [anchorElementUser, setAnchorElementUser] = useState(null);

  const { category, id, status, price, currencyCode, pricePer, workplaces, description } = service;

  const editProfileUrl = `${ROUTES.myService}?id=${id}`;

  const [updateService] = useUpdateService({
    onCompleted() {
      onToast('success');
    },
    onError() {
      onToast('error');
    },
    refetchQueries: [GET_CURRENT_USER],
  });

  const [deleteService] = useDeleteService({
    onCompleted: (data) => {
      setUserLocalState((previous) => ({
        ...previous,
        services: previous.services.filter((s) => s.id !== id),
      }));
      return data.deleteService ? onToast('successDelete') : onToast('errorDelete');
    },
    onError: () => onToast('errorDelete'),
  });

  const handleDeleteService = (deleteServiceId) => async () => {
    const result = await deleteService({
      variables: {
        deleteServiceId,
      },
    });

    if (result.data.deleteService) {
      setDeletedServiceIds((previous) => [...previous, deleteServiceId]);
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElementUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElementUser(null);
  };

  const handleUpdateServiceStatus = (serviceStatus) => async () => {
    const parameters = {
      categoryId: category?.id,
      status: serviceStatus,
      description,
      price,
      pricePer,
    };
    await updateService({
      variables: {
        updateServiceId: id,
        input: parameters,
      },
    });
    handleCloseUserMenu();
  };

  return (
    <StyledReservation>
      <StyledUserInfo>
        <Box display='flex'>
          {userInfo?.photo ? (
            <Link to={`${editProfileUrl}`}>
              <Avatar src={`${HOST_IMAGE_URL}/${userInfo?.photo}`} alt={'avatar'} />
            </Link>
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <StyledReservationInfo>
            <Link to={`${editProfileUrl}`}>
              <CenteredTypography variant={'body1Semibold'} component={'div'}>
                {
                  category?.translations?.find(
                    (translate) => translate?.languageCode === i18n?.resolvedLanguage,
                  )?.name
                }
              </CenteredTypography>
            </Link>
            <CenteredTypography
              variant={'body2'}
              component={'div'}
              color={palette.oldLavender}
              textTransform={'lowercase !important'}
            >
              {workplaces?.map((workplace) => {
                return (
                  <Box component={'span'} display={'flex'} flexWrap={'wrap'} key={workplace.id}>
                    {
                      workplace?.translations?.find(
                        (translate) => translate?.languageCode === i18n?.resolvedLanguage,
                      )?.name
                    }{' '}
                    <Dot />
                  </Box>
                );
              })}
              {`${t('from')} ${price} ${t(currencyCode)}/${
                Number.isNaN(+pricePer) ? t(pricePer) : convertToMinutes(pricePer, t)
              }`}
            </CenteredTypography>
          </StyledReservationInfo>
        </Box>
        <StyledActionBlock>
          <StyledChip
            label={status === 'ACTIVE' ? t('actively') : t('suspended')}
            variant={status === 'ACTIVE' ? 'new' : 'canceled'}
          />
          <Box
            sx={{
              flexGrow: 0,
            }}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{
                p: 0,
              }}
            >
              <MoreVertIcon />
            </IconButton>

            <StyledMenu
              id='menu-appbar'
              anchorEl={anchorElementUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElementUser)}
              onClose={handleCloseUserMenu}
            >
              {status === 'ACTIVE' ? (
                <MenuItem onClick={handleUpdateServiceStatus('SUSPENDED')}>
                  <Typography textAlign='center'>{t('suspend')}</Typography>
                </MenuItem>
              ) : (
                <MenuItem onClick={handleUpdateServiceStatus('ACTIVE')}>
                  <Typography textAlign='center'>{t('active')}</Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleDeleteService(id)}>
                <Typography textAlign='center'>{t('justDelete')}</Typography>
              </MenuItem>
            </StyledMenu>
          </Box>
        </StyledActionBlock>
      </StyledUserInfo>
    </StyledReservation>
  );
};

export default Service;
