import React from 'react';

const CheckedIcon = ({ width = 18, height = 18, className, color }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      color={color}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z'
        fill='#D9D9D9'
      />
      <path
        d='M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z'
        fill='url(#paint0_linear_541_4199)'
      />
      <path
        d='M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z'
        fill='url(#paint1_linear_541_4199)'
      />
      <path
        d='M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z'
        fill='url(#paint2_linear_541_4199)'
      />
      <path
        d='M7.6 13.2L14.65 6.15L13.25 4.75L7.6 10.4L4.75 7.55L3.35 8.95L7.6 13.2ZM2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16ZM2 2V16V2Z'
        fill='url(#paint3_linear_541_4199)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_541_4199'
          x1='9'
          y1='0'
          x2='9'
          y2='18'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8BFF58' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_541_4199'
          x1='17.9824'
          y1='-14.7874'
          x2='0.319703'
          y2='-14.7598'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.182292' stopColor='#8BFF58' />
          <stop offset='0.586425' stopColor='#46C7AB' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_541_4199'
          x1='17.9824'
          y1='-14.7874'
          x2='0.319703'
          y2='-14.7598'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#58FF87' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_541_4199'
          x1='17.9824'
          y1='-14.7874'
          x2='0.319703'
          y2='-14.7598'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FFD21' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckedIcon;
