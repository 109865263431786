import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      id
      photo
      slug
      serviceCount
      order
      subcategories {
        parentCategorySlug
        slug
        id
        order
        isOnline
        translations {
          languageCode
          name
        }
      }
      translations {
        languageCode
        name
      }
    }
  }
`;
