import { gql } from '@apollo/client';

import { CATEGORY, USER } from '../fragments';

export const GET_MY_SERVICES = gql`
  ${CATEGORY}
  ${USER}
  query GetMyServices($limit: Int, $offset: Int) {
    getMyServices(limit: $limit, offset: $offset) {
      category {
        ...CategoryFragment
      }
      certificatePhotos {
        path
        about
      }
      city {
        id
        translations {
          languageCode
          name
        }
        country {
          id
          translations {
            languageCode
            name
          }
        }
      }
      country {
        id
        translations {
          languageCode
          name
        }
        cities {
          id
          translations {
            languageCode
            name
          }
        }
      }
      createdAt
      currencyCode
      description
      id
      price
      pricePer
      servicePhotos {
        about
        path
      }
      workplaces {
        translations {
          languageCode
          name
        }
        id
      }
      user {
        ...UserFragment
      }
      subcategories {
        id
        photo
        translations {
          languageCode
          name
        }
      }
    }
  }
`;
