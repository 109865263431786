/* eslint-disable object-curly-newline */
import {
  Button,
  Fade,
  FormControlLabel,
  IconButton,
  Rating,
  Select,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';
import { ControlSelect } from '../UI/Select';

export const WrapperFormStyled = styled(Box)({
  width: '100%',
  minHeight: '268px',
  paddingBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const CloseModal = styled(Button)({
  position: 'absolute',
  zIndex: 999,
  right: '13px',
  top: '13px',
  padding: 0,
  margin: 0,
  minWidth: '20px',
  width: '20px',
  height: '20px',
  '&:hover': {
    backgroundColor: 'inherit',
  },
});

export const WrapperModal = styled('div')({
  padding: '48px 24px 0',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '504px',
  background: palette.secondary,
  maxHeight: 'calc(100vh - 50px)',
  overflow: 'auto',
  '&.zoomedImage': {
    padding: 0,
    width: 'auto',
    maxWidth: '90%',
    maxHeight: '90%',
    overflow: 'hidden',
    '.MuiButtonBase-root': {
      transition: 'none',
      background: 'white',
    },
    'svg, path': {
      stroke: palette.primary,
      fill: palette.primary,
    },
  },
  [theme.breakpoints.down('lg')]: {
    '&.zoomedImage': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '330px',
    padding: '15px',
    overflowX: 'hidden',
  },
});

export const CenterTypography = styled(Typography)(
  ({ textAlign = 'center', margin = '0 0 34px 0' }) => ({
    textAlign,
    margin,
    fontWeight: '600',
    '&.m-0': {
      marginBottom: '0px',
    },
    '&.fw-400': {
      fontWeight: 400,
    },
  }),
);

export const CenterButton = styled('div')(({ withoutMarginOnMob = false }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  marginBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    ...(withoutMarginOnMob && { marginBottom: '0px' }),
  },
}));

export const Form = styled('form')({ width: '100%' });

export const ButtonText = styled(Button)({
  textTransform: 'none',
  color: palette.oldLavender,
  padding: 0,
  margin: 0,
  height: '16px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'inherit',
  },
  '&.blue': {
    color: palette.blue,
    fontSize: '14px',
    lineHeight: '16px',
    margin: 0,
  },
});

export const Block = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '-6px',
});

export const ContinueButtonWrapper = styled('div')(({ margin }) => ({
  margin: margin || '0',
  display: 'flex',
}));

export const BlockQuestion = styled('div')({
  padding: '24px 0',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  span: {
    marginRight: '7px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Greeting = styled(Typography)({
  margin: '0 auto',
  maxWidth: '407px',
  color: palette.oldLavender,
});

export const CommentUserInfo = styled('div')({
  display: 'flex',
});

export const Avatar = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '8px',
  objectFit: 'cover',
});

export const CommenterInfo = styled('div')({
  marginLeft: '8px',
});

export const CenteredTypography = styled(Typography)(({ lineHeight }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: lineHeight ? `${lineHeight}px` : '20px',
  marginBottom: '6px',
}));

export const CenteredText = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: {
    marginRight: '4px',
    marginTop: '-4px',
  },
});

export const StyledRating = styled(Rating)({
  '&.reviewRating': {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 0',
    '.MuiRating-icon': {
      width: '44px',
      height: '44px',
    },
  },
  '.MuiRating-icon': {
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    margin: 0,
  },
});

export const SymbolCounter = styled(Typography)({
  margin: '-12px 0 34px',
});

export const StyledError = styled(Typography)({
  color: 'red',
  textAlign: 'center',
  marginTop: '20px',
});

export const ModalThankWrapper = styled('div')({
  padding: '80px 0px 128px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

export const StyledImg = styled('img')({});

export const ChooseServiceTypesWrapper = styled('div')({
  height: '584px',
  boxSizing: 'border-box',
});

export const CancelOrderWrapper = styled('div')({
  height: 'auto',
  boxSizing: 'border-box',
});

export const SubcategoriesList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '272px',
  maxHeight: '272px',
  overflow: 'auto',
  margin: '0 -23px 0 -24px',
  padding: '0 24px 0 24px',
  // marginTop: '-4px',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.oldLavender,
    borderRadius: '29px',
  },
  '&::-webkit-scrollbar': {
    width: '4px',
  },
});

export const SelectedSubcategories = styled('div')({
  height: '92px',
  borderBottom: `1px solid #E8E9E9`,
  borderTop: `1px solid #E8E9E9`,
  margin: '0 -24px',
  padding: '16px 0 16px 24px',
  boxSizing: 'border-box',
});
export const Counter = styled('div')({
  fontWeight: 600,
  marginBottom: '12px',
  fontSize: '12px',
  lineHeight: '16px',
});
export const EmptyBlock = styled('div')({
  height: '92px',
  borderBottom: `1px solid #E8E9E9`,
  margin: '0 -24px',
  padding: '16px 24px 4px',
});

export const ButtonManage = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 0',
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',
    '&.cancel': {
      color: palette.oldLavender,
    },
    '&.save': {
      color: palette.blue,
    },
  },
});

export const List = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  '&::-webkit-scrollbar': {
    width: 0,
  },
  '.MuiChip-root': {
    background: 'rgba(123, 238, 64, 0.2)',
    height: '32px',
    padding: '6px 14px 6px 20px',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '12px',
    ':not(:last-child)': {
      marginRight: '12px',
    },
  },
  '.MuiChip-label': {
    padding: 0,
    marginRight: '6px',
  },
  '.MuiChip-deleteIcon': {
    margin: '0!important',
  },
});

export const StyledScrollButton = styled(IconButton)({
  position: 'absolute',
  right: '0',
  borderRadius: 0,
  padding: 0,
  background: palette.secondary,
  width: '30px',
  height: '32px',
  '&:hover': {
    background: palette.secondary,
  },
});

export const FirstLoginStyled = styled('div')({});

export const LocationSelect = styled(Select)({
  '&.MuiInputBase-root': {
    margin: '24px 0 12px',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '12px',
    background: palette.desertStorm,
    width: '456px',
    height: '56px',
  },

  '&.shortSelect': {
    width: '96px',
    margin: 0,
  },

  '.MuiSelect-select': {
    padding: '16px 20px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '&.MuiInputBase-root': {
      width: '100%',
    },

    '&.shortSelect': {
      width: '96px',
    },
  },
});

export const StyledSelect = styled(ControlSelect)({
  '&.MuiInputBase-root': {
    margin: '24px 0 12px',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '12px',
    background: palette.desertStorm,
    width: '456px',
    height: '56px',
  },

  '&.shortSelect': {
    width: '96px',
    margin: 0,
  },

  '.MuiSelect-select': {
    padding: '16px 20px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '.MuiOutlinedInput-input': {
    paddingRight: '18px',
  },

  [theme.breakpoints.down('md')]: {
    '&.MuiInputBase-root': {
      width: '100%',
    },

    '&.shortSelect': {
      width: '96px',
    },
  },
});

export const ConfirmEmailWrapper = styled('div')({
  padding: '80px 0px 128px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

export const NewSubcategory = styled('div')({
  padding: '16px',
  borderRadius: '16px',
  background: palette.desertStorm,
  '&.successful': {
    background: '#E5FCD9',
  },
  span: {
    margin: '10px 0 0',
    display: 'block',
    fontSize: '14px',
  },
  button: {
    marginTop: '20px!important',
    height: '48px',
  },
});

export const CenterBlock = styled(Box)({
  height: '272px',
});

export const StyledForgotPassword = styled('div')({
  height: '418px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

export const ResendPassword = styled('div')(({ margin }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  margin,
  button: {
    fontWeight: 'bold',
    marginLeft: '9px!important',
  },
}));

export const ModalDescription = styled(Typography)({
  margin: '0 auto',
  maxWidth: '407px',
  color: palette.oldLavender,
});

export const StyledCreateOrderFooter = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingTop: '40px',
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',
  },
});

export const StyledEditPhotoWrap = styled(Box)({
  position: 'relative',
  width: 'fit-content',
  margin: '0 auto',
});

export const StyledPhotoPhotoWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '11px',
  right: '11px',
  with: '32px',
  minWidth: '32px',
  height: '32px',
  borderRadius: '50%',
  background: palette.secondary,
});

export const StyledUploadZone = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  height: '254px',
  padding: '20px',
  background: palette.seashell,
  border: '1px dashed rgba(26, 30, 38, 0.1)',
  borderRadius: '12px',
  '& .hiddenServicePhotoUpload': {
    margin: 0,
    width: '100%',
    height: '100%',
  },
  '& .MuiInputBase-root,.MuiFormControl-root': {
    display: 'contents',
    '& input[type="file"]': {
      display: 'block',
      width: '100%',
      height: '100%',
    },
  },
});

export const StyledFadeImage = styled(Fade)({
  outline: 'none',
  maxHeight: '100%',
  maxWidth: '100%',
});

export const ShowBigImageModal = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ControlButtons = styled('div')({
  position: 'absolute',
  top: '50%',
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  height: '32px',
  transform: 'translateY(-50%)',
  button: {
    position: 'absolute',
    background: 'white',
    transition: '.3s!important',
    '&:hover': {
      background: palette.whiteSmoke,
    },
    '&.previous': {
      left: '15px',
    },
    '&.next': {
      right: '15px',
    },
  },
});

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const About = styled('div')({
  background: palette.secondary,
  padding: '10px',
  textAlign: 'center',
});

export const StyledRadioLabel = styled('span')({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledFormControlRadioLabel = styled(FormControlLabel)({
  '&:not(:last-of-type)': {
    marginBottom: '10px',
  },
  '.Mui-checked': {
    svg: {
      fill: 'linear-gradient(269.91deg, #8FFD21 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #58FF87 -0.03%, #008EFF 97.94%), linear-gradient(269.91deg, #8BFF58 17.83%, #46C7AB 57.42%, #008EFF 97.94%), linear-gradient(180deg, #8BFF58 0%, #008EFF 100%), #D9D9D9;',
    },
  },
});

export const WarningMessage = styled(Box)({
  color: palette.artyClickRed,
  fontSize: '12px',
  position: 'absolute',
  bottom: '-20px',
});

export const DeleteModalWrapper = styled(Box)({
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 0px',
    '&.MuiButton-textPrimary:hover': {
      background: 'transparent',
    },

    '&.MuiButton-contained': {
      width: '116px',
      height: '44px',
    },
    '&.cancel': {
      color: palette.oldLavender,
      marginRight: '20px',
    },
    '&.deleteAccount': {
      width: 'auto',
      padding: '6px 16px',
    },
  },
  '.btnWrapper': {
    paddingBottom: '30px',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
      margin: '20px 0 0 0!important',
    },
  },
});
