import palette from '../../../theme/palette';

export const styledTooltip = {
  maxWidth: '164px',

  '& .MuiTooltip-tooltip': {
    boxShadow: palette.boxShadow.drawerPaperLight,
    background: palette.secondary,
    borderRadius: '12px',
    color: palette.artyClickRed,
    padding: '12px',
    fontSize: '12px',
    textAlign: 'center',
  },
  '& .MuiTooltip-arrow': {
    color: palette.secondary,
    width: '30px',
    height: '24px',
    transform: 'rotate(45deg)',
    marginBottom: '-1.71em !important',
    borderRadius: '4px',
  },
};

export const titleWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};
