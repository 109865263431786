import { Pagination } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../../theme/palette';

export const StyledPagination = styled(Pagination)({
  '.MuiPaginationItem-root': {
    background: 'transparent',
    ':hover': {
      background: 'transparent',
    },
    '&.Mui-selected': {
      background: 'transparent',
      fontWeight: 'bold',
      border: `1px solid ${palette.primary}`,
    },
    '.MuiSvgIcon-root': {
      color: palette.blue,
    },
  },
});
