import { useMutation } from '@apollo/client';

import * as MUTATIONS from '../../mutations';

export const useSignIn = (options) => {
  return useMutation(MUTATIONS.SIGN_IN, options);
};

export const useSignUp = (options) => {
  return useMutation(MUTATIONS.SIGN_UP, options);
};

export const useUpdateProfile = (options) => {
  return useMutation(MUTATIONS.UPDATE_PROFILE, options);
};

export const useCreateReview = (options) => {
  return useMutation(MUTATIONS.CREATE_REVIEW, options);
};

export const useCreateOrder = (options) => {
  return useMutation(MUTATIONS.CREATE_ORDER, options);
};

export const useCreateService = (options) => {
  return useMutation(MUTATIONS.CREATE_SERVICE, options);
};

export const useUpdateService = (options) => {
  return useMutation(MUTATIONS.UPDATE_SERVICE, options);
};

export const useSetLanguages = (options) => {
  return useMutation(MUTATIONS.SET_USER_LANGUAGES, options);
};

export const useConfirmEmail = (options) => {
  return useMutation(MUTATIONS.CONFIRM_EMAIL, options);
};

export const useCreateContact = (options) => {
  return useMutation(MUTATIONS.CREATE_CONTACT, options);
};

export const useCreateWorkSchedule = (options) => {
  return useMutation(MUTATIONS.CREATE_WORK_SCHEDULE, options);
};

export const useUpdateWorkSchedule = (options) => {
  return useMutation(MUTATIONS.UPDATE_WORK_SCHEDULE, options);
};

export const useUpdateContact = (options) => {
  return useMutation(MUTATIONS.UPDATE_CONTACT, options);
};

export const useSetLocation = (options) => {
  return useMutation(MUTATIONS.SET_LOCATION, options);
};

export const useDeleteContact = (options) => {
  return useMutation(MUTATIONS.DELETE_CONTACT, options);
};

export const useSetLocale = (options) => {
  return useMutation(MUTATIONS.SET_LOCALE, options);
};

export const useSetFavouriteProvider = (options) => {
  return useMutation(MUTATIONS.SET_FAVOURITE_PROVIDER, options);
};

export const useResetPassword = (options) => {
  return useMutation(MUTATIONS.RESET_PASSWORD, options);
};

export const useSetPassword = (options) => {
  return useMutation(MUTATIONS.SET_PASSWORD, options);
};

export const useRequestAddingSubcategory = (options) => {
  return useMutation(MUTATIONS.REQUEST_ADDING_SUBCATEGORY, options);
};

export const useCancelOrder = (options) => {
  return useMutation(MUTATIONS.CANCEL_ORDER, options);
};

export const useUpdateOrder = (options) => {
  return useMutation(MUTATIONS.UPDATE_ORDER, options);
};

export const useConfirmOrder = (options) => {
  return useMutation(MUTATIONS.CONFIRM_ORDER, options);
};

export const useUpdatePhoto = (options) => {
  return useMutation(MUTATIONS.UPDATE_PHOTO, options);
};

export const useDeletePhoto = (options) => {
  return useMutation(MUTATIONS.DELETE_PHOTO, options);
};

export const useAddNonWorkingTime = (options) => {
  return useMutation(MUTATIONS.ADD_NON_WORKING_TIME, options);
};

export const useDeleteNonWorkingTime = (options) => {
  return useMutation(MUTATIONS.DELETE_NON_WORKING_TIME, options);
};

export const useUpdateNotification = (options) => {
  return useMutation(MUTATIONS.UPDATE_NOTIFICATION, options);
};

export const useMarkAsReadNotifications = (options) => {
  return useMutation(MUTATIONS.MARK_AS_READ_NOTIFICATIONS, options);
};

export const useDeleteService = (options) => {
  return useMutation(MUTATIONS.DELETE_SERVICE, options);
};

export const usePasswordChangeRequest = (options) => {
  return useMutation(MUTATIONS.PASSWORD_CHANGE_REQUEST, options);
};

export const useDeleteAccountRequest = (options) => {
  return useMutation(MUTATIONS.DELETE_ACCOUNT_REQUEST, options);
};

export const useDeleteAccount = (options) => {
  return useMutation(MUTATIONS.DELETE_ACCOUNT, options);
};
