import { IconButton } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledCookieNotification = styled('div')(({ isHideAgreement }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: isHideAgreement ? '108px' : '178px',
  position: 'fixed',
  background: palette.secondary,
  left: 0,
  bottom: 0,
  '.wrapper': {
    width: '100%',
  },
  '.container': {
    maxWidth: '1180px',
    margin: '0 auto',
    width: '100%',
  },
  '.link': {
    color: palette.blue,
  },
  [theme.breakpoints.down('lg')]: {
    minHeight: isHideAgreement ? '78px' : '124px',
    height: 'auto',
  },
}));

export const TopBlock = styled(Box)({
  background: '#E5FCD9',
  padding: '24px 0',
  '> div': {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    padding: 0,
  },
  [theme.breakpoints.down('lg')]: {
    padding: '12px 0',
    '.MuiTypography-h5': {
      fontSize: '16px',
    },
  },
});

export const BottomBlock = styled(Box)({
  background: palette.secondary,
  padding: '24px 0',
  '> div': {
    alignItems: 'center',
    '> div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  button: {
    minWidth: '216px',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '12px 0',
    '.MuiTypography-h5': {
      fontSize: '16px',
    },
    button: {
      height: '40px',
      maxWidth: '140px',
      minWidth: '140px',
    },
  },
  [theme.breakpoints.down('md')]: {
    '> div': {
      flexDirection: 'column',
      '> div': {
        flexDirection: 'row-reverse',
        '> div': {
          margin: '0 24px 0 0!important',
        },
      },
    },
    button: {
      marginTop: '10px!important',
    },
  },
});

export const StyledCloseButton = styled(IconButton)({});
