import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const UPDATE_CONTACT = gql`
  ${USER}
  mutation UpdateContact($id: ID!, $input: ContactInput!) {
    updateContact(id: $id, input: $input) {
      id
      type
      user {
        ...UserFragment
      }
      value
    }
  }
`;
