import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledAdvertising = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  padding: '16px 0',
  borderTop: `1px solid ${palette.seashell}`,
  borderBottom: `1px solid ${palette.seashell}`,

  [theme.breakpoints.down('sm')]: {
    paddingTop: 0,
    border: 'none',
  },
});

export const AdvertisingWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '728px',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    maxWidth: '375px',
  },
});

export const AdvertisingBanner = styled('a')({
  display: 'flex',
  marginBottom: '10px',

  '& .adaptive': {
    display: 'none',
  },

  [theme.breakpoints.down('md')]: {
    '& .desktop': {
      display: 'none',
    },

    '& .adaptive': {
      display: 'flex',
    },

    '& img': {
      width: '100%',
    },
  },

  [theme.breakpoints.down('sm')]: {
    marginBottom: 0,
  },
});

export const AdvertisingText = styled(Typography)({
  alignSelf: 'flex-start',
  color: palette.oldLavender,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});
