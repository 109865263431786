import React from 'react';
import { useTranslation } from 'react-i18next';

import AddIconGradient from '../../../icons/AddIconGradient';
import { ButtonText } from './styles';

const ButtonAdd = ({ onClick, title = 'addPhoto', children }) => {
  const { t } = useTranslation();

  return (
    <ButtonText disableRipple={true} onClick={onClick}>
      <AddIconGradient />
      <span>{t(title)}</span>
      {children}
    </ButtonText>
  );
};

export default ButtonAdd;
