import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CITY_ID } from '../../constants/constants';
import { useGetCountries } from '../../graphql/hooks/useQueries';
import { useAuth } from '../../hooks';
import DropdownIcon from '../../icons/DropdownIcon';
import { Label, StyledLocation } from './styles';

const Location = ({ openModal }) => {
  const { t, i18n } = useTranslation();
  const { isAuth, userInfo } = useAuth();
  const { data } = useGetCountries();

  const locationText = () => {
    const cityId = isAuth && userInfo?.city?.id ? userInfo.city.id : localStorage.getItem('city');
    const countryId =
      isAuth && userInfo?.countryId ? userInfo.countryId : localStorage.getItem('country');
    let location = '';
    if (data && cityId && cityId !== 'null') {
      for (const country of data.getCountries) {
        for (const city of country.cities) {
          if (city.id === +cityId) {
            const countryName = country.translations.find(
              (countryTranslation) => countryTranslation.languageCode === i18n.resolvedLanguage,
            )?.name;
            const cityName = city.translations.find(
              (cityTranslation) => cityTranslation.languageCode === i18n.resolvedLanguage,
            )?.name;
            location = `${countryName}, ${cityName}`;
            break;
          }
        }
      }
    } else if (data && cityId === 'null') {
      for (const country of data.getCountries) {
        if (country.id === +countryId) {
          const countryName = country.translations.find(
            (countryTranslation) => countryTranslation.languageCode === i18n.resolvedLanguage,
          )?.name;
          location = `${countryName}`;
          break;
        }
      }
    }

    if (!location) {
      localStorage.setItem('city', cityId || DEFAULT_CITY_ID);
    }

    return location;
  };

  return (
    <StyledLocation>
      <Label variant='standard'>
        <Typography variant='body2' paddingRight={'5px'}>
          {t('locationLabel')}:
        </Typography>
      </Label>
      <Typography onClick={openModal} variant='body2' className='locationText'>
        {locationText()}
        <DropdownIcon />
      </Typography>
    </StyledLocation>
  );
};

export default Location;
