import { gql } from '@apollo/client';

export const GET_COUNTRIES = gql`
  query Query {
    getCountries {
      id
      translations {
        languageCode
        name
      }
      cities {
        id
        translations {
          name
          languageCode
        }
      }
    }
  }
`;
