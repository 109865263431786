import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const SIGN_IN = gql`
  ${USER}
  mutation signIn($email: String!, $password: String!, $rememberMe: Boolean) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      user {
        ...UserFragment
      }
    }
  }
`;
