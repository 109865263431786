import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from '../../components/Container';
import Location from '../../components/Location';
import Logo from '../../components/Logo';
import Menu from '../../components/Menu';
import ModalComponent from '../../components/Modal';
import Notification from '../../components/Notification';
import ButtonLogin from '../../components/UI/Button/buttonLogin';
import LanguagePicker from '../../components/UI/LanguagePicker';
import { useAuth } from '../../hooks';
import CloseIcon from '../../icons/CloseIcon';
import mobileMenuLogo from '../../images/mobileMenuLogo.png';
import palette from '../../theme/palette';
import {
  AdaptiveRight,
  AdaptiveWrapper,
  BGLogo,
  BurgerMenu,
  ButtonLogoutStyled,
  Center,
  DesktopWrapper,
  DrawerPaper,
  LeftSide,
  MenuWrapper,
  RightSide,
  StyledAppBar,
} from './styles';

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('signIn');
  const { logout } = useAuth();

  const [state, setState] = useState({
    left: false,
  });
  const { isAuth, userInfo } = useAuth();
  const { t } = useTranslation();

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalType('signIn');
  };

  const toggleDrawer = (anchor, open) => () => {
    setState({
      ...state,
      [anchor]: open,
    });
  };

  const openLocation = () => {
    handleOpenModal();
    setModalType('location');
  };

  return (
    <StyledAppBar position='static'>
      <Container>
        <DesktopWrapper disableGutters>
          {/* Desktop */}
          <LeftSide>
            <Logo />
            <Location openModal={openLocation} isModalOpen={isModalOpen} />
          </LeftSide>
          <Center>
            <Menu />
          </Center>
          <RightSide>
            <LanguagePicker />
            <Notification />
            {isAuth ? (
              <>
                <Link to={'/profile'}>{userInfo?.firstName || t('profile')}</Link>
                <ButtonLogoutStyled
                  variant={'text'}
                  onClick={logout}
                  disableRipple
                  disableFocusRipple
                >
                  {t('logout')}
                </ButtonLogoutStyled>
              </>
            ) : (
              <ButtonLogin onClick={handleOpenModal} />
            )}
          </RightSide>
        </DesktopWrapper>
        {/* Adaptive */}
        <AdaptiveWrapper disableGutters>
          <React.Fragment>
            {state.left === true ? (
              <BurgerMenu
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={toggleDrawer('left', false)}
                color='inherit'
              >
                <CloseIcon width={24} height={24} color={palette.primary} />
              </BurgerMenu>
            ) : (
              <BurgerMenu
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={toggleDrawer('left', true)}
                color='inherit'
              >
                <MenuIcon />
              </BurgerMenu>
            )}
            <DrawerPaper
              hideBackdrop={false}
              open={state.left}
              ModalProps={{
                onBackdropClick: toggleDrawer('left', false),
              }}
              onClose={toggleDrawer('left', false)}
            >
              <MenuWrapper>
                <Menu variant='adaptive' handleClose={toggleDrawer('left', false)} />
              </MenuWrapper>
              <BGLogo>
                <img src={mobileMenuLogo} alt={'SVE U REDU'} />
              </BGLogo>
            </DrawerPaper>
          </React.Fragment>
          <Logo />
          <AdaptiveRight>
            <Notification />
            {isAuth ? (
              <Link to={'/profile'}>{userInfo?.firstName || t('profile')}</Link>
            ) : (
              <ButtonLogin onClick={handleOpenModal} />
            )}
          </AdaptiveRight>
        </AdaptiveWrapper>
      </Container>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        setModalType={setModalType}
        closeAdaptiveMenu={toggleDrawer}
      />
    </StyledAppBar>
  );
};

export default Header;
