import { MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSetLocation } from '../../graphql/hooks/useMutations';
import {
  useGetCategories,
  useGetCountries,
  useGetServicesCount,
} from '../../graphql/hooks/useQueries';
import { useAuth } from '../../hooks';
import Button from '../UI/Button';
import { CenterButton, CenterTypography, LocationSelect } from './styles';

const LocationModal = ({ handleClose }) => {
  const { t, i18n } = useTranslation();
  const { data } = useGetCountries();
  const { refetch: refetchCategories } = useGetCategories();
  const { refetch: refetchServicesCount } = useGetServicesCount();
  const [countryState, setCountryState] = useState('');
  const [countryStateId, setCountryStateId] = useState('');
  const [stateCity, setStateCity] = useState('');
  const [stateCityId, setStateCityId] = useState('');

  const { isAuth, setUserInfo } = useAuth();
  const [setLocation] = useSetLocation();
  const handleChangeCountry = (event) => {
    setCountryState(event.target.value.countryName);
    setCountryStateId(event.target.value.id);
  };

  const handleChangeCity = (event) => {
    setStateCity(event.target.value.cityName);
    setStateCityId(event.target.value.id);
  };

  useEffect(() => {
    if (!countryStateId) {
      return;
    }

    setStateCityId('null');
    setStateCity(t('allCities'));
  }, [countryStateId, data?.getCountries, i18n.resolvedLanguage]);

  const chooseLocation = () => {
    if (isAuth) {
      setLocation({
        variables: {
          cityId: stateCityId || null,
          countryId: countryStateId,
        },
      }).then((response) => {
        if (response?.data?.setLocation?.id) {
          setUserInfo(response?.data?.setLocation);
        }
      });

      handleClose();
    }
    refetchCategories();
    refetchServicesCount();
    localStorage.setItem('country', countryStateId);
    localStorage.setItem('city', stateCityId);
    handleClose();
  };

  return (
    <>
      <CenterTypography variant={'h4'}>{t('locationLabel')}</CenterTypography>
      <LocationSelect
        name='selectCountry'
        value={countryState}
        onChange={(event) => handleChangeCountry(event)}
        variant={'outlined'}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Typography variant='body1'>{t('chooseCountry')}</Typography>;
          }
          return selected;
        }}
      >
        {data?.getCountries?.map((country) => {
          const countryName = country.translations.find(
            (countryTranslation) => countryTranslation.languageCode === i18n.resolvedLanguage,
          ).name;
          return (
            <MenuItem
              key={country.id}
              value={{
                countryName,
                id: country.id,
              }}
            >
              {countryName}
            </MenuItem>
          );
        })}
      </LocationSelect>

      <LocationSelect
        name='selectCity'
        value={stateCity}
        onChange={(event) => handleChangeCity(event)}
        variant={'outlined'}
        disabled={!countryStateId}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Typography variant='body1'>{t('chooseCity')}</Typography>;
          }
          return selected;
        }}
      >
        <MenuItem
          value={{
            cityName: t('allCities'),
            id: 'null',
          }}
        >
          {t('allCities')}
        </MenuItem>
        {data?.getCountries?.map((countryItem) => {
          return countryItem.cities.map((city) => {
            if (countryItem.id === countryStateId) {
              const cityName = city.translations.find(
                (cityTranslation) => cityTranslation.languageCode === i18n.resolvedLanguage,
              ).name;
              return (
                <MenuItem
                  key={city.id}
                  value={{
                    cityName,
                    id: city.id,
                  }}
                >
                  {cityName}
                </MenuItem>
              );
            }
            return null;
          });
        })}
      </LocationSelect>
      <CenterButton>
        <Button
          onClickButton={chooseLocation}
          disabled={!countryStateId}
          margin={'16px 0 0 0'}
          title={t('save')}
        />
      </CenterButton>
      <CenterButton withoutMarginOnMob={true}>
        <Button title={t('cancel')} className={'buttonGray'} onClickButton={handleClose} />
      </CenterButton>
    </>
  );
};

export default LocationModal;
