import { gql } from '@apollo/client';

export const GET_WORK_SCHEDULE = gql`
  query GetWorkSchedule($userId: ID!) {
    getWorkSchedule(userId: $userId) {
      endLunch
      endTime
      id
      startLunch
      startTime
      workdays
    }
  }
`;
