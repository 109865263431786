import React from 'react';

import { useAuth } from '../../hooks';
import ProviderNotificationItem from './ProviderNotificationItem';
import UserNotificationItem from './UserNotificationItem';

const NotificationItem = ({ notification, question, handleOpenModal, showNotificationsWindow }) => {
  const { userInfo } = useAuth();

  return userInfo?.role === 'PROVIDER' ? (
    <ProviderNotificationItem notification={notification} />
  ) : (
    <UserNotificationItem
      notification={notification}
      question={question}
      handleOpenModal={handleOpenModal}
      showNotificationsWindow={showNotificationsWindow}
    />
  );
};

export default NotificationItem;
