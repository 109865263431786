import React, { useState } from 'react';

import { useAuth } from '../../hooks';
import ProviderReservation from './ProviderReservation';
import UserReservation from './UserReservation';

const Reservation = ({ reservation }) => {
  const [anchorElementUser, setAnchorElementUser] = useState(null);
  const { userInfo } = useAuth();

  const handleOpenUserMenu = (event) => {
    setAnchorElementUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElementUser(null);
  };

  return userInfo?.role === 'PROVIDER' ? (
    <ProviderReservation
      reservation={reservation}
      anchorElementUser={anchorElementUser}
      handleOpenUserMenu={handleOpenUserMenu}
      handleCloseUserMenu={handleCloseUserMenu}
    />
  ) : (
    <UserReservation
      reservation={reservation}
      anchorElementUser={anchorElementUser}
      handleOpenUserMenu={handleOpenUserMenu}
      handleCloseUserMenu={handleCloseUserMenu}
    />
  );
};

export default Reservation;
