import { t } from 'i18next';
import React from 'react';

import RelocationAdaptiveImage from '../../images/relocation_adaptive_image.svg';
import RelocationImage from '../../images/relocation_image.svg';
import {
  AdvertisingBanner,
  AdvertisingText,
  AdvertisingWrapper,
  StyledAdvertising,
} from './styles';

const Advertising = () => {
  return (
    <StyledAdvertising>
      <AdvertisingWrapper>
        <AdvertisingBanner href={'https://srb4u.rs/'} target={'_blank'}>
          <img src={RelocationImage} alt='Relocation' className={'desktop'} />
          <img src={RelocationAdaptiveImage} alt='Relocation' className={'adaptive'} />
        </AdvertisingBanner>
        <AdvertisingText variant={'caption'}>{t('advertising')}</AdvertisingText>
      </AdvertisingWrapper>
    </StyledAdvertising>
  );
};

export default Advertising;
