export const RightIcon = ({ width = 20, height = 20, className, color = '#000' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.0308 10.0312L8.03075 15.0312L6.96875 13.9693L10.9067 10.0312L6.96875 6.09325L8.03075 5.03125L13.0308 10.0312Z'
      fill={color}
    />
  </svg>
);
