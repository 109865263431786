import { Chip, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import { ControlSelect } from '../../components/UI/Select';
import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledForm = styled('form')({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'contents',
    h1: {
      padding: '0 15px',
    },
  },
});

export const MyServiceWrapper = styled('div')({
  width: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    '>div': {
      display: 'contents',
    },
  },
});

export const MobilePadding = styled('div')({
  [theme.breakpoints.down('md')]: {
    padding: '0 15px',
  },
});

export const StyledChooseWorkPlaces = styled(Box)({
  margin: '24px 0',
  '.MuiFormControlLabel-root': {
    marginRight: '12px!important',
    marginLeft: 0,
    marginTop: '12px',
    'span:last-of-type': {
      fontSize: '14px',
      lineHeight: '14px',
      color: palette.primary,
      padding: '6px 20px',
      background: 'transparent',
      border: `1px solid ${palette.seashell}`,
      borderRadius: '20px',
      display: 'block',
    },
    'span:first-of-type': {
      display: 'none',
    },
  },
  '.Mui-checked+span.MuiTypography-root': {
    background: '#E5FCD9',
    borderColor: 'transparent',
    fontWeight: '400!important',
  },
});

export const ChooseServiceLocationStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '30px 0 15px',
  [theme.breakpoints.down('sm')]: {
    '.MuiInputBase-root': {
      marginTop: '13px',
    },
    '> div:last-of-type': {
      margin: '0!important',
    },
  },
});

export const StyledSelect = styled(ControlSelect)({
  '&.MuiInputBase-root': {
    margin: '12px 0',
    border: '1px solid rgba(26, 30, 38, 0.1)',
    borderRadius: '12px',
    background: palette.desertStorm,
    width: '456px',
    height: '56px',
  },

  '&.shortSelect': {
    width: '220.5px',
    maxHeight: '400px!important',
    margin: 0,
  },

  '&.shortSelectDuration': {
    width: '216px',
    margin: 0,
  },

  '.MuiSelect-select': {
    padding: '16px 20px',
  },

  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '.MuiOutlinedInput-input': {
    paddingRight: '18px',
  },

  [theme.breakpoints.down('md')]: {
    '&.MuiInputBase-root': {
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&.MuiInputBase-root': {
      margin: 0,
    },
  },
});

export const StyledTitle = styled(Typography)({
  margin: '34px 0 32px',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
    marginBottom: '16px',
  },
});

export const Gallery = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
});

export const PhotoWrap = styled('div')({
  position: 'relative',
  width: '217px',
  height: '217px',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    aspectRatio: '1 / 1',
  },
});

export const Photo = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '13px',
  objectFit: 'cover',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
  },
});

export const StyledPhotoActions = styled('div')({
  marginTop: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '32px',
});

export const StyledPhotoActionsButton = styled(Chip)({
  padding: 0,
  background: 'transparent',
  '& .MuiChip-label': {
    fontSize: '11px',
  },
  '&:hover': {
    background: 'transparent',
  },
});

export const BlockDescription = styled('span')({
  fontSize: '14px',
  lineHeight: '1.4',
  color: palette.oldLavender,
  display: 'block',
  marginTop: '-10px',
});

export const TopPanel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',
  marginBottom: '40px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'contents',
  },
});

export const ButtonManage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',

    '&.cancel': {
      color: palette.oldLavender,
    },

    '&.save': {
      color: palette.blue,
    },

    '&.delete': {
      color: palette.artyClickRed,
    },

    '&.saveLanguage': {
      margin: '20px 0 0',
    },
  },
  [theme.breakpoints.down('md')]: {
    position: 'sticky',
    bottom: '-1px',
    left: '-15px',
    right: '-15px',
    width: '100%',
    justifyContent: 'flex-end',
    background: palette.secondary,
    borderTop: '2px solid',
    borderColor: palette.desertStorm,
    padding: '16px',
    paddingBottom: '20px',
    zIndex: 3,
    marginTop: '15px',
    order: 5,
    button: {
      fontSize: '16px',
      '&.cancel': {
        paddingLeft: 0,
      },
      '&.saveMobile': {
        height: '44px',
        maxWidth: '136px',
        marginLeft: '20px !important',
      },
    },
  },
});

export const Heading = styled(Typography)({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '1.2',
    marginBottom: '20px',
  },
});

export const StyledsubTitle = styled(Typography)({
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
  },
});

export const PriceTimeLine = styled('div')({
  display: 'flex',
  marginBottom: '16px',
  flexWrap: 'wrap',
  maxWidth: '456px',

  '> div': {
    width: '100%',
  },

  '> div:first-of-type': {
    marginBottom: '15px',
    marginRight: '24px',
  },

  '> div:nth-of-type(2)': {
    display: 'flex',
    marginBottom: '7px',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    width: '100%',
    '> div:first-of-type': {
      marginRight: '0!important',
    },
  },
});

export const SelectWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '> div': {
    width: '48.4%',
  },
});

export const ServiceTypes = styled('div')({
  padding: '12px 0 0',
  '> span': {
    color: palette.oldLavender,
  },
  'div.error': {
    color: '#D32F2F',
    marginTop: '-20px',
    marginBottom: '10px',
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export const StyledTypesList = styled('div')({
  marginBottom: '12px',
  padding: '12px 0 0',
  maxWidth: '456px',
  display: 'flex',
  flexWrap: 'wrap',
  '> div': {
    marginBottom: '12px',
  },

  '.MuiChip-root': {
    background: 'rgba(123, 238, 64, 0.2)',
    height: '32px',
    padding: '6px 14px 6px 20px',
    fontSize: '14px',
    lineHeight: '20px',

    ':not(:last-child)': {
      marginRight: '12px',
    },

    '&.addChip': {
      background: palette.desertStorm,
      padding: '6px 20px 6px 14px',

      '.MuiChip-label': {
        margin: '0 0 0 6px',
        padding: 0,
      },

      '.MuiChip-icon': {
        margin: 0,
      },
    },
  },

  '.MuiChip-label': {
    margin: '0 6px 0 0',
    padding: 0,
  },

  '.MuiChip-deleteIcon': {
    margin: '0!important',
  },

  '.addButton': {
    background: 'red',
  },

  [theme.breakpoints.down('md')]: {
    overflowX: 'scroll !important',
    width: 'auto',
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap-reverse',
  },
});

export const StyledAddButton = styled('div')({
  '.addChip': {
    fontSize: '14px',
    background: palette.desertStorm,
    padding: '6px 20px 6px 14px',

    '.MuiChip-label': {
      margin: '0 0 0 6px',
      padding: 0,
    },

    '.MuiChip-icon': {
      margin: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
});

export const StyledRemoveIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '11px',
  right: '11px',
  with: '32px',
  minWidth: '32px',
  height: '32px',
  borderRadius: '50%',
  background: palette.secondary,
  zIndex: '999',
  cursor: 'pointer',
  '&.icon': {
    background: 'transparent',
  },
});

export const Wrapper = styled(Box)({
  display: 'block',
  width: 'fit-content',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
});

export const ServicePriceBlock = styled(Box)({
  position: 'relative',
  '.icon': {
    position: 'absolute',
    right: '-20px',
    top: 0,
  },
});

export const DeleteInputButton = styled('button')({
  border: 'none',
  background: 'transparent',
  color: '#D32F2F',
  cursor: 'pointer',
  margin: '-10px 0 10px',
  display: 'block',
  fontFamily: ['Open Sans, sans-serif'].join(','),
});

export const WrapperEnableOnlineBooking = styled('div')({
  margin: '15px 0',
});

export const Warning = styled('div')({
  color: '#D32F2F',
  fontSize: '14px',
});

export const SymbolCounter = styled(Typography)({
  margin: '-12px 0 15px',
});
