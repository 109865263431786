import React from 'react';

const OldNotificationIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.667 14.1667H18.3337V15.8334H1.66699V14.1667H3.33366V8.33341C3.33366 6.5653 4.03604 4.86961 5.28628 3.61937C6.53652 2.36913 8.23222 1.66675 10.0003 1.66675C11.7684 1.66675 13.4641 2.36913 14.7144 3.61937C15.9646 4.86961 16.667 6.5653 16.667 8.33341V14.1667ZM15.0003 14.1667V8.33341C15.0003 7.00733 14.4735 5.73556 13.5359 4.79788C12.5982 3.8602 11.3264 3.33341 10.0003 3.33341C8.67424 3.33341 7.40247 3.8602 6.46479 4.79788C5.52711 5.73556 5.00033 7.00733 5.00033 8.33341V14.1667H15.0003ZM7.50033 17.5001H12.5003V19.1667H7.50033V17.5001Z'
        fill='#706F7A'
      />
    </svg>
  );
};

export default OldNotificationIcon;
