import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useResetPassword, useSetPassword } from '../../graphql/hooks/useMutations';
import { useAuth } from '../../hooks';
import palette from '../../theme/palette';
import {
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
} from '../../validation/schema/forgotPasswordSchema';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import EndAdornment from '../UI/Input/components/EndAdornment';
import {
  ButtonText,
  CenterTypography,
  ContinueButtonWrapper,
  Form,
  ModalDescription,
  ResendPassword,
  StyledForgotPassword,
} from './styles';

const ForgotPassword = ({ handleClose }) => {
  const [step, setStep] = useState(1);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(
      step === 1 ? firstStepSchema : step === 2 ? secondStepSchema : thirdStepSchema,
    ),
    defaultValues: {
      email: '',
      temporaryPassword: '',
      password: '',
      confirmPassword: '',
    },
  });
  const { t } = useTranslation();
  const [resetPassword, { loading: resetPasswordLoading }] = useResetPassword();
  const { signIn, signInLoading } = useAuth();
  const [setPassword, { loading: setPasswordLoading }] = useSetPassword();
  const isLoading = resetPasswordLoading || signInLoading || setPasswordLoading;

  const handleClickShowPassword = (setStateFunction, state) => {
    setStateFunction(!state);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const sendTemporaryPassword = async (email) => {
    await resetPassword({
      variables: {
        email,
      },
      onError: () =>
        setError('email', {
          message: t('unregisteredEmail'),
        }),
    }).then((result) => result?.data?.resetPassword && setStep(2));
  };

  const firstStep = useCallback(
    () => (
      <div>
        <ModalDescription align={'center'} variant={'body1'}>
          {t('descriptionRestorePass')}
        </ModalDescription>
        <ControlInput
          control={control}
          name={'email'}
          errors={errors?.email?.message}
          placeholder={'Email'}
          margin={'20px 0 0'}
          type={'email'}
        />
      </div>
    ),
    [],
  );

  const secondStep = useCallback(
    () => (
      <div>
        <ModalDescription align={'center'} variant={'body1'} component={'div'}>
          {t('sentNewPass')}
          <br />
          <strong>{watch('email')}</strong>
          <p
            style={{
              paddingTop: '10px',
            }}
          >
            {t('enterTemporaryPass')}
          </p>
        </ModalDescription>
        <ControlInput
          control={control}
          name={'temporaryPassword'}
          errors={errors?.temporaryPassword?.message}
          placeholder={t('enterTemporaryPass')}
          margin={'20px 0 0'}
          value={watch('temporaryPassword')}
        />
        <ResendPassword margin={'50px 0 20px'}>
          <Typography variant={'body-2'}>{t('passwordNotReceived')}</Typography>
          <ButtonText
            className={'blue'}
            onClick={() => sendTemporaryPassword(watch('email'))}
            disableRipple
            disableFocusRipple
            type={'button'}
          >
            {t('resendPass')}
          </ButtonText>
        </ResendPassword>
      </div>
    ),
    [],
  );

  const thirdStep = useCallback(
    () => (
      <div>
        <ModalDescription align={'center'} variant={'body1'}>
          {t('createNewPass')}
        </ModalDescription>
        <ControlInput
          control={control}
          name={'password'}
          variant={'filled'}
          label={t('password')}
          type={isShowPassword ? 'text' : 'password'}
          margin={'20px 0'}
          endAdornment={
            <EndAdornment
              onClick={() => handleClickShowPassword(setIsShowPassword, isShowPassword)}
              onMouseDown={handleMouseDownPassword}
              isShow={isShowPassword}
            />
          }
        />
        <ControlInput
          control={control}
          name={'confirmPassword'}
          variant={'filled'}
          label={t('confirmPassword')}
          type={isShowConfirmPassword ? 'text' : 'password'}
          endAdornment={
            <EndAdornment
              onClick={() =>
                handleClickShowPassword(setIsShowConfirmPassword, isShowConfirmPassword)
              }
              onMouseDown={handleMouseDownPassword}
              isShow={isShowConfirmPassword}
            />
          }
        />
      </div>
    ),
    [isShowPassword, isShowConfirmPassword],
  );

  const onSubmit = async ({ email, temporaryPassword, password }) => {
    if (step === 1) {
      await sendTemporaryPassword(email);
    } else if (step === 2) {
      await signIn({
        email,
        password: temporaryPassword,
      })
        .then(() => setStep(3))
        .catch((error_) => setError(error_?.message));
    } else {
      await setPassword({
        variables: {
          password,
        },
      });
      handleClose();
    }
  };

  return (
    <StyledForgotPassword>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('forgotPassword')}
      </CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {step === 1 ? firstStep() : step === 2 ? secondStep() : step === 3 ? thirdStep() : null}
        <ContinueButtonWrapper margin={'28px 0 39px'}>
          <Button
            type={'submit'}
            title={
              isLoading ? (
                <CircularProgress
                  sx={{
                    color: palette.secondary,
                  }}
                />
              ) : (
                t('goNextStep')
              )
            }
            margin={'0 auto'}
          />
        </ContinueButtonWrapper>
      </Form>
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
