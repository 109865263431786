import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Box, Typography } from '@mui/material';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { enUS, ru, sr } from 'date-fns/locale';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import React from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

import { getClassNameByStatus } from '../../utils/getClassNameByStatus';
import { StyledWrap } from './styles';
import { CustomToolbar } from './toolbar/toolbar';

const locales = {
  ru,
  sr,
  enUS,
};

export const DaySchedule = ({ selectedDate, events, onSelectTime }) => {
  const { i18n, t } = useTranslation();
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const isPastDate = (date) => {
    return date < new Date();
  };

  const onSelectEvent = (event) => {
    if (!isPastDate(event.start)) {
      onSelectTime(event);
    }
  };

  const eventPropertyGetter = (event) => {
    const isPastEvent = isPastDate(event.start);
    return {
      style: isPastEvent
        ? {
            pointerEvents: 'none',
          }
        : null,
      className: getClassNameByStatus(event.status),
    };
  };

  return (
    <StyledWrap>
      <Calendar
        formats={{
          eventTimeRangeFormat: () => '',
        }}
        showMultiDayTimes={true}
        scrollToTime={events?.[0]?.start}
        culture={i18n.resolvedLanguage}
        components={{
          toolbar: CustomToolbar,
          event: ({ event }) => {
            return (
              <Box display='flex'>
                <Typography className='rbc-event-label'>
                  {`${format(event.start, 'HH.mm')}`}
                </Typography>
                <Typography className='rbc-event-label'>{t(event.title)}</Typography>
              </Box>
            );
          },
        }}
        localizer={localizer}
        date={selectedDate}
        defaultDate={selectedDate}
        events={events}
        onSelectEvent={onSelectEvent}
        eventPropGetter={eventPropertyGetter}
        onNavigate={() => {}}
        defaultView={Views.DAY}
        startAccessor='start'
        endAccessor='end'
        step={30}
        style={{
          height: 380,
        }}
      />
    </StyledWrap>
  );
};
