import { CONTACT_ORDER } from '../constants/constants';

export const compareContacts = (a, b) => {
  const typeA = a.type;
  const typeB = b.type;
  const orderA = CONTACT_ORDER[typeA];
  const orderB = CONTACT_ORDER[typeB];

  if (orderA < orderB) return -1;
  if (orderA > orderB) return 1;
  return 0;
};
