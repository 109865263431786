import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const SET_USER_LANGUAGES = gql`
  ${USER}
  mutation SetUserLanguages($userLanguageIds: [ID]!) {
    setUserLanguages(userLanguageIds: $userLanguageIds) {
      ...UserFragment
    }
  }
`;
