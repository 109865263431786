import { gql } from '@apollo/client';

export const GET_WORKPLACES = gql`
  query GetWorkplaces {
    getWorkplaces {
      id
      translations {
        languageCode
        name
      }
    }
  }
`;
