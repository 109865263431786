import { gql } from '@apollo/client';

export const SET_SERVICE_COUNTRY = gql`
  mutation SetServiceCountry($countryId: ID!) {
    setServiceCountry(countryId: $countryId) {
      serviceCountry {
        id
        translations {
          languageCode
          name
        }
        cities {
          id
          translations {
            languageCode
            name
          }
        }
      }
    }
  }
`;
