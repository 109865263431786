import { gql } from '@apollo/client';

import { SERVICE, USER } from '../fragments';

export const GET_FAVOURITE_SERVICES = gql`
  ${SERVICE}
  ${USER}
  query GetFavouriteServices {
    getFavouriteServices {
      count
      rows {
        ...ServiceFragment
        user {
          ...UserFragment
        }
      }
    }
  }
`;
