import { gql } from '@apollo/client';

import { REVIEW } from '../fragments';

export const GET_REVIEWS = gql`
  ${REVIEW}
  query GetReviews($userId: ID, $limit: Int, $offset: Int) {
    getReviews(userId: $userId, limit: $limit, offset: $offset) {
      count
      rows {
        ...ReviewFragment
      }
    }
  }
`;
