import EmptyImageIcon from '../../icons/EmptyImageIcon';
import { StyledDefaultImage } from './styles';

const DefaultImage = ({ className, iconSize, margin, withIcon = true }) => (
  <StyledDefaultImage
    className={className}
    sx={{
      margin,
    }}
  >
    {withIcon && <EmptyImageIcon width={iconSize} height={iconSize} />}
  </StyledDefaultImage>
);

export default DefaultImage;
