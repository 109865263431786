import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../../theme';
import palette from '../../../theme/palette';

export const InputWrapper = styled('div')(({ margin = '0 0 20px 0' }) => ({
  margin,

  '&.hidden': {
    opacity: 0,
    position: 'absolute',
    height: '249px',
    width: '217px',
    display: 'block',
    margin: '40px 0 0 0',
    zIndex: 99,
    'input, div': {
      height: '249px',
      width: '217px',
      padding: 0,
    },
  },

  '&.hiddenServicePhotoUpload': {
    opacity: 0,
    position: 'absolute',
    width: '120px',
    height: '20px',
    zIndex: 99,
    div: {
      padding: 0,
    },
    input: {
      cursor: 'pointer',
    },
  },

  '&.searchWrapper': {
    display: 'flex',
    position: 'relative',
    marginBottom: '16px',
  },

  '&.saveWrapper': {
    display: 'flex',
    position: 'relative',
    '.MuiFormHelperText-root.Mui-error': {
      padding: 0,
      margin: 0,
      marginTop: '3px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&.searchWrapper': {
      flexDirection: 'row',
    },
  },
}));

export const StyledInput = styled(TextField)({
  width: '100%',
  "[type='password']": {
    fontFamily: 'Verdana',
  },
  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
  '.MuiInputLabel-shrink + div': {
    padding: '20px 20px 12px',
  },
  '.MuiFilledInput-root': {
    input: {
      marginTop: '8px',
    },
  },
  '.MuiInputBase-root': {
    padding: '16px 20px',
    fontSize: '16px',
    color: palette.primary,
    maxWidth: '100%',
    width: '456px',
    height: '54px',
    background: palette.seashell,
    borderRadius: '12px',
    boxSizing: 'border-box',

    border: `1px solid transparent`,
    '&:after, &:before': {
      display: 'none',
    },
    '&:hover': {
      background: palette.seashell,
      border: `1px solid #e0e0e1`,
    },
    fieldset: {
      border: `none`,
    },
    '&.Mui-focused': {
      border: `1px solid #e0e0e1`,
      background: palette.seashell,
      fieldset: {
        border: 'none',
      },
    },
    '&.Mui-error': {
      border: '1px solid #D32F2F',
    },

    '&:focus, &:active': {
      outline: 'none',
    },
  },

  '.MuiInputBase-input': {
    padding: 0,
    border: 'none',
  },

  '.MuiInputBase-multiline': {
    height: '189px',
  },

  '&.serviceDescription .MuiInputBase-multiline': {
    height: '70px',
  },

  '&.reviewTextArea': {
    '.MuiInputBase-multiline': {
      height: '165px',
      marginTop: '20px',
    },
  },

  '&.sm .MuiInputBase-multiline': {
    height: '100px',
  },

  '.MuiFormLabel-root': {
    left: '8px',
    color: palette.primary,
    '&.Mui-focused': {
      color: palette.oldLavender,
    },
  },

  /* Search Input */
  '&.searchInput': {
    width: '100%',

    '.MuiInputBase-root': {
      width: '100%',
      height: '60px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  [theme.breakpoints.down('md')]: {
    '.MuiInputBase-root': {
      width: '100%',
      maxWidth: '100%',
    },

    '&.searchInput': {
      minWidth: '242px',
      color: palette.oldLavender,
      '.MuiInputBase-root': {
        height: '48px',
        fontSize: '14px',
      },
    },
  },

  '.MuiFormHelperText-root': {
    paddingLeft: '7px',
  },

  '.eye-icon': {
    position: 'absolute',
    right: '25px',
    top: '17px',
  },

  '&.border .MuiInputBase-root': {
    border: `1px solid #e0e0e1!important`,
  },

  '&.borderSave .MuiInputBase-root': {
    border: `1px solid #e0e0e1!important`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  '&.searchService .MuiInputBase-root': {
    height: '40px',
    padding: '10px',
    'input::placeholder': {
      fontSize: '14px',
      color: palette.oldLavender,
    },
  },

  [theme.breakpoints.down('sm')]: {
    '&.borderSave .MuiInputBase-root': {
      borderTopRightRadius: '12px',
      borderBottomRightRadius: '12px',
    },
  },
});

export const StyledEyeIcon = styled(IconButton)({
  width: '20px',
  height: '20px',
  padding: 0,
});
