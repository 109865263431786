import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1200,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ['Open Sans, sans-serif'].join(','),
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16px',

    h1: {
      fontSize: 56,
      fontWeight: 400,
      lineHeight: '62px',
      marginBottom: '24px',

      '@media (max-width: 768px)': {
        fontSize: 46,
        lineHeight: '52px',
      },

      '@media (max-width: 480px)': {
        fontSize: 36,
        lineHeight: '42px',
      },
    },

    h2: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: '38px',

      '@media (max-width: 768px)': {
        fontSize: 30,
        lineHeight: '36px',
      },
    },

    h3: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '32px',
    },

    h4: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: '30px',
    },

    h5: {
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '22px',
    },

    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },

    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },

    body3: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },

    body1Semibold: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '22px',
    },

    body2Semibold: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
    },

    caption: {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
    },

    captionNormal: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 500,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          height: '40px',
          fontSize: 16,
          fontWeight: 500,
          borderRadius: '18px',
          boxShadow: 'none',
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 99999,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },

            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.1)',
            },

            '&.Mui-focused fieldset': {
              borderColor: '#19d263',
            },
          },
          '& [placeholder]::placeholder': {
            color: palette.oldLavender,
            fontSize: '16px',
            lineHeight: '140%',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.1) !important',
          },

          '&.Mui-focused fieldset': {
            borderColor: '#19d263 !important',
          },
        },
      },
    },

    MuiChip: {
      variants: [
        {
          props: {
            variant: 'gradient',
          },
          style: {
            color: palette.secondary,
            background: palette.mainGradient,
          },
        },
        {
          props: {
            variant: 'border',
          },
          style: {
            color: palette.primary,
            background: palette.secondary,
            border: '1px solid',
            borderColor: palette.primary,
          },
        },
        {
          props: {
            variant: 'new',
          },
          style: {
            color: palette.primary,
            background: palette.secondary,
            backgroundClip: 'padding-box',
            border: '1px solid',
            borderColor: 'transparent',
            position: 'relative',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              margin: '-1px',
              borderRadius: 'inherit',
              background: palette.mainGradient,
            },
          },
        },
        {
          props: {
            variant: 'canceled',
          },
          style: {
            color: palette.artyClickRed,
            background: 'transparent',
            fontWeight: '500',
          },
        },
        {
          props: {
            variant: 'confirmed',
          },
          style: {
            color: palette.secondary,
            background: palette.blue,
            fontWeight: '600',
          },
        },
      ],
    },
  },
});

export default theme;
