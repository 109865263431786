import React from 'react';

import palette from '../theme/palette';

const HeartIcon = ({ width = 40, height = 40, className, color = palette.oldLavender }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={color}
        d='M19.999 34.958L18.041 33.208C15.1517 30.5693 12.7627 28.2917 10.874 26.375C8.98536 24.4583 7.47836 22.743 6.35303 21.229C5.22836 19.715 4.4437 18.34 3.99903 17.104C3.55436 15.868 3.33203 14.611 3.33203 13.333C3.33203 10.8057 4.18636 8.68766 5.89503 6.97899C7.60303 5.27099 9.70703 4.41699 12.207 4.41699C13.763 4.41699 15.2077 4.77099 16.541 5.47899C17.8744 6.18766 19.027 7.19466 19.999 8.49999C21.0824 7.13866 22.2767 6.11799 23.582 5.43799C24.888 4.75733 26.291 4.41699 27.791 4.41699C30.291 4.41699 32.395 5.27099 34.103 6.97899C35.8117 8.68766 36.666 10.8057 36.666 13.333C36.666 14.611 36.4437 15.868 35.999 17.104C35.5544 18.34 34.7697 19.715 33.645 21.229C32.5197 22.743 31.0127 24.4583 29.124 26.375C27.2354 28.2917 24.8464 30.5693 21.957 33.208L19.999 34.958ZM19.999 31.292C22.749 28.764 25.0197 26.5973 26.811 24.792C28.603 22.986 30.0197 21.4093 31.061 20.062C32.103 18.7153 32.8324 17.514 33.249 16.458C33.6657 15.4027 33.874 14.361 33.874 13.333C33.874 11.5557 33.3047 10.0907 32.166 8.93799C31.0267 7.78466 29.5684 7.20799 27.791 7.20799C26.4017 7.20799 25.117 7.61799 23.937 8.43799C22.7564 9.25733 21.8464 10.389 21.207 11.833H18.791C18.1517 10.4163 17.2417 9.29133 16.061 8.45799C14.881 7.62466 13.5964 7.20799 12.207 7.20799C10.4297 7.20799 8.97136 7.78466 7.83203 8.93799C6.69336 10.0907 6.12403 11.5557 6.12403 13.333C6.12403 14.389 6.33236 15.4447 6.74903 16.5C7.1657 17.5553 7.89503 18.7637 8.93703 20.125C9.97836 21.4863 11.395 23.0627 13.187 24.854C14.9784 26.646 17.249 28.792 19.999 31.292Z'
      />
    </svg>
  );
};

export default HeartIcon;
