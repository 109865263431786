import { Chip, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ChipsList from '../../components/ChipsList';
import Container from '../../components/Container';
import ModalComponent from '../../components/Modal';
import Provider from '../../components/Provider';
import Breadcrumbs from '../../components/UI/Breadcrumbs';
import { ControlCheckbox } from '../../components/UI/Checkbox';
import Divider from '../../components/UI/Divider';
import Pagination from '../../components/UI/Pagination';
import { ROUTES } from '../../constants/constants';
import { useGetCategoryBySlug, useGetServices } from '../../graphql/hooks/useQueries';
import { useImageZoom, usePagination } from '../../hooks';
import CloseIcon from '../../icons/CloseIcon';
import FilterIcon from '../../icons/FilterIcon';
import deleteIcon from '../../images/deleteIcon.svg';
import theme from '../../theme';
import palette from '../../theme/palette';
import { alphabeticalSorting } from '../../utils/alphabeticalSorting';
import { combineMetatags } from '../../utils/combineMetatags';
import {
  findFunction,
  getCorrectObjectFromArray,
  getNameFromTranslations,
  getObjectNameAndIdFromArray,
} from '../../utils/getDifferentFormatOfData';
import {
  CategoriesList,
  FilterList,
  FiltersForm,
  ProvidersList,
  StyledDrawer,
  StyledFilterButton,
  StyledH1,
  StyledTopAction,
  Wrapper,
} from './styles';

const Service = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { t, i18n } = useTranslation();
  const [searchParameters, setSearchParameters] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState('createOrder');

  const categorySlug = searchParameters.get('category');
  const subcategorySlugs = searchParameters.getAll('sub');
  const [subcategories, setSubcategories] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [categoryMetatags, setCategoryMetatags] = useState({});
  const [metatags, setMetatags] = useState([]);
  const [combinedMetatags, setCombinedMetategs] = useState({});
  const { data: category, loading: categoryLoading } = useGetCategoryBySlug({
    variables: {
      slug: categorySlug,
    },
    skip: !categorySlug,
    onCompleted: (data) =>
      setCategoryMetatags({
        slug: data?.getCategoryBySlug?.slug,
        ...findFunction(data?.getCategoryBySlug?.metatags, i18n),
      }),
  });

  // * ==================== START PAGINATION ===========================

  const [ordersCounter, setOrdersCounter] = useState(0);
  const [offset, setOffset] = useState(0);
  const ordersPerPageForServicesData = 10;

  const parametersForGetServices =
    categorySlug && subcategorySlugs.length > 0
      ? {
          categorySlug,
          subcategorySlugs: subcategorySlugs?.[0]?.split('-'),
        }
      : {
          categorySlug,
        };

  const {
    data: servicesData,
    loading: servicesLoading,
    fetchMore,
    refetch,
  } = useGetServices({
    variables: {
      ...parametersForGetServices,
      offset,
      limit: ordersPerPageForServicesData,
    },
    skip: !categorySlug,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setOrdersCounter(data?.getServices?.count);
    },
  });

  const { onChangeHandler } = usePagination(
    fetchMore,
    refetch,
    ordersPerPageForServicesData,
    setOffset,
  );

  // * ============================== END PAGINATION ====================================

  const { control, watch, setValue } = useForm({
    defaultValues: {
      categories: [],
    },
  });
  const filteredCategories = watch('categories');

  const categoryName = getNameFromTranslations(category?.getCategoryBySlug?.translations, i18n);

  const { handleZoomImage } = useImageZoom(setIsModalOpen, setModalType, setModalData);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  useEffect(() => {
    setCategoryMetatags({
      slug: category?.getCategoryBySlug?.slug,
      ...findFunction(category?.getCategoryBySlug?.metatags, i18n),
    });
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    const correctLocaleNames = getCorrectObjectFromArray(
      category?.getCategoryBySlug?.subcategories,
      i18n,
    );

    if (subcategorySlugs.length > 0) {
      const selected = correctLocaleNames?.filter((item) =>
        subcategorySlugs?.[0]?.split('-')?.includes(item.slug),
      );

      setValue('categories', selected);
    }
    setSubcategories(category?.getCategoryBySlug?.subcategories);
    if (category && metatags.length <= 1) {
      setMetatags([categoryMetatags]);
    }
  }, [categoryMetatags, category]);

  const [searchParametersLoaded, setSearchParametersLoaded] = useState(false);

  useEffect(() => {
    if (filteredCategories) setSearchParametersLoaded(true);
  });

  useEffect(() => {
    setCombinedMetategs(combineMetatags(metatags));
  }, [metatags]);

  useEffect(() => {
    const shouldUpdateSearchParameters =
      !categoryLoading && searchParametersLoaded && filteredCategories.length > 0;
    const shouldResetSearchParameters =
      searchParametersLoaded && filteredCategories && filteredCategories.length === 0;

    if (shouldUpdateSearchParameters) {
      const selected = category?.getCategoryBySlug?.subcategories?.filter((subCat) => {
        const isIncludes = filteredCategories?.some((selectedCat) => selectedCat.id === subCat.id);
        return isIncludes;
      });
      setSearchParameters(
        {
          category: categorySlug,
          sub: selected?.map((it) => it.slug).join('-'),
        },
        {
          replace: true,
        },
      );

      setMetatags([
        categoryMetatags,
        ...selected.map((sub) => {
          return {
            slug: sub.slug,
            ...findFunction(sub.metatags, i18n),
          };
        }),
      ]);
    } else if (shouldResetSearchParameters) {
      setSearchParameters(
        {
          category: categorySlug,
        },
        {
          replace: true,
        },
      );
      if (!categoryLoading) {
        setMetatags([categoryMetatags]);
      }
    }
  }, [searchParametersLoaded, filteredCategories]);

  const handleDelete = (chipToDelete) => {
    setValue(
      'categories',
      filteredCategories.filter((itemCategory) => {
        return itemCategory.id !== chipToDelete;
      }),
    );
  };

  const toggleDrawer = (value) => {
    setIsFilterOpen(value);
  };

  const filtersForm = () => (
    <FiltersForm>
      <Box
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
        }}
      >
        <Typography variant={'h5'}>{categoryName}</Typography>
      </Box>
      <Divider margin={'10px 0 15px'} />
      {isDesktop && filteredCategories?.length > 0 && (
        <>
          <FilterList>
            {filteredCategories.map(({ id, name }) => (
              <Chip
                key={id}
                deleteIcon={<img src={deleteIcon} alt={'remove'} />}
                label={name}
                onDelete={() => handleDelete(id)}
              />
            ))}
          </FilterList>
          <Divider margin={'3px 0 10px'} />
        </>
      )}
      {subcategories && (
        <CategoriesList>
          <ControlCheckbox
            options={getObjectNameAndIdFromArray(
              alphabeticalSorting(subcategories, (sub) => sub.translations, i18n),
              i18n,
            )}
            control={control}
            name={'categories'}
            margin={'0 0 14px 0'}
          />
        </CategoriesList>
      )}
    </FiltersForm>
  );

  return (
    <>
      <Helmet>
        <title>{combinedMetatags?.title || categoryName}</title>
        <meta name='description' content={combinedMetatags?.description} />
        <meta name='keywords' content={combinedMetatags?.keywords} />
      </Helmet>
      <Container direction='column'>
        <StyledTopAction>
          <Breadcrumbs
            title={'allServices'}
            url={`${ROUTES.categoryList}?category=${categorySlug}`}
          />
          <StyledFilterButton
            className={isFilterOpen && 'closeBtn'}
            onClick={() => toggleDrawer(!isFilterOpen)}
          >
            {isFilterOpen ? <CloseIcon /> : <FilterIcon />}
          </StyledFilterButton>
        </StyledTopAction>
        <StyledH1 variant='h1'>{categoryName}</StyledH1>
        {!isDesktop && filteredCategories?.length > 0 && (
          <>
            <FilterList>
              {isMobile ? (
                <ChipsList
                  chipsArr={filteredCategories}
                  withDeleteButton
                  handleDelete={handleDelete}
                  margin={'4px 0 0'}
                />
              ) : (
                filteredCategories.map(({ id, name }) => (
                  <Chip
                    key={id}
                    deleteIcon={<img src={deleteIcon} alt={'remove'} />}
                    label={name}
                    onDelete={() => handleDelete(id)}
                  />
                ))
              )}
            </FilterList>
          </>
        )}
        <Wrapper>
          <ProvidersList>
            {servicesLoading ? (
              <CircularProgress
                sx={{
                  color: palette.primary,
                }}
              />
            ) : servicesData?.getServices?.rows?.length > 0 ? (
              servicesData?.getServices?.rows?.map((service) => (
                <Provider
                  key={service?.id}
                  // ===== service response fields
                  user={service?.user}
                  inFavorites={!!service?.inFavorites}
                  id={service?.id}
                  servicePhotos={service?.servicePhotos}
                  subservices={service?.subservices}
                  price={service?.price}
                  pricePer={service?.pricePer}
                  currencyCode={service?.currencyCode}
                  enableBooking={service?.enableBooking}
                  subcategories={service?.subcategories}
                  translations={service?.translations}
                  // ===== others props
                  setModalData={setModalData}
                  setIsModalOpen={setIsModalOpen}
                  setModalType={setModalType}
                  handleZoomImage={handleZoomImage}
                />
              ))
            ) : (
              <Typography variant={'h4'}>{t('currentlyNoSpecialistWhoProvideService')}</Typography>
            )}
            <Pagination
              sx={{
                margin: '10px 0',
              }}
              onChange={onChangeHandler}
              elementsCount={ordersCounter}
              ordersPerPage={ordersPerPageForServicesData}
            />
          </ProvidersList>
          {isDesktop && filtersForm()}
        </Wrapper>
        <StyledDrawer anchor={'right'} open={isFilterOpen} onClose={() => toggleDrawer(false)}>
          {filtersForm()}
        </StyledDrawer>
      </Container>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        modalData={modalData}
      />
    </>
  );
};

export default Service;
