import { gql } from '@apollo/client';

export const SET_WORKPLACES = gql`
  mutation SetWorkplaces($workplaceIds: [ID]!) {
    setWorkplaces(workplaceIds: $workplaceIds) {
      workplaces {
        id
        translations {
          languageCode
          name
        }
      }
    }
  }
`;
