export const getRequiredObject = (t) => {
  return {
    value: true,
    message: t('NOT_EMPTY_FIELD'),
  };
};

// pattern types : PHONE_PATTERN, EMAIL_PATTERN, TELEGRAM_NICKNAME_PATTERN, SOCIAL_PATTERN
export const getPatternObject = (type, t) => {
  switch (type) {
    case 'PHONE_PATTERN': {
      return {
        value: /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im,
        message: t('INCORRECT_PHONE'),
      };
    }

    case 'TELEGRAM_NICKNAME_PATTERN': {
      return {
        value: /.*\B@(?=\w{5,64}\b)[\dA-Za-z]+(?:_[\dA-Za-z]+)*.*/gm,
        message: t('INCORRECT_TELEGRAM_USER_NAME'),
      };
    }

    case 'EMAIL_PATTERN': {
      return {
        value:
          /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?(?:\.[\dA-Za-z](?:[\dA-Za-z-]{0,61}[\dA-Za-z])?)*$/,
        message: t('INCORRECT_EMAIL'),
      };
    }

    case 'SOCIAL_PATTERN': {
      return {
        value:
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\w$&+,:;=-]+@)?[\d.A-Za-z-]+|(?:www.|[\w$&+,:;=-]+@)[\d.A-Za-z-]+)((?:\/[%+./~\w-_]*)?\??[\w%&+.;=@-]*#?\w*)?)/,
        message: t('INCORRECT_URL'),
      };
    }

    default: {
      return null;
    }
  }
};
