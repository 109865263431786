import { Box } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const StyledWrap = styled(Box)({
  borderRadius: '14px',
  with: '100%',
  maxWidth: '440px',

  '& .rdp-month': {
    width: '100%',
  },
  '& .rdp-table': {
    width: '100%',
    maxWidth: '100%',
  },
  '& .rdp-head_row': {
    display: 'contents',
  },
  '& .rdp-day': {
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: 400,

    '&.today': {
      border: '1px solid',
      borderColor: palette.primary,
    },
  },
  '& .rdp-day_outside': {
    color: palette.oldLavender,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  '& .selected:not([disabled])': {
    background: palette.mainGradient,
    color: 'white',
    border: '1px',

    '&:hover': {
      bgColor: 'blue',
      opacity: 0.8,
    },
  },
  '& .rdp-tbody': {
    p: '0 8px',
  },
  '& .booked': {
    fontWeight: 600,
    color: palette.blue,
  },
  '& .rdp-cell': {
    padding: '3px',
  },
  '& .rdp-head_cell': {
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
  },
});
