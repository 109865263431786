import palette from '../theme/palette';

const PhoneIcon = ({ width = 15, height = 15, className, color = palette.primary }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3962 14.979C11.6876 14.8403 10.0762 14.4133 8.56225 13.698C7.04891 12.9827 5.71924 12.0487 4.57325 10.896C3.42725 9.74334 2.49325 8.41 1.77125 6.896C1.04924 5.382 0.625578 3.77067 0.500245 2.062C0.472245 1.77067 0.558912 1.51734 0.760245 1.302C0.961578 1.08667 1.20825 0.979004 1.50025 0.979004H4.33325C4.56991 0.979004 4.77491 1.04867 4.94825 1.188C5.12158 1.32667 5.23624 1.514 5.29224 1.75L5.79224 3.979C5.81958 4.14567 5.81258 4.31234 5.77125 4.479C5.72925 4.64567 5.64591 4.79167 5.52125 4.917L3.50025 6.958C4.08358 8.014 4.79891 8.96534 5.64625 9.812C6.49358 10.6593 7.44491 11.3747 8.50025 11.958L10.5622 9.958C10.7016 9.81934 10.8546 9.73267 11.0212 9.698C11.1879 9.66334 11.3476 9.66 11.5002 9.688L13.7292 10.167C13.9652 10.2223 14.1529 10.3403 14.2922 10.521C14.4309 10.7017 14.5002 10.91 14.5002 11.146V13.979C14.5002 14.3543 14.3682 14.6183 14.1042 14.771C13.8402 14.9237 13.6042 14.993 13.3962 14.979ZM2.81224 5.5L4.27125 4.042L3.91724 2.479H2.04224C2.11158 3.007 2.20858 3.521 2.33325 4.021C2.45858 4.521 2.61824 5.014 2.81224 5.5ZM9.97925 12.667C10.4652 12.861 10.9619 13.017 11.4692 13.135C11.9759 13.253 12.4862 13.347 13.0002 13.417V11.542L11.4382 11.208L9.97925 12.667Z'
        fill={color}
      />
    </svg>
  );
};

export default PhoneIcon;
