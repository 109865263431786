import { AppBar, Box, Drawer, IconButton, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledAppBar = styled(AppBar)({
  color: palette.primary,
  background: 'transparent',
  boxShadow: 'none',
  [theme.breakpoints.down('lg')]: {
    borderBottom: `1px solid ${palette.gainsboro}`,
  },
});

/* Desktop */
export const DesktopWrapper = styled(Toolbar)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '72px',

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

export const LeftSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
});

export const Center = styled('div')({
  flexGrow: 1,
});

export const RightSide = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 0,
  a: {
    marginLeft: '24px',
    padding: 0,
    color: palette.primary,
    fontSize: '14px',
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'none',
  },
});

/* Adaptive */
export const AdaptiveWrapper = styled(Toolbar)({
  display: 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '72px',

  [theme.breakpoints.down('lg')]: {
    height: '64px',
    display: 'flex',
  },
});

export const AdaptiveRight = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const AdaptiveLanguagePicker = styled(Box)({
  marginRight: '10px',
});

export const BurgerMenu = styled(IconButton)({
  '&.MuiButtonBase-root.MuiIconButton-root': {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const DrawerPaper = styled(Drawer)({
  '& .MuiPaper-root.MuiDrawer-paper': {
    width: '100%',
    height: 'fit-content',
    padding: '20px 16px',
    marginTop: '65px',
    background: 'rgba(255, 255, 255, 0.4)',
    borderBottom: '1px solid rgba(26, 30, 38, 0.1)',
    backdropFilter: 'blur(30px)',
    overflow: 'hidden',
    boxShadow: 'none',
  },
  '.MuiBackdrop-root': {
    background: 'transparent',
  },
});

export const DrawerPresentation = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '72px',
});

export const MenuWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  padding: 0,

  '& > div': {
    justifyContent: 'center',
  },
});

export const BGLogo = styled(Box)({
  position: 'absolute',
  top: '20px',
  right: '20px',
  width: '73px',
  height: '134px',
  opacity: 0.6,
  img: {
    width: '100%',
    height: '100%',
  },
});

export const ButtonClose = styled(IconButton)({
  '&.MuiButtonBase-root.MuiIconButton-root': {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const StyledIconButton = styled(IconButton)({
  margin: '0 20px',
});

export const ButtonLogoutStyled = styled('span')({
  marginLeft: '24px',
  padding: 0,
  color: '#1A1E26',
  fontSize: '14px',
  fontWeight: 600,
  cursor: 'pointer',
  textTransform: 'none',
});
