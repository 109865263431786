import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const DELETE_CONTACT = gql`
  ${USER}
  mutation DeleteContact($deleteContactId: ID!) {
    deleteContact(id: $deleteContactId) {
      ...UserFragment
    }
  }
`;
