import { styled } from '@mui/system';

import theme from '../theme';

export const StyledLayout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const Main = styled('div')({
  flex: '1 1 auto',
  padding: '24px 0',

  [theme.breakpoints.down('md')]: {
    padding: '16px 0',
  },
});
