import * as yup from 'yup';

export const firstStepSchema = yup.object().shape({
  email: yup.string('PROMPT_MAIL').email('PROMPT_MAIL').required('REQUIRE_MESSAGE'),
});

export const secondStepSchema = yup.object().shape({
  temporaryPassword: yup.string('PROMPT_PASSWORD').required('REQUIRE_MESSAGE'),
});

export const thirdStepSchema = yup.object().shape({
  password: yup.string('PROMPT_PASSWORD').min(8, 'MIN_SYMBOL').required('REQUIRE_MESSAGE'),
  confirmPassword: yup
    .string('PROMPT_PASSWORD')
    .oneOf([yup.ref('password'), null], 'PASSWORD_MUST_MATCH')
    .required('REQUIRE_MESSAGE'),
});
