import { Chip, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';

export const StyledProvider = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '16px',
});

export const TopBlock = styled('div')({
  display: 'flex',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
});

export const ProviderPhoto = styled('div')({});
export const Photo = styled('img')({
  marginRight: '16px',
  width: '104px',
  height: '104px',
  minWidth: '104px',
  borderRadius: '8px',
  cursor: 'pointer',
  '&.avatar': {
    objectFit: 'cover',
  },
  objectFit: 'contain',
});

export const ProviderDescription = styled('div')({
  overflow: 'hidden',
  flexGrow: 1,

  [theme.breakpoints.down('md')]: {
    marginTop: '20px',
  },
});

export const ProviderInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexGrow: 1,
});

export const StyledDescription = styled(Typography)({
  margin: '12px 0',
  display: '-webkit-box',
  lineHeight: '18px',
  fontSize: '14px',
  maxHeight: '72px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
});

export const Gallery = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '577px',

  [theme.breakpoints.down('xl')]: {
    overflowX: 'scroll !important',
    maxWidth: 'inherit',
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const StyledChip = styled(Chip)({
  background: 'rgba(123, 238, 64, 0.2)',
  height: '32px',
  padding: '6px 20px',
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '10px',

  ':not(:last-child)': {
    marginRight: '12px',
  },

  '.MuiChip-label': {
    padding: 0,
    letterSpacing: '.4',
  },
});

export const ChipWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  margin: '20px 0 0',

  [theme.breakpoints.down('lg')]: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

export const ConnectionBlock = styled('div')({
  marginLeft: '120px',

  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
  display: 'flex',
});

export const StyledPriceLine = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '> div': {
    minWidth: '216px',
    marginRight: '24px',
  },
  [theme.breakpoints.down('md')]: {
    '> div': {
      minWidth: 'auto',
      width: '200px',
    },
  },
});
