import 'react-day-picker/dist/style.css';

import { format } from 'date-fns';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { getLocale } from '../../utils/getLocale';
import { Navbar } from './navbar/navbar';
import { StyledWrap } from './styles';

const formatWeekdayName = (date, options) => {
  return (
    <>
      {format(date, 'EEE', {
        locale: options?.locale,
      })}
    </>
  );
};

// MODE TYPES : 'single' | 'multiple' | 'range' | 'default'

export const DatePicker = ({
  days,
  bookedDays,
  setDays,
  mode = 'single',
  disableWeekends,
  disabledDays,
  disabledDaysBeforeToday,
  setMonth,
}) => {
  const { i18n } = useTranslation();

  return (
    <StyledWrap>
      <DayPicker
        mode={mode}
        selected={days}
        onSelect={setDays}
        components={{
          Caption: (properties) => <Navbar setMonth={setMonth} {...properties} />,
        }}
        showOutsideDays
        modifiersClassNames={{
          selected: 'selected',
          today: 'today',
          booked: 'booked',
        }}
        modifiers={{
          booked: bookedDays || [],
        }}
        locale={getLocale(i18n.resolvedLanguage)}
        formatters={{
          formatWeekdayName,
        }}
        disabled={[
          ...(disabledDays || []),
          {
            before: disabledDaysBeforeToday ? new Date() : null,
          },
          {
            dayOfWeek: disableWeekends || [],
          },
        ]}
      />
    </StyledWrap>
  );
};
