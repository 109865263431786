import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledServiceCatalog = styled(Box)({
  marginTop: '32px',

  '& .subTitle': {
    marginTop: '6px',
  },

  '&.catalog-page': {
    h1: {
      display: 'none',
    },
  },

  [theme.breakpoints.down('lg')]: {
    h1: {
      marginBottom: '10px',
    },
    '&.catalog-page': {
      display: 'block',
      marginTop: 0,
      '> div': {
        display: 'block',
      },
    },
  },
});

export const CatalogWrapper = styled(Box)({
  padding: '26px 0',
  backgroundColor: palette.desertStorm,
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

export const CatalogInner = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridColumnGap: '48px',
  gridRowGap: '52px',
  justifyContent: 'space-between',
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [theme.breakpoints.down('md')]: {
    gridColumnGap: '30px',
    gridRowGap: '30px',
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridColumnGap: '30px',
    gridRowGap: '30px',
  },
});

export const CatalogItem = styled(Box)({
  width: '258px',

  [theme.breakpoints.down('lg')]: {
    width: 'auto',
  },
});

export const CatalogLink = styled(Link)({
  display: 'inline-block',
  marginBottom: '20px',

  '& span': {
    marginLeft: '5px',
    color: palette.oldLavender,
    fontWeight: 400,
  },
});
