import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import { useGetCategorySubcategoryWithPhoto } from '../../graphql/hooks/useQueries';
import theme from '../../theme';
import Container from '../Container';
import { PreviewCatalogItem, StyledPreviewCatalog } from './styles';

const PreviewCatalog = () => {
  const { i18n } = useTranslation();
  const { data } = useGetCategorySubcategoryWithPhoto();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Container>
      <StyledPreviewCatalog>
        {data?.getCategorySubcategoryWithPhoto.length > 0 &&
          data?.getCategorySubcategoryWithPhoto
            ?.map((item) => {
              const itemName = item.translations.find(
                (trans) => trans.languageCode === i18n.resolvedLanguage,
              ).name;
              const itemShortName = item?.translations?.find(
                (trans) => trans.languageCode === i18n.resolvedLanguage,
              )?.shortName;

              return (
                <PreviewCatalogItem
                  key={item.id}
                  to={`${ROUTES.service}?${
                    item?.parentCategorySlug
                      ? `category=${item?.parentCategorySlug}&sub=${item.slug}`
                      : `category=${item.slug}`
                  }`}
                >
                  <img src={`${HOST_IMAGE_URL}/${item.photo}`} alt={itemName} />
                  <Typography variant='h4' className='catalogItemTitle'>
                    {itemShortName || itemName}
                  </Typography>
                </PreviewCatalogItem>
              );
            })
            .filter((_, index) =>
              isDesktop ? index !== data.getCategorySubcategoryWithPhoto.length - 1 : true,
            )}
      </StyledPreviewCatalog>
    </Container>
  );
};

export default PreviewCatalog;
