import PropTypes from 'prop-types';

import { BasicModal } from '../ModalController';

export const ModalLayout = ({ children }) => {
  return (
    <div>
      {children}
      <BasicModal />
    </div>
  );
};

ModalLayout.propTypes = {
  children: PropTypes.node,
};
