const YoutubeIcon = ({ width = 20, height = 20 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    data-name='Layer 1'
    viewBox='0 0 128 128'
    id='movie'
    width={width}
    height={height}
  >
    <circle cx='64' cy='64' r='64' fill='#e21a20'></circle>
    <path
      fill='#fff'
      fill-rule='evenodd'
      d='M98.62 53.92c-.49-6.75-1.72-13.72-10.35-14.23a426.5 426.5 0 0 0-48.55 0c-8.63.5-9.86 7.48-10.35 14.23a135 135 0 0 0 0 20.16c.49 6.75 1.72 13.72 10.35 14.23a426.5 426.5 0 0 0 48.55 0c8.63-.5 9.86-7.48 10.35-14.23a135 135 0 0 0 0-20.16ZM57 73V53l19 10Z'
    ></path>
  </svg>
);

export default YoutubeIcon;
