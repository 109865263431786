import React from 'react';

import palette from '../theme/palette';

const NoImgIcon = ({ width = 20, height = 20, color = palette.oldLavender }) => {
  return (
    <svg
      fill={color}
      height={height}
      width={width}
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 299.998 299.998'
    >
      <g>
        <g>
          <path
            d='M149.995,0.001C67.156,0.001,0,67.16,0,149.999s67.156,149.997,149.995,149.997s150.003-67.161,150.003-149.997
			C299.997,67.157,232.834,0.001,149.995,0.001z M229.25,226.844H70.75c-3.981,0-7.205-3.221-7.205-7.202V80.353
			c0-3.979,3.224-7.202,7.205-7.202h158.497c3.981,0,7.205,3.224,7.205,7.202v97.537h-16.941V89.834H80.323v93.027h7.949
			l32.119-50.087l21.465,33.468l10.66,16.62h9.241l-15.281-23.827l9.959-15.53l25.241,39.355h19.491v5.405v16.321v10.374h10.374
			h24.909v4.684h0.005C236.454,223.624,233.231,226.844,229.25,226.844z M207.264,121.042c0,8.232-6.676,14.908-14.908,14.908
			s-14.908-6.676-14.908-14.908s6.676-14.908,14.908-14.908S207.264,112.813,207.264,121.042z M236.455,204.587h-24.911v-16.321
			h24.909h24.909v16.321H236.455z'
          />
        </g>
      </g>
    </svg>
  );
};

export default NoImgIcon;
