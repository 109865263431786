import React from 'react';

const HeartFillIcon = ({ width = 40, height = 40, className, color = '#E03F5C' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={color}
        d='M19.999 34.958L18.041 33.208C15.1517 30.5693 12.7627 28.2917 10.874 26.375C8.98536 24.4583 7.47836 22.743 6.35303 21.229C5.22836 19.715 4.4437 18.34 3.99903 17.104C3.55436 15.868 3.33203 14.611 3.33203 13.333C3.33203 10.8057 4.18636 8.68766 5.89503 6.97899C7.60303 5.27099 9.70703 4.41699 12.207 4.41699C13.763 4.41699 15.2077 4.77099 16.541 5.47899C17.8744 6.18766 19.027 7.19466 19.999 8.49999C21.0824 7.13866 22.2767 6.11799 23.582 5.43799C24.888 4.75733 26.291 4.41699 27.791 4.41699C30.291 4.41699 32.395 5.27099 34.103 6.97899C35.8117 8.68766 36.666 10.8057 36.666 13.333C36.666 14.611 36.4437 15.868 35.999 17.104C35.5544 18.34 34.7697 19.715 33.645 21.229C32.5197 22.743 31.0127 24.4583 29.124 26.375C27.2354 28.2917 24.8464 30.5693 21.957 33.208L19.999 34.958Z'
      />
    </svg>
  );
};

export default HeartFillIcon;
