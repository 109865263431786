import { Box, styled, Typography } from '@mui/material';

import palette from '../../../theme/palette';

export const StyledNavBar = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '17px',
});

export const StyledMounth = styled(Typography)({
  cursor: 'pointer',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '1.2',
  color: palette.oldLavender,
  textTransform: 'capitalize',
});

export const StyledSelectedMounth = styled(Typography)({
  cursor: 'default',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '1.2',
  color: palette.primary,
  textTransform: 'capitalize',
});
