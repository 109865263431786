import { ApolloClient, ApolloLink, InMemoryCache, Observable, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';
import create from 'zustand';

import { getCookie } from '../utils/getCookie';

class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        {
          ...operation,
          query: print(operation.query),
        },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (error) => {
            if (Array.isArray(error))
              // GraphQLError[]
              return sink.error(new Error(error.map(({ message }) => message).join(', ')));

            if (error instanceof CloseEvent)
              return sink.error(
                new Error(
                  `Socket closed with event ${error.code} ${error.reason || ''}`, // reason will be available on clean closes only
                ),
              );
            return sink.error(error);
          },
        },
      );
    });
  }
}

const wsLink = new WebSocketLink({
  url: `${process.env.REACT_APP_API_SOCKET_HOST}/graphql`,
  connectionParams: async () => {
    const token = localStorage.getItem('token');
    return {
      Authorization: token ? `Bearer ${JSON.parse(token)}` : '',
    };
  },
});

export const useErrorsStore = create(() => ({
  hasError: false,
  error: null,
  date: null,
}));

const httpLink = createUploadLink({
  // eslint-disable-next-line no-undef
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError || graphQLErrors[0]) {
    useErrorsStore.setState({
      hasError: true,
      error: networkError || graphQLErrors[0],
      date: Date.now(),
    });
  }
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  const locale = localStorage.getItem('switch');
  const cityid = JSON.parse(localStorage.getItem('city')) || '*';
  const countryid = localStorage.getItem('country');
  const userip = getCookie('USER_IP');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${JSON.parse(token)}` : '',
      locale: locale || 'sr',
      'X-Forwarded-For': userip || '',
      'Apollo-Require-Preflight': 'true',
      cityid,
      countryid,
      userip,
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
});
