import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HOST_IMAGE_URL } from '../../constants/constants';
import { useCreateReview } from '../../graphql/hooks/useMutations';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import StarIcon from '../../icons/StarIcon';
import starsImg from '../../images/stars.svg';
import palette from '../../theme/palette';
import { createReviewSchema } from '../../validation/schema/createReviewSchema';
import DefaultImage from '../DefaultImage';
import Button from '../UI/Button';
import Dot from '../UI/Dot';
import { ControlInput } from '../UI/Input';
import {
  Avatar,
  CenteredText,
  CenteredTypography,
  CenterTypography,
  CommenterInfo,
  CommentUserInfo,
  Form,
  ModalThankWrapper,
  StyledImg,
  StyledRating,
  SymbolCounter,
} from './styles';

const ModalThank = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <ModalThankWrapper>
      <StyledImg src={starsImg} />
      <CenterTypography variant={'h4'} className={'m-0'}>
        {t('weValueYouOpinion')}
      </CenterTypography>
      <Typography align={'center'} variant={'body1'} margin={'20px 0'}>
        {t('thankForLeavingReview')}
      </Typography>
      <Button title={t('ready')} margin={'10px auto 0px'} onClickButton={handleClose} />
    </ModalThankWrapper>
  );
};

export const CreateReview = ({ modalData, handleClose, refetchFunction }) => {
  const [isShowSecondStep, setIsShowSecondStep] = useState(false);
  const [symbolCount, setSymbolCount] = useState(1000);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: '',
      rating: null,
      userId: modalData.userId,
    },
    resolver: yupResolver(createReviewSchema),
  });
  const { t } = useTranslation();
  const [createReview, { loading }] = useCreateReview({
    onCompleted: () => {
      setIsShowSecondStep(true);
      if (refetchFunction) refetchFunction();
    },
  });
  const textLength = watch('text').length;

  useEffect(() => {
    setSymbolCount(1000 - textLength);
  }, [textLength]);

  const onSubmit = async (data) => {
    await createReview({
      variables: {
        input: data,
      },
    });
  };

  return isShowSecondStep ? (
    <ModalThank handleClose={handleClose} />
  ) : (
    <>
      <CenterTypography variant={'h4'}>{t('modalReviewTitle')}</CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CommentUserInfo>
          {modalData?.provider?.user?.photo ? (
            <Avatar src={`${HOST_IMAGE_URL}/${modalData?.provider?.user?.photo}`} alt={'avatar'} />
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <CommenterInfo>
            <Typography variant={'body2Semibold'} component={'div'}>
              {`${modalData?.provider?.user?.firstName} ${modalData?.provider?.user?.lastName}`}
            </Typography>
            <CenteredTypography variant={'body2'} component={'div'}>
              <CenteredText>
                <EmptyStarIcon />
                <span>
                  {modalData?.provider?.user.reviewsStatistic?.average?.toFixed(1) || t('noRating')}
                </span>
              </CenteredText>
              <Dot />
              <CenteredText>
                {modalData?.provider?.user.reviewsStatistic?.count || 0}{' '}
                {t('endingOfComments', {
                  count: modalData?.provider?.user.reviewsStatistic?.count || 0,
                })}
              </CenteredText>
            </CenteredTypography>
          </CommenterInfo>
        </CommentUserInfo>
        <ControlInput
          control={control}
          name={'text'}
          rows={6}
          multiline
          placeholder={t('modalTextPlaceholder')}
          inputClassName={'reviewTextArea'}
          maxLength={1000}
        />
        <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount} ${t(
          'symbol',
          {
            count: symbolCount,
          },
        )}`}</SymbolCounter>
        <Typography align={'center'} variant={'body1'}>
          {t('yourMark')}
        </Typography>
        <CenteredText>
          <StyledRating
            icon={<StarIcon width={35} height={35} />}
            emptyIcon={<EmptyStarIcon width={44} height={44} />}
            className={'reviewRating'}
            onChange={(event, value) => {
              clearErrors('rating');
              setValue('rating', value);
            }}
          />
        </CenteredText>
        {errors && errors.rating && (
          <Typography color={'#D32F2F'} component={'div'} textAlign={'center'} variant={'caption'}>
            {t(errors.rating?.message)}
          </Typography>
        )}
        <Button
          type={'submit'}
          title={
            loading ? (
              <CircularProgress
                sx={{
                  color: palette.secondary,
                }}
              />
            ) : (
              t('send')
            )
          }
          margin={'45px auto 40px'}
        />
      </Form>
    </>
  );
};
