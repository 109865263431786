import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HOST_IMAGE_URL } from '../../constants/constants';
import { useUpdateNotification } from '../../graphql/hooks/useMutations';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import starsImg from '../../images/stars.svg';
import palette from '../../theme/palette';
import { createReviewForAdminSchema } from '../../validation/schema/createRevievForAdminSchema';
import DefaultImage from '../DefaultImage';
import Button from '../UI/Button';
import Dot from '../UI/Dot';
import { ControlInput } from '../UI/Input';
import {
  Avatar,
  CenteredText,
  CenteredTypography,
  CenterTypography,
  CommenterInfo,
  CommentUserInfo,
  Form,
  ModalThankWrapper,
  StyledImg,
  SymbolCounter,
} from './styles';

const ModalThank = ({ handleClose }) => {
  const { t } = useTranslation();

  return (
    <ModalThankWrapper>
      <StyledImg src={starsImg} />
      <CenterTypography variant={'h4'} className={'m-0'}>
        {t('weValueYouOpinion')}
      </CenterTypography>
      <Typography align={'center'} variant={'body1'} margin={'20px 0'}>
        {t('thankForLeavingReview')}
      </Typography>
      <Button title={t('ready')} margin={'10px auto 0px'} onClickButton={handleClose} />
    </ModalThankWrapper>
  );
};

export const CreateReviewForAdmin = ({ modalData, handleClose }) => {
  const [isShowSecondStep, setIsShowSecondStep] = useState(false);
  const [symbolCount, setSymbolCount] = useState(1000);
  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      text: '',
      userId: modalData?.userId,
    },
    resolver: yupResolver(createReviewForAdminSchema),
  });
  const { t } = useTranslation();
  const [updateNotification, { loading }] = useUpdateNotification({
    onCompleted: () => {
      setIsShowSecondStep(true);
    },
  });
  const textLength = watch('text').length;

  useEffect(() => {
    setSymbolCount(1000 - textLength);
  }, [textLength]);

  const onSubmit = async (data) => {
    await updateNotification({
      variables: {
        updateNotificationId: data.userId,
        input: {
          questionDescription: data.text,
        },
      },
    });
  };

  return isShowSecondStep ? (
    <ModalThank handleClose={handleClose} />
  ) : (
    <>
      <CenterTypography variant={'h4'}>{t('describeTheReason')}</CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CommentUserInfo>
          {modalData?.provider?.user?.photo ? (
            <Avatar src={`${HOST_IMAGE_URL}/${modalData?.provider?.user?.photo}`} alt={'avatar'} />
          ) : (
            <DefaultImage iconSize={'40px'} />
          )}
          <CommenterInfo>
            <Typography variant={'body2Semibold'} component={'div'}>
              {`${modalData?.provider?.user?.firstName} ${modalData?.provider?.user?.lastName}`}
            </Typography>
            <CenteredTypography variant={'body2'} component={'div'}>
              <CenteredText>
                <EmptyStarIcon />
                <span>
                  {modalData?.provider?.user.reviewsStatistic?.average?.toFixed(1) || t('noRating')}
                </span>
              </CenteredText>
              <Dot />
              <CenteredText>
                {modalData?.provider?.user.reviewsStatistic?.count || 0}{' '}
                {t('endingOfComments', {
                  count: modalData?.provider?.user.reviewsStatistic?.count || 0,
                })}
              </CenteredText>
            </CenteredTypography>
          </CommenterInfo>
        </CommentUserInfo>
        <ControlInput
          control={control}
          name={'text'}
          rows={6}
          multiline
          placeholder={t("couldn'tUseService")}
          inputClassName={'reviewTextArea'}
          maxLength={1000}
        />
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount} ${t(
            'symbol',
            {
              count: symbolCount,
            },
          )}`}</SymbolCounter>
          <Typography variant={'caption'} component={'div'} margin={'-12px 0 34px'}>
            {t('commentForAdmin')}
          </Typography>
        </Box>
        <Button
          type={'submit'}
          title={
            loading ? (
              <CircularProgress
                sx={{
                  color: palette.secondary,
                }}
              />
            ) : (
              t('send')
            )
          }
          margin={'45px auto 40px'}
        />
      </Form>
    </>
  );
};
