import { Box, CircularProgress } from '@mui/material';
import { addMinutes, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetGroupedByDateIntervalOrders,
  useGetNonWorkingHoursByDateInterval,
  useGetWorkSchedule,
} from '../../../graphql/hooks/useQueries';
import palette from '../../../theme/palette';
import { createEventsArray } from '../../../utils/createEventsArray';
import { formatDateWithTimeZone } from '../../../utils/formatTime';
import { getWeekendsArray } from '../../../utils/getWeekendsArray';
import { DatePicker } from '../../DayPicker';
import { DaySchedule } from '../../DaySchedule';
import Button from '../../UI/Button';
import { CenterTypography, StyledCreateOrderFooter } from '../styles';

const CreateOrderStep2 = ({
  handleChangeStep,
  providerId,
  selectedService,
  setSelectedTime,
  selectedTime,
}) => {
  const { t } = useTranslation();
  const [disabledDays, setDisabledDays] = useState();
  const [bookedDays, setBookedDays] = useState([]);
  const [month, setMonth] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [nonWorkingHours, setNonWorkigHours] = useState([]);
  const [bookedHours, setBookedHours] = useState([]);
  const [eventsStateBeforeSelectEvent, setEventsStateBeforeSelectEvent] = useState([]);

  const { data: nonWorking } = useGetNonWorkingHoursByDateInterval({
    variables: {
      providerId,
      month: format(selectedDay || Date.now(), 'yyyy-MM-dd'),
    },
  });

  const { data: orders } = useGetGroupedByDateIntervalOrders({
    variables: {
      providerId,
      month: format(month, 'yyyy-MM-dd'),
    },
    onCompleted: (data) => {
      const booked = data?.getGroupedByDateIntervalOrders.map((day) => new Date(day.date));
      setBookedDays(booked);
    },
  });

  const { data: schedule, loading: loadingSchedule } = useGetWorkSchedule({
    variables: {
      userId: providerId,
    },
  });

  useEffect(() => {
    const updatedDayEvents = events
      .map((event) => {
        return {
          ...event,
          status: 'FREE',
          title: 'free',
        };
      })
      .filter((event) => {
        const isNonWorkingHour = bookedHours?.some((bookedHour) => {
          const eventStart = event.start.getTime();
          const eventEnd = event.end.getTime();
          const bookedStart = bookedHour.start.getTime();
          const bookedEnd = bookedHour.end.getTime();
          return (
            (eventStart >= bookedStart && eventStart < bookedEnd) ||
            (eventEnd > bookedStart && eventEnd <= bookedEnd) ||
            (eventStart <= bookedStart && eventEnd >= bookedEnd) ||
            (eventStart >= bookedStart && eventEnd <= bookedEnd)
          );
        });
        return !isNonWorkingHour;
      })
      .filter((event) => {
        const isNonWorkingHour = nonWorkingHours?.some((canceledHours) => {
          const eventStart = event.start.getTime();
          const eventEnd = event.end.getTime();
          const bookedStart = canceledHours.start.getTime();
          const bookedEnd = canceledHours.end.getTime();
          return (
            (eventStart >= bookedStart && eventStart < bookedEnd) ||
            (eventEnd > bookedStart && eventEnd <= bookedEnd) ||
            (eventStart <= bookedStart && eventEnd >= bookedEnd) ||
            (eventStart >= bookedStart && eventEnd <= bookedEnd)
          );
        });
        return !isNonWorkingHour;
      });

    const concatedEventsArray = [
      ...updatedDayEvents,
      ...(bookedHours || []),
      ...(nonWorkingHours || []),
    ];

    const eventsWithUnAvailable = concatedEventsArray.map((event) => {
      const eventDuration = {
        start: event.start,
        end: addMinutes(event.start, Number(selectedService.pricePer)),
      };

      const isBookedConflict = () => {
        const isUnavailable = concatedEventsArray.some((bookedHour) => {
          const eventEndsAfterBookingStarts = eventDuration.start < bookedHour.end;
          const eventStartsBeforeBookingEnds = eventDuration.end > bookedHour.start;
          return (
            bookedHour.status === 'BOOKED' &&
            eventEndsAfterBookingStarts &&
            eventStartsBeforeBookingEnds
          );
        });
        return isUnavailable && event.status !== 'BOOKED';
      };

      const isNonWorkingConflict = () => {
        const isUnavailable = concatedEventsArray.some((nonWorkingHour) => {
          const eventStartsBeforeNonWorkingEnds = eventDuration.start < nonWorkingHour.end;
          const eventEndsAfterNonWorkingStarts = eventDuration.end > nonWorkingHour.start;
          return (
            nonWorkingHour.status === 'CANCELED_IN_ORDER' &&
            eventStartsBeforeNonWorkingEnds &&
            eventEndsAfterNonWorkingStarts
          );
        });
        return isUnavailable && event.status !== 'CANCELED_IN_ORDER';
      };

      const isLunchConflict = () => {
        const isUnavailable = concatedEventsArray.find(() => {
          const eventStarstsBeforeLunchEnds =
            eventDuration.start <
            new Date(
              `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T${
                schedule?.getWorkSchedule?.endLunch < 10 ? '0' : ''
              }${schedule?.getWorkSchedule?.endLunch}:00:00`,
            );
          const eventEndsAfterLunchStarts =
            eventDuration.end >
            new Date(
              `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T${
                schedule?.getWorkSchedule?.startLunch < 10 ? '0' : ''
              }${schedule?.getWorkSchedule?.startLunch}:00:00`,
            );
          return eventStarstsBeforeLunchEnds && eventEndsAfterLunchStarts;
        });
        return isUnavailable && event.status !== 'BOOKED' && event.status !== 'CANCELED_IN_ORDER';
      };

      const isEndOfDayConflict = () => {
        const isUnavailable = concatedEventsArray.find(() => {
          const eventEndsAfterScheduleEndTime =
            eventDuration.end >
            new Date(
              `${format(selectedDay || Date.now(), 'yyyy-MM-dd')}T${
                schedule?.getWorkSchedule?.endTime < 10 ? '0' : ''
              }${schedule?.getWorkSchedule?.endTime}:00:00`,
            );
          return eventEndsAfterScheduleEndTime;
        });
        return isUnavailable && event.status !== 'BOOKED' && event.status !== 'CANCELED_IN_ORDER';
      };

      const isUnavailable = () =>
        isEndOfDayConflict() || isLunchConflict() || isNonWorkingConflict() || isBookedConflict();

      return {
        ...event,
        status: isUnavailable() ? 'NOT_AVAILABLE' : event.status,
      };
    });
    setEvents(eventsWithUnAvailable);
    setEventsStateBeforeSelectEvent(eventsWithUnAvailable);
  }, [selectedDay, nonWorkingHours, bookedHours, selectedTime]);

  useEffect(() => {
    if (nonWorking) {
      const nonWorkingHoursForDay = nonWorking?.getGroupedByDateIntervalNonWorkingHours?.find(
        (day) => day.date === format(selectedDay || Date.now(), 'yyyy-MM-dd'),
      );

      const arrayOfNonWorkingDays = nonWorking?.getGroupedByDateIntervalNonWorkingHours
        ?.filter((dayOff) => {
          const nonWorkingDay = dayOff?.intervals?.find((hour) => {
            return (
              hour.startTime === `${format(new Date(dayOff.date), 'yyyy-MM-dd')}T00:00:00.000Z` &&
              hour.endTime === `${format(new Date(dayOff.date), 'yyyy-MM-dd')}T23:59:59.000Z`
            );
          });
          return nonWorkingDay;
        })
        .map((d) => new Date(d.date));
      setDisabledDays(arrayOfNonWorkingDays);

      const disabledHours = nonWorkingHoursForDay?.intervals.map((hour) => {
        return {
          start: formatDateWithTimeZone(hour.startTime),
          end: formatDateWithTimeZone(hour.endTime),
          status: 'CANCELED_IN_ORDER',
          title: 'nonWorkingHours',
        };
      });

      setNonWorkigHours(disabledHours);
    } else {
      setNonWorkigHours([]);
    }
  }, [nonWorking, selectedDay]);

  useEffect(() => {
    if (orders) {
      const ordersHoursForDay = orders?.getGroupedByDateIntervalOrders?.find(
        (day) => day.date === format(selectedDay || Date.now(), 'yyyy-MM-dd'),
      );

      const orderHours = ordersHoursForDay?.intervals.map((hour) => {
        return {
          start: formatDateWithTimeZone(hour.startTime),
          end: formatDateWithTimeZone(hour.endTime),
          status: 'BOOKED',
          title: 'booked',
        };
      });

      setBookedHours(orderHours);
    } else {
      setBookedHours([]);
    }
  }, [orders, selectedDay]);

  useEffect(() => {
    const updatedEvents = events
      .map((event) => {
        if (event.start === selectedTime.start) {
          return {
            ...event,
            end: selectedTime.end,
            status: 'SELECTED',
          };
        }
        return event;
      })
      .filter(
        (event) =>
          event.status === 'SELECTED' ||
          event.end <= selectedTime.start ||
          event.start >= selectedTime.end,
      );

    setEvents(updatedEvents);
  }, [selectedTime]);

  const handleSelectTime = (event) => {
    setSelectedTime({
      ...event,
      end: addMinutes(event.start, Number(selectedService.pricePer)),
    });
    setEvents(eventsStateBeforeSelectEvent);
  };

  const handleSelectDay = (date) => {
    if (date) {
      setSelectedDay(date);
      setEvents(
        createEventsArray(
          schedule?.getWorkSchedule?.startTime,
          schedule?.getWorkSchedule?.endTime,
          schedule?.getWorkSchedule?.startLunch,
          schedule?.getWorkSchedule?.endLunch,
          date,
        ),
      );
    }
  };

  if (loadingSchedule) {
    return (
      <Box p={'10px'} display='flex' justifyContent={'center'}>
        <CircularProgress
          sx={{
            color: palette.primary,
          }}
        />
      </Box>
    );
  }

  if (!schedule && !loadingSchedule) {
    return <CenterTypography margin={'40px 0'}>{t('providerNoSchedule')}</CenterTypography>;
  }

  return (
    <>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('bookSrvice')}
      </CenterTypography>
      <CenterTypography margin={'0 0 20px 0'} variant={'body1'}>
        {t('selectDateAndTime')}
      </CenterTypography>
      <>
        <Box width='fit-content' margin='auto'>
          <DatePicker
            days={selectedDay}
            setDays={handleSelectDay}
            bookedDays={bookedDays}
            disableWeekends={getWeekendsArray(schedule?.getWorkSchedule?.workdays)}
            disabledDays={disabledDays}
            setMonth={setMonth}
            disabledDaysBeforeToday
          />
        </Box>
        {selectedDay && (
          <DaySchedule
            selectedDate={selectedDay || Date.now()}
            events={events}
            onSelectTime={handleSelectTime}
          />
        )}
        <StyledCreateOrderFooter>
          <Button
            className={'buttonWhite sm'}
            margin={'0 10px 0 0'}
            title={t('back')}
            onClickButton={handleChangeStep(1)}
          />

          <Button
            className={'sm'}
            sx={{
              width: 'fit-content',
              padding: '0 24px !important',
              background: '#008EFF',
              marginLeft: '10px',
            }}
            title={t('continue')}
            onClickButton={handleChangeStep(3)}
            disabled={!selectedDay || !selectedTime}
          />
        </StyledCreateOrderFooter>
      </>
    </>
  );
};

export default CreateOrderStep2;
