import React from 'react';

const ViberIcon = ({ width = 20, height = 20 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      width={width}
      height={height}
      viewBox='0 0 256 256'
      xmlSpace='preserve'
    >
      <defs></defs>
      <g
        stroke='none'
        strokeWidth='0'
        strokeDasharray='none'
        strokeLinecap='butt'
        strokeLinejoin='miter'
        strokeMiterlimit='10'
        fill='none'
        fillRule='nonzero'
        opacity='1'
        transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'
      >
        <path
          d='M 45 90 L 45 90 C 20.147 90 0 69.853 0 45 v 0 C 0 20.147 20.147 0 45 0 h 0 c 24.853 0 45 20.147 45 45 v 0 C 90 69.853 69.853 90 45 90 z'
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(126,81,159)'
          fillRule='nonzero'
          opacity='1'
        />
        <path
          d='M 67.028 20.852 c -1.491 -1.376 -7.516 -5.75 -20.937 -5.809 c 0 0 -15.827 -0.954 -23.542 6.123 c -4.295 4.296 -5.806 10.581 -5.965 18.374 s -0.366 22.397 13.712 26.357 h 0.014 L 30.3 71.939 c 0 0 -0.09 2.446 1.521 2.945 c 1.948 0.605 3.092 -1.254 4.952 -3.258 c 1.021 -1.1 2.431 -2.716 3.493 -3.952 c 9.628 0.81 17.032 -1.042 17.873 -1.315 c 1.944 -0.63 12.944 -2.04 14.733 -16.643 C 74.719 34.664 71.979 25.143 67.028 20.852 z M 68.66 48.636 c -1.51 12.193 -10.432 12.959 -12.077 13.487 c -0.7 0.225 -7.203 1.843 -15.381 1.309 c 0 0 -6.094 7.352 -7.997 9.263 c -0.297 0.299 -0.646 0.42 -0.88 0.36 c -0.328 -0.08 -0.418 -0.468 -0.414 -1.035 c 0.005 -0.81 0.052 -10.041 0.052 -10.041 s -0.007 0 0 0 c -11.908 -3.306 -11.214 -15.736 -11.079 -22.245 c 0.135 -6.509 1.358 -11.841 4.99 -15.427 c 6.526 -5.911 19.969 -5.028 19.969 -5.028 c 11.353 0.05 16.793 3.468 18.054 4.614 c 4.189 3.586 6.322 12.168 4.762 24.74 V 48.636 z'
          transform=' matrix(1 0 0 1 0 0) '
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(255,255,255)'
          fillRule='nonzero'
          opacity='1'
        />
        <path
          d='M 51.615 39.958 c -0.402 0 -0.737 -0.316 -0.757 -0.721 c -0.128 -2.579 -1.341 -3.84 -3.818 -3.97 c -0.419 -0.022 -0.74 -0.379 -0.718 -0.798 c 0.022 -0.419 0.377 -0.741 0.798 -0.718 c 3.27 0.171 5.087 2.042 5.255 5.41 c 0.021 0.419 -0.302 0.775 -0.72 0.796 C 51.64 39.958 51.628 39.958 51.615 39.958 z'
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(255,255,255)'
          fillRule='nonzero'
          opacity='1'
        />
        <path
          d='M 55.51 41.264 c -0.006 0 -0.011 0 -0.017 0 c -0.419 -0.009 -0.752 -0.356 -0.742 -0.775 c 0.056 -2.594 -0.685 -4.695 -2.265 -6.422 c -1.579 -1.723 -3.742 -2.663 -6.613 -2.873 c -0.418 -0.031 -0.732 -0.394 -0.702 -0.812 c 0.031 -0.418 0.396 -0.732 0.812 -0.702 c 3.23 0.236 5.794 1.368 7.622 3.362 c 1.832 2.001 2.728 4.518 2.663 7.48 C 56.26 40.935 55.922 41.264 55.51 41.264 z'
          transform=' matrix(1 0 0 1 0 0) '
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(255,255,255)'
          fillRule='nonzero'
          opacity='1'
        />
        <path
          d='M 59.497 42.823 c -0.417 0 -0.756 -0.336 -0.759 -0.753 c -0.035 -4.58 -1.409 -8.224 -4.085 -10.832 c -2.681 -2.613 -6.068 -3.952 -10.068 -3.98 c -0.419 -0.003 -0.757 -0.345 -0.754 -0.764 c 0.003 -0.417 0.342 -0.754 0.759 -0.754 c 0.002 0 0.004 0 0.005 0 c 4.406 0.031 8.146 1.515 11.117 4.411 c 2.976 2.902 4.505 6.908 4.543 11.908 c 0.003 0.419 -0.334 0.762 -0.753 0.765 C 59.501 42.823 59.499 42.823 59.497 42.823 z'
          transform=' matrix(1 0 0 1 0 0) '
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(255,255,255)'
          fillRule='nonzero'
          opacity='1'
        />
        <path
          d='M 47.23 49.365 c 0 0 1.067 0.09 1.641 -0.618 l 1.12 -1.409 c 0.54 -0.699 1.844 -1.144 3.121 -0.433 c 0.957 0.546 1.886 1.139 2.783 1.777 c 0.847 0.623 2.582 2.071 2.588 2.071 c 0.827 0.698 1.017 1.722 0.455 2.803 c 0 0.006 -0.005 0.017 -0.005 0.023 c -0.62 1.074 -1.407 2.043 -2.331 2.87 c -0.011 0.005 -0.011 0.011 -0.021 0.016 c -0.803 0.671 -1.592 1.053 -2.367 1.144 c -0.114 0.02 -0.23 0.027 -0.346 0.022 c -0.342 0.003 -0.682 -0.049 -1.007 -0.155 l -0.025 -0.037 c -1.194 -0.337 -3.187 -1.179 -6.507 -3.011 c -1.921 -1.047 -3.75 -2.255 -5.467 -3.611 c -0.861 -0.679 -1.682 -1.407 -2.459 -2.181 l -0.083 -0.083 l -0.083 -0.083 l 0 0 l -0.083 -0.083 c -0.028 -0.027 -0.055 -0.055 -0.083 -0.083 c -0.773 -0.777 -1.501 -1.598 -2.181 -2.459 c -1.356 -1.717 -2.564 -3.546 -3.611 -5.466 c -1.831 -3.321 -2.674 -5.312 -3.011 -6.508 l -0.037 -0.025 c -0.106 -0.325 -0.158 -0.665 -0.154 -1.007 c -0.006 -0.116 0.001 -0.232 0.021 -0.346 c 0.097 -0.774 0.479 -1.563 1.146 -2.369 c 0.005 -0.01 0.011 -0.01 0.016 -0.021 c 0.827 -0.924 1.796 -1.711 2.87 -2.33 c 0.005 0 0.016 -0.005 0.023 -0.005 c 1.08 -0.563 2.105 -0.372 2.802 0.45 c 0.005 0.005 1.451 1.74 2.071 2.588 c 0.639 0.898 1.232 1.828 1.777 2.786 c 0.711 1.276 0.266 2.582 -0.433 3.121 l -1.409 1.12 c -0.711 0.574 -0.618 1.641 -0.618 1.641 S 39.432 47.375 47.23 49.365 z'
          transform=' matrix(1 0 0 1 0 0) '
          stroke='none'
          strokeWidth='0'
          strokeDasharray='none'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          strokeMiterlimit='10'
          fill='rgb(255,255,255)'
          fillRule='nonzero'
          opacity='1'
        />
      </g>
    </svg>
  );
};

export default ViberIcon;
