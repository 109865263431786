import { Chip, Typography } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledReservation = styled('div')({
  padding: '14px 0',
});

export const StyledUserInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(576)]: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '.MuiChip-root': {
    marginLeft: '65px',
    marginTop: '5px',
  },
  '.MuiChip-canceled': {
    marginLeft: '35px',
  },
  '.master-info': {
    flexWrap: 'wrap',
  },
});
export const Avatar = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '8px',
  objectFit: 'cover',
});
export const StyledReservationInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '8px',
});
export const CenteredTypography = styled(Typography)(({ lineHeight, color = palette.primary }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: lineHeight ? `${lineHeight}px` : '20px',
  marginBottom: '0px',
  textTransform: 'capitalize',
  color,
}));
export const StyledChip = styled(Chip)({
  padding: '4px 12px',
  height: '24px',
  minWidth: '118px',
  '& .MuiChip-label': {
    fontSize: '12px',
    lineHeight: '16px',
    padding: 0,
  },
});
export const CenteredText = styled('span')({
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  color: palette.oldLavender,
  svg: {
    marginRight: '4px',
    marginTop: '-4px',
  },
});
export const StyledActionBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(576)]: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
});

export const AnswerActions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '131px',
  justifyContent: 'space-between',
  button: {
    minWidth: '58px',
    maxWidth: '58px',
    height: '44px',
    fontSize: '16px',
    '&.MuiButton-text': {
      color: palette.primary,
      textTransform: 'none',
      borderRadius: '12px',
    },
  },
});

export const Answer = styled('div')({
  display: 'flex',
  button: {
    minWidth: '58px',
    maxWidth: '58px',
    height: '44px',
    fontSize: '16px',
    '&.MuiButton-text': {
      color: palette.primary,
      textTransform: 'none',
      borderRadius: '12px',
    },
  },
  '.disabled': {
    color: `${palette.secondary}!important`,
  },
});

export const StyledReservationComment = styled(Typography)({
  marginTop: '12px',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '16px',
  color: palette.oldLavender,
  marginLeft: '64px',
});
