import React from 'react';

import palette from '../theme/palette';

const UncheckedIcon = ({ width = 18, height = 18, className, color = palette.oldLavender }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM2 16H16V2H2V16Z'
        fill={color}
      />
    </svg>
  );
};

export default UncheckedIcon;
