import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import { useSetFavouriteProvider } from '../../graphql/hooks/useMutations';
import { useGetLocales } from '../../graphql/hooks/useQueries';
import { GET_FAVOURITE_SERVICES, GET_SERVICES } from '../../graphql/queries';
import { useAuth, useToast } from '../../hooks';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import HeartFillIcon from '../../icons/HeartFillIcon';
import HeartIcon from '../../icons/HeartIcon';
import palette from '../../theme/palette';
import { convertToMinutes } from '../../utils/convert';
import {
  getNameFromTranslations,
  transformLocalesToObjectWithUserData,
} from '../../utils/getDifferentFormatOfData';
import { CenteredText, CenteredTypography } from '../Comment/styles';
import DefaultImage from '../DefaultImage';
import MessageToUnregisteredUser from '../MessageToUnregisteredUser';
import Button from '../UI/Button';
import Divider from '../UI/Divider';
import Dot from '../UI/Dot';
import {
  ChipWrapper,
  ConnectionBlock,
  Gallery,
  Photo,
  ProviderDescription,
  ProviderInfo,
  ProviderPhoto,
  StyledChip,
  StyledDescription,
  StyledProvider,
  TopBlock,
} from './styles';

const Provider = ({
  // ===== service response fields
  id: serviceId,
  user: serviceProvider,
  inFavorites: isFavourite,
  servicePhotos,
  subservices,
  price,
  pricePer,
  currencyCode,
  enableBooking,
  subcategories,
  translations,
  // ===== others props
  setModalData,
  setIsModalOpen,
  setModalType,
  handleZoomImage,
}) => {
  const { t, i18n } = useTranslation();
  const { userInfo } = useAuth();
  const [isFavoirite, setIsFavoirite] = useState(isFavourite);
  const { data: locales } = useGetLocales();
  const [variant, setToastConfig] = useToast();
  const providerUrl = `${ROUTES.provider}/${serviceId}`;

  const description = transformLocalesToObjectWithUserData(locales?.getLocales, translations)?.[
    i18n.resolvedLanguage
  ]?.userTranslate?.description;

  const [setFavourite] = useSetFavouriteProvider({
    onError: () => {
      setIsFavoirite(!isFavoirite);
      setToastConfig(variant.error);
    },
  });

  const handleToggleFavourite = () => {
    setIsFavoirite(!isFavoirite);
    setFavourite({
      variables: {
        serviceId,
      },
      refetchQueries: [GET_FAVOURITE_SERVICES, GET_SERVICES],
    });
  };

  const showBooking = () => {
    if (userInfo.role === 'USER' && enableBooking) {
      setIsModalOpen(true);
      setModalType('createOrder');
      setModalData({
        serviceId,
        userId: serviceProvider?.id,
      });
    }
  };

  return (
    <StyledProvider>
      <TopBlock>
        <ProviderPhoto>
          {serviceProvider.photo ? (
            <Link to={providerUrl}>
              <Photo
                src={`${HOST_IMAGE_URL}/${serviceProvider.photo}`}
                alt={`${serviceProvider.firstName} ${serviceProvider.lastName}`}
                className={'avatar'}
              />
            </Link>
          ) : (
            <DefaultImage iconSize={'40px'} className={'medium'} margin={'0 16px 0 0'} />
          )}
        </ProviderPhoto>
        <ProviderDescription>
          <ProviderInfo>
            <Box>
              <Link to={providerUrl}>
                <Typography variant={'body1Semibold'} color={palette.primary} component={'div'}>
                  {`${serviceProvider.firstName} ${serviceProvider.lastName}`}
                </Typography>
              </Link>
              <CenteredTypography variant={'caption'} component={'div'}>
                <CenteredText>
                  <EmptyStarIcon />
                  <span>
                    {serviceProvider.reviewsStatistic?.average?.toFixed(1) || t('noRating')}
                  </span>
                </CenteredText>
                <Dot />
                <Link to={`${providerUrl}?reviews`}>
                  <CenteredText>
                    {serviceProvider.reviewsStatistic?.count || 0}{' '}
                    {t('endingOfComments', {
                      count: serviceProvider.reviewsStatistic?.count || 0,
                    })}
                  </CenteredText>
                </Link>
              </CenteredTypography>
            </Box>
            {userInfo?.role === 'USER' && (
              <Box
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleToggleFavourite}
              >
                {isFavoirite ? <HeartFillIcon /> : <HeartIcon />}
              </Box>
            )}
          </ProviderInfo>

          {userInfo && servicePhotos && (
            <Gallery>
              {servicePhotos?.map(({ path, about }, index, array) => {
                return path ? (
                  <Photo
                    key={`${index}-${serviceProvider.lastName}`}
                    src={`${HOST_IMAGE_URL}/${path}`}
                    alt={`${serviceProvider.firstName} ${serviceProvider.lastName}`}
                    onClick={() =>
                      handleZoomImage(
                        {
                          path: `${HOST_IMAGE_URL}/${path}`,
                          about,
                        },
                        index,
                        array,
                      )
                    }
                  />
                ) : (
                  <DefaultImage
                    key={`${index}`}
                    withIcon={false}
                    className={'medium'}
                    margin={'0 16px 0 0'}
                  />
                );
              })}
            </Gallery>
          )}
          {subcategories && (
            <ChipWrapper>
              {subcategories.map((category) => (
                <StyledChip
                  key={category.id}
                  label={getNameFromTranslations(category?.translations, i18n)}
                />
              ))}
            </ChipWrapper>
          )}
          <Box>
            {description && (
              <StyledDescription variant={'body-2'} component={'div'}>
                {description}
              </StyledDescription>
            )}
            {price && (
              <Typography variant={'captionNormal'} component={'div'}>
                {`${t('priceFrom')}: ${price} ${t(currencyCode)} / ${
                  Number.isNaN(+pricePer) ? t(pricePer) : convertToMinutes(pricePer, t)
                }`}
              </Typography>
            )}
            {!!subservices?.length && <Divider margin={'16px 0'} />}
            {subservices?.map((subservice, index) => {
              const subDescription = transformLocalesToObjectWithUserData(
                locales?.getLocales,
                subservice?.translations,
              )?.[i18n.resolvedLanguage]?.userTranslate?.description;
              return (
                <Box key={subservice.id}>
                  {subDescription && (
                    <StyledDescription variant={'body-2'} component={'div'}>
                      {subDescription}
                    </StyledDescription>
                  )}
                  {subservice?.price && (
                    <Typography variant={'captionNormal'} component={'div'}>
                      {`${t('priceFrom')}: ${subservice.price} ${t(subservice.currencyCode)} / ${
                        Number.isNaN(+subservice.pricePer)
                          ? t(subservice.pricePer)
                          : convertToMinutes(subservice.pricePer, t)
                      }`}
                    </Typography>
                  )}
                  {index !== subservices?.length - 1 && <Divider margin={'16px 0'} />}
                </Box>
              );
            })}
          </Box>
        </ProviderDescription>
      </TopBlock>
      {userInfo?.role !== 'PROVIDER' && enableBooking && (
        <>
          <Divider margin={'16px 0 0'} />
          <ConnectionBlock>
            {userInfo ? (
              <Button
                title={t('bookServiceOnline')}
                className={'sm fullWidth'}
                margin={'16px 0 0 0'}
                onClickButton={showBooking}
              />
            ) : (
              <MessageToUnregisteredUser
                margin={'15px 0 0'}
                nameOfUnavailableBlock={'onlineBookings'}
              />
            )}
          </ConnectionBlock>
        </>
      )}
      <Divider margin={'16px 0 0'} />
    </StyledProvider>
  );
};

export default Provider;
