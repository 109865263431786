/* eslint-disable unicorn/no-thenable */
import * as yup from 'yup';

export const scheduleSchema = yup.object().shape({
  workingHoursFrom: yup
    .object()
    .shape({
      id: yup.string().required('REQUIRE_MESSAGE'),
      name: yup.string().required('REQUIRE_MESSAGE'),
    })
    .test('check-require', 'REQUIRE_MESSAGE', ({ value }) => {
      return value !== undefined;
    })
    .test('check-more-then', 'REQUIRE_TO_MESSAGE', ({ value }, context) => {
      const fields = context.from[1].value;
      return fields?.workingHoursTo?.value > value;
    }),
  workingHoursTo: yup
    .object()
    .shape({
      id: yup.string().required('REQUIRE_MESSAGE'),
      name: yup.string().required('REQUIRE_MESSAGE'),
    })
    .test('check-require', 'REQUIRE_MESSAGE', ({ value }) => {
      return value !== undefined;
    })
    .test('check-more-then', 'REQUIRE_FROM_MESSAGE', ({ value }, context) => {
      const fields = context.from[1].value;
      return fields?.workingHoursFrom?.value < value;
    }),

  lunchBreak: yup.array().nullable().notRequired(),
  lunchBreakFrom: yup
    .object()
    .shape({
      id: yup.string().required('REQUIRE_MESSAGE'),
      name: yup.string().required('REQUIRE_MESSAGE'),
    })
    .when(['lunchBreak'], {
      is: (value) => {
        return value.length > 0;
      },
      then: yup
        .object()
        .shape({
          id: yup.string().required('REQUIRE_MESSAGE'),
          name: yup.string().required('REQUIRE_MESSAGE'),
        })
        .test('check-require', 'REQUIRE_MESSAGE', ({ value }) => {
          return value !== undefined;
        })
        .test('check-more-then', 'REQUIRE_TO_MESSAGE', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.lunchBreakTo?.value > value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursFrom?.value <= value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursTo?.value >= value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursFrom?.value !== value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursTo?.value !== value;
        }),
    }),
  lunchBreakTo: yup
    .object()
    .shape({
      id: yup.string().required('REQUIRE_MESSAGE'),
      name: yup.string().required('REQUIRE_MESSAGE'),
    })
    .when(['lunchBreak'], {
      is: (value) => {
        return value.length > 0;
      },
      then: yup
        .object()
        .shape({
          id: yup.string().required('REQUIRE_MESSAGE'),
          name: yup.string().required('REQUIRE_MESSAGE'),
        })
        .test('check-require', 'REQUIRE_MESSAGE', ({ value }) => {
          return value !== undefined;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursFrom?.value <= value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursTo?.value >= value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursFrom?.value !== value;
        })
        .test('check-is-over', 'CHECK_TIME_LUNCH', ({ value }, context) => {
          const fields = context.from[1].value;
          return fields?.workingHoursTo?.value !== value;
        }),
    }),
});
