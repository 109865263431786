import * as yup from 'yup';

export const updateUserProfileValidationSchema = yup.object().shape({
  email: yup.string('PROMPT_MAIL').nullable(true).required('REQUIRE_MESSAGE').email('PROMPT_MAIL'),
  name: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_NAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
  surname: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_SURNAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
});

export const updateProviderProfileValidationSchema = yup.object().shape({
  email: yup.string('PROMPT_MAIL').nullable(true).required('REQUIRE_MESSAGE').email('PROMPT_MAIL'),
  name: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_NAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
  surname: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_SURNAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
  userLanguageIds: yup.array().of(yup.number()).min(1, 'REQUIRE_MESSAGE'),
  about: yup.object().shape({
    ru: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
    sr: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
    en: yup.string().nullable(true).required('REQUIRE_MESSAGE'),
  }),
});
