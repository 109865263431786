import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const Heading = styled(Typography)({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '1.2',
    marginBottom: 0,
  },
});

export const StyledProfileForm = styled('div')({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'contents',
  },
});

export const FieldsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '15px',
    padding: '0 15px',
  },
});

export const Image = styled('img')({
  maxWidth: '217px',
  height: '217px',
  borderRadius: '18px',
  objectFit: 'cover',
});

export const Form = styled('form')({
  [theme.breakpoints.down('sm')]: {
    display: 'contents',
  },
});

export const ControlsBox = styled(Box)({
  '&>div': {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      '&:not(:last-of-type)': {
        marginBottom: '15px',
      },
    },
  },
  '.changePass': {
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#706F7A',
    ':hover': {
      background: 'inherit',
      textDecoration: 'none',
    },
    svg: {
      marginRight: '8px',
    },
  },
  [theme.breakpoints.down('md')]: {
    order: 1,
    marginTop: '20px',
    '.changePass': {
      margin: '-10px 0 10px',
    },
  },
});

export const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    order: 0,
    alignItems: 'center',
  },
});

export const AddPhotoButton = styled(Button)({
  margin: 0,
  padding: 0,
  border: 0,
  background: 'inherit',
  cursor: 'pointer',
  minWidth: '217px',
  maxWidth: '217px',
  height: '217px',
  '&:hover': {
    background: 'inherit',
  },
});

export const Photo = styled('img')({
  borderRadius: '12px',
  width: '217px',
  height: '217px',
});

export const TopPanel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '16px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'contents',
  },
});

export const ButtonManage = styled('div')({
  display: 'flex',
  alignItems: 'center',
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',

    '&.cancel': {
      color: palette.oldLavender,
    },

    '&.save': {
      color: palette.blue,
    },

    '&.saveLanguage': {
      margin: '20px 0 0',
    },
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
});

export const ButtonManageServices = styled('div')(({ margin }) => ({
  margin,
  display: 'flex',
  alignItems: 'center',
  button: {
    textTransform: 'inherit',
    fontWeight: 600,
    padding: '6px 16px',

    '&.cancel': {
      color: palette.oldLavender,
    },

    '&.save': {
      color: palette.blue,
    },

    '&.saveLanguage': {
      margin: '0 0 0',
    },
  },
}));

export const SymbolCounter = styled(Typography)({
  margin: '-12px 0 10px',
});

export const BlockDescription = styled('span')({
  fontSize: '14px',
  lineHeight: '1.4',
  color: palette.oldLavender,
  display: 'block',
  marginTop: '-10px',
});

export const SelectLanguageWrapper = styled('div')({
  padding: '12px 0 0',
  '> span': {
    color: palette.oldLavender,
  },
  'div.error': {
    color: '#D32F2F',
    marginTop: '-20px',
    marginBottom: '10px',
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export const StyledTypesList = styled('div')({
  marginBottom: '12px',
  padding: '12px 0 0',
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  '> div': {
    marginBottom: '12px',
  },

  '.MuiChip-root': {
    background: 'rgba(123, 238, 64, 0.2)',
    height: '32px',
    padding: '6px 14px 6px 20px',
    fontSize: '14px',
    lineHeight: '20px',

    ':not(:last-child)': {
      marginRight: '12px',
    },

    '&.addChip': {
      background: palette.desertStorm,
      padding: '6px 20px 6px 14px',

      '.MuiChip-label': {
        margin: '0 0 0 6px',
        padding: 0,
      },

      '.MuiChip-icon': {
        margin: 0,
      },
    },
  },

  '.MuiChip-label': {
    margin: '0 6px 0 0',
    padding: 0,
  },

  '.MuiChip-deleteIcon': {
    margin: '0!important',
  },

  '.addButton': {
    background: 'red',
  },

  [theme.breakpoints.down('md')]: {
    overflowX: 'scroll !important',
    width: 'auto',
    whiteSpace: 'nowrap',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap-reverse',
  },
});

export const StyledRemoveIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '11px',
  right: '11px',
  with: '32px',
  minWidth: '32px',
  height: '32px',
  borderRadius: '50%',
  background: palette.secondary,
  zIndex: '999',
  cursor: 'pointer',
  '&.icon': {
    background: 'transparent',
  },
});

export const PhotoWrap = styled('div')({
  position: 'relative',
  width: '217px',
  height: '217px',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    aspectRatio: '1 / 1',
  },
});
