import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const GET_REVIEWS_WRITTEN = gql`
  ${USER}
  query GetReviewsWritten($limit: Int, $offset: Int) {
    getReviewsWritten(limit: $limit, offset: $offset) {
      count
      rows {
        id
        user {
          ...UserFragment
        }
        author {
          ...UserFragment
        }
        text
        rating
        createdAt
      }
    }
  }
`;
