import { gql } from '@apollo/client';

import { USER } from './user.fragment';

export const REVIEW = gql`
  ${USER}
  fragment ReviewFragment on Review {
    id
    user {
      ...UserFragment
    }
    author {
      ...UserFragment
    }
    text
    rating
    createdAt
  }
`;
