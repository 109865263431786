import React from 'react';

const AddIconGradient = ({ width = 20, height = 20, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.2507 16.3201L9.74883 16.3201V10.7509L4.17966 10.7509V9.24905L9.74883 9.24905V3.67988L11.2507 3.67988L11.2507 9.24905H16.8199V10.7509H11.2507L11.2507 16.3201Z'
        fill='#D9D9D9'
      />
      <path
        d='M11.2507 16.3201L9.74883 16.3201V10.7509L4.17966 10.7509V9.24905L9.74883 9.24905V3.67988L11.2507 3.67988L11.2507 9.24905H16.8199V10.7509H11.2507L11.2507 16.3201Z'
        fill='url(#paint0_linear_211_8304)'
      />
      <path
        d='M11.2507 16.3201L9.74883 16.3201V10.7509L4.17966 10.7509V9.24905L9.74883 9.24905V3.67988L11.2507 3.67988L11.2507 9.24905H16.8199V10.7509H11.2507L11.2507 16.3201Z'
        fill='url(#paint1_linear_211_8304)'
      />
      <path
        d='M11.2507 16.3201L9.74883 16.3201V10.7509L4.17966 10.7509V9.24905L9.74883 9.24905V3.67988L11.2507 3.67988L11.2507 9.24905H16.8199V10.7509H11.2507L11.2507 16.3201Z'
        fill='url(#paint2_linear_211_8304)'
      />
      <path
        d='M11.2507 16.3201L9.74883 16.3201V10.7509L4.17966 10.7509V9.24905L9.74883 9.24905V3.67988L11.2507 3.67988L11.2507 9.24905H16.8199V10.7509H11.2507L11.2507 16.3201Z'
        fill='url(#paint3_linear_211_8304)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_211_8304'
          x1='6.96424'
          y1='6.46447'
          x2='14.0353'
          y2='13.5355'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8BFF58' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_211_8304'
          x1='4.68385'
          y1='-2.8732'
          x2='-2.24389'
          y2='4.07622'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.182292' stopColor='#8BFF58' />
          <stop offset='0.586425' stopColor='#46C7AB' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_211_8304'
          x1='4.68385'
          y1='-2.8732'
          x2='-2.24389'
          y2='4.07622'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#58FF87' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_211_8304'
          x1='4.68385'
          y1='-2.8732'
          x2='-2.24389'
          y2='4.07622'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FFD21' />
          <stop offset='1' stopColor='#008EFF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AddIconGradient;
