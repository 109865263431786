import { Box } from '@mui/system';
import React from 'react';

import MessageToUnregisteredUser from '../../../components/MessageToUnregisteredUser';
import { HOST_IMAGE_URL } from '../../../constants/constants';
import { PhotoTab, TabGallery } from '../styles';

export const TabServicePhotos = ({ userInfo, provider, handleZoomImage }) => {
  return (
    <Box pb='20px' m='0'>
      {userInfo ? (
        provider?.servicePhotos?.length > 0 && (
          <TabGallery>
            {provider?.servicePhotos.map(({ path, about }, index, array) => {
              return (
                <PhotoTab
                  key={`${index}-${provider?.user?.lastName}`}
                  src={`${HOST_IMAGE_URL}/${path}`}
                  alt={`${provider?.user?.firstName} ${provider?.user?.lastName}`}
                  onClick={() =>
                    handleZoomImage(
                      {
                        path: `${HOST_IMAGE_URL}/${path}`,
                        about,
                      },
                      index,
                      array,
                    )
                  }
                />
              );
            })}
          </TabGallery>
        )
      ) : (
        <MessageToUnregisteredUser margin={'0'} nameOfUnavailableBlock={'photos'} />
      )}
    </Box>
  );
};
