import { gql } from '@apollo/client';

export const GET_GROUPED_BY_DATE_INTERVAL_ORDERS = gql`
  query GetGroupedByDateIntervalOrders($providerId: ID!, $month: Date!) {
    getGroupedByDateIntervalOrders(providerId: $providerId, month: $month) {
      date
      intervals {
        endTime
        startTime
      }
    }
  }
`;
