import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCategories } from '../../../graphql/hooks/useQueries';
import { getObjectNameAndIdFromArray } from '../../../utils/getDifferentFormatOfData';
import { StyledSelect } from '../styles';

export const SelectService = ({
  category,
  setCategory,
  selectedCategoryId,
  setModalDataArray,
  setDataFromModal,
  control,
  setValue,
  errors,
  MenuProperties,
}) => {
  const { t, i18n } = useTranslation();
  const [categoriesArray, setCategoriesArray] = useState([]);

  const { data: categories, loading: categoriesLoading } = useGetCategories({
    onCompleted: (data) => {
      const localeNames = getObjectNameAndIdFromArray(data?.getCategories, i18n);
      setCategoriesArray(localeNames);
    },
  });

  useEffect(() => {
    setModalDataArray(
      categories?.getCategories?.find((element) => element.id === selectedCategoryId),
    );
  }, [categories?.getCategories, selectedCategoryId]);

  useEffect(() => {
    if (categories) {
      const localeNames = getObjectNameAndIdFromArray(categories?.getCategories, i18n);
      setCategoriesArray(localeNames);
      setCategory(localeNames?.find((item) => item.id === selectedCategoryId)?.name);
    }
  }, [selectedCategoryId, categories, i18n.resolvedLanguage]);

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    const selectedId = categoriesArray?.find((element) => {
      return element.name === value;
    }).id;
    setValue('categoryId', selectedId);
    setModalDataArray(categories?.getCategories?.find((element) => element.id === selectedId));
    setCategory(value);
    setDataFromModal([]);
  };

  return (
    <>
      <StyledSelect
        control={control}
        name={'categoryId'}
        value={category === '' || category === undefined ? t('service') : category}
        onChange={handleCategoryChange}
        variant={'outlined'}
        displayEmpty
        MenuProps={MenuProperties}
        dataArray={!categoriesLoading && categoriesArray ? categoriesArray : []}
        selectTitle={t('service')}
        error={errors?.categoryId?.message}
        className={errors?.categoryId?.message && 'errorSelect'}
      />
    </>
  );
};
