import { Drawer, IconButton, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledH1 = styled(Typography)({
  marginTop: '10px',
  [theme.breakpoints.down('xl')]: {
    marginBottom: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: 600,
  },
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: '16px',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    paddingTop: 0,
  },
});

export const ProvidersList = styled('div')({
  maxWidth: '696px',
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    order: 1,
    maxWidth: '100%',
  },
});

export const FiltersForm = styled('form')({
  border: `1px solid ${palette.seashell}`,
  borderRadius: '18px',
  padding: '16px 16px 2px 16px',
  width: '364px',
  marginLeft: '23px',

  h5: {
    color: palette.blue,
  },

  [theme.breakpoints.down('xl')]: {
    order: 0,
    width: '100%',
    marginLeft: 0,
    padding: '30px 16px 2px 16px',
    '> hr': {
      display: 'none',
    },
  },
});

export const CategoriesList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('xl')]: {
    marginRight: '40px',
  },
});

export const FilterList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  '.MuiChip-root': {
    background: 'rgba(123, 238, 64, 0.2)',
    height: '32px',
    padding: '6px 14px 6px 20px',
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '12px',
    ':not(:last-child)': {
      marginRight: '12px',
    },
  },
  '.MuiChip-label': {
    padding: 0,
    marginRight: '6px',
  },
  '.MuiChip-deleteIcon': {
    margin: '0!important',
  },
});

export const StyledFilterButton = styled(IconButton)({
  [theme.breakpoints.up('xl')]: {
    display: 'none',
  },
  position: 'absolute',
  right: '15px',
  zIndex: 9999,
  background: '#E5FCD9',
  width: '40px',
  height: '40px',
  borderRadius: '12px',
  '&:hover': {
    background: '#E5FCD9',
  },
  '&.closeBtn': {
    background: palette.seashell,
  },
});

export const StyledDrawer = styled(Drawer)({
  '&.MuiDrawer-root, .MuiBackdrop-root, .MuiPaper-root': {
    top: '70px',
  },
  '.MuiPaper-root': {
    width: '360px',
    boxShadow: 'none',
    form: {
      borderRadius: '0',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '&.MuiDrawer-root, .MuiBackdrop-root, .MuiPaper-root': {
      top: '65px',
      bottom: 0,
      height: 'initial',
    },
    '.MuiPaper-root': {
      form: {
        border: 'none',
      },
    },
  },
  [theme.breakpoints.down('sm')]: {
    '.MuiPaper-root': {
      width: '100%',
    },
  },
});

export const StyledTopAction = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('992px')]: {
    marginTop: '6px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '8px',
  },
});
