import React from 'react';

import palette from '../theme/palette';

const EmptyImageIcon = ({ width = 65, height = 65, className, color = palette.oldLavender }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 65 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M44.466 10.1616H38.3628V30.0277C38.3628 31.2262 38.1185 32.2914 37.6298 33.2235C37.1506 34.1556 36.4693 34.888 35.586 35.4206C34.7121 35.9533 33.6831 36.2196 32.4991 36.2196C31.3245 36.2196 30.2956 35.9533 29.4123 35.4206C28.529 34.888 27.843 34.1556 27.3544 33.2235C26.8751 32.2914 26.6355 31.2262 26.6355 30.0277V10.1616H20.5322V30.5962C20.5322 32.8906 21.0303 34.8982 22.0263 36.619C23.0224 38.3399 24.4178 39.6817 26.2126 40.6445C28.0074 41.5971 30.1029 42.0734 32.4991 42.0734C34.8859 42.0734 36.9767 41.5971 38.7715 40.6445C40.5663 39.6817 41.9618 38.3399 42.9579 36.619C43.9633 34.8982 44.466 32.8906 44.466 30.5962V10.1616ZM44.4665 48.4559H20.5326V54.8382H44.4665V48.4559Z'
        fill={color}
      />
    </svg>
  );
};

export default EmptyImageIcon;
