import { gql } from '@apollo/client';

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($updateOrderId: ID!, $input: UpdateOrderInput!) {
    updateOrder(id: $updateOrderId, input: $input) {
      comment
      id
      status
      startTime
      user {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;
