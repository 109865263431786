import EmailContactIcon from '../icons/EmailContactIcon';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import LinkedinIcon from '../icons/LinkedinIcon';
import NoImgIcon from '../icons/NoImgIcon';
import PhoneContactIcon from '../icons/PhoneContactIcon';
import TelegramIcon from '../icons/TelegramIcon';
import TwitterIcon from '../icons/TwitterIcon';
import ViberIcon from '../icons/ViberIcon';
import WhatsAppIcon from '../icons/WhatsAppIcon';
import YoutubeIcon from '../icons/YoutubeIcon';

export const getIconByContactType = (type) => {
  switch (type) {
    case 'PHONE': {
      return <PhoneContactIcon />;
    }

    case 'EMAIL': {
      return <EmailContactIcon />;
    }

    case 'VIBER': {
      return <ViberIcon />;
    }

    case 'TELEGRAM': {
      return <TelegramIcon />;
    }

    case 'WHATSAPP': {
      return <WhatsAppIcon />;
    }

    case 'INSTAGRAM': {
      return <InstagramIcon />;
    }

    case 'FACEBOOK': {
      return <FacebookIcon />;
    }

    case 'YOUTUBE': {
      return <YoutubeIcon />;
    }

    case 'TWITTER': {
      return <TwitterIcon />;
    }

    case 'LINKEDIN': {
      return <LinkedinIcon />;
    }

    default: {
      return <NoImgIcon />;
    }
  }
};
