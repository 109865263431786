import { FormControlLabel } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../../theme/palette';

export const StyledFormControlLabel = styled(FormControlLabel)({
  marginRight: '19px !important',

  '.MuiCheckbox-root': {
    padding: 0,
    marginRight: '9px',

    '&.Mui-checked + span': {
      fontWeight: 600,
      color: palette.primary,
    },
  },

  '.MuiTypography-root': {
    color: palette.oldLavender,
    fontSize: '16px',
    fontWeight: 400,
  },
});
