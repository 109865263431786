import { ErrorMessage } from '@hookform/error-message';
import { Chip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AddIcon from '../../../icons/AddIcon';
import deleteIcon from '../../../images/deleteIcon.svg';
import {
  getIdFromArray,
  getObjectNameAndIdFromArray,
} from '../../../utils/getDifferentFormatOfData';
import { ServiceTypes, StyledAddButton, StyledTypesList } from '../styles';

export const SelectServiceTypes = ({
  subcategories,
  modalDataArray,
  dataFromModal,
  setDataFromModal,
  selectedCategoryId,
  setModalType,
  setIsModalOpen,
  setValue,
  errors,
}) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (dataFromModal?.length > 0) {
      setValue('subcategoryIds', getIdFromArray(dataFromModal));
    } else {
      setValue('subcategoryIds', []);
    }
  }, [dataFromModal]);

  useEffect(() => {
    const q = modalDataArray?.subcategories?.filter((selectedElement) => {
      return dataFromModal?.find((element) => {
        return element.id === selectedElement.id;
      });
    });

    setDataFromModal(getObjectNameAndIdFromArray(q || subcategories, i18n));
  }, [i18n.resolvedLanguage, subcategories]);

  const handleDelete = (chipToDelete) => {
    const filteredArray = () => {
      return dataFromModal.filter((chip) => {
        return chip.id !== chipToDelete;
      });
    };
    setDataFromModal(filteredArray || []);
  };

  return (
    <ServiceTypes>
      <Typography variant={'caption'}>{t('serviceTypes')}</Typography>
      <StyledTypesList>
        {dataFromModal?.length > 0 &&
          dataFromModal?.map(({ id, name }) => (
            <Chip
              key={id}
              deleteIcon={<img src={deleteIcon} alt={'remove'} />}
              label={name}
              onDelete={() => handleDelete(id)}
            />
          ))}
        <StyledAddButton>
          <Chip
            label={t('add')}
            icon={<AddIcon />}
            clickable
            onClick={() => {
              setModalType('chooseServiceTypes');
              setIsModalOpen(true);
            }}
            disabled={!selectedCategoryId}
            classes={{
              root: 'addChip',
            }}
          />
        </StyledAddButton>
      </StyledTypesList>
      {(dataFromModal?.length === 0 || !dataFromModal) && errors && errors.subcategoryIds && (
        <ErrorMessage
          errors={errors}
          name={'subcategoryIds'}
          render={({ message }) => <div className={'error'}>{t(message)}</div>}
        />
      )}
    </ServiceTypes>
  );
};
