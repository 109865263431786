import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../constants/constants';
import { useAuth } from '../../hooks';
import palette from '../../theme/palette';
import { loginValidationSchema } from '../../validation';
import Button from '../UI/Button';
import { ControlCheckbox } from '../UI/Checkbox';
import Divider from '../UI/Divider';
import { ControlInput } from '../UI/Input';
import EndAdornment from '../UI/Input/components/EndAdornment';
import {
  Block,
  BlockQuestion,
  ButtonText,
  CenterTypography,
  ContinueButtonWrapper,
  Form,
  StyledError,
} from './styles';

const SignIn = ({ setModalType, handleClose, closeAdaptiveMenu }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: '',
    },
  });
  const { signIn, signInLoading } = useAuth();
  const [error, setError] = useState();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async ({ email, password, rememberMe }) => {
    signIn({
      email,
      password,
      rememberMe: rememberMe.length > 0 || false,
    })
      .then(() => {
        if (!signInLoading) {
          handleClose();
          closeAdaptiveMenu('left', false)();
          navigate(ROUTES.profile);
        }
      })
      .catch((error_) => setError(error_?.message));
  };

  const openReg = () => {
    setModalType('registration');
  };

  const openForgotPasswordModal = () => {
    setModalType('forgotPassword');
  };

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CenterTypography variant={'h4'}>{t('input')}</CenterTypography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlInput
          control={control}
          name={'email'}
          errors={errors?.email?.message}
          placeholder={'Email'}
        />
        <ControlInput
          control={control}
          name={'password'}
          errors={errors?.password?.message}
          variant={'filled'}
          label={t('password')}
          type={isShowPassword ? 'text' : 'password'}
          endAdornment={
            <EndAdornment
              onClick={() => handleClickShowPassword(setIsShowPassword, isShowPassword)}
              onMouseDown={handleMouseDownPassword}
              isShow={isShowPassword}
            />
          }
        />
        <Block>
          <ControlCheckbox
            options={[t('rememberMe')]}
            control={control}
            name={'rememberMe'}
            singleCheckbox
          />
          <ButtonText
            variant={'text'}
            disableRipple
            disableFocusRipple
            onClick={openForgotPasswordModal}
          >
            <Typography variant={'caption'}>{t('remindPassword')}</Typography>
          </ButtonText>
        </Block>
        {error && <StyledError variant={'body1'}>{t(error)}</StyledError>}

        <ContinueButtonWrapper margin={'42px 0 39px'}>
          <Button
            type={'submit'}
            title={
              signInLoading ? (
                <CircularProgress
                  sx={{
                    color: palette.secondary,
                  }}
                />
              ) : (
                t('continue')
              )
            }
            margin={'0 auto'}
          />
        </ContinueButtonWrapper>
      </Form>
      <Divider margin={'0 -24px'} />
      <BlockQuestion>
        <Typography variant={'body1'} component={'span'}>
          {t("youDon'tHaveAnAccountYet")}
        </Typography>
        <ButtonText disableRipple disableFocusRipple className={'blue'} onClick={openReg}>
          {t('registration')}
        </ButtonText>
      </BlockQuestion>
    </>
  );
};

export default SignIn;
