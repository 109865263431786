import palette from '../theme/palette';

export const HideEyeIcon = ({ width = 18, height = 17, className, color = palette.primary }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 18 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.271 9.14599L10.979 7.85399C11.0343 7.32599 10.8747 6.87133 10.5 6.48999C10.1253 6.10799 9.674 5.94466 9.146 5.99999L7.854 4.70799C8.03467 4.63866 8.22233 4.58666 8.417 4.55199C8.611 4.51733 8.80533 4.49999 9 4.49999C9.972 4.49999 10.7983 4.84033 11.479 5.52099C12.1597 6.20166 12.5 7.02799 12.5 7.99999C12.5 8.19466 12.4827 8.38899 12.448 8.58299C12.4133 8.77766 12.3543 8.96533 12.271 9.14599ZM15.042 11.917L13.958 10.833C14.458 10.4443 14.913 10.0173 15.323 9.55199C15.733 9.08666 16.0767 8.56933 16.354 7.99999C15.6733 6.59733 14.67 5.49666 13.344 4.69799C12.0173 3.89933 10.5693 3.49999 9 3.49999C8.63867 3.49999 8.28467 3.52066 7.938 3.56199C7.59067 3.60399 7.25033 3.67366 6.917 3.77099L5.708 2.56199C6.236 2.35399 6.77433 2.20833 7.323 2.12499C7.87167 2.04166 8.43067 1.99999 9 1.99999C10.986 1.99999 12.802 2.53833 14.448 3.61499C16.094 4.69099 17.278 6.15266 18 7.99999C17.6947 8.79199 17.2883 9.51066 16.781 10.156C16.2743 10.802 15.6947 11.389 15.042 11.917ZM15 16.125L12.292 13.417C11.764 13.611 11.2257 13.7567 10.677 13.854C10.1283 13.9513 9.56933 14 9 14C7.014 14 5.198 13.4617 3.552 12.385C1.906 11.309 0.722 9.84733 0 7.99999C0.305333 7.20799 0.708 6.48566 1.208 5.83299C1.708 5.18033 2.29133 4.58999 2.958 4.06199L0.875 1.97899L1.938 0.916992L16.062 15.062L15 16.125ZM4.021 5.14599C3.535 5.53466 3.08367 5.96166 2.667 6.42699C2.25033 6.89233 1.91 7.41666 1.646 7.99999C2.32667 9.40266 3.33 10.5033 4.656 11.302C5.98267 12.1007 7.43067 12.5 9 12.5C9.36133 12.5 9.71533 12.4757 10.062 12.427C10.4093 12.3783 10.7567 12.3123 11.104 12.229L10.167 11.292C9.97233 11.3613 9.77767 11.4133 9.583 11.448C9.389 11.4827 9.19467 11.5 9 11.5C8.028 11.5 7.20167 11.1597 6.521 10.479C5.84033 9.79833 5.5 8.97199 5.5 7.99999C5.5 7.80533 5.52433 7.61099 5.573 7.41699C5.62167 7.22233 5.66667 7.02766 5.708 6.83299L4.021 5.14599Z'
      fill={color}
    />
  </svg>
);
