import { gql } from '@apollo/client';

export const CANCEL_ORDER = gql`
  mutation CancelOrder($cancelOrderId: ID!, $rejectionReason: String) {
    cancelOrder(id: $cancelOrderId, rejectionReason: $rejectionReason) {
      comment
      createdAt
      currencyCode
      endTime
      id
      price
      pricePer
      startTime
      status
      rejectionReason
      subservice {
        currencyCode
        translations {
          description
          languageCode
          languageId
        }
        id
        price
        pricePer
      }
      service {
        id
        translations {
          description
          languageCode
          languageId
        }
        category {
          id
          translations {
            languageCode
            name
          }
        }
        user {
          firstName
          lastName
          photo
          reviewsStatistic {
            average
            count
          }
          contacts {
            value
            type
          }
        }
      }
      user {
        id
        firstName
        lastName
        photo
      }
    }
  }
`;
