import { enUS, ru, sr } from 'date-fns/locale';

export const getLocale = (locale) => {
  switch (locale) {
    case 'ru': {
      return ru;
    }

    case 'sr': {
      return sr;
    }

    case 'en': {
      return enUS;
    }

    default: {
      return locale;
    }
  }
};
