import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from '../constants/constants';
import Layout from '../layout';
import About from '../pages/About';
import Catalog from '../pages/Catalog';
import CategoryList from '../pages/CategoryList';
import ConfirmEmail from '../pages/ConfirmEmail';
import DevelopmentPage from '../pages/DevelopmentPage';
import FAQ from '../pages/FAQ';
import Home from '../pages/Home';
import Login from '../pages/Login';
import MyService from '../pages/MyService';
import NotFound from '../pages/NotFound';
import Profile from '../pages/Profile';
import Provider from '../pages/Provider';
import ProviderServices from '../pages/ProviderServices';
import Registration from '../pages/Registration';
import SearchResults from '../pages/SearchResults';
import Service from '../pages/Service';
import TermsOfUse from '../pages/TermsOfUse';

const routes = [
  {
    path: ROUTES.home,
    element: Home,
  },
  {
    path: ROUTES.catalog,
    element: Catalog,
  },
  {
    path: ROUTES.categoryList,
    element: CategoryList,
  },
  {
    path: ROUTES.about,
    element: About,
  },
  {
    path: ROUTES.faq,
    element: FAQ,
  },
  {
    path: ROUTES.searchResults,
    element: SearchResults,
  },
  {
    path: ROUTES.profile,
    element: Profile,
  },
  {
    path: ROUTES.myService,
    element: MyService,
  },
  {
    path: ROUTES.service,
    element: Service,
  },
  {
    path: ROUTES.providerWithId,
    element: Provider,
  },
  {
    path: ROUTES.providerServicesWithId,
    element: ProviderServices,
  },
  {
    path: ROUTES.login,
    element: Login,
  },
  {
    path: ROUTES.registration,
    element: Registration,
  },
  {
    path: ROUTES.confirmEmail,
    element: ConfirmEmail,
  },
  {
    path: ROUTES.termsOfUse,
    element: TermsOfUse,
  },
  {
    path: ROUTES.devPage,
    element: DevelopmentPage,
  },
];

export const RouterWrapper = () => {
  return (
    <Layout>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectedRoute {...route} isAuth={true} />}
          />
        ))}
        <Route key={'NotFound'} path='*' element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

const ProtectedRoute = ({ element: Element, path, isAuth = true }) => {
  // исключение для страниц не требующих авторизации
  // eslint-disable-next-line no-use-before-define
  if (authRoutCheck(path)) {
    if (isAuth) return <Navigate to={ROUTES.home} />;
    return <Element />;
  }

  if (isAuth) {
    return <Element />;
  }

  return (
    <Element />
    /* <Navigate to={ROUTES.login} /> */
  );
};

ProtectedRoute.propTypes = {
  path: PropTypes.node,
  element: PropTypes.any,
  isAuth: PropTypes.bool,
};

const authRoutCheck = (path) => {
  const authRout = [ROUTES.login, ROUTES.registration];
  return authRout.includes(path);
};
