import { Chip, Menu, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledReservation = styled('div')({
  padding: '14px 0',
});

export const StyledUserInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(576)]: {
    width: '100%',
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
  },
});

export const Avatar = styled('img')({
  width: '56px',
  height: '56px',
  borderRadius: '8px',
  objectFit: 'cover',
});

export const StyledReservationInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginLeft: '8px',
});

export const CenteredTypography = styled(Typography)(({ lineHeight, color = palette.primary }) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: lineHeight ? `${lineHeight}px` : '20px',
  marginBottom: '0px',
  color,
}));

export const StyledReservationComment = styled(Typography)({
  marginTop: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  color: palette.oldLavender,
  marginLeft: '64px',
  strong: {
    color: palette.primary,
    fontSize: '14px',
  },
  p: {
    fontSize: '12px',
  },
});

export const StyledChip = styled(Chip)({
  padding: '4px 12px',
  height: '24px',
  minWidth: '118px',
  marginRight: '20px',
  '& .MuiChip-label': {
    fontSize: '12px',
    lineHeight: '16px',
    padding: 0,
  },
});

export const CenteredText = styled('span')({
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  color: palette.oldLavender,
  svg: {
    marginRight: '4px',
    marginTop: '-4px',
  },
});

export const IconWrapper = styled('span')({
  width: '20px',
  height: '20px',
  marginRight: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const StyledMenu = styled(Menu)({
  marginLeft: '20px',
  '.MuiList-root': {
    padding: 0,
  },
  '.MuiMenuItem-root': {
    paddingRight: '15px',
    paddingLeft: '10px',
  },
  a: {
    display: 'flex',
  },
});

export const StyledActionBlock = styled('div')({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(576)]: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
});

export const CommentBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
