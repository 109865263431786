import { Button as MuiButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CONTACT_TYPE } from '../../constants/constants';
import { useCreateContact } from '../../graphql/hooks/useMutations';
import { useAuth } from '../../hooks';
import palette from '../../theme/palette';
import { getMessageByContactType } from '../../utils/getMessageByContactType';
import { getRulesByContactType } from '../../utils/getRulesByContactType';
import Button from '../UI/Button';
import { ControlInput } from '../UI/Input';
import { ButtonManage, CenterTypography, StyledSelect, WarningMessage } from './styles';

const AddContactModal = ({ handleClose, modalData }) => {
  const existingTypes = modalData?.contacts.map((item) => item.type);
  const { t, i18n } = useTranslation();
  const { control, watch, setValue, trigger } = useForm({
    defaultValues: {
      contactTypeVariant: 'PHONE',
      value: '',
    },
  });
  const selectedContactType = watch('contactTypeVariant');

  const [contact, setContact] = useState(CONTACT_TYPE[i18n.resolvedLanguage][0]);
  const [createContact] = useCreateContact();
  const { setUserInfo } = useAuth();
  const handleSave = () => async () => {
    const validated = await trigger('value');
    if (validated) {
      await createContact({
        variables: {
          input: {
            type: watch('contactTypeVariant') || CONTACT_TYPE[i18n.resolvedLanguage][0]?.type,
            value: watch('value'),
          },
        },
      }).then((response) => {
        setUserInfo(response?.data?.createContact?.user);
      });
      handleClose(true);
    }
  };

  const handlePeriodChange = (event) => {
    const { value } = event.target;
    setValue('contactTypeVariant', value.type);
    setContact(value);
  };

  return (
    <>
      <form>
        <CenterTypography variant={'h4'} className={'m-0'}>
          {t('addContact')}
        </CenterTypography>
        <CenterTypography
          variant={'body1'}
          component={'div'}
          className={'fw-400'}
          margin={'24px 0 0'}
          color={palette.oldLavender}
        >
          {t('selectContactType')}
        </CenterTypography>
        <Box position={'relative'}>
          <StyledSelect
            value={contact?.name}
            selectTitle={contact?.name}
            name={'contactTypeVariant'}
            control={control}
            onChange={handlePeriodChange}
            variant={'outlined'}
            dataArray={CONTACT_TYPE[i18n.resolvedLanguage]}
            isCustomValue
          />
          {existingTypes.includes(selectedContactType) && (
            <WarningMessage className={'error'}>{t('onlyOneContactThisType')}</WarningMessage>
          )}
        </Box>
        <div>
          <CenterTypography
            variant={'body1'}
            component={'div'}
            className={'fw-400'}
            margin={'24px 0 0'}
            color={palette.oldLavender}
          >
            {getMessageByContactType(contact?.type, t)}
          </CenterTypography>

          <ControlInput
            control={control}
            name={'value'}
            inputClassName={'border'}
            type={'text'}
            margin={'24px 0'}
            value={watch('value')}
            rules={getRulesByContactType(contact?.type, t)}
          />
        </div>

        <ButtonManage>
          <MuiButton
            className={'cancel'}
            variant={'text'}
            onClick={handleClose}
            disableRipple
            disableFocusRipple
          >
            {t('cancel')}
          </MuiButton>
          <Button
            title={t('add')}
            className={'md'}
            variant={'text'}
            disableRipple
            disableFocusRipple
            onClickButton={handleSave()}
            disabled={existingTypes.includes(selectedContactType)}
          />
        </ButtonManage>
      </form>
    </>
  );
};

export default AddContactModal;
