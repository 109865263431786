import { IconButton, Popper } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledNotification = styled('div')({});

export const StyledIconButton = styled(IconButton)({
  margin: '0 20px',
  [theme.breakpoints.down('sm')]: {
    margin: 0,
  },
});

export const StyledPopper = styled(Popper)({
  background: palette.secondary,
  maxWidth: '744px',
  minWidth: '600px',
  padding: '16px',
  boxShadow: '0px 24px 48px -12px rgba(21, 21, 21, 0.18)',
  maxHeight: '430px',
  overflow: 'auto',
  zIndex: 9999,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#fff',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.primary,
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    minWidth: '100%',
  },
});

export const NotificationTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const UnreadNotifications = styled(Box)({
  position: 'absolute',
  fontSize: '10px',
  color: palette.secondary,
  fontWeight: 'bold',
});
