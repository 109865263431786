import { gql } from '@apollo/client';

export const UPDATE_PHOTO = gql`
  mutation UpdatePhoto($path: String!, $about: String, $photo: Upload) {
    updatePhoto(path: $path, about: $about, photo: $photo) {
      about
      path
    }
  }
`;
