import { Typography } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import Container from '../../components/Container';
import ModalComponent from '../../components/Modal';
import Provider from '../../components/Provider';
import Search from '../../components/Search';
import { useGetServices } from '../../graphql/hooks/useQueries';
import { useImageZoom } from '../../hooks';
import { StyledSearchResults } from './styles';

const SearchResults = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalType, setModalType] = useState('createOrder');
  const { t } = useTranslation();
  const location = useLocation();
  const { handleZoomImage } = useImageZoom(setIsModalOpen, setModalType, setModalData);

  const [parameters] = useSearchParams();

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const { data, loading } = useGetServices({
    variables: {
      search: parameters.get('search'),
    },
  });

  return (
    <>
      <StyledSearchResults>
        <Helmet>
          <title>{t('searchResultsTitle')}</title>
        </Helmet>
        <Container>
          <Typography variant='h1'>{t('searchResultsTitle')}</Typography>
        </Container>
        <Search newValue={location?.state} />
        <Container direction='column'>
          {data?.getServices?.rows?.length > 0
            ? data?.getServices?.rows?.map((service) => (
                <Provider
                  key={service?.id}
                  // ===== service response fields
                  user={service?.user}
                  inFavorites={!!service?.inFavorites}
                  id={service?.id}
                  servicePhotos={service?.servicePhotos}
                  subservices={service?.subservices}
                  price={service?.price}
                  pricePer={service?.pricePer}
                  currencyCode={service?.currencyCode}
                  enableBooking={service?.enableBooking}
                  subcategories={service?.subcategories}
                  translations={service?.translations}
                  // ===== others props
                  setModalData={setModalData}
                  setIsModalOpen={setIsModalOpen}
                  setModalType={setModalType}
                  handleZoomImage={handleZoomImage}
                />
              ))
            : !loading && (
                <Typography variant={'body2Semibold'}>{t('searchResultsEmpty')}</Typography>
              )}
        </Container>
      </StyledSearchResults>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        modalData={modalData}
      />
    </>
  );
};

export default SearchResults;
