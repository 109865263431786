import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useAuthContext } from '../../context/AuthContext';
import { loginValidationSchema } from '../../validation/index';
import { ControlInput } from '../UI/Input';

const LoginForm = () => {
  const { signIn } = useAuthContext();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = () => {
    signIn();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ControlInput control={control} name={'email'} errors={errors?.email?.message} />
      <ControlInput control={control} name={'password'} errors={errors?.password?.message} />
      <button type='submit'>Submit</button>
    </form>
  );
};

export default LoginForm;
