import { Box, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../../theme/palette';

export const StyledLanguagePicker = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const SelectWrapper = styled(Select)({
  padding: 0,
  color: palette.primary,
  fontSize: '14px',
  lineHeight: '20px',

  '&:before': {
    display: 'none',
  },

  '& .MuiSelect-select': {
    padding: 0,
  },

  '& .MuiSelect-icon': {
    top: 'unset',
  },
});

export const Option = styled(MenuItem)({
  color: palette.primary,
  fontSize: '14px',
  lineHeight: '20px',
});

export const FieldValue = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 0',
});

export const Icon = styled(Box)({
  display: 'flex',
  marginRight: '4px',
});
