import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const SIGN_UP = gql`
  ${USER}
  mutation signUp($input: UserInput) {
    signUp(input: $input) {
      ...UserFragment
    }
  }
`;
