const StarIcon = ({ width = 16, height = 15, className, color = '#F2C94C' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.062 15L4.375 9.458L0 5.729L5.75 5.229L8 0L10.25 5.25L16 5.729L11.625 9.458L12.938 15L8 12.062L3.062 15Z'
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
