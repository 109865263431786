/* eslint-disable unicorn/no-thenable */
import * as yup from 'yup';

export const registrationValidationSchema = yup.object().shape({
  surname: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_SURNAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
  name: yup
    .string()
    .nullable(true)
    .min(2, 'MIN_SURNAME_SYMBOL')
    .matches(/[A-Za-z]/, 'LATIN_ONLY'),
  email: yup
    .string('PROMPT_MAIL')
    .required('REQUIRE_MESSAGE')
    .test('contains-at-symbol', 'MISSED_@', (value) => {
      return value && value.includes('@');
    })
    .test('no-space', 'SPACE_NOT_ALLOWED', (value) => {
      return value && !value.includes(' ');
    })
    .email('PROMPT_MAIL'),
  password: yup.string('PROMPT_PASSWORD').min(8, 'MIN_SYMBOL').required('REQUIRE_MESSAGE'),
  confirmPassword: yup
    .string('PROMPT_PASSWORD')
    .oneOf([yup.ref('password'), null], 'PASSWORD_MUST_MATCH')
    .required('REQUIRE_MESSAGE'),
  isSpecialist: yup.mixed().notRequired(),
  isClient: yup.mixed().notRequired(),
  role: yup.string().test('is-chosed-role', 'CHOOSE_ROLE_MESSAGE', (_, context) => {
    const fields = context.from[0].value;
    return fields.isSpecialist || fields.isClient;
  }),
  isSecondStep: yup.boolean().required(),
  languagesList: yup.array().when(['isSecondStep'], {
    is: (value) => {
      return value;
    },
    then: yup.array().test('is-chosed-role', 'CHOOSE_LANG_MESSAGE', (value) => {
      return value?.length;
    }),
  }),
});
