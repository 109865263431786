import { styled } from '@mui/system';
import { Link } from 'react-router-dom';

import theme from '../../theme';

export const StyledLogo = styled(Link)({
  display: 'block',
  width: '153px',
  height: '28px',
  marginRight: '32px',
  '& img': {
    width: '100%',
    height: '100%',
  },

  '&.footerLogo': {
    width: '178px',
    height: '32px',
    margin: '0 0 16px 0',
  },

  [theme.breakpoints.down('lg')]: {
    '&.headerLogo': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      marginRight: 0,
    },
  },
  [theme.breakpoints.down('sm')]: {
    '&.headerLogo': {
      position: 'relative',
      left: 'unset',
      transform: 'unset',
      marginRight: 0,
    },
  },
});
