import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import BasicTabs from '../../components/UI/Tabs';
import { PROVIDER_PROFILE_TAB } from '../../constants/constants';
import TabComments from './components/TabComments';
import TabContacts from './components/TabContacts';
import TabDashboard from './components/TabDashboard';
import TabReservations from './components/TabReservations';
import TabSchedule from './components/TabSchedule';
import TabServices from './components/TabServices';

const ProviderProfile = ({
  userLocalState,
  setUserLocalState,
  setModalType,
  setIsModalOpen,
  setModalData,
}) => {
  const { t } = useTranslation();
  const [searchParameters] = useSearchParams();
  const autoScrollToServices = searchParameters.get('services') !== null;
  const servicesReference = useRef(null);
  const [tabsWithWarnings, setTabsWithWarnings] = useState([]);

  const scrollToServices = (reference) => {
    reference?.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (autoScrollToServices && servicesReference.current) {
      scrollToServices(servicesReference);
    }
  }, [autoScrollToServices, servicesReference]);

  useEffect(() => {
    if (userLocalState?.contacts?.length) {
      setTabsWithWarnings([...tabsWithWarnings].filter((it) => it.title !== 'contacts'));
    } else {
      setTabsWithWarnings([
        ...tabsWithWarnings,
        {
          title: 'contacts',
          message: t('addContacts'),
        },
      ]);
    }
  }, [userLocalState?.contacts?.length]);

  return (
    <>
      <BasicTabs
        tabsNamesArrayWithWarnings={tabsWithWarnings}
        tabs={PROVIDER_PROFILE_TAB}
        tabPanels={[
          <TabServices
            servicesReference={servicesReference}
            services={userLocalState.services || []}
            setUserLocalState={setUserLocalState}
          />,
          <TabContacts
            userLocalState={userLocalState}
            setUserLocalState={setUserLocalState}
            setModalType={setModalType}
            setIsModalOpen={setIsModalOpen}
            setModalData={setModalData}
          />,
          <TabComments userLocalState={userLocalState} />,
          <TabDashboard userLocalState={userLocalState} />,
          <TabSchedule />,
          <TabReservations />,
        ]}
        autoScrollToTab={autoScrollToServices}
      />
    </>
  );
};

export default ProviderProfile;
