import { useQuery } from '@apollo/client';

import * as QUERIES from '../../queries';

export const useGetCategories = (options) => {
  return useQuery(QUERIES.GET_CATEGORIES, options);
};

export const useGetCountries = (options) => {
  return useQuery(QUERIES.GET_COUNTRIES, options);
};

export const useGetSubcategories = (options) => {
  return useQuery(QUERIES.GET_SUBCATEGORIES, options);
};

export const useGetFaq = (options) => {
  return useQuery(QUERIES.GET_FAQS, options);
};

export const useGetReviews = (options) => {
  return useQuery(QUERIES.GET_REVIEWS, options);
};

export const useGetReviewsWritten = (options) => {
  return useQuery(QUERIES.GET_REVIEWS_WRITTEN, options);
};

export const useGetPositiveReviewsCount = (options) => {
  return useQuery(QUERIES.GET_POSITIVE_REVIEWS_COUNT, options);
};

export const useGetCurrentUser = (options) => {
  return useQuery(QUERIES.GET_CURRENT_USER, options);
};

export const useGetUserLanguages = (options) => {
  return useQuery(QUERIES.USER_LANGUAGES, options);
};

export const useGetPage = (options) => {
  return useQuery(QUERIES.GET_PAGE, options);
};

export const useGetCategorySubcategoryWithPhoto = (options) => {
  return useQuery(QUERIES.GET_CATEGORY_SUBCATEGORY_WITH_PHOTO, options);
};

export const useGetCategoryById = (options) => {
  return useQuery(QUERIES.GET_CATEGORY_BY_ID, options);
};

export const useGetCategoryBySlug = (options) => {
  return useQuery(QUERIES.GET_CATEGORY_BY_SLUG, options);
};

export const useGetServices = (options) => {
  return useQuery(QUERIES.GET_SERVICES, options);
};

export const useGetMyServices = (options) => {
  return useQuery(QUERIES.GET_MY_SERVICES, options);
};

export const useGetServicesCount = (options) => {
  return useQuery(QUERIES.GET_SERVICES_COUNT, options);
};

export const useGetService = (options) => {
  return useQuery(QUERIES.GET_SERVICE, options);
};

export const useGetLocales = (options) => {
  return useQuery(QUERIES.GET_LOCALES, options);
};

export const useGetFavouriteServices = (options) => {
  return useQuery(QUERIES.GET_FAVOURITE_SERVICES, options);
};

export const useGetWorkplaces = (options) => {
  return useQuery(QUERIES.GET_WORKPLACES, options);
};

export const useGetWorkSchedule = (options) => {
  return useQuery(QUERIES.GET_WORK_SCHEDULE, options);
};

export const useGetOrders = (options) => {
  return useQuery(QUERIES.GET_ORDERS, options);
};

export const useGetExtraWeekends = (options) => {
  return useQuery(QUERIES.GET_EXTRA_WEEKEND, options);
};

export const useGetGroupedByDateIntervalOrders = (options) => {
  return useQuery(QUERIES.GET_GROUPED_BY_DATE_INTERVAL_ORDERS, options);
};

export const useGetNonWorkingHoursByDateInterval = (options) => {
  return useQuery(QUERIES.GET_NON_WORKING_HOURS_BY_DATE_INTERVAL, options);
};

export const useGetNotifications = (options) => {
  return useQuery(QUERIES.GET_NOTIFICATIONS, options);
};

export const useGetUnreadNotifications = (options) => {
  return useQuery(QUERIES.GET_UNREAD_NOTIFICATIONS, options);
};

export const useGetRandomReviews = (options) => {
  return useQuery(QUERIES.GET_RANDOM_REVIEWS, options);
};
