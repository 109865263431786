import { styled } from '@mui/system';

import theme from '../../theme';

export const StyledContainer = styled('div')({
  display: 'flex',
  maxWidth: '1180px',
  width: '100%',
  margin: '0 auto',
  padding: '0',

  '&.column': {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('xl')]: {
    padding: '0 15px',
  },
});
