import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

import theme from '../../theme';

export const StyledCatalog = styled(Box)({
  '.searchWrapper': {
    margin: 0,
  },
  [theme.breakpoints.down('lg')]: {
    '.catalog-page': {
      marginTop: '20px',
    },
  },
});

export const StyledH1 = styled(Typography)({
  marginTop: '10px',
});
