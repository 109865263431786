import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ControlInput } from '../../../components/UI/Input';
import { CURRENCY, TIME, TIMESTAMPS } from '../../../constants/constants';
import {
  convertArrayValuesToMinutes,
  convertFormatCurrency,
  convertFormatPricePer,
  convertToMinutes,
  convertToSeconds,
} from '../../../utils/convert';
import { PriceTimeLine, SelectWrapper, StyledSelect, SymbolCounter, Wrapper } from '../styles';

const MenuProperties = {
  PaperProps: {
    style: {
      maxHeight: '280px',
    },
  },
};

export const PriceAndDescription = ({ control, watch, setValue, subservice }) => {
  const { t } = useTranslation();

  const [symbolCount, setSymbolCount] = useState({
    ru: 100,
    sr: 100,
    en: 100,
  });

  const setNameAndValue = (data, name) => {
    return subservice ? `subservices[${subservice?.elementIndexInArray}].${name}` : name;
  };

  const currencyCodeValue = t(
    convertFormatCurrency(watch(setNameAndValue(subservice?.currencyCode, 'currencyCode'))),
  );

  const pricePerValue = t(
    convertFormatPricePer(
      watch(setNameAndValue(subservice?.pricePer, 'pricePer')) ||
        (subservice ? convertToMinutes(TIMESTAMPS[1], t) : t('DAY')),
      t,
    ),
  );

  const lengthRu = subservice
    ? watch(`subservices[${subservice?.elementIndexInArray}].description`)?.ru?.length || 0
    : watch('description')?.ru?.length || 0;
  const lengthSr = subservice
    ? watch(`subservices[${subservice?.elementIndexInArray}].description`)?.sr?.length || 0
    : watch('description')?.sr?.length || 0;
  const lengthEn = subservice
    ? watch(`subservices[${subservice?.elementIndexInArray}].description`)?.en?.length || 0
    : watch('description')?.en?.length || 0;

  useEffect(() => {
    setSymbolCount({
      ru: 100 - lengthRu,
      sr: 100 - lengthSr,
      en: 100 - lengthEn,
    });
  }, [lengthRu, lengthSr, lengthEn]);

  const handleCurrencyChange = (event) => {
    const item = event.target.value;
    if (subservice) setValue(setNameAndValue(subservice?.currencyCode, 'currencyCode'), item);
    else setValue('currencyCode', t(item));
  };

  const handlePeriodChange = (event) => {
    const item = event.target.value;
    if (subservice)
      setValue(
        setNameAndValue(subservice?.pricePer, 'pricePer'),
        /\d/.test(item) ? convertToSeconds(item, t) : t(item),
      );
    else setValue('pricePer', /\d/.test(item) ? convertToSeconds(item, t) : t(item));
  };

  return (
    <Wrapper>
      <PriceTimeLine>
        <ControlInput
          control={control}
          name={setNameAndValue(subservice?.price, 'price')}
          placeholder={t('priceFrom')}
          type={'number'}
          value={watch(setNameAndValue(subservice?.price, 'price'))}
          maxLength={9}
          min={0}
        />
        <SelectWrapper>
          <StyledSelect
            value={currencyCodeValue}
            name={setNameAndValue(subservice?.currencyCode, 'currencyCode')}
            control={control}
            onChange={handleCurrencyChange}
            variant={'outlined'}
            className={'shortSelect'}
            dataArray={CURRENCY.map((currency) => t(currency))}
          />
          <StyledSelect
            value={pricePerValue}
            name={setNameAndValue(subservice?.pricePer, 'pricePer')}
            control={control}
            onChange={handlePeriodChange}
            variant={'outlined'}
            className={'shortSelect'}
            MenuProps={MenuProperties}
            dataArray={[
              ...TIME.map((time) => t(time)),
              ...convertArrayValuesToMinutes(TIMESTAMPS, t),
            ]}
          />
        </SelectWrapper>
      </PriceTimeLine>
      <Box mb={'15px'} maxWidth={'456px'}>
        <Typography component={'span'} variant={'body3'}>
          {t('profileAboutRequiredMessage')}
        </Typography>
      </Box>
      <ControlInput
        control={control}
        name={setNameAndValue(subservice?.description, 'description.ru')}
        rows={2}
        placeholder={t('serviceDescriptionRu')}
        inputClassName={'serviceDescription'}
        value={watch(setNameAndValue(subservice?.description, 'description.ru'))}
        multiline
        maxLength={100}
      />
      <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount.ru} ${t(
        'symbol',
        {
          count: symbolCount.ru,
        },
      )}`}</SymbolCounter>
      <ControlInput
        control={control}
        name={setNameAndValue(subservice?.description, 'description.sr')}
        rows={2}
        placeholder={t('serviceDescriptionSr')}
        inputClassName={'serviceDescription'}
        value={watch(setNameAndValue(subservice?.description, 'description.sr'))}
        multiline
        maxLength={100}
      />
      <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount.sr} ${t(
        'symbol',
        {
          count: symbolCount.sr,
        },
      )}`}</SymbolCounter>
      <ControlInput
        control={control}
        name={setNameAndValue(subservice?.description, 'description.en')}
        rows={2}
        placeholder={t('serviceDescriptionEn')}
        inputClassName={'serviceDescription'}
        value={watch(setNameAndValue(subservice?.description, 'description.en'))}
        multiline
        maxLength={100}
      />
      <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount.en} ${t(
        'symbol',
        {
          count: symbolCount.en,
        },
      )}`}</SymbolCounter>
    </Wrapper>
  );
};
