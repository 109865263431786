import { gql } from '@apollo/client';

export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications {
    getUnreadNotifications {
      count
      rows {
        id
        type
        order {
          id
          status
          startTime
          endTime
          comment
          service {
            user {
              id
              photo
              firstName
              lastName
              reviewsStatistic {
                average
                count
              }
            }
            id
            category {
              id
              translations {
                languageCode
                name
              }
            }
          }
          user {
            photo
            firstName
            lastName
            reviewsStatistic {
              average
              count
            }
          }
        }
        questionDescription
        questionResult
        type
        user {
          id
          photo
          lastName
          firstName
        }
      }
    }
  }
`;
