import { gql } from '@apollo/client';

export const GET_SERVICES = gql`
  query GetServices(
    $search: String
    $categorySlug: String
    $subcategorySlugs: [String]
    $userLanguageIds: [ID]
    $userId: ID
    $limit: Int
    $offset: Int
  ) {
    getServices(
      search: $search
      categorySlug: $categorySlug
      subcategorySlugs: $subcategorySlugs
      userLanguageIds: $userLanguageIds
      userId: $userId
      limit: $limit
      offset: $offset
    ) {
      count
      rows {
        id
        user {
          id
          photo
          firstName
          lastName
          reviewsStatistic {
            average
            count
          }
        }
        inFavorites
        servicePhotos {
          about
          path
        }
        subservices {
          id
          price
          pricePer
          currencyCode
          translations {
            languageCode
            description
          }
        }
        price
        pricePer
        currencyCode
        enableBooking
        subcategories {
          id
          photo
          translations {
            languageCode
            name
          }
        }
        translations {
          languageCode
          description
        }
      }
    }
  }
`;
