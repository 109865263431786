export const combineMetatags = (array) => {
  const newMetatags = {
    description: '',
    keywords: '',
    title: '',
  };

  for (const element of array) {
    newMetatags.description = newMetatags.description
      ? `${newMetatags.description}, ${element.description}`
      : `${element.description}`;
    newMetatags.keywords = newMetatags.keywords
      ? `${newMetatags.keywords}, ${element.keywords}`
      : `${element.keywords}`;
    newMetatags.title = newMetatags.title
      ? `${newMetatags.title}, ${element.title}`
      : `${element.title}`;
  }
  return newMetatags;
};
