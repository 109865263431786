import Container from '../../components/Container';
import { TYPE_MODAL } from '../../constants/constants';
import { useModalContext } from '../../context/ModalContext';

const DevelopmentPage = () => {
  const { handleChangeModalParameters } = useModalContext();

  const openTestModal = () => {
    handleChangeModalParameters([
      {
        key: 'type',
        value: TYPE_MODAL.TEST,
      },
      {
        key: 'isOpen',
        value: true,
      },
      {
        key: 'test_value',
        value: 'test_value',
      },
      {
        key: 'onCallBack',
        value: () => alert('test'),
      },
    ]);
  };

  return (
    <>
      <Container>
        DevPage
        <button onClick={openTestModal}> open modal example</button>
      </Container>
    </>
  );
};

export default DevelopmentPage;
