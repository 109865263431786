import { Box, styled } from '@mui/system';

import palette from '../../theme/palette';

export const Block = styled(Box)({
  h5: {
    marginTop: '10px',
  },
});

export const ShowMoreNotifications = styled(Box)({
  margin: '0',
  '.MuiButton-text': {
    margin: 0,
    padding: 0,
    textTransform: 'none',
    height: 'auto',
    width: 'auto',
    minWidth: 'auto',
    color: palette.primary,
    textDecoration: 'underline',
    ':hover': {
      background: 'transparent',
    },
  },
});
