import { Box, Tooltip } from '@mui/material';

import AlertIcon from '../../../icons/AlertIcon';
import { styledTooltip, titleWrap } from './styles';

const ToolTip = ({ children, title }) => {
  return (
    <Tooltip
      open={true}
      title={
        <Box sx={titleWrap}>
          <AlertIcon />
          {title}
        </Box>
      }
      placement={'top'}
      PopperProps={{
        sx: styledTooltip,
      }}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default ToolTip;
