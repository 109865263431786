import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const StyledReviews = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',

  '@media (min-width: 768px)': {
    gap: '25px',
  },

  '> div': {
    width: '376px',
  },
});

export const BlockDescription = styled('span')({
  fontSize: '16px',
  lineHeight: '22px ',
  color: palette.oldLavender,
  display: 'block',
});

export const ButtonManage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
