export const getClassNameByStatus = (type) => {
  switch (type) {
    case 'FREE': {
      return 'free';
    }

    case 'CANCELED': {
      return 'canceled';
    }

    case 'BOOKED': {
      return 'booked';
    }

    case 'CANCELED_IN_ORDER': {
      return 'canceledInOrder';
    }

    case 'NOT_AVAILABLE': {
      return 'unavailable';
    }

    case 'SELECTED': {
      return 'selected';
    }

    default: {
      return '';
    }
  }
};
