import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import palette from '../../../theme/palette';

export const CustomToolbar = (properties) => {
  const { label } = properties;
  return (
    <Box className='rbc-toolbar'>
      <Typography
        sx={{
          cursor: 'pointer',
          fontWeight: 600,
          fontSize: '18px',
          lineHeight: '1.2',
          color: palette.oldLavender,
          textTransform: 'capitalize',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
