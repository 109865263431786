import { Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocale } from '../../../utils/getLocale';
import Button from '../../UI/Button';
import { ControlInput } from '../../UI/Input';
import { CenterTypography, StyledCreateOrderFooter, SymbolCounter } from '../styles';

const CreateOrderStep3 = ({ handleChangeStep, control, watch, selectedTime: { start, end } }) => {
  const { t, i18n } = useTranslation();
  const [symbolCount, setSymbolCount] = useState(100);
  const dayOfTheWeek = format(new Date(start), 'EEEE', {
    locale: getLocale(i18n.resolvedLanguage),
  });
  const date = format(new Date(start), 'dd MMMM', {
    locale: getLocale(i18n.resolvedLanguage),
  });
  const time = `${format(new Date(start), 'HH:mm')}-${format(new Date(end), 'HH:mm')}`;

  const textLength = watch('text').length;

  useEffect(() => {
    setSymbolCount(100 - textLength);
  }, [textLength]);
  return (
    <>
      <CenterTypography margin={'0 0 20px 0'} variant={'h4'}>
        {t('bookSrvice')}
      </CenterTypography>
      <Typography variant={'body1'} display={'flex'} justifyContent={'center'} fontWeight={400}>
        {dayOfTheWeek.charAt(0).toUpperCase() + dayOfTheWeek.slice(1)}
        <>, </>
        {date}
        <>, </>
        {time}
      </Typography>
      <ControlInput
        control={control}
        name={'text'}
        rows={3}
        multiline
        placeholder={t('addComment')}
        inputClassName={'reviewTextArea sm'}
        className={'sm'}
        maxLength={100}
      />
      <SymbolCounter variant={'caption'} component={'div'}>{`${t('left')} ${symbolCount} ${t(
        'symbol',
        {
          count: symbolCount,
        },
      )}`}</SymbolCounter>
      <StyledCreateOrderFooter>
        <Button
          className={'buttonWhite sm'}
          margin={'0 10px 0 0'}
          title={t('back')}
          onClickButton={handleChangeStep(2)}
        />
        <Button
          className={'sm'}
          sx={{
            width: 'fit-content',
            padding: '0 24px !important',
            marginLeft: '10px',
          }}
          type={'submit'}
          title={t('book')}
        />
      </StyledCreateOrderFooter>
    </>
  );
};

export default CreateOrderStep3;
