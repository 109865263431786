import { ORDERS_PER_PAGE } from '../../../constants/constants';
import { StyledPagination } from './styles';

const Pagination = ({ elementsCount, onChange, ordersPerPage = ORDERS_PER_PAGE, sx }) => {
  if (elementsCount <= ordersPerPage) return null;

  return (
    <StyledPagination
      sx={sx}
      count={Math.ceil(elementsCount / ordersPerPage) || 0}
      shape='rounded'
      onChange={onChange}
    />
  );
};

export default Pagination;
