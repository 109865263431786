import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import { InputWrapper, StyledInput } from './styles';

export const InputComponent = ({
  label,
  placeholder,
  defaultValue,
  disabled,
  field,
  name,
  searchButton,
  saveButton,
  clickSaveButton,
  wrapperClassName,
  inputClassName,
  onClick,
  onChange,
  value,
  variant,
  endAdornment,
  startAdornment,
  type,
  multiline,
  rows,
  maxLength,
  error,
  margin,
  multiple,
  accept,
  min,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateCreate = () => {
    navigate(`/search-results?search=${value}`, {
      state: value,
    });
  };

  const handleKeyDown = (event) => {
    if (searchButton && event.key === 'Enter') {
      navigateCreate();
    }
  };

  return (
    <InputWrapper margin={margin} className={wrapperClassName}>
      <StyledInput
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
        {...field}
        value={value}
        error={!!error}
        helperText={t(error?.message) || ''}
        className={inputClassName}
        onClick={onClick}
        onChange={onChange || field.onChange}
        onKeyDown={handleKeyDown}
        variant={variant}
        label={label}
        type={type}
        rows={rows}
        multiline={multiline}
        inputProps={{
          maxLength,
          multiple,
          accept,
          min,
        }}
        InputProps={{
          endAdornment,
          startAdornment,
          multiline,
          multiple,
        }}
      />
      {searchButton && (
        <Button className='searchButton' onClickButton={navigateCreate} title={t('searchButton')} />
      )}
      {saveButton && (
        <Button
          onClickButton={clickSaveButton}
          className='saveButton'
          title={t('save')}
          type={'button'}
        />
      )}
    </InputWrapper>
  );
};

InputComponent.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  nativeLabel: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  errors: PropTypes.string,
};

export const ControlInput = ({ control, name, rules, errors, ...restParameters }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return <InputComponent field={field} error={error} {...restParameters} />;
      }}
    />
  );
};

ControlInput.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  errors: PropTypes.string,
};
