export const getMessageByContactType = (type, t) => {
  switch (type) {
    case 'PHONE': {
      return t('enterYourNumber');
    }

    case 'VIBER': {
      return t('enterYourNumber');
    }

    case 'WHATSAPP': {
      return t('enterYourNumber');
    }

    case 'TELEGRAM': {
      return t('enterYourNicName');
    }

    case 'INSTAGRAM': {
      return t('enterSocialLink');
    }

    case 'FACEBOOK': {
      return t('enterSocialLink');
    }

    case 'YOUTUBE': {
      return t('enterSocialLink');
    }

    case 'TWITTER': {
      return t('enterSocialLink');
    }

    case 'LINKEDIN': {
      return t('enterSocialLink');
    }

    case 'EMAIL': {
      return t('enterYourEmail');
    }

    case 'SOCIAL': {
      return t('enterYourWebsiteLink');
    }

    default: {
      return t('InputField');
    }
  }
};
