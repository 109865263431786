import { Modal } from '@mui/material';
import React from 'react';

import CloseIcon from '../../icons/CloseIcon';
import AddContactModal from './AddContactModal';
import CancelOrder from './CancelOrder';
import ChangePassword from './ChangePassword';
import ChooseLanguages from './ChooseLanguages';
import ChooseServiceTypes from './ChooseServiceTypes';
import { ConfirmAccountDeleting } from './ConfirmAccountDeleting';
import ConfirmEmail from './ConfirmEmail';
import { CreateOrder } from './createOrder/CreateOrder';
import { CreateReview } from './CreateReview';
import { CreateReviewForAdmin } from './CreateReviewForAdmin';
import { EditPhoto } from './EditPhoto';
import ForgotPassword from './ForgotPassword';
import LocationModal from './LocationModal';
import NeedLogin from './NeedLogin';
import NeedUserDetails from './NeedUserDetails';
import Registration from './Registration';
import ShowBigImage from './ShowBigImage';
import SignIn from './SignIn';
import { CloseModal, WrapperModal } from './styles';

const ModalComponent = ({
  open,
  handleClose,
  closeAdaptiveMenu,
  type,
  setModalType,
  modalDataArray,
  dataFromModal,
  setDataFromModal,
  modalData,
  refetchFunction,
  languageModalProps,
}) => {
  const renderModal = (properties) => {
    return type === 'signIn' ? (
      <SignIn
        {...properties}
        setModalType={setModalType}
        closeAdaptiveMenu={closeAdaptiveMenu}
        handleClose={handleClose}
      />
    ) : type === 'registration' ? (
      <Registration
        {...properties}
        setModalType={setModalType}
        closeAdaptiveMenu={closeAdaptiveMenu}
        handleClose={handleClose}
      />
    ) : type === 'changePassword' ? (
      <ChangePassword {...properties} handleClose={handleClose} />
    ) : type === 'deleteAccount' ? (
      <ConfirmAccountDeleting {...properties} handleClose={handleClose} />
    ) : type === 'createReview' ? (
      <CreateReview
        {...properties}
        handleClose={handleClose}
        setModalType={setModalType}
        modalData={modalData}
        refetchFunction={refetchFunction}
      />
    ) : type === 'createReviewForAdmin' ? (
      <CreateReviewForAdmin
        {...properties}
        handleClose={handleClose}
        setModalType={setModalType}
        modalData={modalData}
      />
    ) : type === 'createOrder' ? (
      <CreateOrder {...properties} handleClose={handleClose} modalData={modalData} />
    ) : type === 'cancelOrder' ? (
      <CancelOrder {...properties} handleClose={handleClose} modalData={modalData} />
    ) : type === 'editPhoto' ? (
      <EditPhoto {...properties} handleClose={handleClose} modalData={modalData} />
    ) : type === 'chooseServiceTypes' ? (
      <ChooseServiceTypes
        {...properties}
        modalDataArray={modalDataArray}
        dataFromModal={dataFromModal}
        handleClose={handleClose}
        setDataFromModal={setDataFromModal}
      />
    ) : type === 'chooseLanguages' ? (
      <ChooseLanguages
        {...properties}
        handleClose={handleClose}
        modalDataArray={modalDataArray}
        languageModalProps={languageModalProps}
      />
    ) : type === 'location' ? (
      <LocationModal {...properties} setModalType={setModalType} handleClose={handleClose} />
    ) : type === 'needLogin' ? (
      <NeedLogin {...properties} setModalType={setModalType} handleClose={handleClose} />
    ) : type === 'needUserDetails' ? (
      <NeedUserDetails {...properties} setModalType={setModalType} handleClose={handleClose} />
    ) : type === 'addContactModal' ? (
      <AddContactModal
        {...properties}
        setModalType={setModalType}
        handleClose={handleClose}
        modalData={modalData}
      />
    ) : type === 'confirmEmail' ? (
      <ConfirmEmail {...properties} handleClose={handleClose} />
    ) : type === 'forgotPassword' ? (
      <ForgotPassword {...properties} handleClose={handleClose} />
    ) : type === 'showBigImage' ? (
      <ShowBigImage {...properties} open={open} modalData={modalData} />
    ) : (
      ''
    );
  };

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition={true}>
      <WrapperModal className={modalData?.imageUrl && 'zoomedImage'}>
        <CloseModal onClick={handleClose} disableRipple>
          <CloseIcon />
        </CloseModal>
        {renderModal()}
      </WrapperModal>
    </Modal>
  );
};

export default ModalComponent;
