import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HOST_IMAGE_URL, ROUTES } from '../../constants/constants';
import EmptyStarIcon from '../../icons/EmptyStarIcon';
import StarIcon from '../../icons/StarIcon';
import palette from '../../theme/palette';
import { getNameFromTranslations } from '../../utils/getDifferentFormatOfData';
import DefaultImage from '../DefaultImage';
import ReadMore from '../ReadMore';
import Dot from '../UI/Dot';
import {
  Avatar,
  CenteredText,
  CenteredTypography,
  CommenterInfo,
  CommentUserInfo,
  MiddleBlock,
  StyledComment,
  StyledRating,
} from './styles';

const Comment = ({ comment, type = 'withAuthorPhoto', className }) => {
  const { author, user, rating, text, createdAt } = comment;

  const { t, i18n } = useTranslation();

  return (
    <StyledComment>
      <CommentUserInfo>
        {type === 'withAuthorPhoto' ? (
          <>
            {author?.photo ? (
              <Avatar src={`${HOST_IMAGE_URL}/${author?.photo}`} alt={'avatar'} />
            ) : (
              <DefaultImage iconSize={'40px'} />
            )}
            <CommenterInfo>
              <Typography variant={'body2Semibold'} component={'div'}>
                {`${author?.firstName} ${author?.lastName}`}
              </Typography>
            </CommenterInfo>
          </>
        ) : (
          <>
            {user?.photo ? (
              <Link to={`${ROUTES.providerServices}/${user?.id}?reviews`}>
                <Avatar src={`${HOST_IMAGE_URL}/${user?.photo}`} alt={'avatar'} />
              </Link>
            ) : (
              <DefaultImage iconSize={'40px'} />
            )}
            <CommenterInfo className={className}>
              <Link to={`${ROUTES.providerServices}/${user?.id}`}>
                <Typography variant={'body2Semibold'} component={'div'}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Typography>
              </Link>
              <CenteredTypography variant={'caption'} component={'div'}>
                <CenteredText>
                  <EmptyStarIcon />
                  <span>{user?.reviewsStatistic?.average?.toFixed(1)}</span>
                </CenteredText>
                <Dot />
                <Link to={`${ROUTES.providerServices}/${user?.id}?reviews`}>
                  <CenteredText>
                    {user?.reviewsStatistic?.count}{' '}
                    {t('endingOfComments', {
                      count: user?.reviewsStatistic?.count,
                    })}
                  </CenteredText>
                </Link>
              </CenteredTypography>
            </CommenterInfo>
          </>
        )}
      </CommentUserInfo>
      <MiddleBlock className={className}>
        <Box>
          <Typography variant={'body2'} color={palette.oldLavender}>
            {`${author.firstName} ${t('leftReview')} ${t('endingOfComments_one')}`}
          </Typography>
        </Box>
        <StyledRating
          name='read-only'
          value={rating}
          readOnly
          icon={<StarIcon />}
          emptyIcon={<EmptyStarIcon />}
          precision={0.1}
        />
        <Typography variant={'body2'} component={'div'} color={palette.oldLavender}>
          <ReadMore>{text}</ReadMore>
        </Typography>
      </MiddleBlock>
      <CenteredTypography variant={'caption'} component={'span'} lineHeight={16}>
        <span>{new Date(createdAt).toLocaleDateString()}</span>
      </CenteredTypography>
      <CenteredTypography variant={'caption'} lineHeight={16}>
        {type !== 'withAuthorPhoto' &&
          getNameFromTranslations(user?.service?.category?.translations, i18n)}
      </CenteredTypography>
    </StyledComment>
  );
};

export default Comment;
