import palette from '../theme/palette';

const EditIcon = ({ width = '20', height = '20', className, color = palette.oldLavender }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.25 15.75H5.479L12.479 8.75L11.25 7.521L4.25 14.521V15.75ZM16.188 7.542L12.458 3.812L13.479 2.792C13.8263 2.44466 14.2397 2.271 14.719 2.271C15.1983 2.271 15.6113 2.44466 15.958 2.792L17.208 4.042C17.5413 4.37533 17.708 4.78833 17.708 5.281C17.708 5.77433 17.5413 6.18766 17.208 6.521L16.188 7.542ZM14.958 8.771L6.229 17.5H2.5V13.771L11.229 5.042L14.958 8.771ZM11.875 8.146L11.25 7.521L12.479 8.75L11.875 8.146Z'
      fill={color}
    />
  </svg>
);

export default EditIcon;
