import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const currentLanguage = localStorage.getItem('switch') || 'sr';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: currentLanguage,
    fallbackLng: ['sr'],
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
  });

export * as i18n from 'i18next';
