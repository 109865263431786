import React from 'react';

import CommentsList from '../../../components/CommentsList';

export const TabComments = ({ reviews, reviewsLoading, reviewsReference, handleOpenModal }) => {
  return (
    <CommentsList
      loading={reviewsLoading}
      scrollToReference={reviewsReference}
      reviews={reviews}
      openCreateComment={handleOpenModal}
      createNewComment={true}
    />
  );
};
