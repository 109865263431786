import { CircularProgress } from '@mui/material';
import React from 'react';

import palette from '../../../theme/palette';

const Loader = ({ color, size = 40 }) => {
  return (
    <CircularProgress
      sx={{
        color: color || palette.primary,
      }}
      size={size}
    />
  );
};

export default Loader;
