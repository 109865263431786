import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MessageToUnregisteredUser = ({ nameOfUnavailableBlock, margin = 0 }) => {
  const { t } = useTranslation();
  return (
    <Typography margin={margin} variant={'body1'} component={'div'}>{`${t(
      nameOfUnavailableBlock,
    )} ${t('availableOnlyForRegisteredUsers')}`}</Typography>
  );
};

export default MessageToUnregisteredUser;
