import { gql } from '@apollo/client';

export const GET_FAQS = gql`
  query GetFaqs {
    getFaqs {
      id
      translations {
        answer
        languageCode
        question
      }
    }
  }
`;
