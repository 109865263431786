import { gql } from '@apollo/client';

export const CATEGORY = gql`
  fragment CategoryFragment on Category {
    id
    photo
    slug
    translations {
      languageCode
      name
      shortName
    }
    subcategories {
      id
      categoryId
      photo
      slug
      order
      isOnline
      parentCategorySlug
      translations {
        languageCode
        name
      }
    }
    serviceCount
    order
    metatags {
      title
      description
      keywords
      languageCode
    }
  }
`;
