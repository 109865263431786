import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders($periodType: OrderPeriod, $periodValue: Date, $limit: Int, $offset: Int) {
    getOrders(periodType: $periodType, periodValue: $periodValue, limit: $limit, offset: $offset) {
      count
      rows {
        comment
        createdAt
        currencyCode
        endTime
        id
        price
        pricePer
        startTime
        status
        rejectionReason
        subservice {
          currencyCode
          translations {
            description
            languageCode
            languageId
          }
          id
          price
          pricePer
        }
        service {
          id
          translations {
            description
            languageCode
            languageId
          }
          category {
            id
            translations {
              languageCode
              name
            }
          }
          user {
            firstName
            lastName
            photo
            reviewsStatistic {
              average
              count
            }
            contacts {
              value
              type
            }
          }
        }
        user {
          id
          firstName
          lastName
          photo
        }
      }
    }
  }
`;
