import { gql } from '@apollo/client';

import { USER } from '../fragments';

export const CREATE_CONTACT = gql`
  ${USER}
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      id
      type
      user {
        ...UserFragment
      }
      value
    }
  }
`;
