import { useTranslation } from 'react-i18next';

import { StyledButton } from './styles';

const Button = ({
  title,
  margin,
  onClickButton,
  className,
  startIcon,
  type,
  disabled,
  ...restParameters
}) => {
  const { t } = useTranslation();

  return (
    <StyledButton
      {...restParameters}
      style={{
        margin: margin || 0,
      }}
      variant={'contained'}
      onClick={onClickButton}
      className={className}
      startIcon={startIcon}
      type={type || 'button'}
      disabled={disabled}
    >
      {typeof title === 'string' ? t(title) || t('leaveFeedback') : title}
    </StyledButton>
  );
};

export default Button;
