import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Container from '../../components/Container';
import ModalComponent from '../../components/Modal';
import Provider from '../../components/Provider';
import Breadcrumbs from '../../components/UI/Breadcrumbs';
import Loader from '../../components/UI/Loader';
import { useGetServices } from '../../graphql/hooks/useQueries';
import { useImageZoom } from '../../hooks';
import { ProvidersList, StyledH1, StyledTopAction } from './styles';

const ProviderServices = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { t } = useTranslation();
  const { data: servicesData, loading: servicesLoading } = useGetServices({
    variables: {
      userId,
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [modalType, setModalType] = useState('createOrder');

  const { handleZoomImage } = useImageZoom(setIsModalOpen, setModalType, setModalData);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  return (
    <Container direction='column'>
      <StyledTopAction onClick={() => navigate(-1)}>
        <Breadcrumbs title={'back'} />
      </StyledTopAction>
      <StyledH1 variant='h1'>{t('allMasterServices')}</StyledH1>
      <ProvidersList>
        {servicesLoading ? (
          <Loader />
        ) : (
          servicesData?.getServices?.rows?.map((service) => (
            <Provider
              key={service?.id}
              // ===== service response fields
              user={service?.user}
              inFavorites={!!service?.inFavorites}
              id={service?.id}
              servicePhotos={service?.servicePhotos}
              subservices={service?.subservices}
              price={service?.price}
              pricePer={service?.pricePer}
              currencyCode={service?.currencyCode}
              enableBooking={service?.enableBooking}
              subcategories={service?.subcategories}
              translations={service?.translations}
              // ===== others props
              setModalData={setModalData}
              setIsModalOpen={setIsModalOpen}
              setModalType={setModalType}
              handleZoomImage={handleZoomImage}
            />
          ))
        )}
      </ProvidersList>
      <ModalComponent
        type={modalType}
        open={isModalOpen}
        handleClose={handleCloseModal}
        modalData={modalData}
      />
    </Container>
  );
};

export default ProviderServices;
