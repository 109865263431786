import React from 'react';

import palette from '../theme/palette';

const EmptyStarIcon = ({ width = 20, height = 20, className, color = palette.oldLavender }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.333 14.896L10 13.312L12.688 14.896L11.979 11.896L14.292 9.917L11.229 9.646L10 6.792L8.771 9.646L5.708 9.917L8.042 11.896L7.333 14.896ZM5.062 18L6.375 12.458L2 8.729L7.75 8.229L10 3L12.25 8.25L18 8.729L13.625 12.458L14.938 18L10 15.062L5.062 18Z'
        fill={color}
      />
    </svg>
  );
};

export default EmptyStarIcon;
