import { Box } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const StyledWrap = styled(Box)({
  borderRadius: '14px',
  with: '100%',
  maxWidth: '440px',
  minWidth: '320px',

  '& .rbc-time-header': {
    display: 'none',
  },
  '& .rbc-time-content': {
    borderTop: 'none',
    borderBottom: 'none',
  },
  '& .rbc-time-view': {
    borderColor: palette.seashell,
  },
  '& .rbc-timeslot-group': {
    borderColor: palette.seashell,
    borderBottom: 'none',
  },
  '& .rbc-timeslot-group:not(:first-of-type)': {
    borderBottom: 'none',
    borderTop: '1px solid',
    borderColor: palette.seashell,
  },
  '& .rbc-time-content > * + * > *': {
    borderColor: palette.seashell,
  },
  '& .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot:nth-of-type(even)': {
    display: 'none',
  },
  '& .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot:nth-of-type(odd)': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .rbc-time-gutter .rbc-timeslot-group .rbc-label': {
    fontSize: '10px',
    color: palette.oldLavender,
  },
  '& .rbc-day-slot .rbc-events-container': {
    margin: 0,
  },
  '& .rbc-day-slot .rbc-event': {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  '& .rbc-day-slot .rbc-event-label,.rbc-day-slot .rbc-event-content': {
    fontSize: '10px',
    lineHeight: 1,
    maxHeight: '100%',
    height: '100%',
    width: 'fit-content',
    maxWidth: 'fit-content',
    display: 'flex',
    alignItems: 'center',
  },

  '& .rbc-event': {
    border: '1px solid',
    borderColor: palette.primary,
    borderRadius: '10px',
    color: palette.primary,
    background: palette.secondary,
    textTransform: 'lowercase',
  },
  '& .rbc-event.rbc-selected, .rbc-event.selected': {
    borderColor: palette.selected,
    background: `${palette.selected} !important`,
    color: palette.secondary,
    pointerEvents: 'none',
  },
  '& .rbc-event.free': {
    color: palette.secondary,
    background: palette.mainGradient,
    borderColor: palette.secondary,
  },
  '& .rbc-event.canceled': {
    color: palette.secondary,
    background: palette.oldLavender,
    fontWeight: '500',
    borderColor: palette.secondary,
    opacity: 0.7,
  },
  '& .rbc-event.booked': {
    color: palette.secondary,
    background: palette.blue,
    fontWeight: '500',
    borderColor: palette.secondary,
    pointerEvents: 'none',
  },
  '& .rbc-event.canceledInOrder': {
    color: palette.secondary,
    background: palette.oldLavender,
    fontWeight: '500',
    borderColor: palette.secondary,
    opacity: 0.5,
    pointerEvents: 'none',
  },
  '& .rbc-event.unavailable': {
    color: palette.secondary,
    background: palette.oldLavender,
    borderColor: palette.secondary,
    opacity: 0.7,
    pointerEvents: 'none',
  },
  '.rbc-today .rbc-event-continues-earlier': {
    display: 'none',
  },
});
